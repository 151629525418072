/** Libraries */
import React from 'react'

interface iCard {
  shadow?: boolean
  styles?: string
  children: React.ReactNode
  border?: boolean
  onClick?: () => void
}

const Card = React.forwardRef<HTMLDivElement, Partial<iCard>>(
  ({shadow, styles = '', children, ...rest}, ref) => {
    const cardProps = () => {
      if (shadow) return 'shadow-2'
      return ''
    }
    return (
      <div
        className={`border border-gray-10 rounded-6 p-20 ${cardProps()} ${styles}`}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    )
  },
)

export default Card
