import {iOperationData} from 'Constants/Interfaces/iGlobal'
import {OperationType} from './useOperationsPolling'

export interface appListInterface {
  [x: string]: any
  id: number
  name: string
  created: string
  app_fqdn: string
  cname: string | null
  region: string
  app_stats: {
    disk_space?: number
    bandwidth?: number
    visits?: number
  }
  app_type: {
    active?: boolean
    id?: number
    name?: string
    name_backend?: string
  }
  operation: iOperationData
}
export interface iListGridItem {
  app: appListInterface
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checkbox?: boolean
  isPolling?: boolean
  deleteAppHandler?: () => void
  cloneAppHandler?: () => void
  stageAppHandler?: () => void
  exceptionType?: string
  showAppCard?: object
  setShowAppCard?: React.Dispatch<object>
}
export interface iAppHeader {
  headerSelection?: (e: {
    target: {
      checked: boolean
    }
  }) => void
  setShowAppCard?: React.Dispatch<iShowCard>
  sortText?: string
  sortDateHandler?: (value: string) => void
  viewHandler?: () => void
  view?: boolean
  headerCheck?: boolean
  headerDeselectAll?: () => void
  totalApps: number
  selectedApp: number
  multiDeleteHandler?: () => void
}
export interface AppListTrackingObj {
  event_name: string
  attributes: {
    view?: string
    page?: string
    current_url?: string
  }
}
export interface iShowCard {
  [key: string]: boolean
}
export const headerIntermediateChecker = (
  selectedApp: number,
  totalApps: number,
) => {
  if (selectedApp !== 0 && selectedApp < totalApps) {
    return true
  } else {
    return false
  }
}
export interface iAppDetailCard {
  handler: () => void
  isPolling?: boolean
  app?: appListInterface
  operationData?: iOperationData
}
export const HeaderChecker = (
  headerChecked: boolean,
  selectedApp: number,
  setCheckbox: React.Dispatch<React.SetStateAction<boolean>>,
  checkbox: boolean,
  isPolling: boolean,
  exceptionType: string,
) => {
  if (headerChecked && !isPolling && !exceptionType) {
    !checkbox && setCheckbox(true)
    return
  }
  if (!headerChecked && selectedApp === 0 && !isPolling && !exceptionType) {
    checkbox && setCheckbox(false)
    return
  }
}
