const Separator = ({
  w = '100%',
  h = '1px',
  top = '',
  bottom = '',
  right = '',
  left = '',
  styles = '',
}: any) => {
  return (
    <div
      style={{width: w, height: h}}
      className={`bg-[#C1C7D0] ${styles}`}
    ></div>
  )
}

export default Separator
