/** Assets (CSS/Images) */
import './Tooltip.css'
import {Tooltip as AntdTooltip} from 'antd'
import React, {useEffect, useRef} from 'react'
export const Tooltip = ({
  children,
  bottom,
  right,
  top,
  left,
  leftBottom,
  text = '',
  styles = '',
  hide = false,
}: any) => {
  const Bottom = bottom ? 'bottom' : ''
  const Left = left ? 'left' : ''
  const Right = right ? 'right' : ''
  const Top = top ? 'top' : ''
  const LeftBottom = leftBottom ? 'bottomLeft' : ''
  if (hide)
    return (
      <>
        {React.Children.map(children, (child: any) =>
          React.cloneElement(child, {...child.props}),
        )}
      </>
    )
  return (
    <AntdTooltip
      placement={Left || Right || Top || Bottom || LeftBottom || 'bottom'}
      title={text}
      zIndex={9999}
      className={`${styles}`}
      overlayInnerStyle={{borderRadius: 4, backgroundColor: '#253858'}}
    >
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {...child.props}),
      )}
    </AntdTooltip>
  )
}
Tooltip.defaultProps = {
  bottom: false,
  top: false,
  right: false,
  left: false,
}
