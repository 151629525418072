/** Assets (CSS/Images) */
import './Toggle.css'
const Toggle = ({disabled, styles = '', index = 1, ...rest}: any) => {
  const Disabled = disabled ? 'opacity-[50%]' : ''
  return (
    <label
      htmlFor={`toggle-example-${index}`}
      className={`flex items-center cursor-pointer relative mb-4 ${styles} `}
    >
      <input
        type="checkbox"
        id={`toggle-example-${index}`}
        {...rest}
        className="sr-only"
        disabled={disabled}
      />
      <div
        className={`toggle-bg bg-gray-40 border-2 border-gray-40 h-24 w-[44px] rounded-full  ${Disabled}`}
      ></div>
    </label>
  )
}
export default Toggle
