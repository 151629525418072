/** Libraries */
import React, {SetStateAction, useRef, useState} from 'react'

/** Reusable Components */
import {
  Container,
  Row,
  Icon,
  Text,
  Col,
  Button,
  Card,
} from 'Common/Components/Core/DesignTemplates'
import RestoreDataModal from './RestoreDataModal'

/** Assets (CSS/Images) */
import {ReactComponent as AllfilesICon} from '../../../../../../../Assets/img/manage-app/workflow/all-files.svg'
import {ReactComponent as OnlydatabaseIcon} from '../../../../../../../Assets/img/manage-app/workflow/only-database.svg'
import {ReactComponent as OnlyfilesIcon} from '../../../../../../../Assets/img/manage-app/workflow/only-file.svg'
import {useGetRestorePointsQuery} from 'app/services'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {handleInputChange} from 'react-select/dist/declarations/src/utils'
import Item from 'antd/lib/list/Item'
import Skeleton from 'react-loading-skeleton'
import {iRestorePoints} from '../util'
// import {ReactComponent as BackupIcon} from '../../../../../../Assets/img/manage-app/workflow/backup.svg'

enum BackupTypes {
  AUTO = 'auto',
  MANUAL = 'manual',
}

const backupObj = {
  [BackupTypes.AUTO]: 'Automated backup by system',
  [BackupTypes.MANUAL]: 'On-demand backup taken by user',
}

const RestoreLogs = ({
  // logsList = [],
  styles = '',
  restoreData: pointsData,
}: {
  // logsList: Logslist[]
  styles?: string
  restoreData: iRestorePoints[]
}) => {
  const {app_id} = useParams()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [restoreData, setRestoreData] = useState([])

  return (
    <>
      <Container styles="cwpf_restorelog_mainBox min-h-[48px] my-16">
        {pointsData?.map((item: any, key: any) => {
          return (
            <>
              <Container styles="cwpf_restorelog_inrBox">
                <Icon styles="cwpf_restorelogDtl_point"></Icon>
                <Row
                  between
                  middle
                  styles="cwpf_restorelogs_box pl-28 py-6 hover:bg-gray-3"
                >
                  <Col key={key}>
                    <Text styles="p3 text-gray-80 text-left">
                      {`${moment(item?.date).format('DD MMMM, YYYY,')} ${
                        item?.time
                      } UTC`}
                    </Text>
                    <Text type="strong" styles="p3">
                      {item?.status === BackupTypes.AUTO
                        ? backupObj[BackupTypes.AUTO]
                        : backupObj[BackupTypes.MANUAL]}
                    </Text>
                  </Col>
                  <Col styles="cwpf_restore_backuprestore">
                    <Button
                      small
                      neutralText
                      icon="settings_backup_restore"
                      iconAlign="left"
                      styles="whitespace-nowrap p3 text-gray-80 mr-32"
                      onClick={(e: any) => {
                        setShowModal(true)
                        setRestoreData(item)
                      }}
                    >
                      Restore
                    </Button>
                  </Col>
                </Row>
              </Container>
            </>
          )
        })}
      </Container>
      {showModal && (
        <RestoreDataModal
          id={1}
          restoreData={restoreData}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default RestoreLogs
