/** Reusable Components */
import {Col, Row, Text} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {
  abbreviateNumber,
  niceBytes,
  niceBytesDiskspace,
} from 'Common/Components/Core/Modal/util'
import {useEffect, useState} from 'react'
import {appListInterface} from '../util'

const AppStats = ({
  app,
  color,
  gutter = 40,
}: {
  color: string
  gutter?: number
  app: appListInterface
}): JSX.Element => {
  const mathRandom = (n: number): number => Math.round(Math.random() * n)

  return (
    <Row flex middle end gutter={gutter} styles="max-lg:my-20">
      <Col>
        <Row col styles="lg:flex max-lg:flex-row max-lg:justify-start">
          <Col>
            <Text
              styles={`p3 sm:text-10 ${color} ${
                app?.app_stats?.disk_space === 0 ? 'text-gray-10' : ''
              } whitespace-nowrap`}
            >
              Disk Usage
            </Text>
            {/* </Col>
          <Col> */}
            <Text
              type="h5"
              styles={`h5-med ${color} ${
                app?.app_stats?.disk_space === 0 ? 'text-gray-10' : ''
              }`}
            >
              {app?.app_stats?.disk_space === 0
                ? '-'
                : niceBytesDiskspace(app?.app_stats?.disk_space || 0)}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row col start styles="lg:flex ">
          <Col>
            <Text
              styles={`p3 ${color} ${
                app?.app_stats?.visits === 0 ? 'text-gray-10' : ''
              }`}
            >
              Visits
            </Text>
          </Col>
          <Col>
            <Text
              type="h5"
              styles={`h5-med ${color} ${
                app?.app_stats?.visits === 0 ? 'text-gray-10' : ''
              }`}
            >
              {app?.app_stats?.visits === 0
                ? '-'
                : abbreviateNumber(app?.app_stats?.visits || 0)}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row col start styles="lg:flex ">
          <Col>
            <Text
              styles={`p3 ${color} ${
                app?.app_stats?.bandwidth === 0 ? 'text-gray-10' : ''
              }`}
            >
              Bandwidth
            </Text>
          </Col>
          <Col>
            <Text
              type="h5"
              styles={`h5-med ${color} ${
                app?.app_stats?.bandwidth === 0 ? 'text-gray-10' : ''
              }`}
            >
              {app?.app_stats?.bandwidth === 0
                ? '-'
                : niceBytes(app?.app_stats?.bandwidth || 0, 0, true, 2)}
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AppStats
