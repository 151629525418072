/** Libraries */
import moment from 'moment'

/** Reusable Components */
import {
  Button,
  Col,
  Icon,
  Row,
  Text,
  Container,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {iListGridItem} from '../util'

/** Assets (CSS/Images) */
import wordpressLogo from '../../../../../Assets/img/application-listing/wp-logo.svg'
import woocommerceLogo from '../../../../../Assets/img/application-listing/woo-logo.svg'

const AppPolling = ({
  onChange,
  checkbox,
  app,
  setShowAppCard,
}: iListGridItem): JSX.Element => {
  const {name, created, app_type, region} = app
  const createDate = moment(created).format('Do MMMM, YYYY')

  return (
    <>
      <Row
        middle
        styles={`cwpf_appView_listBox mb-24 relative cwpf_appView_polling`}
      >
        <Col styles="flex-1 cwpf_appView_listBox_install">
          <Row middle styles="cwpf_listingItemPolling_mainRow">
            <Col>
              <Container styles="cwpf_userApp_screenShot cwpf_listingItemPolling_spinner">
                <div className="cwpf_appinstall_spinner"></div>
              </Container>
            </Col>
            <Col styles="flex-1">
              <Row
                middle
                between
                styles="lg:pr-20 w-full max-lg:text-center max-lg:p-20 gap-20"
              >
                <div className={`flex flex-col lg:justify-start`}>
                  <div className="flex items-center space-x-8 max-lg:justify-center">
                    <Text semi type="h5" styles="text-gray-80">
                      {name}
                    </Text>
                    <img
                      className={`mr-4 cwpf_app_type_img cwpf_app_type_${app_type.name}`}
                      src={app_type.id === 2 ? wordpressLogo : woocommerceLogo}
                      alt={app_type.name}
                    ></img>
                  </div>
                  <span className="text-12 text-gray-60 my-4">
                    Created: {createDate}
                  </span>

                  <div className="flex items-center max-lg:justify-center">
                    <div
                      className="flex items-center mr-10 tooltip ml-[-5px]"
                      data-tip={region}
                    >
                      <Icon className="material-icons text-gray-80">place</Icon>
                      <Text styles="p3 cwpf_listingItemPolling_region">
                        {region}
                      </Text>
                    </div>
                  </div>
                </div>
                <Row middle styles="mr-30">
                  <Col>
                    <Text styles="p2">
                      Your Application will be ready in approx{' '}
                      <Text type="b">1 Minute</Text> .
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      small
                      primaryText
                      styles="px-0 p2 ml-4 hidden"
                      onClick={setShowAppCard}
                    >
                      Click here to learn more
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AppPolling
