import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Formik, Form} from 'formik'
import {useTracking} from 'react-tracking'
import * as Yup from 'yup'

//design system
import {
  Button,
  Col,
  Container,
  Row,
  Text,
  TextField,
} from 'Common/Components/Core/DesignTemplates'
import {useParams, useOutletContext} from 'react-router-dom'

//Apis
import {apiSlice, useUpdateSingleApplicationMutation} from 'app/services'

//Custom Toast
import {customToast} from 'Common/Components/Core/CustomizedToast'

//Regex
import Regex from 'Constants/Regex'

//components
import CloudfareFeature from './Cloudfare/CloudfareFeature'
import CloudfareVerify from './Cloudfare/CloudfareVerify'

//modals
import DeleteCloudfareModal from './Cloudfare/Modal/DeleteCloudfareModal'
import {MANAGE_ACCESS_SUB_TABS} from '../util'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {domainStatus, iDomainInitialValues} from './utils'
import {useAppDispatch} from 'app/hooks'
import TxtRecordModal from './Cloudfare/Modal/TxtRecordModal'
import TxtOneRecordModal from './Cloudfare/Modal/TxtOneRecordModal'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'

interface iDomainTrackingObj {
  event_name?: string
  attributes?: {
    sub_tab?: MANAGE_ACCESS_SUB_TABS.DOMAIN_MANAGEMENT
    is_success?: boolean
    domain?: string
  }
}
const validate = Yup.object({
  domain: Yup.string()
    .required('Please enter valid domain name')
    .matches(Regex.domainName_regx, 'Please enter valid domain name'),
})
export default function DomainContainer() {
  const dispatch = useAppDispatch()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [txtRecordModal, setTxtRecordModal] = useState(false)
  const formikRef = useRef() as any
  const {app_id} = useParams()
  const propsOutlet: iPropsOutlet = useOutletContext()
  const domain = propsOutlet.applicationData?.custom_domain
  const {Track, trackEvent} = useTracking<iDomainTrackingObj>({
    attributes: {
      sub_tab: MANAGE_ACCESS_SUB_TABS.DOMAIN_MANAGEMENT,
    },
  })

  const [updateAppParams, {isLoading: isDomainLoading}] =
    useUpdateSingleApplicationMutation()

  const getActionsMessage = (type: string, id?: string) => {
    if (type === 'delete') {
      setDeleteModal(false)
      customToast({
        level: !id ? 'success' : 'info',
        title: '',
        description: id
          ? 'Domain is being removed. You wont be able to perform certain actions while the operation is taking place'
          : 'Domain has been removed.',
        loading: !!id,
      })
      id &&
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: id,
          }),
        )
    }
    if (type === 'add') {
      // customToast({
      //   level: 'success',
      //   title: '',
      //   description: 'Domain has been added.',
      // })
      setTxtRecordModal(true)
      formikRef?.current?.setFieldValue('domain', '')
      formikRef?.current?.setSubmitting(false)
    }
  }
  const updateDomainFunc = (domain: string, type: string) => {
    updateAppParams({
      app_id: app_id,
      subPath: 'domain',
      body: {
        domain: domain,
      },
    })
      .unwrap()
      .then((fulfilled: any) => {
        if (fulfilled.error) throw new Error()
        trackEvent({
          event_name: `fmui_${type}_domain`,
          attributes: {
            domain,
            is_success: true,
          },
        })
        dispatch(
          apiSlice.util.updateQueryData(
            'getSingleApplication',
            app_id,
            draft => {
              draft.custom_domain = fulfilled
            },
          ),
        )
        getActionsMessage(type, fulfilled.operation_id || '')
      })
      .catch(() => {
        formikRef?.current?.setSubmitting?.(false)
        trackEvent({
          event_name: `fmui_${type}_domain`,
          attributes: {
            is_success: false,
          },
        })
      })
  }

  const onSubmit = (values: iDomainInitialValues) => {
    const newValue = values.domain
      .toLowerCase()
      .replace(/^https?:\/\/www\.|https?:\/\/|www\./, '')
    updateDomainFunc(newValue, 'add')
  }

  const confirmHandler = (e: {domain: string}) => {
    updateDomainFunc(e.domain, 'update')
  }

  const handleRemoveDomain = () => {
    updateDomainFunc('', 'delete')
  }
  const initialValues = {
    domain: domain?.domain || '',
  }
  console.log(formikRef?.current, 'formikRef?.current ')
  const AddDomain = (
    <Container styles="mt-[30px] ">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({dirty, isValid, values, setFieldValue, isSubmitting}) => (
          <Form>
            <Row bottom styles="mb-40">
              <Col styles="w-[400px] mr-20">
                <TextField
                  disabled={propsOutlet.checkPolling(Parameters.domain)}
                  label="Your domain"
                  name="domain"
                  type="text"
                  labelStyle="text-gray-80"
                  icon="warning_amber"
                  placeHolder="www.yourdomain.com"
                  id="4"
                />
              </Col>
              <Col>
                <Button
                  type="submit"
                  styles="cwpf_rmvdmain_pad"
                  variant="primary"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  + Add Domain
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <CloudfareFeature />
    </Container>
  )
  const Header = (
    <Row between>
      <Col>
        <article>
          <Text type="h4" semi styles="text-dark">
            Add / Manage your domain addresses
          </Text>
          <Text type="p" styles="p2 text-gray-80 max-w-[88%]">
            Configure CloudwaysCDN for your Application here. By setting up CDN
            for your Application, you can speed up delivery of static resources
            for your Application, and make your Application load faster.
          </Text>
        </article>
      </Col>
    </Row>
  )
  return (
    <Track>
      <Container styles="w-[100%]">
        {Header}
        {domain?.domain || propsOutlet.isAppLoading ? (
          <CloudfareVerify
            customDomain={domain || {}}
            setTxtRecordModal={setTxtRecordModal}
            deleteModal={setDeleteModal}
          />
        ) : (
          AddDomain
        )}
      </Container>
      <DeleteCloudfareModal
        loading={isDomainLoading}
        domain={domain?.domain}
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        handler={handleRemoveDomain}
      />
      {domain?.txt_records && Object.keys(domain?.txt_records)?.length > 1 ? (
        <TxtRecordModal
          show={txtRecordModal}
          onClose={() => setTxtRecordModal(false)}
          customDomain={domain}
        />
      ) : (
        <TxtOneRecordModal
          show={txtRecordModal}
          onClose={() => setTxtRecordModal(false)}
          customDomain={domain}
        />
      )}
    </Track>
  )
}
