import {customToast} from 'Common/Components/Core/CustomizedToast'
import {
  AppStatus,
  OperationType,
  opreationPerameters,
} from 'Common/Components/Hooks/useOperationsPolling'

const operationToast = (operation: any, appName: string) => {
  /* If operation completed */
  if (operation?.operation_status === AppStatus.COMPLETED) {
    // success
    customToast({
      level: 'success',
      title: '',
      description:
        operation?.type === OperationType.ADD_APP ? (
          <>
            Application <span className="break-all">{appName}</span> installed{' '}
            successfully
          </>
        ) : (
          <>
            Operation {opreationPerameters[operation.parameters[0]]} for{' '}
            <span className="break-all">{appName}</span> completed successfully
          </>
        ),
    })
    /* If operation failed */
  } else if (
    [AppStatus.ERROR, AppStatus.TIMEOUT].includes(operation?.operation_status)
  ) {
    customToast({
      level: 'error',
      title: '',
      description:
        operation?.type === OperationType.ADD_APP ? (
          <>Error installing {appName}</>
        ) : (
          <>
            Operation {opreationPerameters[operation.parameters[0]]} for{' '}
            <span className="break-all">{appName}</span> failed
          </>
        ),
    })
  }
}

export default operationToast
