import React from 'react'
import moment from 'moment'
import {useTracking} from 'react-tracking'
import {useDeleteApplicationMutation} from '../../../../../app/services'
import errorimg from '../../../../../Assets/img/modal/error-icon.png'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {Button, Container, Text, TextField} from '../../DesignTemplates'

interface DltAPPlication {
  delete_application: string
}

const initialValues: DltAPPlication = {
  delete_application: '',
}

const validate = Yup.object({
  delete_application: Yup.string()
    .required('Please Type "Delete" to delete application')
    .matches(/^([dD]elete|DELETE)$/, 'please validate delete'),
})

const DeleteApp = ({data, show, onClose}: any) => {
  const [deleteApplication, {isLoading: isDeleting}] =
    useDeleteApplicationMutation()
  const {trackEvent} = useTracking()
  const onSubmit = (values: DltAPPlication, {setSubmitting}: any) => {
    data.ids.forEach((item: any) => {
      trackEvent({
        event_name: 'fmui_delete_app',
        page: 'app_list',
        attributes: {
          app_id: item,
        },
      })
      deleteApplication(item)
        .unwrap()
        .then(() => {
          onClose()
        })
        .catch(err => {
          setSubmitting(false)
          console.log(err, 'error')
        })
    })
  }
  return (
    <Modal show={show} onClose={onClose} classes="max-w-[540px]">
      <Container styles="cwpf_cnfrmPlan_mainsec ">
        <Container styles="cwpf_cnfrmPlan_mainBox pt-100 px-[48px]">
          <Container styles="cwpf_cnfrmPlan_img mb-24">
            <img src={errorimg} alt="" />
          </Container>
          <Container styles="text-gray-80 mb-24">
            <Text semi type="h3">
              Are you sure
            </Text>
            <Text semi styles="break-words" type="h3">
              you want to delete
              {data?.name
                ? ` "${data?.name}" ?`
                : ` "${data?.ids?.length} Applications" ?`}
            </Text>
          </Container>
          <Container styles="my-20">
            <Text styles="p2 text-gray-80">
              Created: <b> {moment(data.created).format('MMM Do, YYYY')}</b>{' '}
              <br />{' '}
              <div className="hidden">
                WordPress: <b>5.8</b>
              </div>
            </Text>
          </Container>
          <Text styles="text-gray-100 p2">Please type “delete” to confirm</Text>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={onSubmit}
          >
            {({dirty, isValid, values, setFieldValue, isSubmitting}) => (
              <Form>
                <Container>
                  <TextField
                    name="delete_application"
                    type="text"
                    placeHolder="delete"
                  />
                </Container>
                <Container styles="cwpf_cnfrmPlan_ctaButton ">
                  <Button
                    medium
                    type="submit"
                    block
                    dangerOutline
                    loading={isDeleting}
                    disabled={isDeleting || isSubmitting}
                    styles={`mb-14 mt-40`}
                    id="6"
                  >
                    {isDeleting ? 'Deleting...' : 'Delete'}
                  </Button>
                  <Button
                    type="button"
                    medium
                    onClick={onClose}
                    block
                    primaryOutline
                  >
                    Cancel
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Modal>
  )
}

export default DeleteApp
