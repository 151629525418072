/** Libraries */
import {useState} from 'react'
import {useTracking} from 'react-tracking'
import {useNavigate, useOutletContext, useParams} from 'react-router-dom'
import moment from 'moment'

/** Store/Services */
import {
  useGetSingleApplicationQuery,
  useUpdateSingleApplicationMutation,
} from 'app/services'

/** Reusable Components */
import {
  Button,
  Card,
  Col,
  Container,
  Icon,
  Row,
  Text,
  WebSnap,
} from 'Common/Components/Core/DesignTemplates'
import EditApplicationNameModal from 'Common/Components/Core/Modal/ManageAppModals/EditApplicationNameModal/EditApplicationNameModal'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

/** Assets (CSS/Images) */
import {ReactComponent as SFTPDetails} from '../../../../../../Assets/img/manage-app/overview/sftp-details.svg'
import {ReactComponent as DomainManagement} from '../../../../../../Assets/img/manage-app/overview/domain-management.svg'
import {ReactComponent as DatabaseAccess} from '../../../../../../Assets/img/manage-app/overview/database-access.svg'
import './ApplicationOverview.css'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'

const ApplicationOverview = ({isBackup = false}) => {
  const {app_id} = useParams()
  const {trackEvent} = useTracking()

  const navigate = useNavigate()
  const propsOutlet: iPropsOutlet = useOutletContext()
  const navigateQucikLink = (currentTab: any) => {
    navigate(`/manage-app/${app_id}/manage-access`, {state: {tab: currentTab}})
  }

  // const {data: appData, isLoading: isAppLoading} =
  //   useGetSingleApplicationQuery(app_id)

  const [updateAppParams, {isLoading: isAppParamsUpdating}] =
    useUpdateSingleApplicationMutation()

  const [show, setShow] = useState(false)

  const AppImg = (
    <WebSnap
      url={
        propsOutlet.applicationData?.custom_domain?.domain
          ? propsOutlet.applicationData?.custom_domain?.domain
          : propsOutlet.applicationData?.app_fqdn
      }
      styles="w-[100%] h-[100%] rounded-l-[4px]"
    />
  )
  const RenderBackUp = () => {
    switch (isBackup) {
      case true:
        return (
          <Row col start gutter={8}>
            <Col>
              <Text type="h5" styles="h6-med text-gray-80">
                Not Created
              </Text>
            </Col>
            <Button
              primaryText
              small
              icon="backup_outlined"
              iconStyles="w-28"
              iconAlign="left"
              styles="w-[165px] p-0 ml-[-5px]"
            >
              <Text med styles=" text-gray-80 text-[17px]">
                Go to backup
              </Text>
            </Button>
          </Row>
        )
      default:
        return (
          <Row middle gutter={8}>
            <Col>
              <Text med styles="p1 text-gray-80">
                23rd Jan-2022
              </Text>
            </Col>
            <Col>
              <Icon
                name="settings_backup_restore"
                styles="text-gray-60 text-24 hidden"
              ></Icon>
            </Col>
          </Row>
        )
    }
  }

  return (
    <Container>
      <Row between>
        <Col>
          <Text type="h5" styles="text-gray-100" semi>
            Overview
          </Text>
        </Col>
      </Row>

      <Row between middle styles="bg-gray-3 my-30 py-12 px-24 rounded-8">
        <Col lg={6} styles="">
          <Text semi styles="p2 text-mask">
            Quick Settings
          </Text>
        </Col>
        <Col>
          <Row middle gutter={24} styles="cwpf_shortcutsRowResp">
            {/* <Col sm={3}>
              <Text styles="p2  text-gray-40">Shortcuts</Text>
            </Col> */}
            <Col>
              <Row middle>
                <SFTPDetails />
                <Button
                  small
                  primaryText
                  styles="p3 text-blue-100"
                  onClick={() => navigateQucikLink('SSH/SFTP')}
                >
                  SSH / SFTP Details
                </Button>
              </Row>
            </Col>
            <Col>
              <Row middle>
                <DomainManagement />
                <Button
                  small
                  primaryText
                  styles="p3 text-blue-100"
                  onClick={() => navigateQucikLink('Domain Management')}
                >
                  Domain Management
                </Button>
              </Row>
            </Col>
            <Col>
              <Row middle>
                <DatabaseAccess />
                <Button
                  small
                  primaryText
                  styles="p3 text-blue-100"
                  onClick={() => navigateQucikLink('Database')}
                >
                  Database Access
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Card styles="px-[22px] mt-10 py-[18px]">
        <Row middle styles="cwpf_overviewListResp">
          <Col styles="cwpf_spacingResp">
            <Container styles="cwpf_userApp_screenShot lg:mr-[26px]  flex items-center justify-center w-[150px] h-[112px] max-lg:w-[100%] relative">
              <div className="absolute top-[3px] right-[3px] bg-gray-80 w-[25px] h-[25px] cursor-pointer">
                <Icon
                  name="open_in_new_outlined"
                  styles="text-white text-20 w-[23px] h-[20px] mt-[-1px] ml-[2px]"
                  onClick={() =>
                    window.open(
                      'http://' +
                        (propsOutlet.applicationData?.custom_domain?.domain
                          ? propsOutlet.applicationData?.custom_domain?.domain
                          : propsOutlet.applicationData?.app_fqdn),
                      '_blank',
                    )
                  }
                />
              </div>
              {AppImg}
            </Container>
          </Col>

          <Col styles="flex-1 cwpf_spacingResp">
            {/* <Row col styles="lg:pr-20 w-[100%] max-lg:text-center"> */}
            <Row middle gutter={8}>
              <Col>
                <Tooltip text={propsOutlet.applicationData?.name}>
                  <Text
                    semi
                    type="h5"
                    styles="mb-[-5px] text-gray-80 whitespace-nowrap break-all  "
                    ellipsis={180}
                  >
                    {propsOutlet.applicationData?.name}
                  </Text>
                </Tooltip>
              </Col>
              <Col>
                <Icon
                  onClick={() => setShow(true)}
                  name="edit"
                  styles="text-gray-60 text-20"
                  hidden
                />
              </Col>
            </Row>
            <Col>
              <Text className="text-12 text-gray-60 my-[5px]">
                Created:{' '}
                {moment(propsOutlet.applicationData?.activate_date).format(
                  'Do MMMM, YYYY',
                )}
              </Text>
            </Col>
            {/* </Row> */}
          </Col>

          <Col styles="flex-1 cwpf_spacingResp">
            <Row col styles="cwpf_centerResp">
              <Col>
                <Text styles="p3 text-gray-80">Location</Text>
                {/* </Col>
              <Col> */}
                <Text
                  type="h5"
                  styles="h6-med text-gray-80 whitespace-nowrap text-ellipsis overflow-hidden  max-w-[180px]"
                >
                  {propsOutlet.applicationData?.bucket?.region?.name}
                </Text>
              </Col>
            </Row>
          </Col>
          <Tooltip text="Coming Soon">
            <Col styles="flex-1 hidden">
              <Row>
                <Col>
                  <Text styles="p3 text-gray-80 text-left">Last Backup</Text>
                  {/* </Col>
              <Col> */}
                  <RenderBackUp />
                </Col>
              </Row>
            </Col>
          </Tooltip>

          {/* <Row end styles="flex-1 mr-20 cwpf_btnSpacingResp">
            <Button
              primaryText
              disabled
              medium
              icon="content_copy"
              iconAlign="left"
              onClick={() =>
                trackEvent({
                  event_name: 'fmui_clone_app',
                })
              }
            >
              Clone Application
            </Button>
          </Row> */}
        </Row>
      </Card>

      <EditApplicationNameModal
        show={show}
        onClose={() => setShow(false)}
        onConfirm={(name: string) => console.log(name)}
      />
    </Container>
  )
}

export default ApplicationOverview
