/** Libraries */
import React, {useCallback, useEffect, useRef, useState} from 'react'

/** Reusable Components */
import Animation from '../Animations/Animation'
import Icon from '../Icon/Icon'
import Text from '../Text/Text'

interface DropDownMenuProps {
  icon?: Array<string> | string
  buttons: {name: string; icon?: string; handler?: (props: any) => any}[]
  styles?: string
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  topLeft?: boolean
  topRight?: boolean
  bottomLeft?: boolean
  bottomRight?: boolean
  disabled?: boolean
  text?: any
  border?: boolean
  hide?: boolean
}

const DropDownMenu = React.forwardRef((props: DropDownMenuProps, ref: any) => {
  const {
    icon = 'more_vert',
    buttons = [],
    styles = '',
    top = false,
    bottom = false,
    left = false,
    right = false,
    topLeft = false,
    topRight = false,
    bottomLeft = true,
    bottomRight = false,
    disabled = false,
    text = {
      value: '',
      textStyle: '',
    },
    border,
    hide = false,
  } = props

  const [showMenu, setShowMenu] = useState(false)

  const iconLinkRef = useRef<any>(null)
  // const popupRef = useRef<any>(null)

  const getPosition = () => {
    if (bottom) return 'top-[110%]'
    else if (bottomRight) return 'top-[110%] left-0'
    else if (top) return 'top-[110%]'
    else if (topLeft) return 'bottom-[110%] right-0'
    else if (topRight) return 'bottom-[110%] left-0'
    else if (right) return 'left-[110%]'
    else if (left) return 'right-[110%]'
    else if (bottomLeft) return 'top-[115%] right-0'

    return ''
  }
  const checkBorder = () => {
    if (border)
      return 'w-30 h-26  border border-solid rounded-4 border-gray-10 bg-white'
    return ''
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        // popupRef.current &&
        // !popupRef.current.contains(event.target) &&
        iconLinkRef.current &&
        !iconLinkRef.current.contains(event.target)
      ) {
        setShowMenu(false)
      }
    }
    document.addEventListener('keydown', onEscapePress, false)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', onEscapePress, false)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [iconLinkRef])

  const onEscapePress = useCallback(
    event => {
      if (event.key === 'Escape') {
        setShowMenu(false)
      }
    },
    [setShowMenu],
  )
  if (hide) return <></>
  return (
    <Icon
      ref={iconLinkRef}
      styles={`relative  flex items-center justify-center text-20 cursor-pointer  hover:text-gray-60 ${styles} ${checkBorder()}`}
      onClick={() => setShowMenu(prev => !prev)}
      data-testid="dropdown-icon"
    >
      {text && (
        <Text type="p" className={`${text.textStyle}`}>
          {text.value}
        </Text>
      )}
      {typeof icon === 'string' ? icon : icon[showMenu ? 1 : 0]}

      {!disabled && (
        <Animation
          in={showMenu}
          animationStyle="fadeInDropdown"
          timeout={150}
          unmountOnExit
        >
          <div
            //ref={popupRef}
            className={`cwpf_glbl_popup flex flex-col bg-white absolute items-start justify-evenly border border-[#C1C7D0] border-solid rounded-4 cursor-pointer z-10 ${getPosition()}`}
            data-testid="menu-popup"
          >
            {buttons.map((item: any, index: number) => (
              <button
                className="flex items-center whitespace-nowrap text-14 text-gray-80 px-24 py-10 w-[100%] hover:bg-gray-3"
                onClick={item.handler}
                key={index}
                data-testid={`menu-popup-${index}`}
              >
                {item.icon && (
                  <span className="material-icons mr-[15px] w-24 h-24">
                    {item.icon}
                  </span>
                )}
                {item.name}
              </button>
            ))}
          </div>
        </Animation>
      )}
    </Icon>
  )
})

export default DropDownMenu
