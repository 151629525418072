/** Libraries */
import {useTracking} from 'react-tracking'
import {Chart} from './Chart'

/** Reusable Components */
import {
  Button,
  Container,
  CommingSoonScreen,
  Row,
  Col,
  Text,
  Card,
  Icon,
} from 'Common/Components/Core/DesignTemplates'
import AttackMitigationTable from './AttackMitigateTable'
import EnableBar from 'Common/Components/Core/DesignTemplates/EnableBar/EnableBar'

/** Helpers */
import {iAttackMEventObj, SECURITY_SUB_TABS} from '../util'

/** Assets (CSS/Images) */
import NoVisualization from '../../../../../../Assets/img/cw_Novisualization.png'
import Doslogo from '../../../../../../Assets/img/protection.png'
import Protectionlogo from '../../../../../../Assets/img/DOSprotection.png'
import empty from '../../../../../../Assets/img/whitelistImg.png'
import {ReactComponent as attackLogsImg} from '../../../../../../Assets/img/attack-logs.svg'
import './AttackMitigated.css'

export default function AttackMitigation() {
  const {Track, trackEvent} = useTracking<iAttackMEventObj>({
    attributes: {
      sub_tab: SECURITY_SUB_TABS.ATTACK_MITIGATION,
    },
  })

  return (
    <Track>
      <Container styles="container">
        <Row between hidden>
          <Col>
            <Text type="h5" styles="text-gray-80" semi>
              Attacks Mitigated
            </Text>
            <Text styles="p1 text-gray-80">
              Manage security settings, whitelist your IP and/or IP subnets for
              SSH/SFTP and MySQL connections.
            </Text>
          </Col>
          <Col>
            <Button
              small
              primaryOutline
              icon="refresh"
              iconStyles="mb-2"
              large
              iconAlign="left"
              onClick={() =>
                trackEvent({
                  event_name: 'fmui_attack_mitigation_refresh',
                })
              }
            >
              Refresh
            </Button>
          </Col>
        </Row>

        <Card styles="relative mb-30 hidden">
          <Row middle center styles=" min-h-[500px]">
            <Col styles="pt-[16px]">
              <Container styles="relative left-[18%]">
                <img src={NoVisualization} alt="" />
              </Container>
              <Text med styles="mt-16 p3 text-gray-80">
                Not enough data to draw visualization
              </Text>
            </Col>
          </Row>
        </Card>

        <Container styles="mt-28">
          <Row wrap between gutter={24} styles="cwpf_AttackMitiResp">
            <Col sm={12} lg={6} styles="mb-20">
              <Card>
                {/* min height changed because learn more button is hidden */}
                <Row wrap gutter={16} styles="py-10 px-10 min-h-[120px] mb-4">
                  <Col lg={2} sm={6} styles="mt-8 max-sm:relative left-60">
                    <img src={Protectionlogo} alt="" />
                  </Col>
                  <Col lg={10} sm={12}>
                    <Row styles="max-sm:my-6 max-sm:justify-center" wrap>
                      <Text type="h6" semi styles="text-dark">
                        DDOS Protection
                      </Text>
                      <EnableBar styles="max-sm:mt-4 " success>
                        Enabled
                      </EnableBar>
                    </Row>
                    <Container styles="mt-4">
                      <Text styles="p3 text-gray-80 max-sm:text-center" reg>
                        DDoS attacks are a primary concern in Internet security
                        today. Explore details about how It’s attacks function,
                        and how they can be stopped.
                      </Text>
                    </Container>
                    <Button primaryOutline small styles="mt-8 hidden">
                      Learn More
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col sm={12} lg={6} styles="mb-20">
              <Card>
                {/* min height changed becasue learn more button is hidden */}
                <Row
                  wrap
                  center
                  gutter={16}
                  styles="py-10 px-10 min-h-[120px] mb-4"
                >
                  <Col lg={2} sm={12} styles="mt-8 max-sm:relative left-60">
                    <img src={Doslogo} alt="" />
                  </Col>
                  <Col lg={10} sm={12}>
                    <Row styles="max-sm:my-6 max-sm:justify-center" wrap>
                      <Text type="h6" semi styles="text-dark">
                        WAF Protection
                      </Text>
                      <EnableBar styles="max-sm:mt-4 " success>
                        Enabled
                      </EnableBar>
                    </Row>
                    <Container styles="mt-4">
                      <Text styles="p3 text-gray-80 max-sm:text-center" reg>
                        WAF attacks are a primary concern in Internet security
                        today. Explore details about how It’s attacks function,
                        and how they can be stopped
                      </Text>
                    </Container>
                    <Button primaryOutline small styles="mt-8 hidden">
                      Learn More
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        <Chart />

        <Container styles="relative mt-24">
          <CommingSoonScreen
            ImgComponent={attackLogsImg}
            heading="Attack logs"
          />

          <Container styles="px-10 relative h-[100%]">
            <Text semi type="h5" styles="pt-36 text-gray-80 blur-sm">
              Attack Logs
            </Text>

            <AttackMitigationTable />
            <Card shadow styles="my-24 hidden">
              <Row middle center styles="min-h-[500px] ">
                <Col className="pt-[16px]">
                  <Container styles="relative left-[18%]">
                    <img src={empty} alt="" />
                  </Container>

                  <Text styles="p3 text-gray-80 mt-16">
                    No attack logs to show right now
                  </Text>
                </Col>
              </Row>
            </Card>
          </Container>
        </Container>
      </Container>
    </Track>
  )
}
