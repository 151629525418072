export interface iDomainInitialValues {
  domain: string
}
export const domainStatus = {
  pendingVerification: 'pending_verification',
  domainisNotLive: 'domain_not_live',
  domainisLive: 'domain_live',
  verificationFailed: 'verification_failure',
  domainRemoved: 'domain_removed',
}
export const cloudfareLookup: Record<string, any> = {
  pending_verification: {
    name: 'Pending validation',
    error:
      'Your DNS records are not propagated yet, please retry after a few mins',
  },
  domain_not_live: {
    name: 'Domain is not Live',
    error:
      'Your domain ownership has been successfully verified but your DNS records are not propagated yet, please retry after a few mins',
  },
  domain_live: {
    name: 'Domain is Live',
    error: 'Your domain is live now”',
  },
  verification_failure: {
    name: 'Failed to verify',
    error: '',
  },
  domain_removed: {
    name: '',
    error: '',
  },
}
