/** Libraries */
import React, {useEffect, useState} from 'react'

/** Reusable Components */
import Icon from '../Icon/Icon'

const CopyClipboard = ({
  initialIcon = 'content_copy',
  secondIcon = 'check_circle_outline',
  copyText = '',
  tooltip = '',
  styles = '',
  noStyles = false,
  hide = false,
}) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }, [isCopied])

  const handlerCopy = () => {
    navigator.clipboard.writeText(copyText)
    setIsCopied(true)
  }
  if (hide) return <></>
  return (
    <>
      <Icon
        name={isCopied ? secondIcon : initialIcon}
        onClick={handlerCopy}
        positionStyles={
          !noStyles
            ? 'absolute top-[50%] translate-y-[-50%] right-12 z-[9] cursor-pointer'
            : ''
        }
        styles={`text-20 text-gray-60
             ${styles}`}
        dataTip={`Copy ${tooltip}`}
      />
    </>
  )
}

export default CopyClipboard
