/** Libraries */
import React, {useState} from 'react'
import {useTracking} from 'react-tracking'
import {useOutletContext, useParams} from 'react-router-dom'

/** Store/Services */
import {useUpdateSingleApplicationMutation} from 'app/services'

/** Reusable Components */
import {customToast} from 'Common/Components/Core/CustomizedToast'
import CustomForm from '../common/CustomForm'
import {Container, Text} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {customFormType, iManageAccess, MANAGE_ACCESS_SUB_TABS} from '../util'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'

/** Assets (CSS/Images) */

interface iDomainTrackingObj {
  event_name?: string
  attributes?: {
    sub_tab?: MANAGE_ACCESS_SUB_TABS.DATABASE
    is_success?: boolean
  }
}

export default function DatabaseContainer() {
  const [editMode, setEditMode] = useState<boolean>(false)
  const propsOutlet: iPropsOutlet = useOutletContext()

  const {app_id} = useParams()
  const {Track, trackEvent} = useTracking<iDomainTrackingObj>({
    attributes: {
      sub_tab: MANAGE_ACCESS_SUB_TABS.DATABASE,
    },
  })

  const [updateAppParams] = useUpdateSingleApplicationMutation()

  const initialValues: iManageAccess = {
    databaseName: `application${propsOutlet.applicationData?.id}`, //isAppData?.db_params?.name,
    username: propsOutlet.applicationData?.db_params?.user,
    password: propsOutlet.applicationData?.db_params?.password,
  }

  const onSubmit = (values: iManageAccess) => {
    updateAppParams({
      app_id: app_id,
      subPath: 'db-params',
      body: {
        name: values.databaseName,
        user: values.username,
        password: values.password,
      },
    })
      .unwrap()
      .then((fulfilled: Record<string, unknown>) => {
        if (fulfilled.error) throw new Error()
        trackEvent({
          event_name: `fmui_update_db_password`,
          attributes: {
            is_success: true,
          },
        })
        customToast({
          level: 'success',
          title: '',
          description: 'Database password updated successfully',
        })
      })
      .catch(() =>
        trackEvent({
          event_name: `fmui_update_db_password`,
          attributes: {
            is_success: false,
          },
        }),
      )
    setEditMode(false)
  }

  return (
    <Track>
      <Container styles="w-[100%]">
        <Text type="h5" semi styles=" text-gray-80">
          MySQL Access
        </Text>
        <Text styles="p1 text-gray-80">
          {' '}
          You can use these credentials to remotely access your database.
        </Text>

        <CustomForm
          type={customFormType.database}
          onSubmit={onSubmit}
          initialValues={initialValues}
          isLoading={propsOutlet.isAppLoading}
          hideIcon
          editMode={editMode}
          setEditMode={setEditMode}
          label={{fieldOne: 'Database Name', fieldTwo: 'Username'}}
          name={{fieldOne: 'databaseName', fieldTwo: 'username'}}
        />
        {/* <DatabaseManager /> */}
      </Container>
    </Track>
  )
}
