export const timezone = [{
        value: 'Pacific/Midway (GMT-11:00) Midway Island',
        label: '(GMT-11:00) Midway Island',
    },
    { value: 'Pacific/Samoa (GMT-11:00) Samoa', label: '(GMT-11:00) Samoa' },
    { value: 'Pacific/Honolulu (GMT-10:00) Hawaii', label: '(GMT-10:00) Hawaii' },
    {
        value: 'America/Los_Angeles (GMT-08:00) Pacific Time (US & Canada)',
        label: '(GMT-08:00) Pacific Time (US & Canada)',
    },
    { value: 'America/Tijuana', label: '(GMT-08:00) Tijuana' },
    { value: 'America/Chihuahua', label: '(GMT-07:00) Chihuahua , La Paz' },
    // {value: 'America/Chihuahua', label: '(GMT-07:00) La Paz'},
    { value: 'America/Mazatlan', label: '(GMT-07:00) Mazatlan' },
    { value: 'America/Managua', label: '(GMT-06:00) Central America' },
    {
        value: 'America/Mexico_City',
        label: '(GMT-06:00) Guadalajara , Mexico City',
    },
    // {value: 'America/Mexico_City', label: '(GMT-06:00) Mexico City'},
    { value: 'America/Monterrey', label: '(GMT-06:00) Monterrey' },
    { value: 'America/Bogota', label: '(GMT-05:00) Bogota , Quito' },
    // {value: 'America/Bogota', label: '(GMT-05:00) Quito'},
    { value: 'America/Lima', label: '(GMT-05:00) Lima' },
    { value: 'America/Caracas', label: '(GMT-04:30) Caracas' },
    { value: 'America/La_Paz', label: '(GMT-04:00) La Paz' },
    { value: 'America/Santiago', label: '(GMT-04:00) Santiago' },
    { value: 'America/Sao_Paulo', label: '(GMT-03:00) Brasilia' },
    { value: 'America/Argentina/Buenos_Aires', label: '(GMT-03:00) Buenos Aires' },
    { value: 'America/Argentina/Buenos_Aires', label: '(GMT-03:00) Georgetown' },
    { value: 'America/Godthab', label: '(GMT-03:00) Greenland' },
    { value: 'America/Noronha', label: '(GMT-02:00) Mid-Atlantic' },
    { value: 'Atlantic/Azores', label: '(GMT-01:00) Azores' },
    { value: 'Atlantic/Cape_Verde', label: '(GMT-01:00) Cape Verde Is.' },
    { value: 'Europe/London', label: '(GMT+00:00) London , Edinburgh' },
    // {value: 'Europe/London', label: '(GMT+00:00) London'},
    { value: 'Europe/Lisbon', label: '(GMT+00:00) Lisbon' },
    { value: 'UTC', label: '(GMT+00:00) UTC' },
    { value: 'Europe/Amsterdam', label: '(GMT+01:00) Amsterdam' },
    { value: 'Europe/Belgrade', label: '(GMT+01:00) Belgrade' },
    { value: 'Europe/Berlin', label: '(GMT+01:00) Berlin , Bern' },
    // {value: 'Europe/Berlin', label: '(GMT+01:00) Bern'},
    { value: 'Europe/Bratislava', label: '(GMT+01:00) Bratislava' },
    { value: 'Europe/Brussels', label: '(GMT+01:00) Brussels' },
    { value: 'Europe/Budapest', label: '(GMT+01:00) Budapest' },
    { value: 'Europe/Copenhagen', label: '(GMT+01:00) Copenhagen' },
    { value: 'Europe/Ljubljana', label: '(GMT+01:00) Ljubljana' },
    { value: 'Europe/Madrid', label: '(GMT+01:00) Madrid' },
    { value: 'Europe/Paris', label: '(GMT+01:00) Paris' },
    { value: 'Europe/Prague', label: '(GMT+01:00) Prague' },
    { value: 'Europe/Rome', label: '(GMT+01:00) Rome' },
    { value: 'Europe/Sarajevo', label: '(GMT+01:00) Sarajevo' },
    { value: 'Europe/Skopje', label: '(GMT+01:00) Skopje' },
    { value: 'Europe/Stockholm', label: '(GMT+01:00) Stockholm' },
    { value: 'Europe/Vienna', label: '(GMT+01:00) Vienna' },
    { value: 'Europe/Warsaw', label: '(GMT+01:00) Warsaw' },
    { value: 'Europe/Zagreb', label: '(GMT+01:00) Zagreb' },
    { value: 'Europe/Athens', label: '(GMT+02:00) Athens' },
    { value: 'Europe/Bucharest', label: '(GMT+02:00) Bucharest' },
    { value: 'Europe/Helsinki', label: '(GMT+02:00) Helsinki' },
    { value: 'Europe/Istanbul', label: '(GMT+02:00) Istanbul , Kyiv' },
    // {value: 'Europe/Helsinki', label: '(GMT+02:00) Kyiv'},
    { value: 'Asia/Jerusalem', label: '(GMT+02:00) Jerusalem' },
    { value: 'Europe/Riga', label: '(GMT+02:00) Riga' },
    { value: 'Europe/Sofia', label: '(GMT+02:00) Sofia' },
    { value: 'Europe/Tallinn', label: '(GMT+02:00) Tallinn' },
    { value: 'Europe/Vilnius', label: '(GMT+02:00) Vilnius' },
    { value: 'Asia/Baghdad', label: '(GMT+03:00) Baghdad' },
    { value: 'Asia/Kuwait', label: '(GMT+03:00) Kuwait' },
    { value: 'Europe/Minsk', label: '(GMT+03:00) Minsk' },
    { value: 'Asia/Riyadh', label: '(GMT+03:00) Riyadh' },
    { value: 'Europe/Volgograd', label: '(GMT+03:00) Volgograd' },
    { value: 'Asia/Tehran', label: '(GMT+03:30) Tehran' },
    { value: 'Asia/Muscat', label: '(GMT+04:00) Abu Dhabi' },
    { value: 'Asia/Baku', label: '(GMT+04:00) Baku' },
    { value: 'Europe/Moscow', label: '(GMT+04:00) Moscow' },
    { value: 'Asia/Muscat', label: '(GMT+04:00) Muscat' },
    { value: 'Europe/Moscow', label: '(GMT+04:00) St. Petersburg' },
    { value: 'Asia/Tbilisi', label: '(GMT+04:00) Tbilisi' },
    { value: 'Asia/Yerevan', label: '(GMT+04:00) Yerevan' },
    { value: 'Asia/Kabul', label: '(GMT+04:30) Kabul' },
    { value: 'Asia/Karachi', label: '(GMT+05:00) Karachi' },
    { value: 'Asia/Tashkent', label: '(GMT+05:00) Tashkent' },
    { value: 'Asia/Kolkata', label: '(GMT+05:30) Kolkata' },
    {
        value: 'Asia/Calcutta',
        label: '(GMT+05:30) Chennai , Mumbai , New Delhi , Sri Jayawardenepura',
    },
    // {value: 'Asia/Calcutta', label: '(GMT+05:30) Mumbai'},
    // {value: 'Asia/Calcutta', label: '(GMT+05:30) New Delhi'},
    // {value: 'Asia/Calcutta', label: '(GMT+05:30) Sri Jayawardenepura'},
    { value: 'Asia/Katmandu', label: '(GMT+05:45) Kathmandu' },
    { value: 'Asia/Almaty', label: '(GMT+06:00) Almaty' },
    { value: 'Asia/Dhaka', label: '(GMT+06:00) Astana , Dhaka' },
    // {value: 'Asia/Dhaka', label: '(GMT+06:00) Dhaka'},
    { value: 'Asia/Yekaterinburg', label: '(GMT+06:00) Ekaterinburg' },
    { value: 'Asia/Rangoon', label: '(GMT+06:30) Rangoon' },
    { value: 'Asia/Bangkok', label: '(GMT+07:00) Bangkok , Hanoi' },
    // {value: 'Asia/Bangkok', label: '(GMT+07:00) Hanoi'},
    { value: 'Asia/Jakarta', label: '(GMT+07:00) Jakarta' },
    { value: 'Asia/Novosibirsk', label: '(GMT+07:00) Novosibirsk' },
    { value: 'Asia/Hong_Kong', label: '(GMT+08:00) Hong Kong , Beijing' },
    // {value: 'Asia/Hong_Kong', label: '(GMT+08:00) Hong Kong'},
    { value: 'Asia/Chongqing', label: '(GMT+08:00) Chongqing' },
    { value: 'Asia/Krasnoyarsk', label: '(GMT+08:00) Krasnoyarsk' },
    { value: 'Asia/Kuala_Lumpur', label: '(GMT+08:00) Kuala Lumpur' },
    { value: 'Australia/Perth', label: '(GMT+08:00) Perth' },
    { value: 'Asia/Singapore', label: '(GMT+08:00) Singapore' },
    { value: 'Asia/Taipei', label: '(GMT+08:00) Taipei' },
    { value: 'Asia/Ulan_Bator', label: '(GMT+08:00) Ulaan Bataar' },
    { value: 'Asia/Urumqi', label: '(GMT+08:00) Urumqi' },
    { value: 'Asia/Irkutsk', label: '(GMT+09:00) Irkutsk' },
    { value: 'Asia/Tokyo', label: '(GMT+09:00) Osaka , Tokyo , Sapporo' },
    // {value: 'Asia/Tokyo', label: '(GMT+09:00) Sapporo'},
    // {value: 'Asia/Tokyo', label: '(GMT+09:00) Tokyo'},
    { value: 'Asia/Seoul', label: '(GMT+09:00) Seoul' },
    { value: 'Australia/Adelaide', label: '(GMT+09:30) Adelaide' },
    { value: 'Australia/Darwin', label: '(GMT+09:30) Darwin' },
    { value: 'Australia/Brisbane', label: '(GMT+10:00) Brisbane' },
    { value: 'Australia/Canberra', label: '(GMT+10:00) Canberra' },
    { value: 'Pacific/Guam', label: '(GMT+10:00) Guam' },
    { value: 'Australia/Hobart', label: '(GMT+10:00) Hobart' },
    { value: 'Australia/Melbourne', label: '(GMT+10:00) Melbourne' },
    { value: 'Pacific/Port_Moresby', label: '(GMT+10:00) Port Moresby' },
    { value: 'Australia/Sydney', label: '(GMT+10:00) Sydney' },
    { value: 'Asia/Yakutsk', label: '(GMT+10:00) Yakutsk' },
    { value: 'Asia/Vladivostok', label: '(GMT+11:00) Vladivostok' },
    { value: 'Pacific/Auckland', label: '(GMT+12:00) Wellington , Auckland' },
    // {value: 'Pacific/Auckland', label: '(GMT+12:00) Wellington'},
    { value: 'Pacific/Fiji', label: '(GMT+12:00) Fiji , Marshall Is' },
    // {value: 'Pacific/Fiji', label: '(GMT+12:00) Marshall Is.'},
    {
        value: 'Pacific/Kwajalein',
        label: '(GMT+12:00) International Date Line West',
    },
    { value: 'Asia/Kamchatka', label: '(GMT+12:00) Kamchatka' },
    {
        value: 'Asia/Magadan',
        label: '(GMT+12:00) Magadan , Solomon Is , New Caledonia',
    },
    // {value: 'Asia/Magadan', label: '(GMT+12:00) New Caledonia'},
    // {value: 'Asia/Magadan', label: '(GMT+12:00) Solomon Is.'},
    { value: 'Pacific/Tongatapu', label: "(GMT+13:00) Nuku'alofa" },
]