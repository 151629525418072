import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const currentUserBrowserData = createApi({
  reducerPath: 'currentUserBrowserData',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://geolocation-db.com/',
  }),
  endpoints: build => ({
    getCurrentUserBrowserData: build.query<any, void>({
      query: () => ({url: 'json'}),
    }),
  }),
})

export const {useGetCurrentUserBrowserDataQuery} = currentUserBrowserData
