/** Libraries */
import React, {useState} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import {useTracking} from 'react-tracking'

/** Store/Services */
import {
  apiSlice,
  useAddSFTPCredentialsForAppMutation,
  useGetSFTPCredentialsForAppQuery,
} from 'app/services'

/** Reusable Components */
import {
  Button,
  Icon,
  Separator,
  Text,
  Row,
  Col,
} from 'Common/Components/Core/DesignTemplates'
import AddSSHUser from 'Common/Components/Core/Modal/ManageAppModals/ManageAccessModal/AddSSHUserModal'
import {ManageAccessTable} from './ManageAccessTable'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {NoData} from './NoData'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {useAppDispatch} from 'app/hooks'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const Details = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {app_id} = useParams()
  const {checkPolling}: {checkPolling: (arg0: string) => true} =
    useOutletContext()
  const {trackEvent} = useTracking()

  const {data: getSFTPCredsList, refetch} =
    useGetSFTPCredentialsForAppQuery(app_id)

  const [addCredentials] = useAddSFTPCredentialsForAppMutation()

  const handleCredSubmission = (
    values: Record<string, string>,
    {setSubmitting}: {setSubmitting: (value: boolean) => void},
  ) => {
    const eventTrackingObj = {
      event_name: 'fmui_add_sftp_user',
      attributes: {
        user_name: values.username,
        is_success: false,
      },
    }
    addCredentials({app_id, values})
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.error) throw new Error()
        trackEvent({
          ...eventTrackingObj,
          attributes: {...eventTrackingObj.attributes, is_success: true},
        })
        customToast({
          level: 'info',
          title: '',
          description: `New SFTP credential is being added. You wont be able to perform certain actions while the operation is taking place`,
          loading: true,
        })
        setShowModal(false)
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.operation_id,
          }),
        )
          .unwrap()
          .then(operation => {
            setSubmitting(false)
            // dispatch(
            //   apiSlice.util.updateQueryData(
            //     'getSFTPCredentialsForApp',
            //     app_id,
            //     draft => {
            //       draft.results = [...draft.results, fulfilled.ssh_credential]
            //     },
            //   ),
            // )
          })
      })
      .catch(() => {
        setSubmitting(false)
        trackEvent(eventTrackingObj)
      })
  }
  const SshDetailsContainer = (
    <>
      <Separator></Separator>
      <Row
        center
        between
        styles="mt-20 cwpf_SSHdestailsResp
"
      >
        <Col styles="cwpf_SshWrapResp">
          <Text semi type="h5" styles="text-dark">
            SSH/SFTP Details
          </Text>
          <Text styles="p2">
            You can create and use multiple Application credentials for SFTP or
            SSH access to this Application.
            <br />
            <a
              href=""
              className="p2 font-semibold underline text-blue-100 hidden"
            >
              More Details
            </a>
          </Text>
        </Col>
        <Col styles="cwpf_SshWrapResp">
          <Row middle styles="mb-[54px] cwpf_logsCtaWrapResp">
            <Text styles="p2 text-blue-100 flex mr-20 hidden">
              View logs
              <Icon styles=" relative left-4 text-blue-100">list</Icon>
            </Text>
            <Tooltip
              text="This action is temporariliy disabled because 
of an operation in the background which might
potentially cause conflicts"
              style="text-left"
              hide={!checkPolling(Parameters.ssh_creds)}
            >
              <Button
                medium
                type="button"
                onClick={() => setShowModal(true)}
                styles="cwpf_CtaResp"
                disabled={checkPolling(Parameters.ssh_creds)}
              >
                + Add SFTP user
              </Button>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      {getSFTPCredsList?.count ? (
        <ManageAccessTable
          getSFTPCredsList={getSFTPCredsList.results}
          refetch={refetch}
        />
      ) : (
        <NoData />
      )}

      <AddSSHUser
        show={showModal}
        onClose={() => setShowModal(false)}
        handleCredSubmission={handleCredSubmission}
      />
    </>
  )
  return <>{SshDetailsContainer}</>
}

export default Details
