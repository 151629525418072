/** Libraries */
import React, {useEffect, useRef, useState} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import moment from 'moment'
import {useTracking} from 'react-tracking'

/** Store/Services */
import {
  apiSlice,
  useDeleteSFTPCredsForAppMutation,
  useUpdateSFTPCredsForAppMutation,
} from 'app/services'

/** Reusable Components */
import EditSSHUser from 'Common/Components/Core/Modal/ManageAppModals/ManageAccessModal/EditSSHUserModal'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import {
  Container,
  Toggle,
  DropDownMenu,
  CopyClipboard,
  Icon,
  Text,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {iEditUser, iManageAccessCreds, iManageAccessTable} from '../util'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {useAppDispatch} from 'app/hooks'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {iSshCredential} from 'app/services/iServiceType'

const TableRow = ({
  appCredential,
  index,
  refetch,
}: {
  appCredential: iManageAccessCreds
  index: number
  refetch: () => void
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isCredEnable, setIsCredEnable] = useState<boolean>(false)
  const propsOutlet: iPropsOutlet = useOutletContext()
  const {app_id} = useParams()
  const dispatch = useAppDispatch()

  const {trackEvent} = useTracking()

  const [updateSFTPCreds, {isLoading: isUpdating}] =
    useUpdateSFTPCredsForAppMutation()
  const [deleteSFTPCreds] = useDeleteSFTPCredsForAppMutation()

  // useEffect(() => {
  //   if (!propsOutlet.checkPolling(Parameters.ssh_creds)) {
  //     refetch()
  //   }
  // }, [propsOutlet, refetch])

  useEffect(() => {
    appCredential?.enabled ? setIsCredEnable(true) : setIsCredEnable(false)
  }, [appCredential?.enabled])

  const handleCredSubmission = (
    values: iEditUser,
    {setSubmitting}: {setSubmitting: (value: boolean) => void},
  ) => {
    const eventTrackingObj = {
      event_name: 'fmui_update_sftp_user',
      attributes: {
        user_name: values.username,
        cred_id: appCredential?.id,
        is_success: false,
      },
    }
    updateSFTPCreds({
      app_id,
      cred_id: appCredential?.id,
      payload: values,
    })
      .unwrap()
      .then(fulfilled => {
        if (fulfilled?.error) throw new Error()
        trackEvent({
          ...eventTrackingObj,
          attributes: {...eventTrackingObj.attributes, is_success: true},
        })
        customToast({
          level: 'info',
          title: '',
          description: `Credential is being updated. You wont be able to perform certain actions while the operation is taking place`,
          loading: true,
        })
        setShowModal(false)
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.operation_id,
          }),
        )
          .unwrap()
          .then(operation => {
            setSubmitting(false)
            // dispatch(
            //   apiSlice.util.updateQueryData(
            //     'getSFTPCredentialsForApp',
            //     app_id,
            //     draft => {
            //       const index = draft.results.findIndex(
            //         (item: iSshCredential) =>
            //           item.id === fulfilled.ssh_credential.id,
            //       )
            //       draft.results[index] = fulfilled.ssh_credential
            //     },
            //   ),
            // )
          })
      })
      .catch(() => {
        setSubmitting(false)
        trackEvent(eventTrackingObj)
      })
  }

  const deleteSFTPHandler = () => {
    const eventTrackingObj = {
      event_name: 'fmui_delete_sftp_user',
      attributes: {
        cred_id: appCredential.id,
        is_success: false,
      },
    }
    deleteSFTPCreds({
      app_id,
      cred_id: appCredential.id,
    })
      .unwrap()
      .then((fulfilled: {operation_id: string}) => {
        trackEvent({
          ...eventTrackingObj,
          attributes: {...eventTrackingObj.attributes, is_success: true},
        })
        customToast({
          level: 'success',
          title: '',
          description: `Credential is being deleted. You wont be able to perform certain actions while the operation is taking place`,
        })
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.operation_id,
          }),
        )
      })
      .catch(() => trackEvent(eventTrackingObj))
  }

  const handleToogleCredStatus = useDebounce(() => {
    const eventTrackingObj = {
      event_name: 'fmui_update_sftp_user',
      attributes: {
        enabled: isCredEnable,
        cred_id: appCredential?.id,
        is_success: false,
      },
    }
    updateSFTPCreds({
      app_id,
      cred_id: appCredential?.id,
      payload: {enabled: isCredEnable},
    })
      .then((fulfilled: any) => {
        if (fulfilled?.error) throw new Error()
        trackEvent({
          ...eventTrackingObj,
          attributes: {...eventTrackingObj.attributes, is_success: true},
        })
        customToast({
          level: 'success',
          title: '',
          description: `Credential status is being updated. You wont be able to perform certain actions while the operation is taking place`,
        })
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.data.operation_id,
          }),
        )
      })
      .catch(() => trackEvent(eventTrackingObj))
  }, 3000)

  const underDevMode = () => {
    return customToast({
      level: 'warning',
      title: '',
      description: `This feature is in developent mode`,
    })
  }

  return (
    <tr className="text-gray-80" key={appCredential?.id}>
      <td className="p1">{appCredential?.username}</td>
      <td className="p1 flex mt-8 cwpf_manageAccess_passToggleTd">
        <div className="relative">
          <Text
            ellipsis={showPassword ? 100 : 'auto'}
            styles="max-w-[100px] overflow-x-hidden"
          >
            {showPassword
              ? appCredential?.password
              : appCredential?.password.split('').fill('*', 0).join('')}
          </Text>

          <div className="flex absolute top-[-6px] right-[-60px]">
            <Icon
              styles="text-20 cwpf_manageAccess_passwordToggle text-gray-40 pl-10"
              onClick={() => setShowPassword(!showPassword)}
            >
              {' '}
              {showPassword ? 'visibility' : 'visibility_off'}
            </Icon>
            <Container styles="relative cwpf_manageAccess_passwordToggle">
              <CopyClipboard
                noStyles
                styles="text-gray-40 ml-8 text-20"
                copyText={appCredential?.password}
              />
            </Container>
          </div>
        </div>
      </td>
      <td className="p1">
        {moment(appCredential?.created).format('DD/MM/YY')}
      </td>
      {/* <td className="p1">
        {!propsOutlet.checkPolling(Parameters.ssh_creds) && (
          <Toggle
            checked={isCredEnable}
            index={index}
            disabled={propsOutlet.checkPolling(Parameters.ssh_creds)}
            defaultChecked={appCredential?.enabled}
            onClick={(e: {target: {checked: boolean}}) => {
              setIsCredEnable(e.target.checked)
              handleToogleCredStatus()
            }}
          />
        )}
      </td> */}
      <td>
        {!propsOutlet.checkPolling(Parameters.ssh_creds) && (
          <DropDownMenu
            border
            bottomLeft
            buttons={[
              // {
              //   name: 'Manage SSH Keys',
              //   icon: 'keys',
              //   handler: () => underDevMode(),
              // },
              {
                name: 'Edit SFTP User',
                icon: 'edit',
                handler: () => setShowModal(true),
              },
              {
                name: 'Delete SFTP User',
                icon: 'delete',
                handler: () => deleteSFTPHandler(),
              },
            ]}
          />
        )}
      </td>
      {showModal && (
        <EditSSHUser
          show={showModal}
          onClose={() => setShowModal(false)}
          handleCredSubmission={handleCredSubmission}
          isUpdating={isUpdating}
          appCredential={appCredential}
        />
      )}
    </tr>
  )
}

export const ManageAccessTable = ({
  getSFTPCredsList = [],
  refetch,
}: iManageAccessTable): JSX.Element => {
  return (
    <Container styles="mt-[20px] mb-[90px] cwpf_glb_tblBox">
      <table className="table w-full ">
        <thead>
          <tr className="text-gray-80">
            <th className="p2-semi cwpf_manageAccess_userName">Username</th>
            <th className="p2-semi">Password</th>
            <th className="p2-semi">Date Created</th>
            {/* <th className="p2-semi">Enable</th> */}
            <th className="p2-semi"></th>
          </tr>
        </thead>
        <tbody>
          {getSFTPCredsList.map(
            (appCredential: iManageAccessCreds, index: number) => {
              return (
                <TableRow
                  appCredential={appCredential}
                  index={index}
                  key={appCredential?.id}
                  refetch={refetch}
                />
              )
            },
          )}
        </tbody>
      </table>
    </Container>
  )
}
