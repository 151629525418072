/** Reusable Components */
import {Card, Row, Text} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import Empty from '../../../../../../Assets/img/manage-app/empty.png'
import Arrow from '../../../../../../Assets/img/manage-app/arrow.png'

const NoData = () => {
  return (
    <Card styles="w-[100%] space-y-20 pt-40 pb-[73px] relative">
      <Row col middle center>
        <img src={Empty} className="w-[118px] h-[122px]"></img>
        <img src={Arrow} className="absolute right-[103px] top-[16px]"></img>

        <Text med styles="p1 text-gray-80 pt-30">
          You dont have any rules right now. You may click on Add New Exclusion
          above to add one.
        </Text>
      </Row>
    </Card>
  )
}

export default NoData
