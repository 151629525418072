/** Libraries */
import React, {useState, useEffect, useRef, useCallback} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

/** Helpers */
import {
  abbreviateNumber,
  niceBytes,
  niceBytesDiskspace,
} from 'Common/Components/Core/Modal/util'

const PieChart1 = ({
  Legendtitle,
  Title,
  space,
  totalspace,
  apps = [],
  type = 'number',
}: {
  Legendtitle: string
  Title: string
  space: string
  totalspace: any
  apps?: {value: any; name: string}[]
  type?: 'number' | 'space'
}) => {
  const convertValues = useCallback(
    (value: any) => {
      if (type === 'space') return niceBytes(value, 0, true, 2)
      return abbreviateNumber(value)
    },
    [type],
  )

  const chartRef = useRef<any>(null)
  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv1', am4charts.PieChart)

      const pieSeries = chartRef.current.series.push(new am4charts.PieSeries())
      pieSeries.colors.list = [
        am4core.color('#2F39BF'),
        am4core.color('#BBC0FF'),
        am4core.color('#646EF6'),
        am4core.color('#FEB155'),
        am4core.color('#D30B0B'),
      ]
      const title = chartRef.current.titles.create()
      title.text = `[bold font-size:14px]${Legendtitle}`
      title.fontSize = 20
      title.marginLeft = 14
      title.align = 'left'
      pieSeries.dataFields.value = 'litres'
      pieSeries.dataFields.category = 'country'
      pieSeries.labels.template.disabled = true
      pieSeries.ticks.template.disabled = true
      pieSeries.slices.template.tooltipText = ''
      chartRef.current.innerRadius = am4core.percent(72)
      chartRef.current.legend = new am4charts.Legend()
      chartRef.current.legend.itemContainers.template.marginBottom = 9
      chartRef.current.legend.maxHeight = 450
      chartRef.current.legend.maxWidth = 100
      pieSeries.slices.template.hoverable = false

      chartRef.current.legend.valueLabels.template.disabled = true
      chartRef.current.legend.useDefaultMarker = false
      chartRef.current.legend.labels.template.text =
        '[font-size:18px #222E37]{country}[/]\n'
      chartRef.current.legend.itemContainers.template.clickable = false
      chartRef.current.legend.position = 'left'
      chartRef.current.legend.itemContainers.template.paddingBottom = 18
      chartRef.current.legend.labels.template.marginLeft = 15
      chartRef.current.legend.labels.template.paddingTop = 12
      chartRef.current.legend.itemContainers.template.clickable = false
      chartRef.current.legend.itemContainers.template.focusable = false
      chartRef.current.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default

      const label = pieSeries.createChild(am4core.Label)

      label.text = `[font-size:36px font-weight:600 color:#253858]${
        Legendtitle === 'Disk Space Breakdown'
          ? niceBytesDiskspace(space)
          : convertValues(space)
      }[/][font-size:20px font-weight:600 color:#253858]/${
        Title == 'Total Bandwidth'
          ? niceBytes(totalspace * 1000 * 1000, 0, false, 2)
          : type == 'space'
          ? totalspace + 'GB'
          : convertValues(totalspace)
      }[/] \n\t[font-size:16px font-weight:600 color:#253858]${Title}`

      label.horizontalCenter = 'middle'
      label.verticalCenter = 'middle'
      label.textAlign = 'middle'
      pieSeries.labels.template.maxWidth = 130
      pieSeries.labels.template.wrap = true

      pieSeries.labels.template.relativeRotation = 80
    }
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])
  React.useEffect(() => {
    if (chartRef.current) {
      let sortedApps = [...apps]

      sortedApps = sortedApps.sort((appA, appB) => appB?.value - appA.value)

      // if more then 5 elements
      if (sortedApps.length > 5) {
        // get the sum of elements after index 4
        const sum = sortedApps.slice(4).reduce((sum, e) => e.value + sum, 0)
        const others = sortedApps.slice(4)
        // trime sortedApps to 4 elements
        sortedApps = sortedApps.slice(0, 4)
        // push new element with others name, sum as their value
        sortedApps.push({
          name: 'Others ' + `(${others.length})`,
          value: sum,
        })
      }

      const getOtherAppsIndex = sortedApps?.findIndex(
        (el: any) => el?.name === 'others' && el?.value <= 0,
      )

      const getSortedApps = getOtherAppsIndex !== -1 && sortedApps.splice(1, 1)

      chartRef.current.data = sortedApps.map(eachPoint => {
        return {
          country: `[font-size:18px #222E37]${
            Legendtitle === 'Disk Space Breakdown'
              ? niceBytesDiskspace(eachPoint?.value)
              : convertValues(eachPoint?.value)
          }\r\n[font-size:14px #000000] ${eachPoint.name}`,
          litres: eachPoint?.value,
        }
      })
    }
  }, [apps, convertValues])
  return (
    <>
      <div
        id="chartdiv1"
        className="px-10 py-10  max-md:px-80"
        style={{width: '590px', height: '440px'}}
      ></div>
    </>
  )
}

export default PieChart1
