import {createSlice} from '@reduxjs/toolkit'

export const initialState: {isOnline: boolean} = {
  isOnline: true,
}

const isOnlineSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setIsOnline: (state, action) => {
      state.isOnline = action.payload
    },
  },
})

export const {setIsOnline} = isOnlineSlice.actions

export default isOnlineSlice.reducer
