import {Col, Row, Text} from 'Common/Components/Core/DesignTemplates'
import Arrow from '../../../../../Assets/img//application-listing/arrow.png'
import React from 'react'

const EmptyAppView = () => {
  return (
    <Row center middle styles=" relative h-[72vh]">
      <Col styles="absolute top-20 right-100">
        <img alt="arrow" src={Arrow} />
      </Col>
      <Col>
        <Text className="text-gray-80 flex items-center text-center" type="h5">
          You do not have any application installed.
          <br />
          Please add an application to proceed
        </Text>
      </Col>
    </Row>
  )
}

export default EmptyAppView
