import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState, AppThunk} from '../../app/store'
import {login} from './loginAPI'

//Login State
export interface LoginState {
  status: 'idle' | 'loading' | 'failed'
  isLogin: boolean
  username: string | null
  userID: string | null
  token: null
  error: string | null
}

const initialLoginState: LoginState = {
  status: 'idle',
  isLogin: false,
  username: null,
  userID: null,
  token: null,
  error: null,
}

export const loginAsync = createAsyncThunk(
  'login/getLoginState',
  async (token: string) => {
    const response = await login(token)

    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    isLogout: state => {
      state.isLogin = false
      state.username = null
      state.userID = null
      state.token = null
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(loginAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.isLogin = true
        state.username = action.payload.name
        state.userID = action.payload.id
        state.token = action.payload.token
      })
  },
})

export const {isLogout} = loginSlice.actions

export const isCurrentUser = (state: RootState) => state.login.username
export const isUserAuthenticated = (state: RootState) => state.login.isLogin

export default loginSlice.reducer
