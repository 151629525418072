const FetchClientData = async (endpoint: string, token: string) => {
  try {
    const getResponse = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: 'Basic ' + token,
        'Content-Type': 'application/json',
      },
    })
    const setResponse = await getResponse.json()
    return setResponse
  } catch (err) {
    console.error(err)
  }
}

export function login(token: string) {
  return FetchClientData('/user/login', token)
}
