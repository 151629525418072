/** Assets (CSS/Images) */
import './Avatar.css'

/** Interface */
interface iAvatar {
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  alt: string
  source: string
  active: boolean
  border: boolean
  rounded: boolean
  styles: string
}

export const Avatar = ({
  sm,
  md,
  lg,
  xl,
  source,
  active,
  border,
  rounded,
  alt = '',
  styles = '',
}: Partial<iAvatar>) => {
  const AvatarType = () => {
    if (sm) return 'cwpf_sm_avatar'
    else if (md) return 'cwpf_md_avatar'
    else if (lg) return 'cwpf_lg_avatar'
    else if (xl) return 'cwpf_xl_avatar'
    return 'cwpf_md_avatar'
  }
  const Border = border ? 'cwpf_avatarBorder' : ''
  const Active = active ? 'cwpf_actv_avatar' : ''
  return (
    <>
      {!source || source === undefined ? (
        <div
          className={`${AvatarType()} ${styles} ${Border} ${Active} flex items-center justify-center text-blue-75 font-bold`}
        ></div>
      ) : (
        <img
          src={source}
          alt={alt}
          className={`${AvatarType()} ${styles} ${Border} ${Active} `}
        />
      )}
    </>
  )
}

Avatar.defaultProps = {
  rounded: false,
  border: false,
  active: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
}

export default Avatar
