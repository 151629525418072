//libraries
import {useEffect} from 'react'
import {useTracking} from 'react-tracking'
import {useAppSelector, useAppDispatch} from 'app/hooks'

//apis
import {useGetAllPlansQuery} from '../../app/services'

//redux slice
import {
  setCheckoutPlan,
  setCheckoutPlanIndex,
  setPlansDataLength,
} from './PlansSliderSlice'

/* Shared Components*/

import RangeSlider from '../../Common/Components/Hooks/RangeSlider/RangeSlider'
import PlanPrice from './PlanPrice'
import PlanSpecs from './PlanSpecs'
/* ---x---  */

const PlanSlider = () => {
  const dispatch = useAppDispatch()
  const {trackEvent} = useTracking()
  /* State Data */
  const {data: {results} = [], isLoading} = useGetAllPlansQuery()
  const checkoutPlan = useAppSelector(state => state.plans.checkoutPlan)
  const checkoutPlanIndex = useAppSelector(
    state => state.plans.checkoutPlanIndex,
  )
  /* ---x---  */
  const handleCurrentPlan = (planIdx: number) => {
    const getCurrentPlan = results.find(
      (plan: Record<string, unknown>) => plan.id === planIdx,
    )
    if (getCurrentPlan) {
      dispatch(setCheckoutPlan(getCurrentPlan))
      dispatch(setCheckoutPlanIndex(planIdx))
      trackEvent({
        event_name: 'fmui_plan_slider',
        attributes: {
          plan_id: getCurrentPlan.id,
          plan_disk_space: getCurrentPlan.storage_gb,
          plan_bandwidth: getCurrentPlan.bandwith_gb,
          plan_visits: getCurrentPlan.max_montly_visits,
          plan_app_limit: getCurrentPlan.max_app_count,
          plan_price: getCurrentPlan.price,
          page: 'select_plan',
        },
      })
    }
  }

  useEffect(() => {
    if (isLoading) return
    dispatch(setCheckoutPlan(results[Math.ceil((results?.length - 1) / 2)]))
    dispatch(setCheckoutPlanIndex(Math.ceil(results?.length / 2)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      {isLoading ? (
        <div>Loading </div>
      ) : (
        <>
          <PlanPrice plansData={checkoutPlan} />

          <div className="cwpf_slctPlan_sldr_wrap">
            <div className="cwpf_slctPlan_sldrBox my-40">
              <RangeSlider
                value={checkoutPlanIndex}
                max={results?.length}
                classes="cwpf_slctPlan_sldrInput w-full"
                handleCurrentPlan={handleCurrentPlan}
                data={results}
              />
            </div>

            <PlanSpecs plansData={checkoutPlan} />
          </div>
        </>
      )}
    </>
  )
}

export default PlanSlider
