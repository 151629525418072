/** Libraries */
import moment from 'moment'
import Item from 'antd/lib/list/Item'

/** Reusable Components */
import {
  Row,
  Col,
  Container,
  Text,
  Icon,
  Button,
  DropDownMenu,
} from '../../../Core/DesignTemplates/index'

/** Store/Services */
import {useAddApplicationMutation, useGetRegionsQuery} from 'app/services'
import {useGetCurrentUserQuery} from 'app/services/platform3'

/** Assets (CSS/Images) */
import wordpressLogo from '../../../../../Assets/img/application-listing/wp-logo.svg'
import woocommerceLogo from '../../../../../Assets/img/application-listing/woo-logo.svg'

/** Helper */
import {iListGridItem} from '../util'

const AppException = ({app}: iListGridItem): JSX.Element => {
  const [addApplication] = useAddApplicationMutation()
  const currentUser = useGetCurrentUserQuery('')
  const {data: regionList} = useGetRegionsQuery()

  const {name, created, app_type, region} = app
  const createDate = moment(created).format('Do MMMM, YYYY')
  const addAgainHandler = () => {
    addApplication({
      app_type_id: app_type.id,
      name: name,
      region_id: regionList?.results
        .filter((item: {active: boolean}) => item.active)
        .find(
          (item: {name: string}) =>
            item?.name.toLowerCase() === region.toLowerCase(),
        ).id,
      user_email: currentUser.currentData.email,
    })
  }
  const AppActions = (
    <Row middle center styles="pointer-events-none opacity-[0.5]">
      <Col onClick={(e: {stopPropagation: () => void}) => e.stopPropagation()}>
        <DropDownMenu
          border
          bottomLeft
          disabled
          buttons={[
            {
              name: 'Clone Application',
              icon: 'content_copy',
            },
            {
              name: 'Push to Staging',
              icon: 'exit_to_app',
            },
            {
              name: 'Delete Application',
              icon: 'delete_outlined',
            },
          ]}
        />
      </Col>
    </Row>
  )
  return (
    <>
      <Row middle styles={`cwpf_appView_listBox mb-24 relative`}>
        <Col styles={`flex-1 cwpf_appView_listBox_install`}>
          <Row middle styles="cwpf_listingItemException_mainRow">
            <Col styles="">
              <Container styles="cwpf_userApp_screenShot flex items-center justify-center w-[167px] h-[120px] max-lg:w-[100%] relative">
                <Container styles="cwpf_listingItem_warningIcon">
                  <Icon styles=" text-[50px] text-red-75">warning_amber</Icon>
                </Container>
              </Container>
            </Col>

            <Col styles="w-full py-10 pl-28">
              <Row middle between styles="lg:pr-20 w-full max-lg:text-center">
                <Col lg={11}>
                  <Row col styles={`lg:flex  lg:justify-start`}>
                    <Col>
                      <Row middle gutter={8} styles=" max-lg:justify-center">
                        <Col>
                          <Text type="h5" styles="h5-bold text-gray-80">
                            {name}
                          </Text>
                        </Col>
                        <Col>
                          <img
                            className={`mr-4 cwpf_app_type_img cwpf_app_type_${app_type.name}`}
                            src={
                              app_type.id === 2
                                ? wordpressLogo
                                : woocommerceLogo
                            }
                            alt={app_type.name}
                          ></img>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <span className="text-12 text-gray-60 ">
                        Created: {createDate}
                      </span>
                    </Col>
                    <Col>
                      <Row middle styles="mt-8 max-lg:justify-center">
                        <Col>
                          <Text styles="text-14 text-red-75">
                            <Icon styles=" text-14 text-red-75 mr-8 relative top-[2px]">
                              warning_amber
                            </Icon>
                            There was an error encountered while installing this
                            app.
                          </Text>
                        </Col>
                        <Col
                          end
                          className="flex items-center justify-center hidden"
                        >
                          <Button small primaryText onClick={addAgainHandler}>
                            Try Again
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={1}>{AppActions}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default AppException
