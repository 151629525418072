const RangeSlider = (props: any) => {
  const handleChange = (e: any) => {
    const eventValue = Number(e.target.value)
    props.handleCurrentPlan(eventValue)
    // props.setCurrentSliderValue(eventValue)
  }

  const get_slider_points = []
  for (let i = 1; i < props.max; i++) {
    get_slider_points.push(
      <span
        key={i}
        className={
          'absolute w-[6px] h-[6px] bg-light rounded-[100%] top-[50%] translate-y-[-50%]'
        }
        style={{left: (i / (props.max - 1)) * 100 + '%'}}
      ></span>,
    )
  }

  const set_sliderThumb_post =
    props.value === props.max ? 98 : ((props.value - 1) / (props.max - 1)) * 100

  return (
    <div className="relative">
      <input
        id={`myRange-${props.value}`}
        type="range"
        value={props.value}
        className={`slider ${props.classes}`}
        onChange={handleChange}
        min={1}
        max={props.max}
        step={1}
      />

      <div
        data-tip={`US $${parseInt(props.data[props.value - 1]?.price)}/mo`}
        className="tooltip tooltip-open tooltip-bottom items-center cwpf_slctPlan_sldrThumb"
        style={{
          left: set_sliderThumb_post + '%',
          transform: 'translate(-50%, 50%)',
        }}
      ></div>

      <div
        className="cwpf_slctPlan_sldrSteps"
        style={{
          backgroundSize:
            ((props.value - 1) / (props.max - 1)) * 100 + '% 100%',
        }}
      >
        {get_slider_points}
      </div>
    </div>
  )
}

export default RangeSlider
