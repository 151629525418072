import {useState, useEffect, useRef} from 'react'

export const useDebounce = (callback: any, delay: number) => {
  const latestCallback = useRef<any>()
  const [callCount, setCallCount] = useState<number>(0)

  useEffect(() => {
    latestCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (callCount > 0) {
      const fire = () => {
        setCallCount(0)
        latestCallback.current()
      }

      const id = setTimeout(fire, delay)
      return () => clearTimeout(id)
    }
  }, [callCount, delay])

  return () => setCallCount(callCount => callCount + 1)
}
