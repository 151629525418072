/** Store/Services */

/** Reusable Components */
import {Row, Col} from '../../../Core/DesignTemplates/index'
import {iAppHeader} from '../util'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'

const AppListHeader = (props: iAppHeader):JSX.Element => {
  const {
    headerSelection,
    sortText,
    sortDateHandler,
    viewHandler,
    view,
    headerCheck,
    headerDeselectAll,
    totalApps,
    selectedApp,
    multiDeleteHandler,
  } = props

  return (
    <Row middle between styles="py-40 max-md:flex-col z-[9999]">
      <Col styles="flex-2">
        <Row>
          <Col>
            <HeaderLeft
              selectedApp={selectedApp}
              totalApps={totalApps}
              headerDeselectAll={headerDeselectAll}
              headerSelection={headerSelection}
              headerCheck={headerCheck}
            />
          </Col>
        </Row>
      </Col>

      <Col>
        <HeaderRight
          totalApps={totalApps}
          view={view}
          sortText={sortText}
          sortDateHandler={sortDateHandler}
          viewHandler={viewHandler}
          selectedApp={selectedApp}
          multiDeleteHandler={multiDeleteHandler}
        />
      </Col>
    </Row>
  )
}

export default AppListHeader
