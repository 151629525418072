/** Libraries */
import React from 'react'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {useGetTotalAppsQuery, useGetTotalDiskSpaceQuery} from 'app/services'

/** Reusable Components */
import {Col, Container, Row, Text} from 'Common/Components/Core/DesignTemplates'
import ListBoxes from './Charts/ListBoxes'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'

const TotalInstalledApp = () => {
  const {data, isLoading, isSuccess} = useGetTotalAppsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <Container styles="px-8 min-h-[102px]">
      <Row between>
        <Col>
          <Text type="p" semi styles="text-gray-80 mb-10">
            Total Installed{' '}
            {`${data?.used > 1 ? ' Applications' : ' Application'}`}
          </Text>
        </Col>
        <Col>
          {isSuccess && (
            <ListBoxes installedApp={data?.used || 0} listColor="bg-blue-100" />
          )}
        </Col>
      </Row>

      <Row>
        {isLoading ? (
          <Skeleton width={100} height={10} borderRadius={10} />
        ) : (
          <Col styles="flex">
            <Text type="div" styles="h1-semi text-gray-80">
              {data?.used || 0}
            </Text>
            <Text
              type="h5"
              semi
              styles="text-gray-80 relative top-[5px] self-center"
            >
              /{data?.allocated || 0}
            </Text>
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Text styles="p3 text-gray-80">
            You can install {data?.allocated - data?.used || 0} more
            {`${
              data?.allocated - data?.used > 1
                ? ' applications'
                : ' application'
            }`}
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(TotalInstalledApp)
