import * as Yup from 'yup'
import {Formik, Form} from 'formik'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {
  Button,
  Col,
  Container,
  Row,
  TextField,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import Regex from 'Constants/Regex'
import {useState} from 'react'
const userNames = [
  'ansible',
  'backup',
  'bin',
  'daemon',
  'Debian-exim',
  'games',
  'gnats',
  'irc',
  'libuuid',
  'list',
  'lp',
  'mail',
  'man',
  'memcache',
  'messagebus',
  'mysql',
  'newrelic',
  'news',
  'nobody',
  'ntp',
  'postfix',
  'proxy',
  'root',
  'sensu',
  'shellinabox',
  'sshd',
  'statd',
  'sync',
  'sys',
  'systeam',
  'systemd-bus-proxy',
  'systemd-network',
  'systemd-resolve',
  'systemd-timesync',
  'test',
  'uucp',
  'uuidd',
  'varnish',
  'varnishlog',
  'vnstat',
  'www-data',
  'cw-syncuser',
  'gcp',
  'gke',
  'pub',
  'sub',
]
interface iAddUSer {
  username: string
  password: string
}

const initialValues: iAddUSer = {
  username: '',
  password: '',
}

const validate = Yup.object({
  username: Yup.string()
    .required('Please enter username')
    .trim()
    .min(6, 'User name must be atleast 6 characters.')
    .max(63, 'User Name must be less than 63 characters')
    .test(
      'is-42',
      "Special characters are not allowed except '-'",
      (value: any) => {
        const val = '!@#$%^&*()_+=[]{};\':"|,.<>/?]\\+'
          .split('')
          .some(element => value.includes(element))
        return !val
      },
    )
    .matches(/^([a-zA-Z\d]+(-[a-zA-Z\d]+)*)$/, 'Invalid Username')
    .matches(/^([a-z\d]+(-[a-z\d]+)*)$/, 'Capital letters are not allowed')
    .test(
      'username-include-check',
      (value: any) => `${value.value} is not allowed for username`,
      (value: any) => !userNames.includes(value),
    ),
  password: Yup.string()
    .required('Please enter password')
    .min(10, 'Password must be atleast 10 characters.')
    .max(40, 'Password must be less than 40 characters')
    .matches(
      Regex.generalPassword_regex.smallChar,
      'At least one small character',
    )
    .matches(
      Regex.generalPassword_regex.capsChar,
      'At least one capital character',
    )
    .matches(Regex.generalPassword_regex.number, 'At least one number')
    .matches(
      Regex.excludeSpecialChar,
      'Password should not contain any special character',
    ),
})

const AddSSHUser = (props: any) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Modal show={props.show} classes="min-w-[507px]" onClose={props.onClose}>
      <Container styles="py-32 px-24">
        <Text type="h6" med styles="text-gray-80">
          Add SSH/SFTP user
        </Text>
        <Container styles="cwpf_mangaeaccess_form mt-16">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={props.handleCredSubmission}
          >
            {({dirty, isValid, values, isSubmitting}) => (
              <Form>
                <Row col>
                  <Col styles="mb-24">
                    <TextField
                      label="Username"
                      name="username"
                      type="text"
                      placeHolder="jhon-ducket"
                    />
                  </Col>
                  <Col styles="mb-[38px]">
                    <TextField
                      label="Password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeHolder="*******"
                      styles="cwpf_passowrd_field"
                      postfixIcon={
                        values.password !== '' && {
                          name: `${
                            showPassword ? 'visibility' : 'visibility_off'
                          }`,
                          handler: () => setShowPassword(prev => !prev),
                          styles: 'cwpf_password_icon text-gray-80',
                        }
                      }
                      showCopyIcon={values.password !== '' ? true : false}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Button disabled={isSubmitting}>Confirm</Button>
                      <Button primaryText onClick={props.onClose}>
                        Cancel
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Modal>
  )
}
export default AddSSHUser
