import React, {useState} from 'react'

//design system
import {
  Card,
  Row,
  Text,
  Button,
  Col,
  Container,
  Icon,
  DropDownMenu,
  CopyClipboard,
} from 'Common/Components/Core/DesignTemplates'

//apis
import {
  apiSlice,
  useGetSingleApplicationQuery,
  useVerifyDomainMutation,
} from 'app/services'

import {useOutletContext, useParams} from 'react-router-dom'
//components
import CloudfareFeature from './CloudfareFeature'

//modals
import DomainCnameModal from './Modal/DomainCnameModal'
import DomainARecordModal from './Modal/DomainARecordModal'

//svg
import {ReactComponent as Pendingsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/pending.svg'
import {ReactComponent as Completedsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/complete.svg'
import {ReactComponent as Errorsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/error.svg'
import {ReactComponent as Launchsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/launch.svg'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {cloudfareLookup, domainStatus} from '../utils'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {useAppDispatch} from 'app/hooks'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import Skeleton from 'react-loading-skeleton'

const CloudfareVerify = ({
  deleteModal,
  setTxtRecordModal,
  customDomain: {
    domain = '',
    domain_status = '',
    is_cloudflare_managed = 'false',
  },
}: any) => {
  //const domain_status = domainStatus.domainisLive
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [domainARecordModal, setDomainARecordModal] = useState(false)
  const [domainCnameModal, setDomainCnameModal] = useState(false)

  const propsOutlet: iPropsOutlet = useOutletContext()
  const {app_id} = useParams()
  const [verifyDomain] = useVerifyDomainMutation()

  // const checkDomainStatus = (val: string) => {
  //   if (domain_status === domainStatus.pendingVerification)
  //     return `${errorLookup.pendingVerification.name}`
  //   if (domain_status === domainStatus.domainisNotLive)
  //     return 'Domain is not Live'
  //   if (domain_status === domainStatus.domainisLive) return 'Domain is Live'
  //   if (domain_status === domainStatus.verificationFailed)
  //     return 'Failed to Verify'
  //   return ''
  // }

  const verifyAgainHandler = useDebounce(() => {
    setSubmitting(true)
    verifyDomain({app_id})
      .unwrap()
      .then((fulfilled: any) => {
        setSubmitting(false)
        if (fulfilled.error) throw new Error()
        if (fulfilled.operation_id) {
          customToast({
            level: 'info',
            title: '',
            description: `Your domain is being added.`,
            loading: true,
          })
          dispatch(
            apiSlice.endpoints.getOperations.initiate({
              id: fulfilled.operation_id,
            }),
          )
          return
        }
        customToast({
          level:
            domain_status === domainStatus.pendingVerification ||
            domain_status === domainStatus.domainisNotLive
              ? 'warning'
              : 'success',
          title: '',
          description: `${cloudfareLookup[fulfilled.domain_status]?.error}`,
        })
      })
      .catch(err => {
        console.error(err, 'error')
      })
  }, 2000)

  const iconView = (
    <>
      {domain_status === domainStatus.pendingVerification && <Pendingsvg />}
      {domain_status === domainStatus.domainisLive && (
        <Tooltip text="Cloudflare SSL Certifcate" top>
          <Completedsvg />
        </Tooltip>
      )}
      {[domainStatus.verificationFailed, domainStatus.domainisNotLive].includes(
        domain_status,
      ) && <Errorsvg />}
    </>
  )

  const AccordionText = (
    <>
      {domain_status === domainStatus.domainisLive && (
        <Container styles="bg-gray-3 mt-10 ">
          <CloudfareFeature accordion />
        </Container>
      )}
      {[
        domainStatus.pendingVerification,
        domainStatus.domainisNotLive,
      ].includes(domain_status) && (
        <Container styles="bg-gray-3 rounded-4  mt-24">
          <Row styles="p-16">
            <Col>
              <Icon name="info_outline" styles="text-blue-100 cursor-default" />
            </Col>
            <Col>
              {domain_status === domainStatus.pendingVerification && (
                <Text styles="text-gray-80 p3 ml-12">
                  Your domain ownership verification is in process, it may take
                  up to 24 hours for the verification to complete depending on
                  your DNS service provider. Once the verification is complete,
                  you will need to point your domain to our Cloudflare
                  Enterprise network using A/CNAME records.
                </Text>
              )}
              {domain_status === domainStatus.domainisNotLive && (
                <Text styles="text-gray-80 p3 ml-12">
                  We're checking if your domain's DNS records point to our
                  Cloudflare Enterprise Network. This can take up to 24 hours
                  depending on your DNS service provider.
                </Text>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
  const ButtonView = (
    <>
      {
        <>
          {[
            domainStatus.domainisNotLive,
            domainStatus.pendingVerification,
          ].includes(domain_status) && (
            <Button
              hide={propsOutlet.isAppLoading}
              small
              primaryText
              icon="refresh"
              large
              iconStyles="mb-2 text-18 "
              iconAlign="left"
              onClick={verifyAgainHandler}
              disabled={submitting && true}
            >
              {domain_status === domainStatus.domainisNotLive
                ? 'Refresh'
                : 'Verify Again'}
            </Button>
          )}
          {domain_status === domainStatus.domainisNotLive && (
            <Button
              hide={propsOutlet.isAppLoading}
              small
              primary
              icon={is_cloudflare_managed ? 'add' : ''}
              iconStyles="mb-2 text-18"
              large
              iconAlign="left"
              onClick={() => {
                is_cloudflare_managed
                  ? setDomainCnameModal(true)
                  : setDomainARecordModal(true)
              }}
            >
              {is_cloudflare_managed ? 'View CNAME Records' : ' Add A Record'}
            </Button>
          )}
        </>
      }
      {/* {domain_status === domainStatus.pendingVerification && (
        <Button
          small
          primaryText
          icon="refresh"
          large
          iconStyles="mb-2 text-18 "
          iconAlign="left"
          onClick={verifyAgainHandler}
        >
          Verify Again
        </Button>
      )} */}
    </>
  )
  return (
    <>
      <Container styles="mt-32">
        <Card>
          <Row middle between>
            <Col>
              <Row gutter={16} middle>
                <Col>
                  {propsOutlet.isAppLoading ? (
                    <Skeleton height={60} width={60} borderRadius={4} />
                  ) : (
                    iconView
                  )}
                </Col>
                <Col>
                  <Row>
                    <Text type="h6" semi styles="text-gray-100">
                      {propsOutlet.isAppLoading ? (
                        <Skeleton height={19} width={221} borderRadius={4} />
                      ) : (
                        propsOutlet.applicationData?.custom_domain?.domain
                      )}
                    </Text>
                    <Container styles="relative">
                      {domain_status === domainStatus.domainisLive ? (
                        <Launchsvg
                          className="text-gray-40 ml-8 mt-2 text-20"
                          onClick={() =>
                            window.open(
                              'http://' +
                                (propsOutlet.applicationData?.custom_domain
                                  ?.domain
                                  ? propsOutlet.applicationData?.custom_domain
                                      ?.domain
                                  : propsOutlet.applicationData?.app_fqdn),
                              '_blank',
                            )
                          }
                        />
                      ) : (
                        <CopyClipboard
                          noStyles
                          hide={propsOutlet.isAppLoading}
                          styles="text-gray-40 ml-8 text-20"
                          copyText={
                            propsOutlet.applicationData?.custom_domain?.domain
                          }
                        />
                      )}
                    </Container>
                  </Row>
                  <Text styles="text-gray-60 p2">
                    {propsOutlet.isAppLoading ? (
                      <Skeleton height={11} width={134} borderRadius={4} />
                    ) : (
                      cloudfareLookup[domain_status]?.name
                    )}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                {ButtonView}
                <DropDownMenu
                  hide={propsOutlet.isAppLoading}
                  text={{textStyle: 'text-gray-80 p3'}}
                  styles="ml-12"
                  border
                  disabled={propsOutlet.checkPolling(Parameters.domain)}
                  buttons={
                    domain_status === domainStatus.pendingVerification
                      ? [
                          {
                            name: 'View TXT Records',
                            icon: 'list',
                            handler: () => setTxtRecordModal(true),
                          },
                          {
                            name: 'Delete',
                            icon: 'delete_outline',
                            handler: () => deleteModal(true),
                          },
                        ]
                      : [
                          {
                            name: 'Delete',
                            icon: 'delete_outline',
                            handler: () => deleteModal(true),
                          },
                        ]
                  }
                />
              </Row>
            </Col>
          </Row>

          {propsOutlet.isAppLoading ? (
            <Skeleton height={72} width={'100%'} borderRadius={4} />
          ) : (
            AccordionText
          )}
        </Card>
      </Container>

      <DomainARecordModal
        show={domainARecordModal}
        domain={domain}
        onClose={() => setDomainARecordModal(false)}
      />
      <DomainCnameModal
        show={domainCnameModal}
        domain={domain}
        onClose={() => setDomainCnameModal(false)}
      />
    </>
  )
}

export default CloudfareVerify
