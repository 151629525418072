/** Libraries */
import {ReactChild, useRef, useState} from 'react'

/** Reusable Components */
import Card from '../Card/Card'
import Container from '../Container/Container'
import Icon from '../Icon/Icon'

/** Assets (CSS/Images) */
import './Accordion.css'

/** Interface */
interface iAccordion {
  title: string | React.ReactNode
  children: React.ReactNode
}

const Accordion = ({title, children}: iAccordion) => {
  const [visible, setVisible] = useState(false)
  const contentEl = useRef() as React.MutableRefObject<HTMLDivElement>
  return (
    <Container styles={`cursor-pointer rounded-[0px] p-[16px]`}>
      <Container
        styles="relative overflow-hidden pr-40"
        onClick={() => {
          setVisible(!visible)
        }}
      >
        {title}
        <Icon styles="absolute top-[0px] right-0 text-gray-80">
          {!visible ? `expand_more` : `expand_less`}
        </Icon>
      </Container>
      <div
        ref={contentEl}
        className={`cwPltfrm_accordion`}
        style={
          visible
            ? {height: contentEl.current && contentEl.current?.scrollHeight}
            : {height: '0px'}
        }
      >
        {children}
      </div>
    </Container>
  )
}

export default Accordion
