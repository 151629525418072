/** Libraries */
import {useTracking} from 'react-tracking'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

/** Reusable Components */
import {
  Row,
  Col,
  Container,
  Text,
  Icon,
  WebSnap,
} from '../../../Core/DesignTemplates/index'
import AppPolling from './AppPolling'
import DropDownMenu from 'Common/Components/Core/DesignTemplates/DropDownMenu/DropDownMenu'
import AppStats from './AppStats'
import AppException from './AppException'

/** Helpers */
import {iListGridItem} from '../util'

/** Assets (CSS/Images) */
import wordpressLogo from '../../../../../Assets/img/application-listing/wp-logo.svg'
import woocommerceLogo from '../../../../../Assets/img/application-listing/woo-logo.svg'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const ListingItem = ({
  app,
  onChange,
  checkbox,
  isPolling,
  deleteAppHandler,
  cloneAppHandler,
  stageAppHandler,
  exceptionType,
  setShowAppCard,
}: iListGridItem): JSX.Element => {
  const {trackEvent} = useTracking()

  const navigate = useNavigate()
  const {id, name, created, app_type, app_fqdn, cname, region} = app
  const createDate = moment(created).format('Do MMMM, YYYY')
  const get_cname = app?.custom_domain?.domain
    ? app?.custom_domain?.domain
    : app_fqdn

  const AppImg = (
    <>
      <WebSnap url={get_cname} styles="rounded-l w-full h-full" />
      {exceptionType && (
        <Container styles="cwpf_listingItem_warningIcon">
          <Icon styles=" text-[50px] text-red-75">warning_amber</Icon>
        </Container>
      )}
    </>
  )
  const AppDescription = (
    <Row col styles={`lg:flex  lg:justify-start`}>
      <Col>
        <Row middle gutter={8} styles=" max-lg:justify-center">
          <Col>
            <Text semi type="h5" styles="text-gray-80">
              {name}
            </Text>
          </Col>
          <Col>
            <img
              className={`mr-4 cwpf_app_type_img cwpf_app_type_${app_type.name}`}
              src={app_type.id === 2 ? wordpressLogo : woocommerceLogo}
              alt={app_type.name}
            ></img>
          </Col>
        </Row>
      </Col>
      <Col>
        <span className="text-12 text-gray-60 ">Created: {createDate}</span>
      </Col>
      <Col>
        <Row middle styles="mt-8 max-lg:justify-center">
          {exceptionType ? (
            <Col>
              <Text styles="text-14 text-red-75">
                <Icon styles=" text-14 text-red-75 mr-[6px] relative top-[2px]">
                  warning_amber
                </Icon>
                There was an error encountered while installing this app.
              </Text>
            </Col>
          ) : (
            <>
              <Col>
                <Tooltip top text={region}>
                  <Row styles="mr-10 ml-[-5px]">
                    <Icon styles="text-gray-80 mr-2">place</Icon>
                    <Text styles="cwpf_listingItem_region p3">{region}</Text>
                  </Row>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip top text={get_cname}>
                  <Row
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                    ) => e.stopPropagation()}
                  >
                    <Icon styles="text-gray-80 mr-4">link</Icon>
                    <Text
                      type="a"
                      href={`https://${get_cname}`}
                      target="_blank"
                      styles="p3 cwpf_listingItem_cname"
                    >
                      {get_cname}
                    </Text>
                  </Row>
                </Tooltip>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  )

  const AppActions = (
    <Row
      middle
      center
      styles={`${exceptionType ? 'pointer-events-none opacity-50' : ''}`}
    >
      <Col
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <DropDownMenu
          border
          bottomLeft
          buttons={[
            // {
            //   name: 'Clone Application',
            //   icon: 'content_copy',
            //   handler: cloneAppHandler,
            // },
            // {
            //   name: 'Push to Staging',
            //   icon: 'exit_to_app',
            //   handler: stageAppHandler,
            // },
            {
              name: 'Delete Application',
              icon: 'delete_outlined',
              handler: deleteAppHandler,
            },
          ]}
        />
      </Col>
    </Row>
  )
  return isPolling ? (
    <AppPolling
      checkbox={checkbox}
      onChange={onChange}
      app={app}
      setShowAppCard={setShowAppCard}
    />
  ) : exceptionType ? (
    <AppException app={app} />
  ) : (
    <>
      <Row middle styles={`cwpf_appView_listBox mb-24 relative w-full`}>
        <Col
          styles={`flex-1 `}
          onClick={() => {
            if (!(isPolling || exceptionType)) {
              navigate(`/manage-app/${id}`)
              trackEvent({
                event_name: 'fmui_manage_application',
                attributes: {
                  app_id: id,
                },
              })
            }
          }}
        >
          <Row middle styles=" cwpf_listingItem_mainRow">
            <Col>
              <Container styles="cwpf_userApp_screenShot flex items-center justify-center w-[167px] h-[120px] max-lg:w-[100%] relative">
                {AppImg}
              </Container>
            </Col>

            <Col styles="w-full pl-28">
              <Row middle between styles="lg:pr-20 w-full max-lg:text-center">
                <Col lg={6}>{AppDescription}</Col>
                <Col lg={6}>
                  <Row middle gutter={24}>
                    <Col lg={10}>
                      <AppStats app={app} color="text-gray-80" />
                    </Col>
                    <Col lg={2}>{AppActions}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default ListingItem
