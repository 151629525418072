/** Libraries */

/** Reusable Components */
import {
  Button,
  Card,
  Col,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */

/** Assets (CSS/Images) */
import NoDataIMG from '../../../../../../Assets/img/manage-app/app-vitals-no-data.png'

const NoData = ({
  loading,
  clickHandler,
}: {
  loading: boolean
  clickHandler: () => void
}) => {
  return (
    <>
      <Card shadow>
        <Row center col middle styles="w-[100%] space-y-20 pt-40 pb-24">
          <Col>
            <img src={NoDataIMG} className="w-[230px] h-[77px]"></img>
          </Col>
          <Col>
            <Text med styles="p1 text-gray-80 max-w-[487px] text-center">
              Run test to benchmark your website’s performance. We give you
              detailed scores of how to improve your website.
            </Text>
          </Col>
          <Col>
            <Button primaryOutline loading={loading} onClick={clickHandler}>
              {!loading && ' Run Test'}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export default NoData
