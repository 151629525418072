import React from 'react'

//design system
import {
  Card,
  Container,
  Text,
  Row,
  Col,
  Accordion,
  Icon,
  Separator,
  Button,
} from 'Common/Components/Core/DesignTemplates'

//components
import Field from './Field'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {useGetCloudfareApisQuery} from 'app/services'

const DomainCnameModal = (props: any) => {
  const {data} = useGetCloudfareApisQuery()

  return (
    <Modal
      show={props.show}
      classes="min-w-[650px] cwpf_domaincloudfaremodal"
      onClose={props.onClose}
    >
      <Container styles="py-24 px-32">
        <Text type="h6" semi styles="text-gray-80">
          Point your domain to Cloudflare
        </Text>
        <Container styles="my-12">
          <Text styles="p3 ">
            We have successfully verified the ownership of your domain. Go to
            the DNS management section of your Cloudflare account and add the
            CNAME record below to point the domain to our Cloudflare Enterprise
            Network. You must use the DNS-only proxy mode which simply requires
            turning your orange cloud to grey.
          </Text>
          <Text styles="p3 mt-4">
            If you already have a corresponding A, AAAA or CNAME record with the
            given Name/Host, you should remove them and add the one shared below
          </Text>
        </Container>
        <Card>
          <Row top>
            <Col lg={3}>
              <Text semi styles="p2 text-gray-80">
                CNAME <br />
                Record
              </Text>
            </Col>
            <Col lg={9}>
              <Row wrap>
                <Col lg={12}>
                  <Field label="Name /Host" value={props?.domain} />
                </Col>
                <Separator styles="my-12 text-gray-10" h={1} />
                <Col lg={12}>
                  <Field label="Value" value={data?.cloudflare_alias} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Text styles="p3 text-gray-60 my-20">
          In addition to configuring the CNAME record for your root domain, we
          recommend pointing www the www version of your domain to our
          Cloudflare Enterprise as well. To do this, add or edit a CNAME record
          for your www domain pointing to your root domain.
        </Text>
        <Button primary medium styles="mt-32" onClick={props.onClose}>
          Done
        </Button>
      </Container>
    </Modal>
  )
}

export default DomainCnameModal
