/** Libraries */
import {useState} from 'react'

/** Reusable Components */
import {Button, Row} from 'Common/Components/Core/DesignTemplates'
import DummyModal from 'Common/Components/Core/Modal/ManageAppModals/PerformanceModal/DummyModal'

/** Assets (CSS/Images) */
import ResetCacheIcon from 'Assets/img/manage-app/resetcache.svg'

const Footer = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <>
      <Row
        between
        middle
        styles="py-12 pl-[27px] hidden pr-18 border-t border-t-solid border-t-gray-3 rounded-4 min-h-[56px]"
      >
        <Button
          medium
          primaryText
          styles="text-blue-100 px-[0px] hidden"
          onClick={() => setShowModal(true)}
        >
          <img src={ResetCacheIcon} className="mr-4"></img>
          Reset Cache
        </Button>
      </Row>
      <DummyModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
} 

export default Footer
