import {
  Col,
  Container,
  Row,
  Tabs,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import {useParams} from 'react-router-dom'
import {useTracking} from 'react-tracking'
import AccessLogs from './AccessLogs'
import ErrorLogs from './ErrorLogs'

const LogsContainer = () => {
  const {trackEvent} = useTracking()
  const {app_id} = useParams()
  const mixPanelEvent = (label: string) => {
    trackEvent({
      event_name:
        label === 'Access Logs' ? 'fmui_access_logs' : 'fmui_error_logs',
    })
  }
  return (
    <Container styles="my-[12px] mx-30">
      <Text semi type="h4" styles="text-gray-80">
        Log Viewer
      </Text>
      <Row between>
        <Col>
          <Text type="p" styles="p1 text-gray-80">
            Monitor your server events and track error logs as they are reported
            to detect performance issues.
          </Text>
        </Col>
      </Row>
      <Tabs end onClickHanler={mixPanelEvent}>
        <div id="Access Logs">
          <AccessLogs />
        </div>
        <div id="Error Logs">
          <ErrorLogs />
        </div>
      </Tabs>
    </Container>
  )
}

export default LogsContainer
