/** Libraries */
import {Field, ErrorMessage} from 'formik'

/** Reusable Components */
import TextError from './TextError'
import {Container, Text} from '..'

const RadioButton = ({
  name,
  options,
  labelStyles = '',
  styles = '',
  activeClass,
  icon,
  vertical,
  horizontal,
  label = '',
  spacing = '',
  ...rest
}: any) => {
  const align = () => {
    if (vertical) return 'flex'
    if (horizontal) return 'flex flex-col'
    return 'flex'
  }
  return (
    <>
      {label && (
        <Text type="p" styles={`text-gray-80 p2 mb-10 ${labelStyles}`}>
          {label}
        </Text>
      )}
      <Container styles={`${align()} ${spacing}`}>
        <Field name={name} {...rest}>
          {({field}: any) => {
            return options.map((option: any) => {
              return (
                <Container styles="flex items-center" key={option.value}>
                  <input
                    className={`w-20 h-20 radio checked:bg-blue-75  ${styles} mr-10`}
                    type="radio"
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label className="p2 text-gray-80" htmlFor={option.value}>
                    {option.name}
                  </label>
                </Container>
              )
            })
          }}
        </Field>
      </Container>

      <ErrorMessage name={name}>
        {(msg: any) => <TextError icon={icon}>{msg}</TextError>}
      </ErrorMessage>
    </>
  )
}

export default RadioButton
