/** Libraries */
import {useTracking} from 'react-tracking'
import {useAppDispatch} from 'app/hooks'

/** Reusable Components */
import {Button, DropDownMenu} from 'Common/Components/Core/DesignTemplates'
import {toggleModal} from 'Common/Components/Core/Modal/ModalSlice'
import {
  Row,
  Col,
  Text,
  Icon,
  Separator,
} from '../../../Core/DesignTemplates/index'

/** Helpers */
import {ModalName} from 'Common/Components/Core/Modal/util'
import {iAppHeader} from '../util'
import {useGetCurrentPlanQuery} from 'app/services'

const HeaderRight = ({
  sortText,
  sortDateHandler,
  totalApps,
  view,
  viewHandler,
  selectedApp,
  multiDeleteHandler,
}: iAppHeader): JSX.Element => {
  const dispatch = useAppDispatch()
  const {trackEvent} = useTracking()

  const {data: {plan: currentPlan = {}, app = {}} = {}} =
    useGetCurrentPlanQuery()

  const addApplicationHandler = () => {
    trackEvent({
      event_name: 'fmui_add_app',
    })
    if (currentPlan?.max_app_count > totalApps) {
      dispatch(toggleModal(ModalName.addApplicationModal))
    } else {
      dispatch(toggleModal(ModalName.limitAppModal))
    }
  }
  // const {id, name, created, app_type, app_fqdn, cname, region} = app
  // const sortDateHandler = () => {
  //   created.sort((a:any,b:any) => )
  // }
  return (
    <Row middle styles="space-x-10 relative">
      <Col>
        {selectedApp >= 1 && (
          <Button
            neutralText
            icon="delete_outline"
            iconAlign="left"
            iconStyles="mb-4 text-gray-20"
            onClick={multiDeleteHandler}
            styles="px-0 py-0 h-0"
          >
            Delete
          </Button>
        )}
      </Col>

      <Text styles="text-gray-60 whitespace-nowrap">Sort :</Text>

      <DropDownMenu
        icon={['expand_more', 'expand_less']}
        styles="z-[99]"
        text={{value: sortText, textStyle: 'text-blue-100'}}
        buttons={[
          {name: 'Sort By Date', handler: () => sortDateHandler?.('Date')},
          {
            name: 'Sort By Alphabet',
            handler: () => sortDateHandler?.('Alphabet'),
          },
        ]}
      />

      <Separator w={1} h={32}></Separator>
      <Icon
        name={!view ? 'list' : 'grid_on'}
        onClick={viewHandler}
        color="#5E6C84"
        styles="mr-10"
      ></Icon>

      {/* <Separator w={1} h={32}></Separator>
      <Icon name="filter_list" color="#5E6C84"></Icon> */}
      <Button
        medium
        icon="add"
        iconAlign="left"
        iconStyles="text-20"
        onClick={addApplicationHandler}
      >
        Add Application
      </Button>
    </Row>
  )
}

export default HeaderRight
