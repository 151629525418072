import React from 'react'
import {Navigate} from 'react-router-dom'
import Home from 'Common/Components/Pages/Home'
import {CurrentPlan} from 'app/services/plans'

const ConditionalRoute = ({currentPlan}: {currentPlan: CurrentPlan}) => {
  return (
    <>
      {currentPlan ? (
        currentPlan.app.count > 0 ? (
          <Navigate to="/application-listing" />
        ) : (
          <Navigate to="/add-application" />
        )
      ) : (
        <Home />
      )}
    </>
  )
}

export default ConditionalRoute
