/** Reusable Components */
import {Col, Icon, Row, Text} from 'Common/Components/Core/DesignTemplates'
/** Assets (CSS/Images) */
import Applist from '../../../../../Assets/img/app-list.png'
const AccessDrawerList = ({check}: any, {btn}: any) => {
  return (
    <li className="cwpf_dashboard_border bg-white hover:bg-gray-3 cursor-pointer p-0">
      <span className="">
        <img src={Applist} className="cwpf_dashboard_img" alt="" />
        <Row between center styles=" w-full">
          <Col className="ml-12 flex flex-col">
            <Text bold styles=" hover:bg-grey-20">
              Bankfy
            </Text>
            <Text styles="p3 text-blue-100">View details</Text>
          </Col>

          <Icon name="chevron_right" styles=" text-gray-60 " />
        </Row>
      </span>
    </li>
  )
}
export default AccessDrawerList
