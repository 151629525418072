/** Libraries */
import {FC} from 'react'

/** Import Child Components */
import Header from './Header'
import SelectPlan from './SelectPlan'
import FeatureList from './FeatureList'
import Testimonials from './Testimonials'
import FAQ from './FAQ'
import Footer from './Footer'

/** Assets (CSS/Images) */
import './styles/Home.css'
import '../../../../Assets/css/custom.css'

const HomeContainer: FC = (): JSX.Element => {
  return (
    <main className="cwpf_slctPlan_sec">
      <Header />
      <SelectPlan />
      <FeatureList />
      <Testimonials />
      <FAQ />
      <Footer />
    </main>
  )
}

export default HomeContainer
