export interface iSecurityTrackingObj {
  event_name: string
  attributes: {
    tab_name?: string
    sub_tab?: SECURITY_SUB_TABS
  }
}

export interface iAttackMEventObj {
  event_name?: string
  attributes?: {
    sub_tab: SECURITY_SUB_TABS.ATTACK_MITIGATION
  }
}

export const enum SECURITY_SUB_TABS {
  ATTACK_MITIGATION = 'attack_mitigation',
  WHITELIST = 'whitelist',
  SFTP_SSH_LOGS = 'sftp_ssh_logs',
}
