/** Libraries */
import {useNavigate} from 'react-router-dom'

/** Reusable Components */
import {
  Button,
  Col,
  Row,
  Text,
  TextField,
  Container,
} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'

/** Assets (CSS/Images) */
import appRemoved from 'Assets/img/manage-app/appRemoved.svg'

const ApplicationRemovedModal = ({show, onClose}: any) => {
  const navigate = useNavigate()

  const modalHandler = () => {
    navigate('/application-listing')
    onClose()
  }

  return (
    <Modal onClose={modalHandler} show={show}>
      <Container
        styles={'py-[56px] px-[84px] min-w-[600px] background-color:#ffffff'}
      >
        <Row center>
          <Col>
            {' '}
            <img
              className={`w-[100px] h-[100px]`}
              src={appRemoved}
              alt={'worpress_logo'}
            />
          </Col>
        </Row>

        <Row center>
          <Col>
            <Text type="h6" styles="text-gray-80 mt-[20px] mb-[10px]" med>
              Application Removed
            </Text>
          </Col>
        </Row>

        <Row center>
          <Col>
            {' '}
            <Text type="p" styles="p2 text-gray-80">
              The application you are trying to edit has been removed by another
              user.{' '}
            </Text>
          </Col>
        </Row>

        <Row center>
          <Col>
            <Button medium primary styles="mt-[30px]" onClick={modalHandler}>
              View All Applications
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default ApplicationRemovedModal
