/** Libraries */
import Skeleton from 'react-loading-skeleton'

/** Reusable Components */
import {Col, Row} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'

const LoadingState = () => {
  return (
    <Row styles="w-full p-30 px-20" between>
      <Col styles="flex-1">
        <Skeleton height={400} count={1} inline={true} />
      </Col>
      <Col styles="flex-1 ml-30">
        <Skeleton height={400} count={1} inline={true} />
      </Col>
    </Row>
  )
}

export default LoadingState
