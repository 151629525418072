/** Libraries */

/** Reusable Components */
import CheckBox from 'Common/Components/Core/DesignTemplates/CheckBox/CheckBox'
import {Row, Col, Text, DropDownMenu} from '../../../Core/DesignTemplates/index'

/** Helpers */
import {headerIntermediateChecker, iAppHeader} from '../util'
import {useGetCurrentPlanQuery} from 'app/services'

const HeaderLeft = ({
  selectedApp,
  totalApps,
  headerDeselectAll,
  headerSelection,
  headerCheck,
}: iAppHeader) => {
  const {data: {plan: currentPlan = {}, app = {}} = {}} =
    useGetCurrentPlanQuery()

  const intermediateCheckbox = headerIntermediateChecker(selectedApp, totalApps)
  const deSelectHandler = () => {
    headerDeselectAll?.()
  }
  return (
    <Row middle between>
      <Col>
        <Row middle styles="relative max-md:mb-20">
          <CheckBox
            onChange={headerSelection}
            name="selectall"
            checked={headerCheck}
            neutral={intermediateCheckbox}
            styles="hidden"
          />
          <DropDownMenu
            bottomRight
            styles=" hidden"
            icon="expand_more"
            buttons={[
              {name: ' Deselect All', handler: deSelectHandler},
              {name: 'Select Custom'},
            ]}
          />

          <Text type="h4" semi styles="text-gray-100 whitespace-nowrap">
            {`${totalApps > 1 ? 'My Applications' : 'My Application'}`}
            <Text type="span" styles="text-18">
              <Text type="b" styles="font-normal text-blue-100 ml-10">
                {totalApps}
              </Text>
              /{currentPlan?.max_app_count}
            </Text>
          </Text>
        </Row>
      </Col>
    </Row>
  )
}

export default HeaderLeft
