/** Libraries */
import {FC} from 'react'

/** Reusable Components */
import {Container, Icon, Text} from 'Common/Components/Core/DesignTemplates'

const Footer: FC = (): JSX.Element => {
  return (
    <footer>
      <Container styles="container">
        <Text styles="py-20 flex items-center justify-center text-gray-80">
          <Icon styles="text-16">copyright</Icon>
          2011-2022 Cloudways Ltd. All rights reserved
        </Text>
      </Container>
    </footer>
  )
}

export default Footer
