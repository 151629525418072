/** Libraries */
import {FC} from 'react'
import {useTracking} from 'react-tracking'

/** Store/Services */
import {useAppDispatch} from 'app/hooks'
import {AppDispatch} from 'app/store'

/** Reusable Components */
import {Button, Row, Col, Text} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {toggleModal} from 'Common/Components/Core/Modal/ModalSlice'
import {ModalName} from 'Common/Components/Core/Modal/util'
import PlanSlider from 'features/PlanSlider/PlanSlider'

/** Dump Components */
const RightSection: FC = (): JSX.Element => (
  <Row row gutter={20}>
    <Col>
      <Text type="h3" styles="text-dark mb-30">
        We’re Here For You
      </Text>

      <Text type="p" styles="text-18 mb-40  ">
        Not sure which plan to choose? Talk to a sales expert to figure out.
      </Text>
      <Button
        medium
        primaryOutline
        block
        icon="arrow_forward"
        iconAlign="right"
        styles="h6"
        onClick={() => window.location.replace('https://support.cloudways.com')}
      >
        Contact Sales
      </Button>
    </Col>
  </Row>
)

const LeftSection: FC<{dispatch: AppDispatch}> = ({dispatch}): JSX.Element => (
  <Row col styles="px-40 py-40">
    <PlanSlider />
    <Col styles=" mt-60 mb-40">
      <Button
        primary
        block
        icon="arrow_forward"
        iconAlign="right"
        styles="h6"
        onClick={() => {
          dispatch(toggleModal(ModalName.confirmPlanModal))
        }}
      >
        Select Plan
      </Button>
    </Col>

    <Text type="h6" styles="text-dark mb-24">
      Additional usage info:
    </Text>
    <Col styles="cwpf_slctPlan_noteBox">
      <ul>
        <li>$2/Additional Bandwidth MB</li>
        <li>$1/Additional 1000 Visits</li>
        <li>$4/Additional 1 GB of Disk Space</li>
      </ul>
    </Col>
  </Row>
)
/** Dump Components Ends*/

const SelectPlan: FC = (): JSX.Element => {
  /* User Tracking */
  const {Track} = useTracking({
    attributes: {
      page: 'select_plan',
      current_url: window.location.href,
    },
  })

  //Dispatch Selected Plan
  const dispatch: AppDispatch = useAppDispatch()

  return (
    <Track>
      <section className="bg-gray-3">
        <div className="container">
          <div className="cwpf_slctPlan_wrap">
            <Row>
              <Col lg={8}>
                <LeftSection dispatch={dispatch} />
              </Col>
              <Col lg={4} styles="flex-1 cwpf_slctPlan_rhtBox ">
                <RightSection />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </Track>
  )
}

export default SelectPlan
