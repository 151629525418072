/** Libraries */
import {useNavigate} from 'react-router-dom'

/** Reusable Components */
import {
  Button,
  Col,
  Row,
  Text,
  TextField,
  Container,
} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import appNotFoundImg from 'Assets/img/manage-app/appNotFound.svg'

const ApplicationNotFound = ({show, onClose}: any) => {
  const navigate = useNavigate()

  const modalHandler = () => {
    navigate('/application-listing')
    onClose()
  }

  return (
    <Container
      styles={
        'py-[56px] px-[84px] background-color:#ffffff flex items-center justify-center h-[calc(100vh-180px)]'
      }
    >
      <Row col>
        <Row center>
          <Col>
            {' '}
            <img
              className={`w-[247px] h-[172px]`}
              src={appNotFoundImg}
              alt={'worpress_logo'}
            />
          </Col>
        </Row>

        <Row center>
          <Col>
            <Text type="h6" styles="text-gray-80 mt-[30px] mb-[10px]" med>
              Application not available
            </Text>
          </Col>
        </Row>

        <Row center>
          <Col>
            {' '}
            <Text type="p" styles="p2 text-gray-80">
              The application you are trying to view has been deleted
            </Text>
          </Col>
        </Row>

        <Row center>
          <Col>
            <Button medium primary styles="mt-[35px]" onClick={modalHandler}>
              View All Applications
            </Button>
          </Col>
        </Row>
      </Row>
    </Container>
  )
}

export default ApplicationNotFound
