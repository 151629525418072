import {TABNAMES} from '../Util'
import CachePro from '../../../../../Assets/img/manage-app/object-cache-pro.png'
import LiteSpeed from '../../../../../Assets/img/manage-app/life-speed.png'
import Cloudflare from '../../../../../Assets/img/manage-app/cloudflare.png'

export interface iOverviewAppTrackingObj {
  event_name: string
  attributes: {
    tab_name?: TABNAMES.PERFORMANCE
  }
}
export interface iCachingTable {
  value: string
  method: string
  type: string
  id: number
}
/** Assets (CSS/Images) */

export const data = [
  {
    name: 'Object Cache Pro',
    detail: 'Integrates with your WordPress itself & the dashboard',
    ImgSrc: CachePro,
    // button: 'Learn More',
  },
  {
    name: 'Lite Speed',
    detail: 'Web Server is compatible with all popular Apache features',
    ImgSrc: LiteSpeed,
    // button: 'Learn More',
  },
]
export const CDNData = [
  {
    name: 'Cloudfare CDN',
    detail:
      'Secure your websites, APIs Internet applications and Protect networks',
    ImgSrc: Cloudflare,
    button: 'Learn More',
  },
]
export interface iCacheDetails {
  name: string
  detail: string
  ImgSrc: string
  button?: string
  buttonHandler?: () => void
}
export const PerformanceData = [
  {
    name: 'Performance',
    percent: 98,
    icon: 'shutter_speed',
  },
  {
    name: 'Accessibility ',
    percent: 84,
    icon: 'accessibility_new',
  },
  {
    name: 'Best Practices',
    percent: 89,
    icon: 'thumb_up_alt',
  },
  {
    name: 'SEO',
    percent: 49,
    icon: 'location_searching',
  },
]
