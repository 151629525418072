/** Libraries */
import React, {useState, useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

const HeadpieChart = ({total = 0, allocated = 0}) => {
  const chartRef = useRef<any>(null)
  const data = [
    {
      country:
        ' [font-size:28px][#222E37]4.7 GB\n[font-size:14px] Ranch Market',
      litres: Math.ceil((allocated / total) * 100),
    },

    {
      country: '[font-size:18px][#222E37]1.4 GB \n [font-size:14px] Others',
      litres: 100 - (allocated / total) * 100,
      config: {
        isActive: true,
      },
    },
  ]

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv4', am4charts.PieChart)

      const pieSeries = chartRef.current.series.push(new am4charts.PieSeries())
      pieSeries.colors.list = [
        am4core.color('#2F39BF'),
        am4core.color('#CCCCCC'),
      ]
      chartRef.current.data = data
      const title = chartRef.current.titles.create()
      title.text = `[font-size:14px bold]`
      title.fontSize = 20
      title.marginLeft = 14
      title.align = 'left'
      pieSeries.dataFields.value = 'litres'
      pieSeries.dataFields.category = 'country'
      pieSeries.labels.template.disabled = true
      pieSeries.ticks.template.disabled = true
      pieSeries.slices.template.tooltipText = ''
      chartRef.current.innerRadius = am4core.percent(56)

      chartRef.current.legend = new am4charts.Legend()
      chartRef.current.legend.disabled = true
      pieSeries.slices.template.hoverable = false

      chartRef.current.legend.itemContainers.template.marginBottom = 9
      chartRef.current.legend.maxHeight = 450
      chartRef.current.legend.maxWidth = 100
      // chart.legend.maxWidth = 300;

      chartRef.current.legend.valueLabels.template.disabled = true
      chartRef.current.legend.useDefaultMarker = false
      chartRef.current.legend.labels.template.text =
        '[font-size:18px][#222E37]{country}[/]\n'
      chartRef.current.legend.itemContainers.template.clickable = false
      chartRef.current.legend.position = 'left'
      chartRef.current.legend.itemContainers.template.paddingBottom = 39
      chartRef.current.legend.labels.template.marginLeft = 15
      const label = pieSeries.createChild(am4core.Label)
      label.text = ``
      //label.text = '[font-size:30px]1047[/][font-size:20px]/20k[/] \n[font-size:16px][#253858 bold]${Legendtitle}'
      label.horizontalCenter = 'middle'
      label.verticalCenter = 'middle'
      pieSeries.labels.template.maxWidth = 130
      pieSeries.labels.template.wrap = true
      pieSeries.labels.template.radius = am4core.percent(-20)
      pieSeries.labels.template.relativeRotation = 80
      // chart.dispose();
    }
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  return (
    <>
      <div
        id="chartdiv4"
        className=" "
        style={{width: '80px', height: '70px'}}
      ></div>
    </>
  )
}

export default HeadpieChart
