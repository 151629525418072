import Modal from 'Common/Components/Hooks/Modal/Modal'
import {Link, useNavigate} from 'react-router-dom'
import './PlanAddedModal.css'
// Design System
import {Button, Container, Text} from 'Common/Components/Core/DesignTemplates'

const PlanAddedModal = ({show, onClose}: any) => {
  //Navigate to Add Application Route
  const navigate = useNavigate()
  const navigateAddApp = () => {
    onClose()
    navigate('/dashboard')
  }
  const navigateAddApplication = () => {
    onClose()
    navigate('/add-application')
  }

  return (
    <Modal show={show} onClose={onClose} classes="cwpf_planAdded_modal">
      <Container styles="cwpf_planAdded_sec px-[56px] pt-[200px] pb-40">
        <Container styles="cwpf_planAdded_ttlBox mt-[24px]">
          <Text semi type="h3" styles="text-gray-80 ">
            Plan Added Successfully
          </Text>
          <Text styles="pt-36 p1">
            Your fully scalable, high performance plan is <br /> ready. Now you
            can add an Application to get <br /> started.
          </Text>
        </Container>

        <Container styles="mt-[42px] mb-[20px]">
          <Button
            icon="add_circle_outline"
            iconAlign="left"
            block
            onClick={navigateAddApplication}
            id="2"
          >
            Add Application
          </Button>
        </Container>

        <Button primaryOutline block onClick={navigateAddApp}>
          Manage Plan
        </Button>
      </Container>
    </Modal>
  )
}

export default PlanAddedModal
