/** Libraries */
import React, {useState, useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {DatePicker} from 'antd'

/** Reusable Components */
import {
  Container,
  Row,
  Col,
  Text,
  Card,
  CustomSelect,
} from 'Common/Components/Core/DesignTemplates'
import {niceBytes} from 'Common/Components/Core/Modal/util'
import Skeleton from 'react-loading-skeleton'
import LoadingState from 'Common/Components/Pages/Dashboard/ResourceAnalysisTabs/LoadingState'

interface iMtdOpitons {
  date_Options: any
}
//validations
const validate = Yup.object({
  date_Options: Yup.string().required('Please select an option.'),
})
const initialValues: iMtdOpitons = {
  date_Options: '',
}
const onSubmit = (
  values: iMtdOpitons,
  {setSubmitting}: FormikHelpers<iMtdOpitons>,
) => {
  return
}

const BandwidthChart = ({
  bandwidthSummary,
  bandwidthDatapoints,
  isBandwidthSummaryLoading,
  isBandwidthDatapointsLoading,
  title,
  tooltipTitle,
  datapoints = [],
}: any) => {
  const chartRef = useRef<any>(null)
  const {RangePicker} = DatePicker
  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv2', am4charts.XYChart)
      // [
      //   {
      //     date: new Date(2018, 0, 1),
      //     value: 450,
      //   },
      const dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.grid.template.location = 0
      dateAxis.renderer.minGridDistance = 48
      const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.grid.template.strokeOpacity = 1
      valueAxis.renderer.minGridDistance = 80
      valueAxis.renderer.grid.template.stroke = am4core.color('#D8D8D8')
      valueAxis.renderer.labels.template.fill = am4core.color('#A2A3A5')
      valueAxis.renderer.labels.template.fontSize = 13
      dateAxis.renderer.labels.template.fill = am4core.color('#A2A3A5')
      dateAxis.renderer.labels.template.fontSize = 13
      dateAxis.renderer.grid.template.strokeWidth = 0
      valueAxis.renderer.grid.template.strokeWidth = 1

      // Create series
      // function createSeries(field: any, name: any) {
      const series = chartRef.current.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'date'
      series.name = 'Series #1'
      series.tooltip = new am4core.Tooltip()
      //series.tooltipText = `[font-size:10px]14th December,2021,\n[font-size:14px bold]${tooltipTitle}\n[/][font-size:11px]09:55 UTC`
      const tooltiphtml = `
      <table>
      <tr>
        <td style=color:white;font-size:10px;font-weight:400>{formattedDate}</td>
      </tr>
      <tr>
        <td style=font-weight:700;font-size:14px>${tooltipTitle}{formattedValue}</td>
      </tr>
      </table>`
      // series.tooltip.html = tooltiphtml
      series.tooltipHTML = tooltiphtml
      series.tooltip.marginBottom = 9
      series.tooltip.background.fillOpacity = 1

      series.tooltip.getFillFromObject = false
      //series.tooltip.zIndex = 100
      series.tooltip.background.fill = am4core.color('#253858')
      series.tooltip.pointerOrientation = 'down'
      valueAxis.cursorTooltipEnabled = false
      series.tooltip.defaultState.transitionDuration = 0
      series.tooltip.hiddenState.transitionDuration = 0
      //series.tooltip.width = 12

      const html =
        '[bold]YEAR {categoryX}[/] ----Cars: {cars}Motorcycles: {motorcycles}Bicycles: {bicycles}'
      series.tooltip.tooltipHTML = '<center>html</center>'

      dateAxis.cursorTooltipEnabled = false
      series.tooltip.label.minWidth = 141
      series.tooltip.label.minHeight = 60
      series.dateFormatter.dateFormat = 'dd-MM-yyyy'
      series.tooltip.background.cornerRadius = 6
      series.strokeWidth = 5
      series.tensionX = 0.8
      series.stroke = am4core.color('#2F39BF')
      series.tooltip.dy = -30
      const bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.stroke = am4core.color('#fff')
      bullet.circle.strokeWidth = 4
      bullet.circle.fill = am4core.color('#2F39BF')
      bullet.fillOpacity = 0
      bullet.circle.width = 2
      bullet.circle.radius = 11
      bullet.strokeOpacity = 0
      bullet.stroke = am4core.color('#2F4858')
      bullet.strokeWidth = 1
      valueAxis.extraMin = 0.02
      valueAxis.extraMax = 0.06
      // Make square drop shadow by adding a DropShadow filter
      const shadow = new am4core.DropShadowFilter()
      shadow.dx = 3
      shadow.dy = 3
      bullet.filters.push(shadow)
      const bulletState = bullet.states.create('hover')
      bulletState.properties.fillOpacity = 1
      bulletState.properties.strokeOpacity = 1
      //   return series
      // }
      // createSeries('value', 'Series #1')
      chartRef.current.cursor = new am4charts.XYCursor()
      chartRef.current.cursor.lineX.stroke = am4core.color('#2F39BF')
      chartRef.current.cursor.lineX.strokeWidth = 2
      chartRef.current.cursor.lineX.strokeDasharray = '9,8'
      chartRef.current.cursor.lineX.strokeOpacity = 2
      chartRef.current.cursor.lineY.disabled = true
    }
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])
  React.useEffect(() => {
    if (chartRef.current) {
      console.log(title, datapoints)
      chartRef.current.data = datapoints
    }
  }, [datapoints])
  // if (isBandwidthDatapointsLoading) return <LoadingState />
  return (
    <>
      <Card styles=" overflow-hidden py-[20px] flex-1">
        <Row between styles="px-6">
          <Col styles="mb-[20px]">
            <Row row between>
              <Text styles="text-16" semi>
                Bandwidth
              </Text>
            </Row>
            {isBandwidthSummaryLoading ? (
              <Skeleton width={100} height={10} borderRadius={10} />
            ) : (
              <Row styles="mt-2" middle>
                <Col styles="ml-[5px]">
                  <Row row bottom>
                    <Text type="h1" semi styles="text-gray-80">
                      {niceBytes(
                        bandwidthSummary?.bandwidth_total_usage,
                        0,
                        true,
                        2,
                      )}
                    </Text>
                    {/* <Text
                      type="h5"
                      semi
                      styles="relative top-[-7px] text-gray-80"
                    >
                      /{' '}
                      {niceBytes(
                        bandwidthSummary?.allocated_bandwidth * 1000 * 1000,
                        0,
                        false,
                        2,
                      )}
                    </Text> */}
                  </Row>
                  <Row middle styles="mt-10">
                    <Text styles="text-14">
                      Total bandwidth consumed by this application since start
                      of this month
                    </Text>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          <Col>
            <Formik
              initialValues={initialValues}
              validationSchema={validate}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className="flex justify-end mt-24 cwpf_dashboard_datepicker">
                  <RangePicker />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        {/* <div id="chartdiv" className=" max-md:min-w-auto h-[411px]"></div> */}
        <div
          id="chartdiv2"
          className="py-10 max-md:px-80"
          style={{width: '1090px', height: '460px'}}
        ></div>
      </Card>
    </>
  )
}
export default BandwidthChart
