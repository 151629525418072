

import {createSlice} from '@reduxjs/toolkit'

export const initialState: {isVisible: boolean} = {
  isVisible: false,
}

const dashboardSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setDashboardWarningVisible: (state, action) => {
      state.isVisible = action.payload
    },
  },
})

export const {setDashboardWarningVisible} = dashboardSlice.actions

export default dashboardSlice.reducer