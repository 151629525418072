import React from 'react'
import {Col, Container, Row, Text} from '../DesignTemplates'
import {ReactComponent as WifiOffsvg} from 'Assets/img/wifi_off.svg'
const NoConnectionLoader = () => {
  return (
    <Container styles="mb-40">
      <Row
        gutter={8}
        middle
        center
        styles="cwpf_noconnection min-h-[48px] w-full bg-orange-25 fixed top-[48px] z-[99]"
      >
        <Col>
          <WifiOffsvg />
        </Col>
        <Col styles="text-gray-100 mt-2 p2">
          You are no longer connected to the internet.
        </Col>
      </Row>
    </Container>
  )
}

export default NoConnectionLoader
