import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ErrorBoundary} from 'react-error-boundary'
import {setupListeners} from '@reduxjs/toolkit/query'

import getCookieByKey from 'utils/getTokenByKey'
import * as serviceWorker from './serviceWorker'
import {store} from './app/store'
import {platform3} from './app/services/platform3'
import App from './App'

import './index.css'
import FullPageErrorFallBack from 'Common/Components/Core/ErrorFallbacks/FullPageErrorFallback'
import reportWebVitals from 'reportWebVitals'
import {sendToAnalytics} from 'utils/sendWebVitalsAnalytics'
import {apiSlice} from 'app/services'

export interface customEvent extends Window {
  events: object[]
  distinct_id?: string
  globalLocationURL?: string
}

/* Data Layer Type */
declare const window: customEvent

/* 
  User session, check for user session and Id.  
  Redirect to 2.0 if either of them is not available 
*/
const userSession = getCookieByKey('cwSession')
let userID
if (userSession) {
  const decodeSession = decodeURIComponent(userSession)
  console.log(decodeSession, 'decode')
  // userID = decodeSession && JSON.parse(decodeSession)
  userID = decodeSession
  if (userID) {
    window.distinct_id = userID
  } else {
    window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))
  }
} else {
  window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))
}

/* If User session and Id is available*/
if (userID) {
  //Pre-fetch User
  store.dispatch(
    platform3.util.prefetch('getCurrentUser', '', {
      force: false,
      ifOlderThan: 60,
    }),
  )

  /** Get All DashBoard Stats */
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={FullPageErrorFallBack}>
          <App />
        </ErrorBoundary>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
setupListeners(store.dispatch)
reportWebVitals(sendToAnalytics)
