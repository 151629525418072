/** Reusable Components */
import {Container, Text} from 'Common/Components/Core/DesignTemplates'
import BackupTabs from './BackupTabs'

const BackupRestore = () => {
  const Heading = (
    <>
      <Text type="h4" semi styles="text-dark">
        Backup & Restore
      </Text>
      <Text styles="p2 text-gray-80 mt-12">
        This section allows you to back up and restore application data (files
        and database). You can create multiple on-demand backups. In addition,
        Cloudways offers option for scheduled and on-demand Server level backup
        that backs up all the applications deployed on the server.
      </Text>
    </>
  )
  return (
    <Container styles="">
      {Heading}
      <BackupTabs />
    </Container>
  )
}

export default BackupRestore
