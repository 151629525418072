/** Reusable Components */
import {
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

const AppDrawerHeader = ({values, onCancel}: any) => {
  return (
    <Container className="mt-16 border-gray-20 border-b bg-white">
      <Row>
        <Col>
          <Icon
            name="close"
            styles="cwpf_dashBoard_drawer top-0 cursor-pointertext-gray-60 "
            onClick={onCancel}
          />
        </Col>
        <Col>
          <Text styles="text-gray-100 p2 flex flex-col font-semibold ml-40">
            {values.heading}
            <Text styles="p3 mb-16">{values.subHeading}</Text>
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

export default AppDrawerHeader
