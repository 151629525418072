/** Libraries */
import {useTracking} from 'react-tracking'

/** Reusable Components */
import {Container} from 'Common/Components/Core/DesignTemplates'
import AppVitals from './AppVitals'
import CachingDetails from './CachingDetails'
import CachingSetting from './CachingSetting'

/** Helpers */
import {iOverviewAppTrackingObj} from './util'
import {TABNAMES} from '../Util'

/** Assets (CSS/Images) */
import './styles/Performance.css'

const Performance = () => {
  const {Track} = useTracking<iOverviewAppTrackingObj>({
    attributes: {
      tab_name: TABNAMES.PERFORMANCE,
    },
  })
  return (
    <Track>
      <Container styles="pt-60 pb-40 space-y-40">
        <CachingDetails />
        <AppVitals />
        <CachingSetting />
      </Container>
    </Track>
  )
}

export default Performance
