import Modal from 'Common/Components/Hooks/Modal/Modal'
import React, {useEffect, useState} from 'react'
import PlanUpgradedModal from '../../ConfirmPlanAddedModal/PlanUpgradedModal/PlanUpgradedModal'
import UnsubscribePlan from '../UnsubscribePlanModal/UnsubscribePlanModal'
import ModifyPlan from './ModifyPlan'
const mock = {
  modify: 'modify',
  unsubscribe: 'unsubscribe',
  planupgraded: 'planupgraded',
}
const ModifyPlanModal = (props: any) => {
  const [modalState, setModalState] = useState('')
  const getClasses = () => {
    return modalState === mock.modify
      ? 'cwpf_modifyPlan_modal'
      : mock.unsubscribe
      ? 'cwpf_unsubPlan_modal '
      : 'cwpf_planAdded_modal'
  }
  useEffect(() => {
    if (props.show) {
      setModalState(mock.modify)
    }
  }, [props.show])

  return (
    <Modal show={props.show} onClose={props.onClose} classes={getClasses()}>
      {modalState === mock.modify && (
        <ModifyPlan
          onUnsubscribe={() => setModalState(mock.unsubscribe)}
          onPlanUpgrade={() => setModalState(mock.planupgraded)}
        />
      )}
      {modalState === mock.unsubscribe && (
        <UnsubscribePlan onClose={props.onClose} />
      )}
      {modalState === mock.planupgraded && (
        <PlanUpgradedModal onClose={props.onClose} propstate />
      )}
    </Modal>
  )
}

export default ModifyPlanModal
