interface iCheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
  lg: boolean
  sm: boolean
  neutral: boolean
  styles: string
  activeClass: string
}

const CheckBox = ({
  name = '',
  lg,
  sm,
  neutral,
  disabled,
  checked,
  styles = '',
  activeClass,
  defaultChecked,
  ...rest
}: Partial<iCheckbox>) => {
  const CheckBoxStyles = () => {
    if (sm) return 'h-[13px] w-[13px]'
    else if (lg) return 'h-[18px] w-[18px]'
    return 'h-[18px] w-[18px]'
  }

  const disable = disabled
    ? '_disable disabled:bg-gray-20 disabled:cursor-pointer disabled:bg-transparent disabled:border-2 disabled:border-gray-60'
    : ''

  return (
    <input
      className={`checkbox ${CheckBoxStyles()} checked:checkbox-primary border-gray-40 border-2 rounded-2 !animate-none	${styles} ${disable}`}
      type="checkbox"
      // id={app.value}
      disabled={disabled}
      checked={checked}
      name=""
      ref={(input: any) => {
        if (input) {
          input.indeterminate = neutral
        }
      }}
      {...rest}
      style={{animation: 'none !important'}}
    />
  )
}
CheckBox.defaultProps = {
  disabled: false,
  lg: false,
  sm: false,
}

export default CheckBox
