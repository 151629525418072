/** Reusable Components */
import {useAppSelector} from 'app/hooks'
import NoConnectionLoader from 'Common/Components/Core/NoConnection/NoConnectionLoader'
import useConnection from 'Common/Components/Hooks/useConnection'
import Header from '../../Core/GlobalHeader/GlobalHeader'
import SideNavPanel from '../../Core/SideNav/SideNav'
import OverageValidation from './OverageValiadtion'
// import {useTracking} from 'react-tracking'

const Dashboard = () => {
  useConnection()
  const {isOnline} = useAppSelector(state => state.connection)
  const {isVisible} = useAppSelector(state => state.dashboardSlice)

  // const {trackEvent} = useTracking()
  // useEffect(() => {
  //   trackEvent({
  //     event_name: 'fmui_dashboard',
  //   })
  // }, [trackEvent])
  return (
    <>
      <Header />
      {!isOnline && <NoConnectionLoader />}
      {/* {isVisible && <OverageValidation />} */}
      <SideNavPanel />
    </>
  )
}

export default Dashboard
