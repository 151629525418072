import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {customEvent} from 'index'
import getCookieByKey from 'utils/getTokenByKey'

declare const window: customEvent
// Create your service using a base URL and expected endpoints
export const platform3 = createApi({
  reducerPath: 'platform3',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH_URL,
    prepareHeaders: (headers, {getState}) => {
      const userSession = getCookieByKey('cwSession')
      if (!userSession || !decodeURIComponent(userSession))
        window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))

      // headers.set('X-USER-ID', String(window.distinct_id))
      return headers
    },
  }),
  tagTypes: ['User', 'CacheSetting'],
  endpoints: build => ({
    getCurrentUser: build.query({
      query: () => ({
        url: 'user',
      }),
      providesTags: ['User'],
    }),

    /** Cache Settings and SSH Details */
    getCacheSetting: build.query<any, void>({
      query: () => ({url: 'cachesettings'}),
      providesTags: ['CacheSetting'],
    }),
    addCacheSetting: build.mutation<any, any>({
      query: body => ({
        url: `cachesettings/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CacheSetting'],
    }),
    getSSHDetails: build.query<any, void>({
      query: () => ({url: 'ssh/'}),
    }),
    /** --- x --- */
  }),
})

export const {
  useGetCurrentUserQuery,
  usePrefetch,
  useGetSSHDetailsQuery,
  useGetCacheSettingQuery,
  useAddCacheSettingMutation,
} = platform3