/** Libraries */
import {FC} from 'react'

/** Reusable Components */
import {Col, Container, Row, Text} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import FaqItem from 'Common/Components/Hooks/Accordion/Accordion'
import {faqData} from './util'

const FaqItems: Array<JSX.Element> = faqData.map((faq, index) => (
  <FaqItem key={index} title={faq.title} content={faq.content} />
))

const FAQ: FC = (): JSX.Element => {
  return (
    <section className="pt-60 pb-80 bg-gray-3 hidden">
      <Container styles="container">
        <Container styles="cwpf_faq_wrap pl-40">
          <Row>
            <Col lg={3}>
              <Container styles="cwpf_faq_headBox mt-20">
                <Text type="h3" bold>
                  Frequently Asked <br className="lg:block hidden" />
                  Questions
                </Text>
              </Container>
            </Col>
            <Col lg={9}>
              <Container styles="cwpf_faq_mainBox">{FaqItems}</Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  )
}

export default FAQ
