/** Libraries */
import React, {useState} from 'react'
import {useTracking} from 'react-tracking'
import {Link, useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import Regex from 'Constants/Regex'
import {Formik, Form, FormikHelpers} from 'formik'
import Skeleton from 'react-loading-skeleton'
import {MessageParams} from 'yup/lib/types'

/** Store/Services */
import {
  useGetRegionsQuery,
  useGetApplicationTypesQuery,
  useAddApplicationMutation,
  useGetCurrentPlanQuery,
  useGetAllApplicationsQuery,
} from '../../../../app/services'
import {useAppDispatch} from 'app/hooks'
import {useGetCurrentUserQuery} from 'app/services/platform3'

/** Reusable Components */
import RadioButton from './RadioButton'
import {Text, Container, Row, Col} from 'Common/Components/Core/DesignTemplates'
import {closeModal, toggleModal} from 'Common/Components/Core/Modal/ModalSlice'
import {
  Button,
  CustomSelect,
  TextField,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {headingProp, iAddAPPlication, iAddAppTrackingObj} from './util'
import {ModalName} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import './styles/AddApplication.css'
import 'react-loading-skeleton/dist/skeleton.css'

const initialValues: iAddAPPlication = {
  name: '',
  region_id: '',
  app_type_id: 2,
}

const Heading = ({modal, name, trackEvent}: headingProp): JSX.Element => {
  const dispatch = useAppDispatch()

  return (
    <Container styles="cwpf_addApp_userInfoBox ">
      {modal ? (
        <Text type="h3" semi styles="mb-[45px]">
          Tell Us About Your Application
        </Text>
      ) : (
        <section className="mb-[45px]">
          <Text type="h1" bold>
            Hello {name}!
          </Text>
          <Text type="h4" semi>
            Welcome to Cloudways PRO
          </Text>
        </section>
      )}
      {!modal && (
        <Row between styles="my-[60px]">
          <Col>
            <Text type="h6" reg styles="text-18 text-gray-80">
              Let’s launch your first application
            </Text>
          </Col>
          <Col>
            <Text styles="p3">
              <Link
                to="/plan-details"
                className="text-blue-100 ml-8 underline"
                onClick={() => {
                  trackEvent({
                    event_name: 'fmui_view_plan_details',
                  })
                  dispatch(toggleModal(ModalName.modifyPlanModal))
                }}
              >
                View Plan Details
              </Link>
            </Text>
          </Col>
        </Row>
      )}
    </Container>
  )
}
const AddApplication = ({modal = false}: {modal?: boolean}): JSX.Element => {
  const [appNameValid, setAppNameValid] = useState('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
 

  //Get Current User Plan
  const {Track, trackEvent} = useTracking<iAddAppTrackingObj>({
    attributes: {
      page: 'add_application',
      current_url: window.location.href,
    },
  })
  const currentUser = useGetCurrentUserQuery('')

  const {data: AllApplications} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )

  const {data: regionList, isLoading: isRegionLoading} = useGetRegionsQuery()
  const {data: appTypes, isLoading: isAppTypeLoading} =
    useGetApplicationTypesQuery()

  const [addApplication] = useAddApplicationMutation()

  const {data: {plan: currentPlan = {}} = {}} = useGetCurrentPlanQuery()

  const getFilterRegion = () => {
    if (isRegionLoading) return
    const getActiveRegion = regionList?.results
      .filter((value: {active: boolean}) => value.active === true)
      .map((item: {name: string; id: number}, i: number) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    return getActiveRegion
  }

  //validations
  const validate = Yup.object({
    name: Yup.string()
      .trim()
      .required('Please Name your Application')
      .min(3, 'Application name must be atleast 3 characters.')
      .max(40, 'Application Name must be less than 40 characters')
      .matches(Regex.appName_regex, 'Application Name is not valid')
      .test(
        'app-name-exist',
        (value: MessageParams) =>
          `The application with ${value.value} name already exists`,
        (value: string | undefined) => {
          if (appNameValid) {
            return value !== appNameValid
          }
          return true
        },
      ),
    region_id: Yup.string().required('Please select a region'),
    app_type_id: Yup.string().required(
      'Please select an application to launch',
    ),
  })
  //form submit handler
  const onSubmit = (
    values: iAddAPPlication,
    {setSubmitting}: FormikHelpers<iAddAPPlication>,
  ) => {
    const eventTrackingObj = {
      event_name: 'fmui_launch_app',
      attributes: {
        app_label: values.name,
        region: getFilterRegion().find(
          (item: {value: number}) => item.value === Number(values.region_id),
        ).label,
        app_type: appTypes?.results.find(
          (item: {id: number}) => item.id === values.app_type_id,
        ).name,
        is_success: false,
      },
    }

    if (currentPlan?.max_app_count >= AllApplications?.count) {
      addApplication({
        ...values,
        name: values.name.trim(),
        user_email: currentUser.currentData.email,
      })
        .unwrap()
        .then(() => {
          trackEvent({
            ...eventTrackingObj,
            attributes: {...eventTrackingObj.attributes, is_success: true},
          })
          navigate('/application-listing')
          setSubmitting(false)
          dispatch(closeModal(ModalName.addApplicationModal))
        })
        .catch(() => {
          setSubmitting(false)
          trackEvent(eventTrackingObj)
        })
    } else {
      dispatch(closeModal(ModalName.addApplicationModal))
      dispatch(toggleModal(ModalName.limitAppModal))
    }
  }

  const handleRegionChange = (
    {field, value}: {field: string; value: number},
    formikChangeEvent: (field: string, value: number) => void,
  ) => {
    formikChangeEvent(field, value)
  }

  const handleAppTypeChange = (
    {field, value}: {field: string; value: string},
    formikChangeEvent: (field: string, value: number) => void,
  ) => {
    formikChangeEvent(field, Number(value))
  }

  return (
    <Track>
      <Container styles="cwpf_addApp_sec py-[68px]">
        <Container styles="container">
          <Container styles="w-[100%] px-[68px] mx-auto max-md:w-[90%]">
            <Heading
              name={currentUser?.currentData.name}
              modal={modal}
              trackEvent={trackEvent}
            />
            <Container styles="cwpf_addAPP_form_wrap min-h-[475px]">
              <Formik
                initialValues={initialValues}
                validationSchema={validate}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValidating,
                  isValid,
                  dirty,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => (
                  <Form>
                    <TextField
                      label="Name of the Application"
                      name="name"
                      type="text"
                      labelStyle="p2"
                      placeHolder="Enter a title for your application"
                      id="uid-field-1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('name', e.target.value)
                        const valid = AllApplications?.results.some(
                          (item: any) =>
                            item.name.toLowerCase() ===
                            e.target.value.toLowerCase().trim(),
                        )
                        valid
                          ? setAppNameValid(e.target.value.trim())
                          : setAppNameValid('')
                      }}
                    />
                    <CustomSelect
                      isSearchable={false}
                      label="Select Region"
                      name="region_id"
                      options={getFilterRegion()}
                      value={values.region_id}
                      onChange={(field: string, value: number) =>
                        handleRegionChange({field, value}, setFieldValue)
                      }
                      onBlur={setFieldTouched}
                      error={errors.region_id}
                      placeholder="Select Region"
                      touched={touched.region_id}
                      id="uid-select-1"
                    />
                    <Container styles="cwpf_addAPP_form_inputBox">
                      <Text styles="p2">Application Type</Text>
                      {isAppTypeLoading ? (
                        <Row gutter={24}>
                          <Col lg={6}>
                            <Skeleton height={40} count={1} inline={true} />
                          </Col>

                          <Col lg={6}>
                            <Skeleton height={40} count={1} inline={true} />
                          </Col>
                        </Row>
                      ) : (
                        <Container styles="cwpf_app_slct_mainBox max-md:flex md:flex max-md:flex-col justify-between pt-10 flex-row-reverse">
                          <RadioButton
                            label=""
                            name="app_type_id"
                            options={appTypes?.results}
                            classes="cwpf_app_slctBtn"
                            activeClass="cwpf_app_slctBtn_actv"
                            // onChange={setFieldValue}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleAppTypeChange(
                                {
                                  field: 'app_type_id',
                                  value: event.target.value,
                                },
                                setFieldValue,
                              )
                            }
                          />
                        </Container>
                      )}
                    </Container>
                    <Button
                      type="submit"
                      styles="h6-med"
                      disabled={!isValid || !dirty || isSubmitting}
                      id="3"
                    >
                      Launch Application
                    </Button>
                  </Form>
                )}
              </Formik>
            </Container>
          </Container>
        </Container>
      </Container>
    </Track>
  )
}
export default AddApplication
