/** Libraries */
import {
  Card,
  Col,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import {iRestorePoints} from '../util'
import RestoreLogs from './RestoreLogs'
import {ReactComponent as EmptySvg} from 'Assets/img/manage-app/BackupAndRestore/BackupEmpty.svg'
const Restore = ({
  restoreData,
  isRestorePointLoading,
}: {
  restoreData: iRestorePoints[]
  isRestorePointLoading: boolean
}) => {
  const Heading = (
    <>
      <Row>
        <Col>
          <Text type="p" semi styles="p1 text-gray-80 mt-40 mb-8">
            Restore
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text type="p" styles="p2 text-gray-80">
            Using one of the backups available below, you can restore either
            part of your application or the
            <br /> complete application in one go.
          </Text>
        </Col>
      </Row>
    </>
  )
  console.log(restoreData, 'restoreData')
  return (
    <Container>
      {Heading}
      <Card styles="px-20 py-[18px] my-20">
        {restoreData && restoreData?.length === 0 ? (
          <Container styles="flex items-center justify-center flex-col py-80">
            <EmptySvg />
            <Text>No Backup to show!</Text>
          </Container>
        ) : (
          <RestoreLogs restoreData={restoreData} />
        )}
      </Card>
    </Container>
  )
}

export default Restore
