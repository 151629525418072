/** Libraries */
import {FC, ReactElement} from 'react'

/** Reusable Components */
import {Col, Row, Text, Avatar} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import AvatarIMG from '../../../../Assets/img/avatar.png'

const Testimonials: FC = (): JSX.Element => {
  return (
    <section className="py-[160px] cwpf_tstml_sec hidden">
      <div className="container">
        <div className="cwpf_tstml_wrap max-w-3xl m-auto">
          <Text type="h2" med>
            We've helped thousands Best Managed Cloud Hosting for WordPress That
            Guarantees Success
          </Text>
          <div className="cwpf_tstml_box">
            <Text
              type="span"
              styles="material-icons"
              style={{marginLeft: '-7px'}}
            >
              format_quote
            </Text>
            <Text type="h4">
              Cloudways made it easy for us to extend our web application
              infrastructure into the cloud.
            </Text>

            <Row styles="mt-40 ">
              <Col styles="inline-block align-middle mr-28">
                <Avatar lg source={AvatarIMG} alt="" />
              </Col>
              <Col styles="inline-block align-middle">
                <Text type="h5" semi>
                  Edward Bull
                </Text>
                <Text type="p">Director at Limesharp</Text>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
