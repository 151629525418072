/** Assets (CSS/Images) */
import './Text.css'
interface TextInt {
  type: string
  med: boolean
  semi: boolean
  bold: boolean
  styles: string
  ellipsis: number
  children: any
}
const Text = ({
  type = 'p',
  med,
  semi,
  bold,
  styles = '',
  ellipsis,
  children,
  ...rest
}: any) => {
  const getAttribute = () => {
    if (med) return `${type}-med`
    if (semi) return `${type}-semi`
    if (bold) return `${type}-bold`
    return ''
  }
  const pType_func = (type: any) => {
    if (type === 'p') {
      if (med) return `font-medium`
      if (semi) return `font-semibold`
      if (bold) return `font-bold`
      return ''
    }
    return ''
  }
  const Ellipsis =
    ellipsis && ellipsis !== 'auto'
      ? ' whitespace-nowrap break-all overflow-hidden text-ellipsis'
      : ''
  const Type = type.toLowerCase()

  return (
    <Type
      className={`${getAttribute()} ${styles} ${Ellipsis} ${pType_func(type)}`}
      style={{maxWidth: ellipsis}}
      {...rest}
    >
      {children}
    </Type>
  )
}

export default Text
