import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit'
import {rtkQueryErrorLogger} from './middlewares/errorLogger'
import {apiSlice} from './services'
import {platform3} from './services/platform3'
import {currentUserBrowserData} from './services/currentUserBrowserData'
import ModalSlice from 'Common/Components/Core/Modal/ModalSlice'
import counterReducer from '../features/counter/counterSlice'
import loginReducer from '../features/login/loginSlice'
import currentPlanSlice from '../features/PlanSlider/PlansSliderSlice'
import ToastSlice from 'Common/Components/Core/DesignTemplates/Toast/ToastSlice'
import NoConnectionslice from 'Common/Components/Core/NoConnection/NoConnectionslice'
import Dashboardslice from 'Common/Components/Pages/Dashboard/Slice/Dashboardslice'
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [platform3.reducerPath]: platform3.reducer,
    [currentUserBrowserData.reducerPath]: currentUserBrowserData.reducer,
    counter: counterReducer,
    login: loginReducer,
    plans: currentPlanSlice,
    modals: ModalSlice,
    toast: ToastSlice,
    connection: NoConnectionslice,
    dashboardSlice: Dashboardslice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      platform3.middleware,
      currentUserBrowserData.middleware,
      rtkQueryErrorLogger,
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
