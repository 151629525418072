import {Col, Container, Row, Text} from 'Common/Components/Core/DesignTemplates'
import {ReactComponent as DashboardEmptySvg} from '../../../../../Assets/img/emptystates.svg'
import React from 'react'

const EmptyStates = () => {
  return (
    <>
      <Container styles="w-full my-40 h-[50vh] flex flex-col justify-center items-center">
        <DashboardEmptySvg />
        <Text type="p" med styles="p1 mt-20 text-gray-80">
          Your Applications statistics will appear here
        </Text>
      </Container>
    </>
  )
}

export default EmptyStates
