/** Libraries */
import {useOutletContext, useParams} from 'react-router-dom'

/** Store/Services */
import {
  apiSlice,
  useAddAppBackupMutation,
  useGetLatestBackupQuery,
} from 'app/services'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'

/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import {ReactComponent as BackupIcon} from '../../../../../../../Assets/img/manage-app/workflow/backup.svg'
import {isFulfilled} from '@reduxjs/toolkit'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {useAppDispatch} from 'app/hooks'
import moment from 'moment'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import {useState} from 'react'
import {niceBytes} from 'Common/Components/Core/Modal/util'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import {iRestorePoints} from '../util'
import Skeleton from 'react-loading-skeleton'

const BackupContainer = ({
  restoreData,
  isRestorePointLoading,
}: {
  restoreData: iRestorePoints[]
  isRestorePointLoading: boolean
}) => {
  const propsOutlet: iPropsOutlet = useOutletContext()
  const isPolling =
    propsOutlet.checkPolling(Parameters.restore) ||
    propsOutlet.checkPolling(Parameters.backup)
  const {app_id} = useParams()
  const [btnDisable, setBtnDisable] = useState(false)
  const {data: latestBackup} = useGetLatestBackupQuery({app_id})
  const dispatch = useAppDispatch()
  const [addAppBackup] = useAddAppBackupMutation()

  const backupHandler = useDebounce(() => {
    setBtnDisable(true)
    addAppBackup({app_id})
      .unwrap()
      .then((fulfilled: any) => {
        if (fulfilled.error) throw new Error()
        if (fulfilled.operation_id) {
          dispatch(
            apiSlice.endpoints.getOperations.initiate({
              id: fulfilled.operation_id,
            }),
          )
            .unwrap()
            .then((fulfilled: any) => {
              setBtnDisable(false)
            })
        }
        customToast({
          level: 'info',
          loading: true,
          title: '',
          description:
            'Backup is being taken. You wont be able to perform certain actions while the operation is taking place',
        })
      })
      .catch(err => {
        setBtnDisable(false)
        console.log(err, 'error')
      })
  }, 1000)
  return (
    <Container styles="mt-40">
      <Row between styles="cwpf_BackupResp">
        <Col lg={6} med={4} sm={12}>
          <Text type="h4" semi styles="text-dark mb-6">
            Backup
          </Text>
          <Text styles="p2 text-gray-80 mb-16">
            You can perform an on-demand backup of your application (files and
            database). This option is useful if you want to backup the
            application before any changes.
          </Text>
          <Row middle styles="cwpf_BackupResp mb-36">
            {isRestorePointLoading ? (
              <Skeleton width={162} height={12}></Skeleton>
            ) : restoreData?.length !== 0 ? (
              <>
                <Text styles="p2 text-gray-80">Last Backup Date: </Text>
                <Text med styles="p2 text-gray-80">
                  {`${moment(restoreData?.[0]?.date).format(
                    'DD MMMM, YYYY,',
                  )} ${restoreData?.[0]?.time} UTC`}
                </Text>
              </>
            ) : (
              <Row middle gutter={8}>
                <Col>
                  <Icon styles="text-blue-100">info_outline</Icon>
                </Col>
                <Col>
                  <Text type="span" styles="text-blue-100">
                    No Backups yet
                  </Text>
                </Col>
              </Row>
            )}
          </Row>
          <Tooltip
            text="This action is temporarily disabled because 
of an operation in the background which might
potentially cause conflicts"
            hide={!propsOutlet.checkPolling(Parameters.backup)}
            styles="inline-block"
          >
            <Button
              medium
              type="button"
              styles=""
              onClick={backupHandler}
              disabled={isPolling || btnDisable}
            >
              Take Backup Now
              <BackupIcon className="inline-block align-middle ml-10" />
            </Button>
          </Tooltip>
        </Col>
        {/* {latestBackup?.size !== 0 && (
          <Col lg={4} med={4} sm={12} styles="text-right cwpf_BackupColResp">
            <Text type="h6" semi styles="text-gray-80">
              OFF-SITE BACKUP SIZE
            </Text>
            <Row middle end styles="cwpf_BackupResp">
              <Col>
                <Text styles="p2 text-gray-80 mt-2">
                  Current backup size &nbsp;
                </Text>
              </Col>
              <Col>
                <Text semi styles="p2 text-gray-80 mt-2">
                  {niceBytes(latestBackup?.size, 1)}
                </Text>
              </Col>
            </Row>
          </Col>
        )} */}
      </Row>
    </Container>
  )
}

export default BackupContainer
