export const ModalName = {
  limitAppModal: 'limitAppModal',
  modifyPlanModal: 'modifyPlanModal',
  unsubscribePlanModal: 'unsubscribePlanModal',
  addApplicationModal: 'addApplicationModal',
  deleteAppModal: 'deleteAppModal',
  deleteModal: 'deleteModal',
  confirmPlanModal: 'confirmPlanModal',
  addAppPlanModal: 'addAppPlanModal',
  planUpgradedModal: 'planUpgradedModal',
  showSessionExpiredModal: 'showSessionExpiredModal',
}

export const EpochToDate = epoch => {
  if (epoch < 10000000000) epoch *= 1000 // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
  epoch = epoch + new Date().getTimezoneOffset() * -1 //for timeZone
  return new Date(epoch)
}

const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const niceBytes = (x, l = 0, fixed = true, loop = 0) => {
  let n = parseInt(x, 10) || 0
  if (n > 0.1) {
    if (loop !== 0) {
      for (let i = 1; i <= loop; i++) {
        n = n / 1000
        ++l
      }
    } else {
      while (n >= 1000 && ++l) {
        n = n / 1000
      }
    }
    return (
      (fixed ? n.toFixed(!Number.isInteger(n) && l > 0 ? 1 : 0) : n) +
      '' +
      units[loop || l]
    )
  } else {
    return 0 + 'GB'
  }
}
export const niceBytesDiskspace = (x, fixed = true, loop = 3) => {
  let n = parseInt(x, 10) || 0
  if (n > 0.1) {
    for (let i = 1; i <= loop; i++) {
      n = n / 1000
    }

    return (fixed ? n.toFixed(!Number.isInteger(n) ? 2 : 0) : n) + 'GB'
  } else {
    return 0 + 'GB'
  }
}

export const abbreviateNumber = value => {
  var suffixes = ['', 'K', 'M', 'B', 'T']
  var suffixNum = Math.floor(('' + value).length / 3)
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2),
  )
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1)
  }
  return shortValue + ' ' + suffixes[suffixNum]
}
