/** Libraries */
import React from 'react'
import {Form, Formik, FormikHelpers} from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import {DatePicker} from 'antd'

/** Store/Services */
import {
  useGetCurrentPlanQuery,
  useGetMonitoringStatsQuery,
  useGetResourceVisitsQuery,
} from 'app/services'

/** Reusable Components */
import {Col, Row} from 'Common/Components/Core/DesignTemplates'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import PieChart1 from '../Charts/PieChartVisits'
import PieChart2 from '../Charts/XYChartVIsits'
import LoadingState from './LoadingState'

/** Helpers */
import {
  abbreviateNumber,
  EpochToDate,
  niceBytes,
} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import '../style.css'
import addDaysToGraphData from './util/addDaysToGraphData'
import EmptyStates from './EmptyStates'
interface iMtdOpitons {
  mtd_options: any
}

const initialValues: iMtdOpitons = {
  mtd_options: '',
}

const VisitsTab = ({
  everythingSummary,
  isEverythingSummaryLoading,
  isEverythingDetailsLoading,
}: any) => {
  // const {data: currentPlan} = useGetCurrentPlanQuery()
  // const {data, isLoading} = useGetResourceVisitsQuery(undefined, {
  //   skip: currentPlan?.app?.count <= 0,
  // })
  const {data: visitsDatapoints, isLoading: isVisitsDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'visits',
    })

  //validations
  const validate = Yup.object({
    mtd_options: Yup.string().required('Please select an option.'),
  })

  const onSubmit = (
    values: iMtdOpitons,
    {setSubmitting}: FormikHelpers<iMtdOpitons>,
  ) => {
    return
  }

  const {RangePicker} = DatePicker

  if (
    isVisitsDatapointsLoading ||
    isEverythingSummaryLoading ||
    isEverythingDetailsLoading
  )
    return <LoadingState />

  return (
    <>
      {everythingSummary?.allocated_visits === 0 ||
      visitsDatapoints === undefined ||
      visitsDatapoints?.application?.length === 0 ? (
        <EmptyStates />
      ) : (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className="flex justify-end mt-24 pr-24 cwpf_dashboard_datepicker">
                <Tooltip text="Coming Soon!">
                  <RangePicker disabled />
                </Tooltip>
              </Form>
            )}
          </Formik>
          <>
            <Row between styles="cwpf_dashboard_chart">
              <Col>
                <PieChart2
                  datapoints={
                    visitsDatapoints?.data?.map((eachPoint: any) => {
                      return {
                        date: new Date(eachPoint.Date),
                        value: eachPoint.UniqueVisits,
                        formattedValue: eachPoint.UniqueVisits,
                        formattedDate: moment(eachPoint.Date).format(
                          'DD MMMM, YYYY',
                        ),
                        utcTime: moment(eachPoint.Date).format('HH:MM UTC'),
                      }
                    }) || []
                  }
                  title="hello"
                  tooltipTitle="Visits: "
                />
              </Col>
              <Col>
                <PieChart1
                  Legendtitle="Visits Breakdown"
                  Title="Total Visits"
                  space={everythingSummary?.total_visits || 0}
                  totalspace={everythingSummary?.allocated_visits || 0}
                  apps={
                    visitsDatapoints?.application?.map((eachApp: any) => ({
                      value: eachApp?.UniqueVisitsSum,
                      name: eachApp.appName,
                    })) || []
                  }
                />
              </Col>
            </Row>
          </>
        </div>
      )}
    </>
  )
}

export default React.memo(VisitsTab)
