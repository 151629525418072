import React from 'react'

const PlanPrice = ({plansData}: any) => {
  return (
    <div className="flex">
      <h2 className="flex-2 h2-semi">
        Select a Plan That
        <br className="lg:block hidden" />
        Works For You
      </h2>
      <div className="flex-1 text-right">
        <div className="cwpf_slctPlan_prcBox">
          <div className="h1-semi">${Math.floor(plansData.price)}</div>
          <span className="p1">per month</span>
        </div>
      </div>
    </div>
  )
}

export default PlanPrice
