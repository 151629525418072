import moment from 'moment'
/**
 * @description - This helper adds blanks days at the end of the graph data, to make sure the last date is always visible
 * @param datapoints  - array of graph datapoints
 * @param days - number days to be added
 * @returns - array of graph datapoints
 */
const addDaysToGraphData = (datapoints: Array<any>, days = 1) => {
  if (datapoints && datapoints.length > 0) {
    const lastDate = datapoints[datapoints.length - 1].date

    Array(days)
      .fill(0)
      .forEach(() => {
        lastDate.setDate(lastDate.getDate() + 1)
        datapoints.push({
          date: lastDate,
          value: null,
          formattedValue: null,
          formattedDate: moment(lastDate).format('DD MMMM, YYYY '),
          utcTime: moment(lastDate).format('HH:MM UTC'),
        })
      })
  }

  return datapoints
}

export const bandwidthChartData = [
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
  {
    Date: '2023-01-05',
    BandwidthSum: 1,
  },
]

export default addDaysToGraphData
