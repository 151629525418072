/** Libraries */
import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTracking} from 'react-tracking'
import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {DatePicker, Space} from 'antd'
import {useAppDispatch, useAppSelector} from 'app/hooks'

/** Store/Services */
import {
  useAddPlanMutation,
  useGetMonitoringStatsQuery,
} from '../../../../app/services'
import {useGetCurrentPlanQuery} from 'app/services'

/** Reusable Components */
import AccessDrawer from './AppDrawer/AccessbilityDrawer'
import Drawer from './AppDrawer/SelectAppDrawer'
import {
  Container,
  Col,
  Button,
  Card,
  Row,
  Text,
  Tabs,
  CommingSoonScreen,
} from 'Common/Components/Core/DesignTemplates'
import AppDropdown from './ApplicationDropdown'
import {toggleModal} from 'Common/Components/Core/Modal/ModalSlice'
import TotalVisits from './TotalVisits'
import TotalDiskSpace from './TotalDiskSpace'
import ListBoxes from './Charts/ListBoxes'
import TotalInstalledApp from './TotalInstalledApp'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import VisitsTab from './ResourceAnalysisTabs/VisitsTab'
import BanndWidthTab from './ResourceAnalysisTabs/BandwidthTab'
import DiskSpaceTab from './ResourceAnalysisTabs/DiskSpaceTab'
import {ReactComponent as Info} from '../../../../Assets/img/info_outline.svg'
import TotalBandwith from './TotalBandwith'

/** Helpers */
import {ModalName} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import {ReactComponent as Thumbup} from '../../../../Assets/img/thumbs_up.svg'
import {ReactComponent as Shutter} from '../../../../Assets/img/shutter_speed.svg'
import {ReactComponent as Location} from '../../../../Assets/img/location_searching.svg'
import {ReactComponent as Dollar} from '../../../../Assets/img/Manage.svg'
import access from '../../../../Assets/img/access.png'
import {ReactComponent as AggregateWebImg} from '../../../../Assets/img/aggregate-web-vitals.svg'
import './style.css'
import 'antd/dist/antd.css'
interface iMtdOpitons {
  mtd_options: any
}

//validations
const validate = Yup.object({
  mtd_options: Yup.string().required('Please select an option.'),
})
const initialValues: iMtdOpitons = {
  mtd_options: '',
}
const onSubmit = (
  values: iMtdOpitons,
  {setSubmitting}: FormikHelpers<iMtdOpitons>,
) => {
  return
}

export default function Dashboard() {
  const navigate = useNavigate()
  const [drawerState, setDrawerState] = useState(false)
  const [drawerAccessState, setAccessDrawerState] = useState(false)
  const [toggle, setToggle] = useState('Visits')
  const [appDrawer, setAppDrawer] = useState(false)
  const dispatch = useAppDispatch()
  const dropdownRef = useRef<any>(null)
  const checkoutPlan = useAppSelector((state: any) => state.plans.checkoutPlan)
  const [addPlan, {isLoading: isAddingPlan}] = useAddPlanMutation()
  const {trackEvent} = useTracking<any>({
    attributes: {
      page: 'dashboard',
      current_url: window.location.href,
    },
  })

  const {RangePicker} = DatePicker

  const {data: currentPlan, isLoading: isCurrentPlanLoading} =
    useGetCurrentPlanQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    })
  // useEffect(() => {
  //   if (currentPlan?.app?.count <= 0) navigate('/add-application')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  const {data: everythingSummary, isLoading: isEverythingSummaryLoading} =
    useGetMonitoringStatsQuery({
      action: 'summary',
      source: 'everything',
    })

  const planAddedHandler = () => {
    addPlan({
      plan_id: checkoutPlan.id,
    })
    //  .unwrap()
    //  .then(response => {
    //    trackEvent({
    //      ...eventObject,
    //      attributes: {...eventObject.attributes, is_success: true},
    //    })

    //  })
    //  .catch(error => {
    //    trackEvent(eventObject)
    //    return new Error(error)
    //  })
  }
  const showModalHandler = () => {
    dispatch(toggleModal(ModalName.modifyPlanModal))
    trackEvent({
      event_name: 'fmui_modify_plan',
    })
  }
  const drawerHandler = () => {
    //setDrawerState(prev => !prev)
    setAppDrawer(prev => !prev)
    setAccessDrawerState(false)
  }
  // const drawerAcceessHandler = () => {
  //   setAccessDrawerState(prev => !prev)
  //   setDrawerState(false)
  //   window.scrollTo(0, 0)
  // }
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAppDrawer(false)
      }
      return
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [dropdownRef, setAppDrawer])

  return (
    <>
      {drawerState && (
        <>
          <Drawer
            values={{
              heading: 'Select Application',
              subHeading: '2 Applications Selected for visualization',
            }}
            drawerState={drawerState}
            onCancel={() => setDrawerState(false)}
            check={true}
          />
          <Container styles="fixed top-0 bottom-0 left-0 right-0 bg-dark opacity-40 z-20"></Container>
        </>
      )}
      {drawerAccessState && (
        <>
          <AccessDrawer
            values={{
              heading: 'Accessibility',
              subHeading: 'These 6 applications have accessibility issues',
            }}
            drawerState={drawerAccessState}
            onCancel={() => setAccessDrawerState(false)}
            check={true}
          />
          <Container styles="fixed top-0 bottom-0 left-0 right-0 bg-dark opacity-40 z-20"></Container>
        </>
      )}
      <Container styles="pt-40 container relative">
        <Row between>
          <Row middle>
            <Text type="h4" semi styles="text-gray-100 poppins mt-10">
              Dashboard
            </Text>
            <Tooltip
              bottom
              text="'This page shows you the usage statistics for your plan based on all your applications. The values for visits and bandwidth usage get reset every billing cycle."
            >
              <Container styles="relative left-6 top-4">
                <Info />
              </Container>
            </Tooltip>
          </Row>
          <Col>
            <Row middle center>
              <Container styles=" mr-28">
                <Container styles="flex flex-row justify-end">
                  <Text semi type="h4" styles="text-gray-80">
                    ${Math.floor(Number(currentPlan?.plan?.price))}&nbsp;
                  </Text>
                  <Text styles="p2 relative top-6 text-gray-80">/&nbsp;mo</Text>
                </Container>
                {/* <Text type="p" styles="p3 text-gray-40 Poppins">
                Next Billing on 20th Feb 2021
              </Text> */}
              </Container>
              <Button medium primaryOutline onClick={showModalHandler}>
                <Dollar className="inline-block align-middle mr-10" />
                Manage Plan
              </Button>
            </Row>
          </Col>
        </Row>

        {/* <Row gutter={8} styles="mt-24 cwpf_details_card">
          <Col lg={6} styles="cwpf_card relative py-[20px]">
            <TotalInstalledApp />
          </Col>

          <Col lg={6} styles="cwpf_card relative py-[20px]">
            <TotalVisits />
          </Col>
        </Row> */}
        <Row gutter={24} styles=" mt-24 cwpf_dashboard_appStats_card">
          <Col lg={6} styles="">
            <div className="cwpf_dashboard_appStats_borderCard  ">
              <TotalInstalledApp />
            </div>
          </Col>
          <Col lg={6}>
            <div className="cwpf_dashboard_appStats_borderCard">
              <TotalVisits
                currentPlan={currentPlan}
                everythingSummary={everythingSummary}
                isEverythingSummaryLoading={isEverythingSummaryLoading}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={24} styles=" mt-24 cwpf_dashboard_appStats_card">
          <Col lg={6} styles="">
            <div className="cwpf_dashboard_appStats_borderCard">
              <TotalBandwith
                currentPlan={currentPlan}
                everythingSummary={everythingSummary}
                isEverythingSummaryLoading={isEverythingSummaryLoading}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="cwpf_dashboard_appStats_borderCard">
              <TotalDiskSpace />
            </div>
          </Col>
        </Row>

        <Text type="p" semi styles="my-24">
          Resource Analysis
        </Text>

        <Container styles="border-solid border rounded border-gray-10">
          <Container>
            <Tabs
              listStyle="pl-20 pt-20"
              navStyle="flex justify-between items-center"
              horizontal
              component={
                <Container className="cursor-pointer relative">
                  <Tooltip text="Coming Soon!">
                    <Button
                      primaryText
                      small
                      icon="chevron_right"
                      onClick={drawerHandler}
                      disabled
                    >
                      All Applications
                    </Button>
                  </Tooltip>

                  {appDrawer && (
                    <AppDropdown dropdownRef={dropdownRef}></AppDropdown>
                  )}
                </Container>
              }
            >
              <div id="Visits">
                <VisitsTab
                  everythingSummary={everythingSummary}
                  isEverythingSummaryLoading={isEverythingSummaryLoading}
                />
              </div>
              <div id="Bandwidth">
                <BanndWidthTab
                  everythingSummary={everythingSummary}
                  isEverythingSummaryLoading={isEverythingSummaryLoading}
                />
              </div>
              <div id="Disk Space">
                <DiskSpaceTab />
              </div>
            </Tabs>
          </Container>

          {/* {toggle == 'Visits' ? (
              <PieChart1
                Legendtitle="Visits Breakdown"
                Title="Total Visits"
                space="1047"
                totalspace="/20 K"
              />
            ) : toggle == 'Bandwidth' ? (
              <PieChart1
                Legendtitle="Bandwith Breakdown"
                Title="Total Bandwidth"
                space="1047"
                totalspace="/20 GB"
              />
            ) : (
              <PieChart1
                Legendtitle="Disk Space Breakdown  "
                Title="Total Usage"
                space="10 GB"
                totalspace="/20 GB"
              />
            )} */}
        </Container>
        <Text type="p" semi styles="text-gray-80 my-24">
          Aggregate App Vitals
        </Text>

        <Container styles="relative mb-40 sm-blur inline-block">
          <CommingSoonScreen
            ImgComponent={AggregateWebImg}
            heading="Aggregate Web Vitals"
          />

          <Row
            stretch
            between
            gutter={16}
            styles="cwpf_dashboardVitals blur-sm"
          >
            <Col md={3} lg={3} sm={12} styles="cwpf_dashboardVitals_wrap mb-20">
              <Card styles="cwpf_dashboard_performance_card">
                <Row between>
                  <Col>
                    <Text type="h6" styles="text-gray-100 md:text-[14px]" semi>
                      Performance
                    </Text>
                  </Col>
                </Row>
                <Row middle>
                  <Col>
                    <Shutter />
                  </Col>
                  <Col styles="ml-4">
                    <Row>
                      <Text type="h1" semi styles="text-gray-80">
                        10
                      </Text>
                      <Text
                        type="h3"
                        styles="self-center text-[20px] text-gray-80"
                        semi
                      >
                        /10
                      </Text>
                    </Row>
                  </Col>
                </Row>
                <Row middle>
                  <Text
                    type="span"
                    styles="material-icons relative  mr-4 text-18"
                  >
                    check_circle_outline
                  </Text>
                  <Text type="p" semi styles="p3 whitespace-nowrap mt-2">
                    All your apps are performing well
                  </Text>{' '}
                </Row>
                <Row middle>
                  <Text type="p" styles="p3">
                    Everything looks good. Your website is at its optimal
                    performance.
                  </Text>{' '}
                </Row>
              </Card>
            </Col>

            <Col md={3} lg={3} sm={12} styles="cwpf_dashboardVitals_wrap mb-20">
              <Card styles="cwpf_dashboard_accessibility_card">
                <Row row between>
                  <Text type="p" styles="p1 md:text-[14px] text-gray-80" semi>
                    Accessibility
                  </Text>
                  <Col>
                    <ListBoxes installedApp={7} listColor="bg-[#F17729]" />
                  </Col>
                </Row>
                <Row middle>
                  <Col>
                    <img src={access} className="w-40 h-40" />
                  </Col>
                  <Col styles="ml-8">
                    <Row row bottom>
                      <Text type="h1" semi styles="text-gray-80">
                        8
                      </Text>
                      <Text
                        type="h3"
                        styles="self-center text-[20px] text-gray-80"
                        semi
                      >
                        /10
                      </Text>
                    </Row>
                  </Col>
                </Row>

                <Row middle>
                  <Text
                    type="span"
                    styles="material-icons relative text-[#F17729] mr-4 text-18"
                  >
                    error_outline
                  </Text>
                  <Text
                    type="p"
                    semi
                    styles="p3 text-[#F17729] whitespace-nowrap mt-2"
                  >
                    7 apps have accessibility
                  </Text>{' '}
                </Row>
                <Row middle>
                  <Text type="p" styles="p3 mb-2">
                    Some of your apps have accessibility issues.
                    <Text type="a" styles="text-blue-100">
                      {' '}
                      &nbsp; View Apps
                    </Text>
                  </Text>{' '}
                </Row>
              </Card>
            </Col>

            <Col md={3} lg={3} sm={12} styles="cwpf_vitals_wrap mb-20">
              <Card styles=" cwpf_dashboard_bestPracticses_card">
                <Row row between>
                  <Text type="p" styles="p1 md:text-[14px]" semi>
                    Best Practices
                  </Text>
                  <Col>
                    <ListBoxes installedApp={6} listColor="bg-red-75" />{' '}
                  </Col>
                </Row>
                <Row middle>
                  <Col>
                    <Thumbup className="" />
                  </Col>
                  <Col styles="ml-[10px]">
                    <Row row bottom>
                      <Text type="h1" semi styles="text-gray-80">
                        6
                      </Text>
                      <Text
                        type="h3"
                        styles="self-center text-[20px] text-gray-80"
                        semi
                      >
                        /10
                      </Text>
                    </Row>
                  </Col>
                </Row>
                <Row middle>
                  <Text
                    type="span"
                    styles="material-icons text-[#EA4545] relative mr-4 text-18"
                  >
                    check_circle_outline
                  </Text>
                  <Text
                    type="p"
                    semi
                    styles="p3 text-[#EA4545] whitespace-nowrap mt-2"
                  >
                    All your apps are performing well
                  </Text>{' '}
                </Row>
                <Row middle>
                  <Text type="p" styles="p3 mb-2">
                    Everything looks good. Your website is at its optimal
                    performance.
                    <Text type="a" styles="text-blue-100">
                      {' '}
                      &nbsp; View Apps
                    </Text>
                  </Text>{' '}
                </Row>
              </Card>
            </Col>

            <Col md={3} lg={3} sm={12} styles="cwpf_vitals_wrap mb-20">
              <Card styles=" cwpf_dashboard_seo_card">
                <Row row between>
                  <Text type="h6" styles="text-gray-100 md:text-[14px]" semi>
                    SEO
                  </Text>{' '}
                  <Col>
                    <ListBoxes installedApp={10} listColor="bg-[#84F5D7]" />
                  </Col>
                </Row>
                <Row middle>
                  <Col>
                    <Location className="relative mr-10" />
                  </Col>
                  <Col styles="ml-2">
                    <Row row bottom>
                      <Text type="h1" semi styles="text-gray-80">
                        10
                      </Text>
                      <Text
                        type="p"
                        styles="self-center text-[20px] text-gray-80"
                        semi
                      >
                        /10
                      </Text>
                    </Row>
                  </Col>
                </Row>
                <Row middle>
                  <Text
                    type="span"
                    styles="material-icons relative  mr-4 text-18"
                  >
                    check_circle_outline
                  </Text>
                  <Text type="p" semi styles="p3 whitespace-nowrap mt-2">
                    All your apps are performing well
                  </Text>{' '}
                </Row>
                <Row middle>
                  <Text type="p" styles="p3 mb-2">
                    Everything looks good. Your website is at its optimal
                    performance.
                  </Text>{' '}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}
