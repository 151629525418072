import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import ListBody from 'antd/lib/transfer/ListBody'
import operationToast from 'app/OperationToast'
import {
  AppStatus,
  OperationType,
} from 'Common/Components/Hooks/useOperationsPolling'
import {appListInterface} from 'Common/Components/Pages/ApplicationListing/util'
import {iOperationData} from 'Constants/Interfaces/iGlobal'
import {customEvent} from 'index'
import moment from 'moment'
import getCookieByKey from 'utils/getTokenByKey'
import {number} from 'yup/lib/locale'
import {iSSHResponse} from './iServiceType'

declare const window: customEvent

type operationStatus =
  | 'enqueued'
  | 'running'
  | 'completed'
  | 'timeout'
  | 'error'
  | 'waiting'

export interface operationPoll {
  app: number
  completed?: unknown
  id: number
  message?: string | null
  operation_status: operationStatus
  type: 'add_app' | 'remove_app' | 'update_app'
  //parameters?: 'wp-params' | 'db-params' | 'cname' | 'php-settings'
  parameters: Array<
    | 'wp_params'
    | 'db_params'
    | 'cname'
    | 'php_settings'
    | 'ssh_creds'
    | 'ssh_password'
    | 'alias'
  >
}

// Create your service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'services',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_NUCLEUS_URL,
    prepareHeaders: (headers, {getState}: any) => {
      const userSession = getCookieByKey('cwSession')
      if (!userSession || !decodeURIComponent(userSession))
        window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))

      // const userId = getState().platform3?.queries['getCurrentUser("")'].data.id
      headers.set('X-USER-ID', String(window?.distinct_id))
      return headers
    },
  }),
  tagTypes: [
    'Plans',
    'Applications',
    'CurrentPlan',
    'CacheSetting',
    'ApplicationById',
    'SFTPCredsByAppId',
    'ApplicationMonitoring',
    'GetOperation',
    'BackupRestorePoints',
    'BackupLatestSpace',
  ],
  endpoints: build => ({
    /** Create Nuclues Session */
    setUserSession: build.mutation<any, any>({
      query: body => ({
        url: `/auth/x-user-id`,
        method: 'POST',
        body,
      }),
    }),

    /** Clear Nuclues Session */
    clearUserSession: build.mutation<any, any>({
      query: arg => {
        const {x_user_id} = arg

        return {
          url: `/auth/x-user-id/${x_user_id}`,
          method: 'DELETE',
        }
      },
    }),

    /** Plans */
    getAllPlans: build.query<any, void>({
      query: () => ({url: 'plans'}),
      providesTags: ['Plans'],
    }),
    getCurrentPlan: build.query<any, void>({
      query: () => ({url: '/plans/current'}),
      providesTags: ['CurrentPlan'],
    }),
    addPlan: build.mutation<any, any>({
      query: body => ({
        url: `plans/purchase`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CurrentPlan'],
    }),
    modifyPlan: build.mutation<any, any>({
      query: planId => ({
        url: `plans/switch`,
        method: 'POST',
        body: planId,
      }),
      invalidatesTags: ['CurrentPlan'],
    }),
    deletePlan: build.mutation<any, void>({
      query() {
        return {
          url: `plans/cancellation`,
          method: 'POST',
        }
      },
      invalidatesTags: ['CurrentPlan'],
    }),

    /** --- x --- */

    /** Applications */
    getAllApplications: build.query<any, {page: number; page_size: number}>({
      query: arg => {
        const {page, page_size} = arg
        return {
          url: `apps`,
          params: {page, page_size},
        }
      },
      transformResponse: (response: any) => {
        const getCompletedPost = response?.results
          ?.filter(
            (post: any) =>
              !post?.operations.some(
                (operation: any) =>
                  [AppStatus.ERROR, AppStatus.TIMEOUT].includes(
                    operation.operation_status,
                  ) && operation?.type === OperationType.ADD_APP,
              ),
          )
          .sort((a: any, b: any) =>
            moment.utc(b?.created).diff(moment.utc(a?.created)),
          )
        //
        //   {...response, results: [...getCompletedPost]},
        //   'completed post',
        // )

        return {...response, results: [...getCompletedPost]}
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.results.map(({id}: {id: number}) => ({
                type: 'Applications',
                id,
              })),
              'Applications',
            ]
          : ['Applications'],
    }),
    deleteApplication: build.mutation<any, number>({
      query(id) {
        return {
          url: `apps/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => [
        {type: 'Applications', id: arg},
        'CurrentPlan',
      ],
    }),
    addApplication: build.mutation<any, any>({
      query: body => ({
        url: `apps`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => ['Applications', 'CurrentPlan'],
    }),

    updateApplication: build.mutation<any, any>({
      query: arg => {
        const {app_id, body} = arg
        return {
          url: `/apps/${app_id}/metadata`,
          method: 'PATCH',
          body,
        }
      },
    }),

    getSingleApplication: build.query<any, string | undefined>({
      query(id) {
        return {
          url: `apps/${id}`,
        }
      },
      providesTags: (result, error, arg) => [
        {type: 'ApplicationById', id: arg},
      ],
    }),
    updateSingleApplication: build.mutation<
      any,
      {
        app_id: any
        subPath?:
          | 'wp-params'
          | 'cname'
          | 'db-params'
          | 'metadata'
          | 'php-settings'
          | 'master-ssh-creds'
          | 'domain'
          | ''
        body: any
      }
    >({
      query: ({app_id, subPath, body}) => {
        //const {app_id, ...payload} = arg
        return {
          url: `/apps/${app_id}${subPath ? `/${subPath}` : ''}`,
          method: 'PATCH',
          body,
        }
      },
      // async onQueryStarted({...patch}, {dispatch, queryFulfilled}) {
      //   const {data: responseData} = await queryFulfilled
      //   dispatch(
      //     apiSlice.endpoints.getOperations.initiate({
      //       id: responseData.operation_id,
      //     }),
      //   )
      // },
      invalidatesTags: (result, error, arg) => [],
    }),
    /** --- x --- */

    /** Dashboard */
    getResourceDiskSpace: build.query<any, void>({
      query: () => ({url: 'dashboard/disk-utilization'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getResourceBandwidth: build.query<any, void>({
      query: () => ({url: 'dashboard/bandwidth'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getResourceVisits: build.query<any, void>({
      query: () => ({url: 'dashboard/visit-per-host'}),
    }),
    getTotalVisits: build.query<any, void>({
      query: () => ({url: 'dashboard/total-visits'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getTotalApps: build.query<any, void>({
      query: () => ({url: 'dashboard/total-apps'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    // getTotalDiskSpace: build.query<any, any>({
    //   query: values => ({
    //     url: `dashboard/total-disk-space`,
    //   }),
    //   providesTags: ['ApplicationMonitoring'],
    // }),
    getTotalDiskSpace: build.query<any, any>({
      query: arg => {
        const {app_id = ''} = arg
        return {
          url: `dashboard/total-disk-space${app_id ? `?app_id=${app_id}` : ''}`,
        }
      },
      providesTags: ['ApplicationMonitoring'],
    }),

    getAllMetrics: build.query<any, void>({
      query: () => ({url: 'dashboard/mql-all-metrics-summary'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getVisitsDatapoints: build.query<any, void>({
      query: () => ({url: 'dashboard/mql-visits-datapoints'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getBandwidthDatapoints: build.query<any, void>({
      query: () => ({url: 'dashboard/mql-bandwidth-datapoints'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getdiskSpaceDatapoints: build.query<any, void>({
      query: () => ({url: 'dashboard/mql-disk-datapoints'}),
      providesTags: ['ApplicationMonitoring'],
    }),
    getMonitoringStats: build.query<any, any>({
      query: arg => {
        const {action, app_ids, source} = arg
        return {
          url: 'monitoring',
          params: {action, app_ids, source},
        }
      },
    }),

    /** SSH/SFTP Settings */
    getSFTPCredentialsForApp: build.query<any, string | undefined>({
      query: app_id => {
        return {
          url: `/apps/${app_id}/creds`,
        }
      },
      providesTags: ['SFTPCredsByAppId'],
    }),

    addSFTPCredentialsForApp: build.mutation<any, any>({
      query: payload => {
        const {app_id, values} = payload
        return {
          url: `/apps/${app_id}/creds`,
          method: 'POST',
          body: values,
        }
      },
      invalidatesTags: (result, error, arg) => ['SFTPCredsByAppId'],
    }),

    updateSFTPCredsForApp: build.mutation<any, any>({
      query: arg => {
        const {app_id, cred_id, payload} = arg

        return {
          url: `/apps/${app_id}/creds/${cred_id}`,
          method: 'PATCH',
          body: payload,
        }
      },
      invalidatesTags: (result, error, arg) => ['SFTPCredsByAppId'],
    }),

    deleteSFTPCredsForApp: build.mutation<any, any>({
      query: arg => {
        const {app_id, cred_id} = arg

        return {
          url: `/apps/${app_id}/creds/${cred_id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => ['SFTPCredsByAppId'],
    }),
    /** --- x --- */

    /** Cache Settings */
    getCacheSetting: build.query<any, void>({
      query: () => ({url: 'cachesettings'}),
      providesTags: ['CacheSetting'],
    }),
    addCacheSetting: build.mutation<any, any>({
      query: body => ({
        url: `cachesettings/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CacheSetting'],
    }),
    /** --- x --- */

    /** app timezone */
    updateTimezone: build.mutation<any, any>({
      query: arg => {
        const {app_id, body} = arg

        return {
          url: `apps/${app_id}/php-settings`,
          method: 'PATCH',
          body,
        }
      },
    }),
    /** --- x --- */
    /** Backup */
    addAppBackup: build.mutation<any, any>({
      query: ({app_id}) => ({
        url: `apps/${app_id}/backup`,
        method: 'POST',
      }),
    }),

    restoreBackup: build.mutation<any, any>({
      query: ({app_id, body}) => ({
        url: `apps/${app_id}/restore`,
        method: 'POST',
        body,
      }),
    }),
    getLatestBackup: build.query<any, any>({
      query: ({app_id}) => {
        return {
          url: `/apps/${app_id}/backup-latest`,
        }
      },
      providesTags: (result, error, {app_id}) => [
        {type: 'BackupLatestSpace', id: app_id},
      ],
    }),

    getRestorePoints: build.query<any, any>({
      query: ({app_id}) => ({
        url: `apps/${app_id}/restore-points`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        const getCompletedPost = response?.map((v: any) => {
          const dateData = v.split('-')
          return {
            date: dateData.slice(0, 3).join('-'),
            time: dateData.slice(3, 4).join(''),
            status: dateData.slice(4, 5).join(''),
          }
        })
        // console.log(
        //   {...response, results: [...getCompletedPost]},
        //   'completed post',
        // )
        return getCompletedPost
      },
      providesTags: (result, error, {app_id}) => [
        {type: 'BackupRestorePoints', id: app_id},
      ],
    }),

    /** --- x --- */

    /** Domain */

    verifyDomain: build.mutation<any, any>({
      query: ({app_id}) => ({
        url: `apps/${app_id}/verify-domain`,
        method: 'POST',
      }),
      async onQueryStarted({app_id}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedData} = await queryFulfilled
          dispatch(
            apiSlice.util.updateQueryData(
              'getSingleApplication',
              app_id,
              draft => {
                draft.custom_domain = updatedData || null
              },
            ),
          )
        } catch (err) {
          console.error(err, 'error')
        }
      },
    }),
    getCloudfareApis: build.query<any, void>({
      query: () => ({url: 'apps/cloudflare-ips'}),
    }),
    /** --- x --- */
    /** Other */
    getRegions: build.query<any, void>({
      query: () => ({url: 'regions'}),
    }),
    getApplicationTypes: build.query<any, void>({
      query: () => ({url: 'app-types'}),
    }),
    getOperations: build.query<iOperationData, {id: string}>({
      query: ({id}) => ({
        url: `operations/${id}`,
      }),
      async onQueryStarted({id}, {dispatch, queryFulfilled}) {
        try {
          const {data: operationData} = await queryFulfilled
          const refetchChecker = [
            AppStatus.COMPLETED,
            AppStatus.TIMEOUT,
            AppStatus.ERROR,
          ]
          dispatch(
            apiSlice.util.updateQueryData(
              'getAllApplications',
              {page: 1, page_size: 100},
              (data: {results: appListInterface[]}) => {
                const appIndex = data?.results.findIndex(
                  (app: appListInterface) => app.id === operationData.app_id,
                )
                //check if app exist
                if (appIndex !== -1) {
                  const operationIndex = data.results[
                    appIndex
                  ].operations.findIndex(
                    (operation: iOperationData) =>
                      operation.id === operationData.id,
                  )

                  if (operationIndex !== -1) {
                    if (
                      data.results[appIndex].operations[operationIndex]
                        .operation_status !== operationData.operation_status
                    ) {
                      data.results[appIndex].operations[operationIndex] = {
                        ...operationData,
                      }
                    }
                    operationToast(operationData, data.results[appIndex].name)
                  } else {
                    data.results[appIndex].operations = [
                      ...data.results[appIndex].operations,
                      operationData,
                    ]
                  }

                  //check list for waiting and start polling when one of them changes to enqueue
                  if (refetchChecker.includes(operationData.operation_status)) {
                    const findWaiting = data.results[appIndex].operations.find(
                      (operation: iOperationData) =>
                        operation.operation_status === AppStatus.WAITING,
                    )
                    if (findWaiting) {
                      dispatch(
                        apiSlice.endpoints.getOperations.initiate({
                          id: String(findWaiting.id),
                        }),
                      )
                    }
                  }
                }
              },
            ),
          )
          //manual cache update of single application no need to invalidate for every operation
          if (
            [
              OperationType.UPDATE_APP,
              OperationType.BACKUP,
              OperationType.RESTORE,
            ].includes(operationData.type)
          ) {
            dispatch(
              apiSlice.util.updateQueryData(
                'getSingleApplication',
                String(operationData.app_id),
                (data: any) => {
                  const operationIndex = data.operations.findIndex(
                    (operation: iOperationData) =>
                      operation.id === operationData.id,
                  )
                  if (operationIndex !== -1) {
                    if (
                      data.operations[operationIndex].operation_status !==
                      operationData.operation_status
                    ) {
                      data.operations[operationIndex] = {
                        ...operationData,
                      }
                    }
                  } else {
                    data.operations = [...data.operations, operationData]
                  }
                },
              ),
            )
          }
          //refetch getSingleApplication/backupRestorePoints when update_app polling is completed/error/timeout
          if (refetchChecker.includes(operationData.operation_status)) {
            operationData.type === OperationType.UPDATE_APP &&
              dispatch(
                apiSlice.util.invalidateTags([
                  {type: 'ApplicationById', id: operationData.app_id},
                ]),
              )

            if (
              [OperationType.BACKUP, OperationType.RESTORE].includes(
                operationData.type,
              )
            ) {
              dispatch(
                apiSlice.util.invalidateTags([
                  {type: 'BackupRestorePoints', id: operationData.app_id},
                  {type: 'BackupLatestSpace', id: operationData.app_id},
                ]),
              )
            }
          }
        } catch {
          console.error('error')
        }
      },
      providesTags: (result, error, arg) => [
        {type: 'GetOperation', id: arg.id},
      ],
    }),
    getSSHDetails: build.query<any, void>({
      query: () => ({url: 'ssh'}),
    }),
    /** --- x --- */
    /** User Events/Tracking */
    postEvents: build.mutation<any, any>({
      query: body => ({
        url: `events`,
        method: 'POST',
        body,
      }),
    }),
    /** --- x --- */
    /**Reset Cloudfare Cache*/
    clearCloudfareCache: build.mutation<any, any>({
      query: ({app_id}) => ({
        url: `apps/${app_id}/cache-clear`,
        method: 'POST',
      }),
    }),
    /** --- x --- */
    /**Intelligence */
    intelligenceLogsCount: build.query<any, any>({
      query: ({app_id, type, datetime_to = moment(new Date()).format()}) => ({
        url: `intelligence/${app_id}/${type}/count`,
        params: {datetime_to},
      }),
    }),
    intelligenceLogs: build.query<any, any>({
      query: ({app_id, limit, offset, datetime_to, type, search_term}) => ({
        url: `intelligence/${app_id}/${type}`,
        params: {limit, offset, datetime_to, search_term},
      }),
    }),

    /** --- x --- */

    /**Overview */
    overviewCharts: build.query<any, any>({
      query: ({app_id, type}) => ({
        url: `dashboard/mql-${type}-datapoints/${app_id}`,
      }),
    }),
    overviewMatrices: build.query<any, any>({
      query: ({app_id, type}) => ({
        url: `dashboard/mql-all-metrics-summary/${app_id}`,
      }),
    }),
    /** --- x --- */
  }),
})

export const {
  useSetUserSessionMutation,
  useClearUserSessionMutation,
  useGetAllPlansQuery,
  useAddPlanMutation,
  useModifyPlanMutation,
  useDeletePlanMutation,
  useGetCurrentPlanQuery,
  useGetAllApplicationsQuery,
  useDeleteApplicationMutation,
  useGetSingleApplicationQuery,
  useUpdateSingleApplicationMutation,
  useUpdateApplicationMutation,
  useGetRegionsQuery,
  useGetApplicationTypesQuery,
  useAddApplicationMutation,
  useGetSSHDetailsQuery,
  useGetCacheSettingQuery,
  useAddCacheSettingMutation,
  useGetOperationsQuery,
  useGetSFTPCredentialsForAppQuery,
  useAddSFTPCredentialsForAppMutation,
  useUpdateSFTPCredsForAppMutation,
  useDeleteSFTPCredsForAppMutation,
  useUpdateTimezoneMutation,
  usePostEventsMutation,
  usePrefetch,
  useGetBandwidthDatapointsQuery,
  useGetdiskSpaceDatapointsQuery,
  useGetVisitsDatapointsQuery,
  useGetAllMetricsQuery,
  useGetResourceDiskSpaceQuery,
  useGetResourceBandwidthQuery,
  useGetResourceVisitsQuery,
  useGetTotalAppsQuery,
  useGetTotalDiskSpaceQuery,
  useGetTotalVisitsQuery,
  useAddAppBackupMutation,
  useGetRestorePointsQuery,
  useRestoreBackupMutation,
  useVerifyDomainMutation,
  useGetCloudfareApisQuery,
  useClearCloudfareCacheMutation,
  useIntelligenceLogsCountQuery,
  useIntelligenceLogsQuery,
  useOverviewChartsQuery,
  useOverviewMatricesQuery,
  useGetLatestBackupQuery,
  useGetMonitoringStatsQuery,
} = apiSlice
