/** Libraries */
import React, {useRef, useState} from 'react'
import {Form, Formik, FormikHelpers} from 'formik'
import {useOutletContext, useParams} from 'react-router-dom'

/** Store/Services */
import {
  apiSlice,
  useAddAppBackupMutation,
  useGetAllApplicationsQuery,
  useRestoreBackupMutation,
} from '../../../../../../../app/services/index'

/** Reusable Components */
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {
  Container,
  Text,
  Row,
  RadioButton,
  Button,
  CustomSelect,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {iSelectApplication} from '../../util'

/** Assets (CSS/Images) */
import {ReactComponent as OnlyfilesIcon} from '../../../../../../../Assets/img/manage-app/workflow/only-file.svg'
import {useAppDispatch} from 'app/hooks'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const initialValues: iSelectApplication = {
  app_id: '',
  appType: 'Yes',
}

const RestoreDataModal = ({
  show,
  restoreData,
  onClose,
  id,
}: {
  show: boolean
  restoreData: any
  onClose: () => void
  id: number
}) => {
  const formatRestoreData = Object.values(restoreData).join('-')
  const formikRef = useRef() as any
  const propsOutlet: iPropsOutlet = useOutletContext()
  const isPolling =
    propsOutlet.checkPolling(Parameters.restore) ||
    propsOutlet.checkPolling(Parameters.backup)

  const {app_id} = useParams()
  const dispatch = useAppDispatch()
  const [restoreBackup] = useRestoreBackupMutation()
  const [addAppBackup] = useAddAppBackupMutation()
  const customEndpoint = ({endpoint, endpointArgs, toastMessage}: any) => {
    // formikRef?.setSubmitting(true)
    endpoint(endpointArgs)
      .unwrap()
      .then((fulfilled: any) => {
        if (fulfilled.error) throw new Error()
        if (fulfilled.operation_id) {
          dispatch(
            apiSlice.endpoints.getOperations.initiate({
              id: fulfilled.operation_id,
            }),
          )
        }
        toastMessage &&
          customToast({
            title: '',
            description: toastMessage,
            level: 'info',
            loading: true,
          })
        onClose()
        formikRef?.setSubmitting(false)
      })
      .catch((err: any) => {
        onClose()
        formikRef?.setSubmitting(true)
        console.log(err, 'error')
      })
  }

  const onSubmit = (isBackup: boolean, {setSubmitting}: FormikHelpers<any>) => {
    customEndpoint({
      endpoint: restoreBackup,
      endpointArgs: {
        app_id: app_id,
        body: {
          backup_id: 1,
          restore_point: formatRestoreData,
          is_backup: isBackup,
        },
      },
      toastMessage:
        'Backup is being Restored. You wont be able to perform certain actions while the operation is taking place',
    })
  }

  // const AppSelectData =
  //   appData?.results !== undefined
  //     ? appData?.results.map((item: any, i: any) => {
  //         return {
  //           label: item?.name,
  //           value: item?.id,
  //         }
  //       })
  //     : []
  return (
    <Modal show={show} classes="min-w-[590px]" onClose={onClose}>
      <Container styles="my-30 px-[40px]">
        <Text semi type="h5" styles="text-gray-80">
          Do you want to take an on-demand backup?
        </Text>
        <Row styles="pt-18">
          <Text semi styles="p3 text-gray-80">
            Before we restore the app, it is advised you take a backup. You can
            restore this version from the job list at anytime.
          </Text>
        </Row>
        {/* <Row middle styles="mt-16">
          <OnlyfilesIcon className="mr-8" />
          <Text med styles="p2 text-gray-80">
            {type}
          </Text>
        </Row> */}
        <Container>
          <Formik
            initialValues={initialValues}
            innerRef={formikRef}
            // validationSchema={Yup.object({
            //   value: Yup.string().required(
            //     `Value already exists! Please choose a unique value`,
            //   ),
            // })}
            onSubmit={() => {
              return
            }}
          >
            {({values, isSubmitting, ...formikHelper}) => (
              <Form>
                {/* <Container styles="mt-16">
                  <RadioButton
                    label=""
                    labelStyles="text-gray-80 p2 font-semibold"
                    name="appType"
                    options={[
                      {value: 'Yes', name: 'Yes'},
                      {value: 'No', name: 'No'},
                    ]}
                    spacing="space-x-40"
                  />
                </Container> */}
                {/* <CustomSelect
                  name="app_id"
                  styles="w-[60%] mt-20"
                  placeholder="Select Application"
                  options={AppSelectData}
                  value={values.app_id}
                  onChange={(field: string, value: number) =>
                    setFieldValue(field, value)
                  }
                  onBlur={setFieldTouched}
                  error={errors.app_id}
                  touched={touched.app_id}
                  id="uid-select-1"
                  isDisabled={appDisabled}
                /> */}
                <Container styles="mt-30 flex space-x-12 text-blue-100">
                  <Tooltip
                    text="This action is temporarily disabled because 
of an operation in the background which might
potentially cause conflicts"
                    hide={!isPolling}
                  >
                    <Button
                      medium
                      type="button"
                      disabled={isSubmitting || isPolling}
                      onClick={() => onSubmit(true, formikHelper)}
                    >
                      Backup and Restore
                    </Button>
                  </Tooltip>
                  <Button
                    medium
                    type="button"
                    primaryOutline
                    disabled={isSubmitting || isPolling}
                    onClick={() => onSubmit(false, formikHelper)}
                    //onClick={onClose}
                  >
                    Just Restore
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Modal>
  )
}
export default RestoreDataModal
