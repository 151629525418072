import {Text} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import React from 'react'

const DummyModal = ({
  show,
  onClose,
}: {
  show: boolean
  onClose: (value: React.SetStateAction<boolean>) => void
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <Text type="h3" med styles="py-[100px] px-30">
        This feature is under construction
      </Text>
    </Modal>
  )
}

export default DummyModal
