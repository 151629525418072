import {useState, useEffect} from 'react'
import {
  apiSlice,
  useGetAllApplicationsQuery,
  useGetOperationsQuery,
} from 'app/services'
import {useAppDispatch} from 'app/hooks'

export enum AppStatus {
  COMPLETED = 'completed',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  RUNNING = 'running',
  ENQUEUED = 'enqueued',
  WAITING = 'waiting',
}

export enum OperationType {
  ADD_APP = 'add_app',
  UPDATE_APP = 'update_app',
  BACKUP = 'backup',
  RESTORE = 'restore',
}
export const Parameters: Record<
  | 'wp_params'
  | 'db_params'
  | 'cname'
  | 'php_settings'
  | 'ssh_creds'
  | 'ssh_password'
  | 'alias'
  | 'domain'
  | 'restore'
  | 'backup',
  string
> = {
  wp_params: 'wp_params',
  db_params: 'db_params',
  cname: 'cname',
  alias: 'alias',
  php_settings: 'php_settings',
  ssh_creds: 'ssh_creds',
  ssh_password: 'ssh_password',
  domain: 'domain',
  restore: 'restore',
  backup: 'backup',
}
export const opreationPerameters: any = {
  wp_params: 'WordPress Credentials',
  db_params: 'Database Credentials',
  cname: 'Domain',
  alias: 'Domain',
  domain: 'Domain',
  php_settings: 'PHP Settings',
  ssh_creds: 'SSH User',
  ssh_password: 'SSH Password',
  restore: 'Restore backup',
  backup: 'Backup',
}

const useOperationsPolling = (
  getOperationFromProp: Record<string, any>,
  app: any,
) => {
  const dispatch = useAppDispatch()
  const [pollingInterval, setPollingInterval] = useState<number>(0)
  // const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [exception, setException] = useState<string>('')
  const [polling, setPolling] = useState<boolean>(false)
  const [opreationType, setOpreationType] = useState<string>('')
  /* Poll Operations by ID */
  const {
    data: getOperationByID,
    isFetching: isFetchingGetOperationByID,
    isError,
  } = useGetOperationsQuery(
    {id: getOperationFromProp?.id},
    {
      skip: !window.navigator.onLine,
      pollingInterval,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  )
  const checkPolling = (value: string) => {
    if (
      polling &&
      getOperationFromProp?.parameters.find((item: any) => item === value)
    ) {
      return true
    }
    return false
  }
  //
  //
  // useEffect(() => {
  //   if (!isOperationError) return
  //   setFetchingError(isOperationError)
  // }, [isOperationError])

  useEffect(() => {
    if (isFetchingGetOperationByID) return
    if (!getOperationFromProp || isError) {
      setPolling(false)
      return
    }
    switch (getOperationByID?.operation_status) {
      case AppStatus.COMPLETED:
        setPollingInterval(0)
        setPolling(false)
        return
      case AppStatus.TIMEOUT:
      case AppStatus.ERROR:
        setPollingInterval(0)
        setPolling(false)
        setException(getOperationByID?.operation_status)
        return
      case AppStatus.WAITING:
        setPollingInterval(20000)
        setPolling(true)
        return
      case AppStatus.RUNNING:
        setPollingInterval(10000)
        setPolling(true)
        getOperationByID.parameters[0] &&
          setOpreationType(opreationPerameters[getOperationByID.parameters[0]])
        return

      case AppStatus.ENQUEUED:
        setPollingInterval(15000)
        setPolling(true)
        getOperationByID.parameters[0] &&
          setOpreationType(opreationPerameters[getOperationByID.parameters[0]])
        return

      default:
        //
        setPolling(false)
        setPollingInterval(0)
        return
    }
  }, [
    isFetchingGetOperationByID,
    isError,
    getOperationFromProp,
    getOperationByID,
  ])

  return {
    operationData: getOperationByID,
    checkPolling,
    exception,
    loading: polling,
    opreationType,
  }
}

export default useOperationsPolling
