/** Libraries */
import React, {useEffect, useState} from 'react'
import {useTracking} from 'react-tracking'
import {useAppDispatch} from 'app/hooks'

/** Store/Services */
import useOperationsPolling, {
  OperationType,
} from 'Common/Components/Hooks/useOperationsPolling'

/** Reusable Components */
import {toggleDeleteModal} from 'Common/Components/Core/Modal/ModalSlice'
import {Col, Row, Text} from 'Common/Components/Core/DesignTemplates'

import ListingItem from './ListingItem'
import ListingItemGrid from './ListingItemGrid'
import AppDetailCard from './AppDetailCard'
import Animation from 'Common/Components/Core/DesignTemplates/Animations/Animation'

/** Helpers */
import {ModalName} from 'Common/Components/Core/Modal/util'
import {appListInterface, HeaderChecker, iShowCard} from '../util'
import {AppStatus} from '../useOperationsPolling'
import {iOperationData} from 'Constants/Interfaces/iGlobal'
const ApplicationListing = ({
  app,
  onChange,
  headerChecked,
  selectedApp,
  isListView,
  showAppCard,
  setShowAppCard,
}: {
  app: appListInterface
  onChange: (app: appListInterface, isChecked: boolean) => void
  headerChecked: boolean
  selectedApp: number
  isListView: boolean
  showAppCard: iShowCard
  setShowAppCard: React.Dispatch<iShowCard>
}) => {
  const dispatch = useAppDispatch()
  const {trackEvent} = useTracking()
  const [checkbox, setCheckbox] = useState<boolean>(false)
  /* Polling Operation by Operation ID*/

  /* ends */
  const findAddApp = app?.operations.find(
    (item: iOperationData) =>
      [AppStatus.RUNNING, AppStatus.ENQUEUED, AppStatus.WAITING].includes(
        item.operation_status,
      ) && item.type === OperationType.ADD_APP,
  )?.operation_status
  const isPolling = findAddApp ? true : false
  const exceptionType = [AppStatus.TIMEOUT, AppStatus.ERROR].includes(
    findAddApp,
  )
    ? findAddApp
    : ''
  useEffect(() => {
    HeaderChecker(
      headerChecked,
      selectedApp,
      setCheckbox,
      checkbox,
      isPolling,
      exceptionType,
    )
  }, [
    headerChecked,
    selectedApp,
    checkbox,
    exceptionType,
    isPolling,
    setCheckbox,
  ])

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(app, e.target.checked)
    setCheckbox(e.target.checked)
  }
  const deleteAppHandler = () => {
    dispatch(
      toggleDeleteModal({
        type: ModalName.deleteAppModal,
        data: {ids: [app.id], name: app.name, created: app.created},
      }),
    )
  }

  const cloneAppHandler = () => {
    trackEvent({
      event_name: 'fmui_clone_app',
      attributes: {
        app_id: app.id,
      },
    })
  }

  const stageAppHandler = () => {
    trackEvent({
      event_name: 'fmui_push_to_staging',
      attributes: {
        app_id: app.id,
      },
    })
  }

  return (
    <>
      {isListView ? (
        <ListingItem
          app={app}
          onChange={changeHandler}
          checkbox={checkbox}
          isPolling={isPolling}
          deleteAppHandler={deleteAppHandler}
          cloneAppHandler={cloneAppHandler}
          stageAppHandler={stageAppHandler}
          exceptionType={exceptionType}
          setShowAppCard={() => setShowAppCard({[app.id]: true})}
        />
      ) : (
        <ListingItemGrid
          app={app}
          onChange={changeHandler}
          checkbox={checkbox}
          isPolling={isPolling}
          deleteAppHandler={deleteAppHandler}
          cloneAppHandler={cloneAppHandler}
          stageAppHandler={stageAppHandler}
          exceptionType={exceptionType}
          setShowAppCard={() => setShowAppCard({[app.id]: true})}
        />
      )}
      <Animation
        in={showAppCard[app.id]}
        unmountOnExit
        timeout={500}
        slideInBottom
      >
        <AppDetailCard
          app={app}
          handler={() => setShowAppCard({[app.id]: false})}
          operationData={app?.operation}
          isPolling={isPolling}
        />
      </Animation>
    </>
  )
}

export default ApplicationListing
