/** Reusable Components */
import {Card, Row, Col, Text} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import Curvedarrow from '../../../../../../Assets/img/manage-app/manage-access/Curved.png'
import LoginLogo from '../../../../../../Assets/img/manage-app/manage-access/Login.png'

export const NoData = () => {
  return (
    <>
      <Card styles="my-[50px]">
        <Row middle evenly>
          <Col styles="cwpf_ssh_imgResp relative left-[14%] top-16">
            <img src={LoginLogo} alt="" />
          </Col>
          <Col styles="cwpf_sshResp left-[6%]">
            <img src={Curvedarrow} alt="" />
          </Col>
        </Row>
        <Row center styles="mt-20 mb-60">
          <Text med styles="p1 text-center text-gray-80">
            You dont have any users right now. You may click on Add User above
            to add one.
          </Text>
        </Row>
      </Card>
    </>
  )
}
