/** Reusable Components */
import {Col, Icon, Row, Text} from '..'

/** Assets (CSS/Images) */
import {ReactComponent as SuccessSvg} from '../../../../../Assets/img/manage-app/overview/check_outline.svg'
import {ReactComponent as ErrorSvg} from '../../../../../Assets/img/manage-app/overview/error_outline.svg'
import {ReactComponent as InfoSvg} from '../../../../../Assets/img/manage-app/overview/info_outline.svg'
import {ReactComponent as WarningSvg} from '../../../../../Assets/img/manage-app/overview/warning_amber.svg'

const EnableBar = ({
  children,
  warning,
  info,
  success,
  Iconstyles = '',
  error,
  showIcon = false,
  showSvg = true,
  styles = '',
}: any) => {
  const iconName = () => {
    if (success) return 'check_circle_outline'
    if (error) return 'error_outline'
    if (info) return 'error_outline'
    if (warning) return 'warning_amber'
  }
  const svgComponent = () => {
    if (success) return <SuccessSvg />
    if (error) return <ErrorSvg />
    if (info) return <InfoSvg />
    if (warning) return <WarningSvg />
  }
  const bgColor = () => {
    if (success) return 'bg-[#D2F6F4]'
    if (error) return 'bg-red-25'
    if (info) return 'bg-blue-25'
    if (warning) return 'bg-orange-25'
  }
  const iconColor = () => {
    if (success) return '#00875A'
    if (error) return '#EA4545'
    if (info) return '#2F39BF'
    if (warning) return '#F17729'
  }
  const textColor = () => {
    if (success) return 'text-[#00875A]'
    if (error) return 'text-[#EA4545]'
    if (info) return 'text-[#2F39BF]'
    if (warning) return 'text-[#F17729]'
  }
  return (
    <div
      className={`flex items-center rounded-[26px] mx-10 px-4 py-6 pr-8 h-[22px] ${bgColor()} ${textColor()} ${styles}`}
    >
      {showIcon && (
        <Icon
          name={iconName()}
          color={iconColor()}
          styles="text-20 leading-[12px]"
        />
      )}
      {showSvg && svgComponent()}
      <Text
        semi
        styles={`ml-4 text-12 semi-bold ${textColor()} leading-[12px] relative top-[0.5px]`}
      >
        {children}
      </Text>
    </div>
  )
}
export default EnableBar
