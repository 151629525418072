const Regex = {
  generalName_regex: /^([a-z\d]+(-[a-z\d]+)*){6,40}$/,
  generalPassword_regex: {
    smallChar: /(?=.*[a-z])/,
    capsChar: /(?=.*[A-Z])/,
    number: /(?=.*\d)/,
    //passwordLimit: /^[A-Za-z\d@$!%*?&]{10,40}$/,
    // specialChar: /(?=.*[@$!%*?&])/,
  },
  appName_regex: /^([A-Za-z\d ()_#-.*]){3,40}$/,
  deleteApp_regex: /^([dD]elete|DELETE)$/,
  // domainName_regx:
  //   /^(?!\\-)(?:[a-zA-Z\d\-\\_]{0,62}[a-zA-Z\d\-\\_]\.){1,15}(?!\d+)[a-zA-Z\d\-\\_]{1,63}$/,
  domainName_regx:
    /^(https?:\/\/www\.|https?:\/\/|www\.)?(?!\\-)(?:[a-zA-Z\d\-\\_]{0,62}[a-zA-Z\d\-\\_]\.){1,15}(?!\d+)[a-zA-Z\d\-\\_]{1,63}$/,
  excludeSpecialChar: /^[A-Za-z0-9 ]+$/,
}

export default Regex
