import React from 'react'
import Modal from '../../../Hooks/Modal/Modal'
import AddApplication from '../../../Pages/AddApplication/AddApplication'

const AddApplicationModal = ({show, onClose}: any) => {
  return (
    <Modal show={show} onClose={onClose} classes="cwpf_addApp_modal">
      <AddApplication modal={true} />
    </Modal>
  )
}

export default AddApplicationModal
