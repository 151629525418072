import {useTracking} from 'react-tracking'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {Link, useNavigate} from 'react-router-dom'
import {
  Button,
  Container,
  Icon,
  Row,
  Col,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {
  useAddPlanMutation,
  useGetAllApplicationsQuery,
} from '../../../../../../app/services'
import {toggleModal} from '../../ModalSlice'
import {ModalName} from '../../util'
import './planUpgradedModal.css'

interface plaUpgradeInterface {
  onClose?: any
  propstate?: any
}

const PlanUpgradedModal = ({onClose, propstate}: plaUpgradeInterface) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {data, isLoading: isAppListLoading} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )

  /* State Data */
  const checkoutPlan = useAppSelector(state => state.plans.checkoutPlan)
  /* ---x---  */
  const [addPlan, {isLoading: isAddingPlan}] = useAddPlanMutation()
  const navigateAddApp = () => {
    navigate('/add-application')
    onClose()
  }

  return (
    <>
      <Container styles="cwpf_cnfrmPlan_sec">
        <Container styles="cwpf_cnfrmPlan_mainBox">
          <Text type="h3" semi styles=" text-gray-80">
            Plan Upgraded Successfully
          </Text>

          <Container styles="cwpf_cnfrmPlan_dtlBox">
            <ul className="cwpf_cnfrmPlan_specBox mt-[10px]">
              <li>
                Applications
                <b>{checkoutPlan?.max_app_count}</b>
              </li>
              <li>
                Disk Space
                <b>
                  {checkoutPlan?.storage_gb} {''}GB
                </b>
              </li>
              <li>
                Bandwidth
                <b>
                  {checkoutPlan?.bandwith_gb} {''}GB
                </b>
              </li>
              <li>
                Visits per Month
                <b>{checkoutPlan?.max_montly_visits}</b>
              </li>
            </ul>

            <ul className="cwpf_cnfrmPlan_noteBox">
              <li>$2 / Additional MB</li>
              <li>$4 / 1GB of Disk Space</li>
              <li>$1 / Additional 1000 Visits</li>
            </ul>

            <Container styles="cwpf_cnfrmPlan_ctaBox mt-[28px]">
              <Button
                type="button"
                block
                onClick={navigateAddApp}
                icon={propstate && data?.count > 0 ? '' : 'add'}
                iconAlign="left"
                styles="text-18"
                iconStyles="text-20"
                loading={isAddingPlan}
              >
                {propstate && data?.count > 0
                  ? 'View Applications'
                  : ' Add Application'}
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  )
}
export default PlanUpgradedModal
