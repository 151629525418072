import {useNavigate} from 'react-router-dom'
import {useTracking} from 'react-tracking'

import {
  useDeletePlanMutation,
  apiSlice,
  useGetAllApplicationsQuery,
  useGetCurrentPlanQuery,
} from '../../../../../../app/services'
import {useAppDispatch} from '../../../../../../app/hooks'

import {Button, Container, Text} from 'Common/Components/Core/DesignTemplates'

import errorimg from '../../../../../../Assets/img/modal/error-icon.png'

export enum AppStatus {
  COMPLETED = 'completed',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  RUNNING = 'running',
  ENQUEUED = 'enqueued',
}
interface iUnsubPlanTrackingObj {
  event_name: string
  attributes: {
    page?: string
    current_url?: string
    is_success?: boolean
  }
}

export default function UnsubscribePlan({onClose}: any) {
  /* HTTP calls*/
  const [deletePlan, {isLoading: isDeleting}] = useDeletePlanMutation()
  const {data: currentPlan} = useGetCurrentPlanQuery()
  const {data, isLoading: isAppListLoading} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )

  const dispatch = useAppDispatch()

  /* Event Tracking */
  const {Track, trackEvent} = useTracking<iUnsubPlanTrackingObj>({
    attributes: {
      page: 'plan_details',
      current_url: window.location.href,
    },
  })

  const navigate = useNavigate()

  /* Handle plan cancellation */
  const planUnsubHandler = () => {
    const eventUnsubscribePlan = {
      event_name: 'fmui_unsubscribe_plan',
      attributes: {
        is_success: false,
        plan_id: currentPlan.plan.id,
      },
    }

    deletePlan()
      .unwrap()
      .then(() => {
        dispatch(apiSlice.util.resetApiState())
        trackEvent({
          ...eventUnsubscribePlan,
          attributes: {...eventUnsubscribePlan.attributes, is_success: true},
        })
        navigate('/')
      })
      .catch(error => {
        trackEvent(eventUnsubscribePlan)
        return new Error(error)
      })
      .finally(() => onClose())
  }

  /* Get App list with successful installs */
  const getCompletedApp = () => {
    let newData
    if (!isAppListLoading) {
      newData = data?.results.filter(
        (item: any) =>
          item?.operation?.operation_status === AppStatus.COMPLETED,
      )
    }
    return newData
  }

  return (
    <Track>
      <Container styles="cwpf_cnfrmPlan_mainsec">
        <Container styles="cwpf_cnfrmPlan_mainBox">
          <Container styles="cwpf_cnfrmPlan_img">
            <img src={errorimg} alt="" />
          </Container>
          <Text semi type="h3" styles="text-gray-80 mt-10 mb-36">
            Are you sure you want to <br /> unsubscribe?
          </Text>
          <Text styles="p1">
            <Text type="b">
              {' '}
              {getCompletedApp()?.length}
              {getCompletedApp()?.length > 1
                ? ' applicaitons '
                : ' applicaiton '}
              installed{' '}
            </Text>
            on your current plan will be deleted if you proceed.
          </Text>
          <Container styles="cwpf_cnfrmPlan_ctaButton mb-20">
            <Button
              medium
              type="button"
              dangerOutline
              loading={isDeleting}
              styles=" mt-[42px] mb-[20px]"
              block
              onClick={planUnsubHandler}
              id="11"
            >
              {isDeleting ? 'Removing Plan...' : 'Unsubscribe'}
            </Button>
            <Button medium type="button" neutralOutline block onClick={onClose}>
              Cancel
            </Button>
          </Container>
        </Container>
      </Container>
    </Track>
  )
}
