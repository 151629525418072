/** Libraries */
import {useParams} from 'react-router-dom'
import {useTracking} from 'react-tracking'

/** Reusable Components */
import {Container, Tabs} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {TABNAMES} from '../util'
import Backup from './Backup'
import Restore from './Restore'
import Setting from './Setting'
import {useGetRestorePointsQuery} from 'app/services'

const BackupTabs = () => {
  const {app_id} = useParams()
  const {trackEvent} = useTracking()
  const {data: pointsData, isLoading} = useGetRestorePointsQuery({app_id})

  const onTabChange = (tab_name: TABNAMES) => {
    trackEvent({
      event_name: 'fmui_wordflow_tab',
      attributes: {
        tab_name,
        app_id,
      },
    })
  }
  return (
    <Container className="mt-40 relative">
      <Tabs>
        <div id="Backup">
          <Backup
            restoreData={pointsData || []}
            isRestorePointLoading={isLoading}
          />
        </div>
        <div id="Restore">
          <Restore
            restoreData={pointsData || []}
            isRestorePointLoading={isLoading}
          />
        </div>
        {/* <div id="Setting">
          <Setting />
        </div> */}
      </Tabs>
    </Container>
  )
}

export default BackupTabs
