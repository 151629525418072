import {
  Button,
  Col,
  Container,
  Row,
  Separator,
  Text,
  TextField,
} from 'Common/Components/Core/DesignTemplates'
import React, {useState} from 'react'
import {ReactComponent as SearchIcon} from '../../../../../../../Assets/img/manage-app/Intelligence/searchIcon.svg'
import {ReactComponent as FilterIcon} from '../../../../../../../Assets/img/manage-app/Intelligence/filterIcon.svg'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import {Form, Formik} from 'formik'
import '../styles/LogsViwer.css'
import * as Yup from 'yup'
import {useTracking} from 'react-tracking'
import {useParams} from 'react-router-dom'
const LogsHeader = ({
  refresh,
  searchValue,
}: {
  refresh?: any
  searchValue?: string
}) => {
  const {trackEvent} = useTracking()
  const [t, setT] = useState<any>(null)
  const {app_id} = useParams()

  // const setLogsRefreshTrigger = useDebounce(() => {
  //   refresh && refresh()
  //   trackEvent({
  //     event_name: 'fmui_refresh_logs',
  //   })
  // }, 200)

  const onSearchChange = (text: string) => {
    if (t) clearTimeout(t)
    setT(
      setTimeout(() => {
        refresh && refresh(text)
        trackEvent({
          event_name: 'fmui_search_logs',
        })
      }, 2000),
    )
  }

  return (
    <Container styles="container">
      <Row middle between styles="my-40">
        <Col>
          {/* <Text semi styles="text-16 text-dark">
            {count} Logs Found
          </Text> */}
        </Col>
        <Col>
          <Row middle center>
            {/* <FilterIcon className="inline-block align-middle" /> */}
            {/* <Separator w={1} h={32} styles="mx-24" /> */}
            <Container styles={`relative h-32 flex items-center`}>
              {/* <SearchIcon
                className={`inline-block align-middle absolute top-2 right-10 z-[99] cursor-pointer`}
                onClick={() => setAnimation(true)}
              /> */}
              <Formik
                initialValues={{search: ''}}
                onSubmit={() => {
                  console.log('')
                }}
              >
                {({dirty, isValid, values, isSubmitting, handleChange}) => (
                  <Form className="w-full">
                    <TextField
                      onChange={(e: any) => {
                        handleChange(e)
                        onSearchChange(e.target.value)
                      }}
                      name="search"
                      styles={`cwpf_logs_searchBox h-32 mt-0 w-20 p-4 border-transparent pl-40 !pr-10`}
                      type="text"
                    />
                  </Form>
                )}
              </Formik>
            </Container>

            <Button
              primaryOutline
              small
              styles="ml-16"
              icon="refresh"
              iconAlign="left"
              onClick={useDebounce(() => {
                refresh && refresh()
                trackEvent({
                  event_name: 'fmui_refresh_logs',
                  attributes: {
                    is_success: true,
                  },
                })
              }, 200)}
            >
              Refresh
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
export default LogsHeader
