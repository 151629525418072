/** Reusable Components */
import {Container} from 'Common/Components/Core/DesignTemplates'
import {useState} from 'react'
import {useTracking} from 'react-tracking'
import {PerformanceData} from '../util'
import AppDetailsItem from './AppDetailsItem'
import NoData from './NoData'

const AppDetails = ({
  testAgainButton,
}: {
  testAgainButton: (e: boolean | ((prevState: boolean) => boolean)) => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const {trackEvent} = useTracking()

  // const dispatch = useDispatch()
  const clickHandler = () => {
    testAgainButton(true)
    setLoading(true)
    trackEvent({
      event_name: 'fmui_run_app_test',
    })
    // setShowTestAgainBtn(true)
    // const url = 'https://www.google.com/'
    // dispatch(performanceFetch(url))
    setTimeout(() => {
      setLoading(false)
      setShow(true)
    }, 5000)
  }
  return (
    <Container styles="cfw-appdetails-progress py-[25px]">
      {show ? (
        PerformanceData.map((item, i) => (
          <AppDetailsItem
            name={item.name}
            percent={item.percent}
            icon={item.icon}
            key={i}
          />
        ))
      ) : (
        <NoData loading={loading} clickHandler={clickHandler} />
      )}
    </Container>
  )
}
export default AppDetails
