import React from 'react'
import './preloader.css'
import {Container, Text} from '../DesignTemplates'

const index = () => {
  return (
    <>
      <Container styles="cwpf_preLoader_wrap">
        <Text type="h1" semi styles="mb-20">
          The Cloud Hosting Platform That Instantly Gives You:
          <br />
          More Speed, More Savings, and Less Work.
        </Text>
        <Text type="p" styles="text-22 text-gray-80">
          Your Partners at CloudwaysFM
        </Text>

        <Container styles="cwpf_preLoader_box">
          <Container></Container>
          <Container></Container>
          <Container></Container>
          <Container></Container>
        </Container>
      </Container>
    </>
  )
}

export default index
