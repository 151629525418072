/** Libraries */
import {FC} from 'react'

/** Import Main Components */
import HomeContainer from './HomeContainer'

const Home: FC = (): JSX.Element => {
  return <HomeContainer />
}

export default Home
