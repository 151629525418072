/** Libraries */
import React, {useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {
  useGetMonitoringStatsQuery,
  useGetResourceBandwidthQuery,
} from 'app/services'

/** Reusable Components */
import {
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import CardChart from './Charts/XYCharthead'

/** Helpers */
import {
  abbreviateNumber,
  EpochToDate,
  niceBytes,
} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {setDashboardWarningVisible} from './Slice/Dashboardslice'
import {useAppDispatch} from 'app/hooks'

const TotalBandwith = ({
  everythingSummary,
  isEverythingSummaryLoading,
  currentPlan,
}: any) => {
  // const {data, isLoading, isSuccess} = useGetResourceBandwidthQuery(undefined, {
  //   refetchOnMountOrArgChange: true,
  // })
  // const {data: bandwidthSummary, isLoading: bandwidthSummaryLoading} =
  //   useGetMonitoringStatsQuery({
  //     action: 'summary',
  //     source: 'bandwidth',
  //   })
  const dispatch = useAppDispatch()
  const {data: bandwidthDatapoints, isLoading: isBandwidthDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'bandwidth',
    })
  useEffect(() => {
    if (
      parseInt(
        niceBytes(everythingSummary?.bandwidth_total_usage, 0, true, 2),
      ) > currentPlan?.plan?.bandwith_gb
    ) {
      dispatch(setDashboardWarningVisible(true))
      return
    }
    setDashboardWarningVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingSummaryLoading])

  return (
    <Container styles="px-8 min-h-[102px]">
      <Row>
        <Col>
          <Row>
            <Text type="p" semi styles="text-gray-80 mb-10">
              Total Bandwidth
            </Text>
            {/* {parseInt(
              niceBytes(everythingSummary?.bandwidth_total_usage, 0, true, 2),
            ) > currentPlan?.plan?.bandwith_gb && (
              <Icon name="warning_amber" styles="text-orange-100 ml-8" />
            )} */}
          </Row>
        </Col>
        <Col>
          <Container styles="h-80 w-[130px] flex absolute right-4 top-0">
            {!isBandwidthDatapointsLoading && (
              <CardChart
                datapoints={
                  bandwidthDatapoints?.data?.map((eachPoint: any) => {
                    return {
                      date: eachPoint?.Date,
                      value: eachPoint?.BandwidthSum,
                    }
                  }) || []
                }
              />
            )}
          </Container>
        </Col>
      </Row>
      <Row>
        {isEverythingSummaryLoading || isBandwidthDatapointsLoading ? (
          <Skeleton width={100} height={10} borderRadius={10} />
        ) : (
          <Col styles="flex">
            <Text type="div" styles="h1-semi text-gray-80">
              {niceBytes(everythingSummary?.bandwidth_total_usage, 0, true, 2)}
            </Text>
            <Text
              type="h5"
              semi
              styles="text-gray-80 relative top-[5px] self-center"
            >
              /
              {niceBytes(
                everythingSummary?.allocated_bandwidth * 1000 * 1000,
                0,
                false,
                2,
              )}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Text type="p" styles="p3 text-gray-80">
            Total bandwidth consumed since start of this month
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(TotalBandwith)
