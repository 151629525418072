/** Libraries */
import {Link, LinkProps, useMatch, useResolvedPath} from 'react-router-dom'

export default function CustomLink({
  children,
  to,
  className,
  ...props
}: LinkProps) {
  const resolved = useResolvedPath(to)
  const match = useMatch({path: resolved.pathname, end: true})
  return (
    <Link
      className={`
          ${match ? 'tab cwpf_manage_app_active ' : 'tab'} ${className}`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  )
}
