import React from 'react'
import {iRestorePoints} from '../util'
import BackupContainer from './BackupContainer'

const Backup = ({
  restoreData,
  isRestorePointLoading,
}: {
  restoreData: iRestorePoints[]
  isRestorePointLoading: boolean
}) => {
  return (
    <BackupContainer
      restoreData={restoreData}
      isRestorePointLoading={isRestorePointLoading}
    />
  )
}

export default Backup
