import React, {useEffect, useState} from 'react'
import {useTracking} from 'react-tracking'
import {useAppSelector, useAppDispatch} from 'app/hooks'
import {
  useModifyPlanMutation,
  useGetAllPlansQuery,
  useGetCurrentPlanQuery,
  useGetAllApplicationsQuery,
} from '../../../../../../app/services'
import {
  setCheckoutPlan,
  setPlanLimitError as setLimitError,
  setCheckoutPlanIndex,
} from 'features/PlanSlider/PlansSliderSlice'
import RangeSlider from '../../../../Hooks/RangeSlider/RangeSlider'
import '../../ConfirmPlanAddedModal/ConfirmPlanModal/ConfirmPlanModal.css'
import {toggleModal} from '../../ModalSlice'
import {ModalName} from '../../util'
//React Skeleton
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Button,
  Col,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {useGetCurrentUserQuery} from 'app/services/platform3'
import {customToast} from 'Common/Components/Core/CustomizedToast'

interface iModifyPlanTrackingObj {
  event_name: string
  attributes: {
    page?: string
    current_url?: string
    plan_id?: number
    plan_disk_space?: number
    plan_bandwidth?: number
    plan_visits?: number
    plan_app_limit?: number
    plan_price?: string
    is_success?: boolean
  }
}

export enum AppStatus {
  COMPLETED = 'completed',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  RUNNING = 'running',
  ENQUEUED = 'enqueued',
}

const ModifyPlan = (props: any) => {
  const dispatch = useAppDispatch()
  /** State Data */
  const {data: {results} = [], isLoading} = useGetAllPlansQuery()
  const {
    data: {plan: currentPlan = {}, app = {}} = {},
    isLoading: currentPlanLoading,
  } = useGetCurrentPlanQuery()
  const {data, isLoading: isAppListLoading} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )

  const {Track, trackEvent} = useTracking<iModifyPlanTrackingObj>({
    attributes: {
      page: 'plan_details',
      current_url: window.location.href,
    },
  })

  const checkoutPlan = useAppSelector((state: any) => state.plans.checkoutPlan)
  const checkoutPlanIndex = useAppSelector(
    (state: any) => state.plans.checkoutPlanIndex,
  )
  const limitError = useAppSelector((state: any) => state.plans.planLimitError)

  //
  const getCurrentPlanState = currentPlan?.id === checkoutPlan?.id

  /** Switch Plan */
  const [switchPlan, {isLoading: isSwitching}] = useModifyPlanMutation()
  const currentUser = useGetCurrentUserQuery('')

  const handleCurrentPlan = async (planIdx: number) => {
    const getCheckoutPlan = results.find(
      (plan: Record<string, unknown>) => plan.id === planIdx,
    )
    dispatch(setCheckoutPlan(getCheckoutPlan))
    dispatch(setCheckoutPlanIndex(planIdx))
    trackEvent({
      event_name: 'fmui_modify_plan_slider',
      attributes: {
        plan_id: getCheckoutPlan.id,
        plan_disk_space: getCheckoutPlan.storage_gb,
        plan_bandwidth: getCheckoutPlan.bandwith_gb,
        plan_visits: getCheckoutPlan.max_montly_visits,
        plan_app_limit: getCheckoutPlan.max_app_count,
        plan_price: getCheckoutPlan.price,
      },
    })
  }

  useEffect(() => {
    !(
      checkoutPlanIndex < currentPlan?.id &&
      app.count > checkoutPlan.max_app_count
    )
      ? dispatch(setLimitError(false))
      : dispatch(setLimitError(true))
  }, [app.count, checkoutPlan, checkoutPlanIndex, currentPlan?.id, dispatch])

  const planUpgraded = () => {
    const eventTrackingObject = {
      event_name: 'fmui_modify_confirm_plan',
      attributes: {
        plan_id: checkoutPlan.id,
        plan_disk_space: checkoutPlan.storage_gb,
        plan_bandwidth: checkoutPlan.bandwith_gb,
        plan_visits: checkoutPlan.max_montly_visits,
        plan_app_limit: checkoutPlan.max_app_count,
        plan_price: checkoutPlan.price,
        is_success: false,
      },
    }

    if (checkoutPlan.id !== currentPlan.id) {
      switchPlan({
        plan_id: checkoutPlan.id,
        username: currentUser?.currentData?.name,
        user_email: currentUser?.currentData?.email,
      })
        .unwrap()
        .then(response => {
          // if (!response.ok) throw new Error(response.status)
          trackEvent({
            ...eventTrackingObject,
            attributes: {...eventTrackingObject.attributes, is_success: true},
          })
          props.onPlanUpgrade()
        })
        .catch(error => {
          trackEvent(eventTrackingObject)
          return new Error(error)
        })
    }
  }

  //UnSubscribe Modal Plan
  const modifyModalHandler = () => {
    if (
      data?.results.some(
        (item: any) =>
          item?.operation?.operation_status === AppStatus.RUNNING ||
          item?.operation?.operation_status === AppStatus.ENQUEUED,
      )
    ) {
      customToast({
        level: 'info',
        title: '',
        description: `One of your application is being installed`,
      })
    } else {
      props.onUnsubscribe()
    }
  }

  // useEffecting, this to set-up checkout plan to selected plan after first mount
  useEffect(() => {
    if (currentPlanLoading) return
    dispatch(setCheckoutPlan(currentPlan))
    dispatch(setCheckoutPlanIndex(currentPlan.id))
  }, [currentPlan, currentPlanLoading, dispatch])

  return (
    <Track>
      <Container styles="cwpf_modifyPlan_sec flex">
        <Container styles="cwpf_cnfrmPlan_sec basis-[53%]">
          <Container styles="cwpf_cnfrmPlan_mainBox">
            <Text type="h3" bold styles="text-gray-80 mb-[18px]">
              Change Your Plan
            </Text>
            <Text type="p1" styles="text-dark">
              Please confirm that you want to select the following <br />
              plan.
            </Text>
            <Row end>
              <Text
                type="h4"
                semi
                styles="mt-[8px] mb-[24px] text-gray-80 flex items-center justify-between"
              >
                <Text type="b" styles="h1-bold text-blue-100">
                  ${Math.floor(Number(checkoutPlan?.price))}
                  <Text
                    type="sub"
                    styles="ml-6 text-16 text-dark relative top-[-4px]"
                  >
                    /mo
                  </Text>
                </Text>
              </Text>
            </Row>

            <Container
              styles={`${
                limitError && 'cwpf_slctPlan_sldrbox_error'
              } cwpf_slctPlan_sldrBox mb-[50px]`}
            >
              <Container styles="cwpf_slctPlan_error_tooltip">
                <>
                  {isLoading ? (
                    <Skeleton height={10} width={500} />
                  ) : (
                    <RangeSlider
                      value={checkoutPlanIndex}
                      max={results?.length}
                      handleCurrentPlan={handleCurrentPlan}
                      classes="cwpf_slctPlan_sldrInput w-[100%]"
                      data={results}
                    />
                  )}
                </>
              </Container>
            </Container>

            <Container styles="cwpf_cnfrmPlan_dtlBox">
              <Text type="h4" semi styles="mb-20">
                Plan Details:
              </Text>
              <ul className="cwpf_cnfrmPlan_specBox">
                <li>
                  Applications
                  <Text type="b">{checkoutPlan?.max_app_count}</Text>
                </li>
                <li>
                  Disk Space
                  <Text type="b">
                    {checkoutPlan?.storage_gb} {''}GB
                  </Text>
                </li>
                <li>
                  Bandwidth
                  <Text type="b">
                    {checkoutPlan?.bandwith_gb} {''}GB
                  </Text>
                </li>
                <li>
                  Visits per Month
                  <Text type="b">{checkoutPlan?.max_montly_visits}</Text>
                </li>
              </ul>

              <ul className="cwpf_cnfrmPlan_noteBox">
                <li>$2 / Additional MB</li>
                <li>$4 / 1GB of Disk Space</li>
                <li>$1 / Additional 1000 Visits</li>
              </ul>

              <Container styles="cwpf_cnfrmPlan_ctaBox mt-30">
                <Button
                  type="button"
                  icon="arrow_forward"
                  iconAlign="right"
                  disabled={limitError || getCurrentPlanState}
                  variant="primary"
                  block
                  onClick={planUpgraded}
                  loading={isSwitching}
                  id="9"
                >
                  {isSwitching ? 'Confirming... ' : 'Confirm Plan'}
                </Button>
              </Container>
              {limitError && (
                <Text type="aside" styles="text-red-75 mt-16">
                  This plan supports only {checkoutPlan?.max_app_count} apps.
                  Please delete some apps or choose another plan to proceed.
                </Text>
              )}
            </Container>
          </Container>
        </Container>

        <Container styles="bg-gray-3 px-60 pt-[120px] pb-60 basis-[47%]">
          <Row between center>
            <Col>
              <Text type="h4" semi>
                Current Plan
              </Text>
            </Col>
            <Col>
              <Text type="b" semi styles="text-dark text-[30px] ">
                ${Math.floor(Number(currentPlan.price))}
                <Text
                  type="sub"
                  relative
                  styles="ml-6 text-16 text-dark top-[-4px]"
                >
                  /mo
                </Text>
              </Text>
            </Col>
          </Row>

          <Container styles="cwpf_cnfrmPlan_dtlBox mt-30">
            <Text type="h6" med styles="mb-20 text-18">
              Plan Details:
            </Text>

            <ul className="cwpf_cnfrmPlan_specBox">
              <li>
                Applications
                <Text type="b">{currentPlan?.max_app_count}</Text>
              </li>
              <li>
                Disk Space
                <Text type="b">
                  {currentPlan?.storage_gb} {''}GB
                </Text>
              </li>
              <li>
                Bandwidth
                <Text type="b">
                  {currentPlan?.bandwith_gb} {''}GB
                </Text>
              </li>
              <li>
                Visits/Month
                <Text type="b">{currentPlan?.max_montly_visits}</Text>
              </li>
            </ul>

            <ul className="cwpf_cnfrmPlan_noteBox">
              <li>$2/Additional Bandwidth MB</li>
              <li>$4/Additional 1 GB of Disk Space</li>
              <li>$1/Additional 1000 Visits</li>
            </ul>

            <Container styles="cwpf_cnfrmPlan_ctaBox">
              <Button
                type="button"
                neutralOutline
                block
                medium
                onClick={modifyModalHandler}
                id="10"
              >
                Unsubscribe
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </Track>
  )
}

export default ModifyPlan
