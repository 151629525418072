const WebSnap = ({url = '', styles = '', ...rest}: any) => {
  return (
    <img
      //src={`https://s.wordpress.com/mshots/v1/http://${url}`}
      //src={`https://res.cloudinary.com/demo/image/url2png/http://${url}`}
      //ab55f5897fd46c4caaf9571dad63e5fe1c9596d7ea3d
      src={`https://api.thumbnail.ws/api/ab55f5897fd46c4caaf9571dad63e5fe1c9596d7ea3d/thumbnail/get?url=http://${url}&width=300&refresh=true`}
      className={styles}
      {...rest}
    />
  )
}

export default WebSnap
