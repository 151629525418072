/** Assets (CSS/Images) */
import './Grid.css'

const Col = ({
  children,
  lg = '',
  md = lg,
  sm = md,
  xs = sm,
  styles = '',
  top,
  middle,
  bottom,
  baseline,
  start,
  center,
  between,
  around,
  evenly,
  end,
  stretch,
  ...rest
}: any) => {
  // const colCalc = col <= 12 ? ((100 * col) / 12).toFixed(3) : 12 / 100
  // const xsCalc = xs <= 12 ? ((100 * xs) / 12).toFixed(3) : colCalc
  // const smCalc = sm <= 12 ? ((100 * sm) / 12).toFixed(3) : colCalc
  // const mdCalc = md <= 12 ? ((100 * md) / 12).toFixed(3) : colCalc
  // const lgCalc = lg <= 12 ? ((100 * lg) / 12).toFixed(3) : colCalc
  const getClasses = () => {
    const flexTop = top ? 'self-start' : ''
    const flexMiddle = middle ? 'self-center	' : ''
    const flexBottom = bottom ? 'self-end' : ''
    const flexStart = start ? 'justify-self-start' : ''
    const flexCenter = center ? 'justify-self-center' : ''
    const flexEnd = end ? 'justify-self-end' : ''
    const flexStretch = stretch ? 'justify-self-stretch' : ''
    const flexBetween = between ? 'justify-self-between' : ''
    const flexEvenly = evenly ? 'justify-self-evenly' : ''
    const flexAround = around ? 'justify-self-around' : ''
    return `${flexTop} ${flexMiddle} ${flexBottom} ${flexStart} ${flexCenter} ${flexEnd} ${flexStretch} ${flexBetween} ${flexEvenly} ${flexAround} ${styles}`
  }
  const XS = xs ? `col-xs-${xs}` : ''
  const SM = sm ? `col-sm-${sm}` : ''
  const MD = md ? `col-md-${md}` : ''
  const LG = lg ? `col-lg-${lg}` : ''
  const classes = `col ${XS} ${SM} ${MD} ${LG} ${styles} ${getClasses()}`
  return (
    <div className={`${classes.replace(/\s+/g, ' ').trim()}`} {...rest}>
      {children}
    </div>
  )
}

export default Col
