/** Libraries */
import {useState, useEffect} from 'react'

/** Store/Services */
import {useGetOperationsQuery} from 'app/services'

export enum AppStatus {
  COMPLETED = 'completed',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  RUNNING = 'running',
  ENQUEUED = 'enqueued',
  WAITING = 'waiting',
}

export enum OperationType {
  ADD_APP = 'add_app',
  UPDATE_APP = 'update_app',
}

const useOperationsPolling = (operation: any, appType: string) => {
  const [pollingInterval, setPollingInterval] = useState<number>(0)
  const [exception, setException] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)

  /* Poll Operations by ID */
  const {data: operationData, isFetching: isFetchingOperation} =
    useGetOperationsQuery(operation?.id, {
      pollingInterval,
      skip: !operation || operation.type !== appType,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    })

  //
  //

  useEffect(() => {
    if (isFetchingOperation) return
    if (!operation) {
      setLoading(false)
      return
    }
    switch (operationData?.operation_status) {
      case AppStatus.COMPLETED:
        setPollingInterval(0)
        setLoading(false)
        return
      case AppStatus.TIMEOUT:
      case AppStatus.ERROR:
        setPollingInterval(0)
        setLoading(false)
        setException(operationData?.operation_status)
        return
      case AppStatus.RUNNING:
      case AppStatus.ENQUEUED:
        setPollingInterval(10000)
        setLoading(true)

        return
      default:
        setLoading(false)
        return
    }
  }, [operationData, isFetchingOperation, operation])

  return {operationData, exception, loading}
}

export default useOperationsPolling
