/** Libraries */
import React from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import * as moment from 'moment'
import {useTracking} from 'react-tracking'
import 'moment-timezone'
import {useParams, useOutletContext} from 'react-router-dom'
/** Store/Services */
import {useUpdateTimezoneMutation, apiSlice} from 'app/services'
/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Row,
  Text,
  Separator,
  CustomSelect,
} from 'Common/Components/Core/DesignTemplates'
import {customToast} from 'Common/Components/Core/CustomizedToast'

/** Helpers */
import {timezone} from 'Constants/mock'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'

/** Assets (CSS/Images) */
import {iTimeZone, iWorkFlowToolsTrackingObj} from '../util'
import {useAppDispatch} from 'app/hooks'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const validate = Yup.object({
  timezone_id: Yup.string().required('Please select a timezone'),
})

const TimezoneContainer = () => {
  const propsOutlet: iPropsOutlet = useOutletContext()
  const {app_id} = useParams()
  const dispatch = useAppDispatch()
  // const [currentTimeZonem, setCurrentTimeZonem] = useState('')
  const {Track, trackEvent} = useTracking<iWorkFlowToolsTrackingObj>({
    attributes: {
      tab_name: 'workflow_tools',
    },
  })

  const [updateTimzone] = useUpdateTimezoneMutation()

  const onSubmit = (
    values: iTimeZone,
    {setSubmitting}: FormikHelpers<iTimeZone>,
  ) => {
    updateTimezoneFn(values.timezone_id, setSubmitting)
  }
  const updateTimezoneFn = (
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    updateTimzone({
      app_id: app_id,
      body: {
        version: propsOutlet.applicationData?.php_settings?.version,
        timezone: value,
      },
    })
      .unwrap()
      .then((fulfilled: {operation_id: string}) => {
        trackEvent({
          event_name: 'fmui_update_timezone',
          attributes: {
            timezone: value,
            is_success: true,
          },
        })
        customToast({
          level: 'info',
          title: '',
          description:
            'Timezone is being updated. You wont be able to perform certain actions while the operation is taking place',
          loading: true,
        })
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.operation_id,
          }),
        )
          .unwrap()
          .then(fulfilled => {
            setSubmitting(false)
            dispatch(
              apiSlice.util.updateQueryData(
                'getSingleApplication',
                app_id,
                draft => {
                  draft.php_settings.timezone = value
                },
              ),
            )
          })
      })
      .catch(() => {
        trackEvent({
          event_name: 'fmui_update_timezone',
          attributes: {
            timezone: value,
            is_success: false,
          },
        })
        setSubmitting(false)
      })
  }
  // const getCurrentTimeZone = moment.tz(currentTimeZonem).format('h:mm:ss')
  // const getCurrentDate = moment
  //   .tz(currentTimeZonem)
  //   .format('dddd,  MMMM Do YYYY')

  const getTimeZone = (
    formikChangeEvent: (field: string, value: string) => void,
  ) => {
    const zone = moment.tz.guess()
    const filteredData = timezone.find(({value}: {value: string}) => {
      return value.toLowerCase() === zone.toLowerCase()
    })
    if (!filteredData?.value) return
    trackEvent({
      event_name: 'fmui_auto_detect',
      attributes: {
        timezone: filteredData.value,
      },
    })
    formikChangeEvent('timezone_id', filteredData?.value)
  }
  const initialValues = {
    timezone_id: propsOutlet.applicationData?.php_settings?.timezone,
  }
  return (
    <Track>
      <Container>
        <Container>
          <Text type="h5" semi styles="text-gray-80 mb-20">
            Application Setting
          </Text>
          <Separator styles="mt-10"></Separator>
        </Container>

        <Container styles="hidden">
          <Row between>
            <Col>
              <Text type="p" bold styles="p2 text-[#000000] pt-24">
                Current Time Zone
              </Text>
            </Col>
            <Col>
              <Row between styles="space-x-8 pt-20">
                <Col middle>
                  <Text type="p" bold styles="p2 text-gray-60 align-bottom">
                    +5:00 GMT
                  </Text>
                </Col>
                <Col middle>
                  <Text type="h4" styles="text-gray-80">
                    {/* {getCurrentTimeZone} */}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row between styles="pb-20">
            <Col>
              <Text type="p" styles="p2 text-gray-80">
                The current time & date according to your settings is
              </Text>
            </Col>
            <Col>
              <Text type="p" styles="p1 text-gray-80">
                {/* {getCurrentDate} */}
              </Text>
            </Col>
          </Row>
          <Separator></Separator>
        </Container>

        <Container styles="py-24">
          <Row between middle styles="cwpf_workflowResp">
            <Col>
              <Text type="p" bold styles="p2 text-dark mb-6">
                Set PHP Time Zone
              </Text>
              <Text type="p" styles="p2 text-gray-80 text-left">
                You can set the date and time for your PHP app. Clicking on the
                Auto Detect button will
                <br />
                automatically select the current time zone on your computer.
              </Text>
            </Col>
            <Col styles="cwpf_workflowColResp">
              <Row middle>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validate}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    isValid,
                    dirty,
                    /* and other goodies */
                  }) => (
                    <>
                      <Col>
                        <Button
                          small
                          primaryText
                          onClick={() => getTimeZone(setFieldValue)}
                        >
                          Auto Detect
                        </Button>
                      </Col>
                      <Col>
                        <Form className="inline-block align-middle">
                          <Row middle>
                            <Col>
                              <CustomSelect
                                name="timezone_id"
                                options={timezone}
                                className=" mr-8 i w-[200px]"
                                onChange={(field: string, value: string) => {
                                  trackEvent({
                                    event_name: 'fmui_select_timezone',
                                    attributes: {
                                      timezone: value,
                                    },
                                  })
                                  setFieldValue(field, value)
                                }}
                                value={values.timezone_id}
                                onBlur={setFieldTouched}
                                error={errors.timezone_id}
                                placeHolder="Select Time Zone"
                                touched={touched.timezone_id}
                                maxMenuHeight={200}
                              />
                            </Col>
                            <Col>
                              <Tooltip
                                text="This action is temporarily disabled because 
of an operation in the background which might
potentially cause conflicts"
                                hide={
                                  !propsOutlet.checkPolling(
                                    Parameters.php_settings,
                                  )
                                }
                              >
                                <Button
                                  styles=" align-middle"
                                  disabled={
                                    propsOutlet.checkPolling(
                                      Parameters.php_settings,
                                    ) ||
                                    isSubmitting ||
                                    !(dirty && isValid)
                                  }
                                >
                                  Apply
                                </Button>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </>
                  )}
                </Formik>
              </Row>
            </Col>
          </Row>
        </Container>
        <Separator></Separator>
      </Container>
    </Track>
  )
}

export default TimezoneContainer
