/** Libraries */
import React, {useState} from 'react'

/** Reusable Components */
import AppDrawerList from './AppDrawerList'
import AppDrawerHeader from './AppDrawerHeader'
import {Button, Container, Row} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import '../style.css'

export default function Drawer({values, onCancel, check, drawerState}: any) {
  return (
    <>
      <Row end>
        <Container
          styles={` py-20 z-[9999] cwpf_dashBoard_drawer flex fixed h-full drawer drawer-end drawer-mobile
         right-0 top-0 bg-white w-[354px] max-md:w-auto border border-gray-3 max-lg:overflow-y-auto ${
           drawerState ? 'cwpf_dashBoard_drawer_actv' : ''
         }`}
        >
          <Container styles="drawer-side max-lg:overflow-y-scroll bg-white w-full">
            <div className="flex flex-col justify-between h-[100vh]">
              <div className="">
                <AppDrawerHeader onCancel={onCancel} values={values} />
                <ul className="menu text-base-content bg-base-100">
                  {[1, 2, 3].map((item: any, index) => (
                    <AppDrawerList
                      onCancel={onCancel}
                      check={check}
                      key={index}
                    />
                  ))}
                </ul>
              </div>
              <div className="h-[120px]">
                <button className="btn btn-primary cwpf_dashboard_btn">
                  Done
                </button>
              </div>
            </div>
          </Container>
        </Container>
      </Row>
    </>
  )
}
