/** Libraries */
import {FC} from 'react'

/** Reusable Components */
import {Container, Text} from 'Common/Components/Core/DesignTemplates'

const FeatureList: FC = (): JSX.Element => {
  return (
    <section className="bg-gray-3 pt-60 pb-80">
      <Container styles="container">
        <Container styles="cwpf_hm_feat_sec pl-40">
          <Text type="h4" med styles="pb-40 text-gray-100">
            All Plans Include:
          </Text>
          <ul className="cwpf_hm_feat_list">
            <li>Turbocharged Performance </li>
            <li>Cutting Edge Technology</li>
            <li>Enterprise Grade Security</li>
            <li>Optimized for WordPress & Woocommerce</li>
            <li>High Availability & Auto Scaling</li>
          </ul>
        </Container>
      </Container>
    </section>
  )
}

export default FeatureList
