import {
  useCallback,
  useEffect,
  useState,
  useRef,
  // useEffect, useCallback, useRef
} from 'react'
import './Modal.css'
import ReactDOM from 'react-dom'

import Animation from 'Common/Components/Core/DesignTemplates/Animations/Animation'
import {ReactComponent as Cross} from 'Assets/img/modal/cross.svg'
import { useLocation } from 'react-router-dom'

interface modalProps {
  show: boolean
  classes: string
  children?: React.ReactNode
  onClose(e: boolean): void
  timeout?: number
  animationStyle?: string
  id?:string
}

const ModalOverlay = ({
  show = false,
  classes = '',
  children,
  onClose,
  timeout = 300,
  animationStyle = 'fadeIn',
  id,
}: any) => {
  const location=useLocation()
  const locationPath = location.pathname.replaceAll("/", "_");

  useEffect(() => {
    const escFunction = (e: any) => {
      if (show) {
        //
        if (e.key === 'Escape') {
          onClose()
        }
      }
    }
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [onClose, show])

  return (
    <Animation
      in={show}
      timeout={timeout}
      appear={show}
      unmountOnExit
      classNames={animationStyle}
    >
      <div
      id={`${locationPath}_${id}_modal`}
      className="overlay">
        <div className="closeModal" onClick={onClose}></div>
        <div className={`cwpf_modal_wrap ${classes}`}>
          <Cross className="close" onClick={onClose} />

          <div className="cwpf_modal_body">{children}</div>
        </div>
      </div>
    </Animation>
  )
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const portalElement = document.getElementById('root-modal')!
const Modal = ({
  show,
  onClose,
  classes,
  children,
  timeout,
  animationStyle,
  id,
}: modalProps) => {
  //

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [show])

  return (
    <>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={onClose}
          show={show}
          classes={classes}
          timeout={timeout}
          animationStyle={animationStyle}
          id={id}
        >
          {children}
        </ModalOverlay>,
        portalElement,
      )}
    </>
  )
}
Modal.defaultProps = {
  classes: '',
}
export default Modal
