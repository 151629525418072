/** Reusable Components */
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import EnableBar from 'Common/Components/Core/DesignTemplates/EnableBar/EnableBar'
import {ReactComponent as ResetCachesvg} from 'Assets/img/manage-app/resetcache.svg'
import {iCacheDetails} from '../util'
import Footer from './Footer'
import {subtract} from 'lodash'

const CacheItems = ({
  itemData,
  submit,
}: {
  submit: boolean
  itemData: iCacheDetails
}) => {
  return (
    <Col styles="cwpf_cachingDetailCardResp ">
      <Row gutter={16}>
        <Col styles="self-start">
          <img src={itemData.ImgSrc} className=" " alt="Cache_Image" />
        </Col>
        <Col lg={10}>
          <Row col between>
            <Col>
              <Text styles="p1 pb-6" med>
                {itemData.name}
              </Text>
              <Text type="p" styles="p3 leading-[20px] min-w-[220px]">
                {itemData.detail}
              </Text>
              {itemData.buttonHandler && (
                <Button
                  small
                  primaryOutline
                  styles="mt-4"
                  onClick={itemData.buttonHandler}
                  disabled={submit}
                >
                  <ResetCachesvg className="mr-6" />
                  {itemData?.button}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const Section = ({
  name,
  data,
  submit,
}: {
  name: string
  submit: boolean
  data: iCacheDetails[]
}) => {
  return (
    <Card styles="p-0 min-h-[223px]">
      <Container styles="pb-[40px] pt-18 px-20">
        <Text type="h6" semi styles={`${'flex items-center  text-gray-100'}`}>
          {name}
          <EnableBar success>Enabled</EnableBar>
        </Text>

        <Row gutter={16} styles="pt-12 cwpf_cachingDetailCardsResp">
          {data?.map((item: iCacheDetails, ind: number) => (
            <>
              <CacheItems key={ind} itemData={item} submit={submit} />
            </>
          ))}
        </Row>
      </Container>
      <Footer />
    </Card>
  )
}

export default Section
