import React from 'react'
import Modal from '../../../Hooks/Modal/Modal'
import {Button, Col, Container, Row, Text} from '../../DesignTemplates'
import appNotFoundImg from 'Assets/img/manage-app/appNotFound.svg'
/**
 * @description - This will show session expired message and also redirect the user to login.
 */
const SessionExpiredModal = ({show, onClose}: any) => {
  return (
    <Modal
      show={show}
      onClose={() => {
        window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))
        onClose()
      }}
      classes=""
    >
      <Container
        styles={
          'py-[56px] px-[84px] background-color:#ffffff flex items-center justify-center '
        }
      >
        <Row col>
          <Row center>
            <Col>
              <img
                className={`w-[247px] h-[172px]`}
                src={appNotFoundImg}
                alt={'worpress_logo'}
              />
            </Col>
          </Row>

          <Row center>
            <Col>
              <Text type="h6" styles="text-gray-80 mt-[30px] mb-[10px]" med>
                Session Expired
              </Text>
            </Col>
          </Row>

          <Row center>
            <Col>
              <Text type="p" styles="p2 text-gray-80">
                Please login again.
              </Text>
            </Col>
          </Row>

          <Row center>
            <Col>
              <Button
                medium
                primary
                styles="mt-[35px]"
                onClick={() =>
                  window.location.replace(
                    String(process.env.REACT_APP_REDIRECT_URL),
                  )
                }
              >
                Log In
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </Modal>
  )
}

export default SessionExpiredModal
