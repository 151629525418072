import React from 'react'

const PlanSpecs = ({plansData}: any) => {
  return (
    <div className="cwpf_slctPlan_statsBox min-h-[66px]">
      <ul className="flex justify-between">
        <li>
          <b>{plansData?.max_app_count}</b>
          <span>Applications</span>
        </li>
        <li>
          <b>{plansData?.storage_gb}GB</b>
          <span>Disk Space</span>
        </li>
        <li>
          <b>{plansData?.max_montly_visits}</b>
          <span>Visits/Month</span>
        </li>
        <li>
          <b>{plansData?.bandwith_gb}GB</b>
          <span>Bandwidth</span>
        </li>
      </ul>
    </div>
  )
}

export default PlanSpecs
