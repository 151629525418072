import {useTracking} from 'react-tracking'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {
  Button,
  Container,
  Icon,
  Row,
  Col,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {useAddPlanMutation} from '../../../../../../app/services'
import {toggleModal} from '../../ModalSlice'
import {ModalName} from '../../util'
import './ConfirmPlanModal.css'
import moment from 'moment'
import {useGetCurrentUserQuery} from 'app/services/platform3'

const ConfirmPlanModal = ({show, onClose}: any) => {
  const dispatch = useAppDispatch()
  const currentUser = useGetCurrentUserQuery('')

  const {trackEvent} = useTracking<any>({
    attributes: {
      page: 'select_plan',
      current_url: window.location.href,
    },
  })
  /* State Data */
  const checkoutPlan = useAppSelector(state => state.plans.checkoutPlan)
  /* ---x---  */
  const [addPlan, {isLoading: isAddingPlan}] = useAddPlanMutation()
  const planAddedHandler = () => {
    const eventObject = {
      event_name: 'fmui_confirm_plan',
      attributes: {
        plan_id: checkoutPlan.id,
        plan_disk_space: checkoutPlan.storage_gb,
        plan_bandwidth: checkoutPlan.bandwith_gb,
        plan_visits: checkoutPlan.max_montly_visits,
        plan_app_limit: checkoutPlan.max_app_count,
        plan_price: checkoutPlan.price,
        is_success: false,
      },
    }
    addPlan({
      plan_id: checkoutPlan.id,
      username: currentUser?.currentData?.name,
      user_email: currentUser?.currentData?.email,
    })
      .unwrap()
      .then(response => {
        trackEvent({
          ...eventObject,
          attributes: {...eventObject.attributes, is_success: true},
        })
        onClose()
      })
      .catch(error => {
        trackEvent(eventObject)
        return new Error(error)
      })
  }

  return (
    <>
      <Modal show={show} onClose={onClose} classes="cwpf_planAdded_modal">
        <Container styles="cwpf_cnfrmPlan_sec">
          <Container styles="cwpf_cnfrmPlan_mainBox">
            <Text type="h3" semi styles=" text-gray-80">
              Your Selected Plan
            </Text>
            <Text type="p" styles="my-28 text-dark">
              Please confirm that you want to proceed with the <br /> following
              plan.
            </Text>

            <Container styles="cwpf_cnfrmPlan_dtlBox">
              <Row between styles="items-baseline">
                <Col>
                  <Text type="h4" semi styles=" text-dark">
                    Details:
                  </Text>
                </Col>
                <Col>
                  <Text type="h1" semi styles="text-blue-100 ">
                    {`$${Math.floor(Number(checkoutPlan?.price))}`}
                    <Text type="sub" styles="text-[16px] top-[-5px] ml-10">
                      /month
                    </Text>
                  </Text>
                </Col>
              </Row>

              <ul className="cwpf_cnfrmPlan_specBox mt-[10px]">
                <li>
                  Applications
                  <b>{checkoutPlan?.max_app_count}</b>
                </li>
                <li>
                  Disk Space
                  <b>{checkoutPlan?.storage_gb} GB</b>
                </li>
                <li>
                  Bandwidth
                  <b>{checkoutPlan?.bandwith_gb} GB</b>
                </li>
                <li>
                  Visits/Month
                  <b>{checkoutPlan?.max_montly_visits}</b>
                </li>
              </ul>

              <ul className="cwpf_cnfrmPlan_noteBox">
                <li>$2/Additional Bandwidth MB</li>
                <li>$1/Additional 1000 Visits</li>
                <li>$4/Additional 1 GB of Disk Space</li>
              </ul>

              <Container styles="cwpf_cnfrmPlan_ctaBox mt-[28px]">
                <Button
                  type="button"
                  block
                  onClick={planAddedHandler}
                  icon="arrow_forward"
                  iconAlign="right"
                  styles="text-18"
                  iconStyles="text-18"
                  loading={isAddingPlan}
                  id="1"
                >
                  Confirm Plan
                </Button>
                <Text
                  styles="text-gray-80 p2 pt-18 text-center cursor-pointer"
                  onClick={() => onClose()}
                >
                  Change Plan
                </Text>
              </Container>
            </Container>
          </Container>
        </Container>
      </Modal>
    </>
  )
}
export default ConfirmPlanModal
