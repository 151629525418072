import {MiddlewareAPI, isRejectedWithValue, Middleware} from '@reduxjs/toolkit'
import {store} from 'app/store'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {toggleModal} from 'Common/Components/Core/Modal/ModalSlice'
import {ModalName} from 'Common/Components/Core/Modal/util'

interface ErrorDetails {
  detail: string
  status_code: number
}

interface ErrorPayload {
  data: string | ErrorDetails
  error: string
  originalStatus: number
  status: string | number
}

interface GetToastReturn {
  level: 'info' | 'success' | 'warning' | 'error'
  title: string
  description: string
}

const getErrorObject = ({
  error,
  payload,
}: {
  error: any
  payload: ErrorPayload
}): GetToastReturn => {
  if (typeof payload?.data === 'string') {
    return {
      level: 'error',
      title: `${payload?.originalStatus}: ${payload?.status}`,
      description: `${payload?.error}`,
    }
  }
  return {
    level: 'warning',
    title: `${payload?.status}: ${error?.message}`,
    description: `${payload?.data?.detail}`,
  }
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action) && window.navigator.onLine) {
      // // if status 403 - forbidden error , redirect the user
      // if ( action?.meta?.arg?.endpointName ==="getCurrentUser" && action?.payload?.status === 403) {
      //   window.location.replace(String(process.env.REACT_APP_REDIRECT_URL))
      // }

      // if status 400 - invalid session error - when the user
      //console.log(action, 'this is my nuclous cookie')

      if (
        action?.payload?.status === 403 &&
        action?.payload?.data?.code === 'invalid_authentication_values'
      ) {
        store.dispatch(toggleModal(ModalName.showSessionExpiredModal))
      }

      if (
        ![
          'getCurrentPlan',
          'getSingleApplication',
          'getOperations',
          'getResourceDiskSpace',
          'getResourceBandwidth',
          'getResourceVisits',
          'getTotalVisits',
          'getTotalApps',
          'getTotalDiskSpace',
          'postEvents',
          'setUserSession',
          'clearUserSession',
          'getLatestBackup',
          'getRestorePoints',
          'getMonitoringStats',
        ].includes(action?.meta?.arg?.endpointName)
      ) {
        console.warn('We got a rejected action!', action)
        customToast(getErrorObject(action))
      }
    }

    return next(action)
  }
