/** Libraries */
import React, {useEffect, useState} from 'react'
import {useTracking} from 'react-tracking'
import {useOutletContext, useParams} from 'react-router-dom'

/** Store/Services */
import {
  useUpdateSingleApplicationMutation,
  useGetSFTPCredentialsForAppQuery,
  apiSlice,
} from 'app/services'

/** Reusable Components */
import CustomForm from '../common/CustomForm'
import Details from './Details'
import {Container, Text} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import {customFormType, iManageAccess, MANAGE_ACCESS_SUB_TABS} from '../util'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {useAppDispatch} from 'app/hooks'
import {customToast} from 'Common/Components/Core/CustomizedToast'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'

export default function SSHContainer(): JSX.Element {
  const {app_id} = useParams()
  const [editMode, setEditMode] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {checkPolling, applicationData}: iPropsOutlet = useOutletContext()
  const {Track} = useTracking({
    attributes: {
      sub_tab: MANAGE_ACCESS_SUB_TABS.SSH_SFTP,
    },
  })

  function truncate(text: string, maxLength: number) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const [updateAppParams] = useUpdateSingleApplicationMutation()

  const {isLoading: isSftpLoading} = useGetSFTPCredentialsForAppQuery(app_id)
  useEffect(() => {
    setEditMode(false)
  }, [app_id])

  useEffect(() => {
    if (checkPolling(Parameters.ssh_password)) {
      setEditMode(true)
      return
    }
    setEditMode(false)
  }, [checkPolling])

  const initialValues: iManageAccess = {
    ip: 'ssh.' + applicationData?.app_fqdn,
    username: applicationData?.ssh_user,
    password: applicationData?.ssh_password,
  }

  const onSubmit = (
    values: iManageAccess,
    {setSubmitting}: {setSubmitting: (value: boolean) => void},
  ) => {
    updateAppParams({
      app_id: app_id,
      subPath: 'master-ssh-creds',
      body: {ssh_password: values.password},
    })
      .unwrap()
      .then((fulfilled: any) => {
        customToast({
          level: 'info',
          title: '',
          description:
            'SSH Password is being updated. You wont be able to perform certain actions while the operation is taking place',
          loading: true,
        })
        dispatch(
          apiSlice.endpoints.getOperations.initiate({
            id: fulfilled.operation_id,
          }),
        )
          .unwrap()
          .then(fulfilled => {
            dispatch(
              apiSlice.util.updateQueryData(
                'getSingleApplication',
                app_id,
                draft => {
                  draft.sshpassword = values.password
                },
              ),
            )
            setSubmitting(false)
          })
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return (
    <Track>
      <Container styles="cw_ssh">
        <Text type="h5" semi styles="text-dark">
          Default Credentials
        </Text>
        <Text styles="p2 text-gray-80">
          SFTP and SSH access details for all applications
        </Text>

        <CustomForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          isLoading={isSftpLoading}
          editMode={editMode}
          setEditMode={setEditMode}
          label={{fieldOne: 'Host', fieldTwo: 'Username'}}
          name={{fieldOne: 'ip', fieldTwo: 'username'}}
          styles={`pr-[50px] overflow-hidden text-ellipsis pointer-events-none`}
          labelStyles="text-gray-80"
          type={customFormType.ssh}
        />
        <Details />
      </Container>
    </Track>
  )
}
