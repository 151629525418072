/** Libraries */
import React, {useRef} from 'react'
import {useNavigate} from 'react-router-dom'

/** Reusable Components */
import {
  Col,
  Container,
  DropDownMenu,
  Icon,
  Row,
  Text,
  WebSnap,
  Button,
} from 'Common/Components/Core/DesignTemplates'
import AppStats from './AppStats'

/** Helpers */
import {iListGridItem} from '../util'

/** Assets (CSS/Images) */
import wordpressLogo from '../../../../../Assets/img/application-listing/wp-logo.svg'
import woocommerceLogo from '../../../../../Assets/img/application-listing/woo-logo.svg'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const ListingItemGrid = ({
  app,
  onChange,
  checkbox,
  isPolling,
  deleteAppHandler,
  cloneAppHandler,
  stageAppHandler,
  exceptionType,
  setShowAppCard,
}: iListGridItem) => {
  const {id, name, app_fqdn, cname, app_type, region} = app
  const get_cname = cname ? cname : app_fqdn
  const navigate = useNavigate()

  return (
    <Container
      styles={`cwpf_appView_gribBox cwpf_userApp_gridScreenShot lg:basis-[23%] max-lg:basis-[48%] mb-24 relative border border-gray-10 rounded-4 ${
        isPolling ? 'cwpf_appView_polling' : ''
      }`}
      onClick={() =>
        !(isPolling || exceptionType) && navigate(`/manage-app/${id}`)
      }
    >
      <div className="w-full h-[180px] cwpf_userApp_screenShot cwpf_userApp_gridScreenShot relative cursor-pointer overflow-hidden">
        {isPolling ? (
          <div className="cwpf_appView_pollingloader rounded-4">
            <div className="cwpf_appinstall_spinner"></div>
            <Text styles="!p2 !text-gray-80">
              Setting up your application...
            </Text>
            <Button
              small
              primaryText
              icon="arrow_forward"
              iconAlign="right"
              onClick={setShowAppCard}
              styles="hidden"
            >
              Learn More
            </Button>
          </div>
        ) : (
          <>
            {exceptionType ? (
              <Row
                col
                center
                styles="absolute top-0 left-0 w-full h-full bg-gray-3"
              >
                <Col className="flex items-center justify-center">
                  <Icon styles="text-14 text-red-75 mr-2">warning_amber</Icon>
                  <Text styles="text-14 text-red-75">
                    Error setting up your application
                  </Text>
                </Col>

                <Col className="flex items-center justify-center hidden">
                  <Button small neutralText icon="autorenew" iconAlign="left">
                    Try Again
                  </Button>

                  <Button
                    small
                    neutralText
                    icon="delete_outline"
                    iconAlign="left"
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            ) : (
              <div className="cursor-pointer">
                <WebSnap
                  url={app_fqdn}
                  styles="rounded-4 w-full h-full"
                  alt={name}
                />

                <Container styles="cwpf_userApp_gridHover flex items-center justify-center absolute bg-mask left-0 top-0 w-full h-full opacity-0 z-10 rounded-4">
                  <AppStats app={app} color="text-light" gutter={24} />
                </Container>
              </div>
            )}
          </>
        )}
      </div>

      <Row
        flex
        col
        top
        styles={`relative space-y-4 px-10 py-6 pb-14 bg-white min-h-[93px] cwpf_userApp_gridHover  ${
          exceptionType && 'item-center justify-center'
        }`}
      >
        <Col styles="w-[100%]">
          <Row flex middle styles="w-full p-4 pb-0">
            <Text
              type="h5"
              semi
              styles="text-gray-80 capitalize max-w-[210px] text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {name}
            </Text>
            <img
              className={`cwpf_app_type_img cwpf_app_type_${app_type.name} ml-8`}
              src={app_type.id === 2 ? wordpressLogo : woocommerceLogo}
              alt={app_type.name}
            />
          </Row>
          {!(isPolling || exceptionType) && (
            <Row>
              <Tooltip
                top
                styles="relative z-[1] ml-[2px] mt-[-3px]"
                text={get_cname}
              >
                <Icon styles="text-20 leading-3 mr-4 inline-block align-middle text-gray-80">
                  link
                </Icon>
                <Text
                  type="a"
                  href={`https://${get_cname}`}
                  target="_blank"
                  styles=" cwpf_listingItemGrid_cname"
                >
                  {get_cname}
                </Text>
              </Tooltip>
            </Row>
          )}
          <Row>
            <Tooltip top text={region}>
              <Icon styles="text-20 leading-3 mr-4 inline-block align-middle text-gray-80">
                location_on
              </Icon>
              <Text type="p" med styles=" cwpf_listingItemGrid_region ">
                {region}
              </Text>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <div
        className={`inline-block absolute bottom-10 right-12`}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        {/* <CheckBox
          type="checkbox"
          onChange={onChange}
          checked={checkbox}
          disabled={isPolling || exceptionType ? true : false}
        /> */}
      </div>
      <div
        className="absolute right-14 top-14"
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        {!isPolling && (
          <DropDownMenu
            border
            icon="more_horiz"
            bottomRight
            styles="z-20 h-26"
            disabled={isPolling || !!exceptionType}
            buttons={[
              {
                name: 'Delete Application',
                icon: 'delete_outlined',
                handler: deleteAppHandler,
              },
            ]}
          />
        )}
      </div>
    </Container>
  )
}

export default ListingItemGrid
