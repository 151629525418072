export interface iManageAppTrackingObj {
  event_name: string
  attributes: {
    page?: string
    current_url?: string
    app_id?: string
  }
}

export interface Logslist {
  title: string
  description: string
}
export const enum TABNAMES {
  OVERVIEW = 'overview',
  PERFORMANCE = 'performance',
  INTELLIGENCE = 'intelligence',
  SECURITY = 'security',
  WORKFLOW_TOOLS = 'workflow_tools',
  MANAGE_ACCESS = 'manage_access',
}
