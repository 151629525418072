//design system
import {
  Card,
  Text,
  Row,
  Col,
  Accordion,
} from 'Common/Components/Core/DesignTemplates'
import React from 'react'
//svg
import {ReactComponent as Cdnsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/cdnenabled.svg'
import {ReactComponent as MobileOptimizationsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/mobileoptimization.svg'
import {ReactComponent as Securitysvg} from 'Assets/img/manage-app/manage-access/DomainManagement/security.svg'
import {ReactComponent as PageCachesvg} from 'Assets/img/manage-app/manage-access/DomainManagement/pagecache.svg'
import {ReactComponent as DdodProtectionsvg} from 'Assets/img/manage-app/manage-access/DomainManagement/ddosprotection.svg'
import {ReactComponent as ImageOptimization} from 'Assets/img/manage-app/manage-access/DomainManagement/imageoptimization.svg'

const CloudfareFeature = ({accordion = false}: any) => {
  const svgColor = accordion ? 'cwpf_domaincomplete_svg' : ''
  const Title = (
    <Text med styles="p2 text-gray-80">
      {!accordion
        ? 'Once you add your domain the following Cloudflare features will be enabled'
        : 'These cloudflare features has been enabled on your domain'}{' '}
    </Text>
  )
  const Content = (
    <Row wrap gutter={10} styles="space-y-20">
      <Col lg={6} styles="mt-20">
        <Row middle>
          <Cdnsvg className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            {accordion ? 'CDN Enabled' : 'CDN for faster loading'}
          </Text>
        </Row>
      </Col>
      <Col lg={6}>
        <Row middle>
          <MobileOptimizationsvg className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            {accordion ? 'Mobile Optimized' : ' Mobile Optimization'}
          </Text>
        </Row>
      </Col>

      <Col lg={6}>
        <Row middle>
          <Securitysvg className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            {accordion ? 'WAF Enabled' : 'WAF for enhanced Security'}
          </Text>
        </Row>
      </Col>
      <Col lg={6}>
        <Row middle>
          <PageCachesvg className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            Full Page Cache
          </Text>
        </Row>
      </Col>

      <Col lg={6}>
        <Row middle>
          <DdodProtectionsvg className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            {accordion ? 'DDOS Protection Enabled' : 'DDOS Protection'}
          </Text>
        </Row>
      </Col>
      <Col lg={6}>
        <Row middle>
          <ImageOptimization className={svgColor} />
          <Text med styles="p3 ml-12 text-gray-60">
            {accordion ? 'Images Optimized' : 'Images Optimization'}
          </Text>
        </Row>
      </Col>
    </Row>
  )
  return (
    <>
      {accordion ? (
        <Accordion title={Title}>{Content}</Accordion>
      ) : (
        <Card styles="!rounded-[0px]">
          {Title}
          {Content}
        </Card>
      )}
    </>
  )
}
export default CloudfareFeature
