/** Libraries */
import React, {useState, useEffect} from 'react'
import {useAppDispatch} from 'app/hooks'

/** Reusable Components */
import {Icon, Text} from '..'
import PropTypes from 'prop-types'
import {deleteToast} from './ToastSlice'

/** Assets (CSS/Images) */
import './Toast.css'

const Toast = (props: any) => {
  const dispatch = useAppDispatch()
  const {toastList, position, autoDelete} = props

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length) {
        dispatch(deleteToast(toastList[0].id))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }

    // eslint-disable-next-line
  }, [toastList, autoDelete, toastList])

  return (
    <>
      <div className={`notification-container ${position}`}>
        {toastList.map((toast: any, i: any) => (
          <div
            key={i}
            className={`notification  ${position}`}
            style={{border: `1px solid ${toast.backgroundColor}`}}
          >
            <div className="flex items-center space-x-10">
              <Icon
                name={toast.icon}
                color={toast.fontColor}
                backgroundColor={toast.backgroundColor}
                styles="h-[48px] w-[48px] flex items-center justify-center text-gray-80"
              />
              <Text type="P" variant="p3" styles="text-gray-80 ">
                {toast.description}
              </Text>
            </div>
            <Icon
              name="close"
              styles=" text-gray-30 pr-[17px] cursor-pointer"
              onClick={() => dispatch(deleteToast(toast.id))}
            />
          </div>
        ))}
      </div>
    </>
  )
}

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
}

export default Toast
