/** Reusable Components */
import {useAppSelector} from 'app/hooks'
import {Dispatch} from 'react'
import { useLocation } from 'react-router-dom'
import {Icon} from '../index'

/** Assets (CSS/Images) */
import './Button.css'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  showIcon: boolean
}

interface iButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  block: boolean
  loading: boolean
  variant: string
  styles: string
  small: boolean
  medium: boolean
  large: boolean
  primary: boolean
  secondary: boolean
  danger: boolean
  neutral: boolean
  primaryOutline: boolean
  secondaryOutline: boolean
  dangerOutline: boolean
  neutralOutline: boolean
  primaryText: boolean
  secondaryText: boolean
  dangerText: boolean
  neutralText: boolean
  icon: string
  iconAlign: string
  iconStyles: string
  iconhandler: () => void
  hide?: boolean
}

const Button = ({
  children,
  block,
  loading,
  variant = '',
  onClick,
  disabled,
  styles = '',
  small,
  medium,
  large,
  primary,
  secondary,
  danger,
  neutral,
  primaryOutline,
  secondaryOutline,
  dangerOutline,
  neutralOutline,
  primaryText,
  secondaryText,
  dangerText,
  neutralText,
  icon = '',
  iconAlign = '',
  iconStyles = '',
  iconhandler,
  hide = false,
  id = '',
  ...rest
}: Partial<iButton>) => {
  const location=useLocation()
  const locationPath = location.pathname.replaceAll("/", "_");
  const getButtonType = () => {
    if (primary) return 'primary'
    if (secondary) return 'secondary'
    if (danger) return 'danger'
    if (neutral) return 'neutral'
    if (primaryOutline) return 'primary_outline'
    if (secondaryOutline) return 'secondary_outline'
    if (dangerOutline) return 'danger_outline'
    if (neutralOutline) return 'neutral_outline'
    if (primaryText) return 'primary_text'
    if (secondaryText) return 'secondary_text'
    if (dangerText) return 'danger_text'
    if (neutralText) return 'neutral_text'
    return 'primary'
  }
  const {isOnline} = useAppSelector(state => state.connection)

  const ButtonStyles = () => {
    const disable = disabled || !isOnline ? '_disable' : ''
    const iconAlignment = iconAlign === 'left' ? 'flex-row' : 'flex-row-reverse'
    const btnLoading = loading ? 'cwpf_glb_spinner_btn' : ''
    const Block = block ? 'w-[100%]' : ''

    return `cwpf_btn_${getButtonType()}${disable} ${iconAlignment} ${btnLoading} ${Block}`
  }

  const iconMargin = () => {
    if (iconAlign.toLowerCase() === 'left') return 'mr-[5px]'
    if (iconAlign.toLowerCase() === 'right') return 'ml-[5px]'
    return 'ml-[5px]'
  }
  const buttonSize = () => {
    if (small) return 'px-8 p2 h-[32px]' //32 py-[4px]
    if (medium) return 'px-16 p1 h-[40px]' //40 py-6
    if (large) return 'px-24 text-18 h-[48px]' //48 py-10
    return 'px-24 text-18 h-[48px]'
  }
  if (hide) return <></>
  return (
    <button
      className={`${ButtonStyles()}   ${buttonSize()} ${styles}`}
      onClick={onClick}
      disabled={!isOnline || disabled}
      id={`${locationPath}${id ? `_${id}`: ''}_btn`}
      {...rest}
    >
      <>
        {icon && !loading && (
          <Icon
            styles={`${iconMargin()} ${iconStyles}`}
            name={icon}
            onClick={iconhandler}
          />
        )}
        <b
          className="font-normal flex items-center"
          style={{lineHeight: 'normal'}}
        >
          {children}
        </b>
      </>
    </button>
  )
}
Button.defaultProps = {
  block: false,
  disabled: false,
  loading: false,
}
export default Button
