/** Assets (CSS/Images) */
import './Grid.css'

const Row = ({
  row,
  col,
  wrap,
  nowrap,
  gutter = 0,
  top,
  middle,
  bottom,
  baseline,
  start,
  center,
  between,
  around,
  evenly,
  end,
  stretch,
  children,
  styles = '',
  ...rest
}: any) => {
  const getClasses = () => {
    const flexRow = row ? 'flex-row' : ''
    const flexCol = col ? 'flex-col' : ''
    const flexWrap = wrap ? 'flex-wrap' : ''
    const flexTop = top ? 'items-start' : ''
    const flexMiddle = middle ? 'items-center	' : ''
    const flexBottom = bottom ? 'items-end' : ''
    const flexBaseline = baseline ? 'items-baseline' : ''
    const flexStart = start ? 'justify-start' : ''
    const flexCenter = center ? 'justify-center' : ''
    const flexEnd = end ? 'justify-end' : ''
    const flexStretch = stretch ? 'justify-stretch' : ''
    const flexBetween = between ? 'justify-between' : ''
    const flexEvenly = evenly ? 'justify-evenly' : ''
    const flexAround = around ? 'justify-around' : ''
    return `flex ${flexRow} ${flexCol} ${flexWrap} ${flexTop} ${flexMiddle} ${flexBottom} ${flexBaseline} ${flexStart} ${flexCenter} ${flexEnd} ${flexStretch} ${flexBetween} ${flexEvenly} ${flexAround} ${styles}`
  }

  const gutterGap = () => {
    let setColGap
    if (gutter === 8) return (setColGap = gutter)
    if (gutter === 16) return (setColGap = gutter)
    if (gutter === 24) return (setColGap = gutter)
    if (gutter === 32) return (setColGap = gutter)
    if (gutter === 40) return (setColGap = gutter)
    return ''
  }
  return (
    <div
      className={`${styles} ${getClasses().replace(/\s+/g, ' ').trim()} ${
        gutterGap() ? 'row-gutter-' + gutterGap() : ''
      }`}
      {...rest}
      // style={{gap: gutter}}
    >
      {children}
    </div>
  )
}

Row.defaultProps = {
  row: false,
  col: false,
  top: false,
  middle: false,
  bottom: false,
  baseline: false,
  start: false,
  center: false,
  end: false,
  stretch: false,
}

export default Row
