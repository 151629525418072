/** Reusable Components */
import Card from '../Card/Card'
import Col from '../Grid/Col'
import Row from '../Grid/Row'
import Text from '../Text/Text'

/** Assets (CSS/Images) */
import './CommonSoonScreen.css'

const CommingSoonScreen = ({
  ImgComponent,
  heading,
  detail,
}: {
  ImgComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  heading: string
  detail?: string
}) => {
  return (
    <Card styles="flex items-center justify-center text-center absolute w-[100%] h-[100%] z-[9] bg-[rgba(255,255,255,0.8)]">
      <Row middle col>
        <Col sm={12} flexMiddle>
          <ImgComponent />
        </Col>

        <Col sm={12}>
          <Row center wrap middle styles="p1 mt-10 mb-12">
            <Text semi styles="text-mask">
              {heading}
            </Text>
            <Text styles="ml-4 text-mask">{detail}</Text>
          </Row>
        </Col>
        <Col sm={12}>
          <button className="transparent p3 semi border border-solid rounded-20 border-blue-100 px-8 text-blue-100 cursor-default">
            Coming Soon
          </button>
        </Col>
      </Row>
    </Card>
  )
}

export default CommingSoonScreen
