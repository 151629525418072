import {Button, Col, Row, Text} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import React from 'react'

const ExclusionDeleteModal = (props: any) => {
  return (
    <Modal show={props.show} onClose={props.onClose}>
      <Row col styles="py-32 pl-32 pr-60">
        <Col styles="pb-32">
          <Text type="h6" med styles="pb-14 text-gray-80">
            Confirmation
          </Text>
          <Text styles="p2 text-gray-80">
            Are you sure you want to delete “Google” exclusion
          </Text>
        </Col>
        <Col>
          <Row>
            <Button danger onClick={props.onClose}>
              Delete Exclusion
            </Button>
            <Button primaryText onClick={props.onClose}>
              Cancel
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default ExclusionDeleteModal
