/** Reusable Components */
import {
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
/** Assets (CSS/Images) */
import Applist from '../../../../../Assets/img/app-list.png'

const AppDrawerList = ({check}: any, {btn}: any) => {
  return (
    <li className="cwpf_dashboard_border bg-white ">
      <input
        type="checkbox"
        id="vehicle1"
        name="vehicle1"
        value="Bike"
        className="border border-gray-3"
      />

      <img src={Applist} className="cwpf_dashboard_img" />
      <Container>
        <Text styles="cwpf_dashboard_appdropwdown_head">Sponeredby</Text>
        {check && (
          <Row styles="space-x-40 ml-20">
            <Col>
              <Icon
                name="developer_board"
                styles="material-icons p3 relative mr-2 text-gray-60"
              >
                <Text styles="absolute text-12">4444gb</Text>
              </Icon>
            </Col>
            <Col>
              <Icon styles="p3 relative mr-2 text-gray-60">
                people
                <Text styles="absolute text-gray-80"> 10k</Text>
              </Icon>
            </Col>
            <Col>
              <Icon styles="p3 realtive mr-2 text-gray-60">
                donut_large
                <Text styles="absolute text-gray-80"> 512GB</Text>
                {/* <span className="absolute text-gray-80">{icon && '512GB'}</span> */}
              </Icon>
            </Col>
          </Row>
        )}
      </Container>
    </li>
  )
}
export default AppDrawerList
