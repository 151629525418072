/** Libraries */
import {useState} from 'react'

/** Reusable Components */
import {
  Button,
  Col,
  CommingSoonScreen,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import AppDetails from './AppDetails'

/** Assets (CSS/Images) */
import {ReactComponent as AggregateWebImg} from '../../../../../../Assets/img/aggregate-web-vitals.svg'

const AppVitals = () => {
  const [testAgain, setTestAgain] = useState<boolean>(false)

  const setTestAgainFunc = (e: boolean | ((prevState: boolean) => boolean)) => {
    setTestAgain(e)
  }

  return (
    <Container styles="relative">
      <CommingSoonScreen
        ImgComponent={AggregateWebImg}
        heading="Application Vitals"
      />
      <Container styles="blur-sm px-12">
        <Row between>
          <Col>
            <Text type="h5" styles="text-dark hidden" semi>
              Application Vitals
            </Text>
          </Col>
          <Col>
            {testAgain ? (
              <Row>
                <Col>
                  <Button
                    primaryText
                    small
                    icon="navigate_next"
                    iconAlign="right"
                  >
                    Share Report
                  </Button>
                </Col>
                <Col>
                  <Button small primaryText icon="restart_alt">
                    Test Again
                  </Button>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <AppDetails testAgainButton={setTestAgainFunc} />
      </Container>
    </Container>
  )
}

export default AppVitals
