/** Libraries */
import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form} from 'formik'

/** Store/Services */

/** Reusable Components */
import {
  Button,
  Container,
  Text,
  TextField,
} from 'Common/Components/Core/DesignTemplates'
import Modal from 'Common/Components/Hooks/Modal/Modal'

/** Helpers */
import {
  iEditUser,
  iManageAccessCreds,
} from 'Common/Components/Pages/ManageApp/ManageAccess/util'
import Regex from 'Constants/Regex'
import {MessageParams} from 'yup/lib/types'

/** Assets (CSS/Images) */

const userNames = [
  'ansible',
  'backup',
  'bin',
  'daemon',
  'Debian-exim',
  'games',
  'gnats',
  'irc',
  'libuuid',
  'list',
  'lp',
  'mail',
  'man',
  'memcache',
  'messagebus',
  'mysql',
  'newrelic',
  'news',
  'nobody',
  'ntp',
  'postfix',
  'proxy',
  'root',
  'sensu',
  'shellinabox',
  'sshd',
  'statd',
  'sync',
  'sys',
  'systeam',
  'systemd-bus-proxy',
  'systemd-network',
  'systemd-resolve',
  'systemd-timesync',
  'test',
  'uucp',
  'uuidd',
  'varnish',
  'varnishlog',
  'vnstat',
  'www-data',
  'cw-syncuser',
  'gcp',
  'gke',
  'pub',
  'sub',
]

const validate = Yup.object({
  username: Yup.string()
    .required('Please enter username')
    .trim()
    .min(6, 'User name must be atleast 6 characters.')
    .max(40, 'User Name must be less than 40 characters')
    .test(
      'is-42',
      "Special characters are not allowed except '-'",
      (value: string | undefined) => {
        if (value === undefined) return true
        const val = '!@#$%^&*()_+=[]{};\':"|,.<>/?]\\+'
          .split('')
          .some(element => value.includes(element))
        return !val
      },
    )
    .matches(/^([a-zA-Z\d]+(-[a-zA-Z\d]+)*)$/, 'Invalid Username')
    .matches(/^([a-z\d]+(-[a-z\d]+)*)$/, 'Capital letters are not allowed')
    .test(
      'username-include-check',
      (value: MessageParams) => `${value.value} is not allowed for username`,
      (value?: string) => {
        if (value) return !userNames.includes(value)
        return true
      },
    ),
  password: Yup.string()
    .required('Please enter password')
    .min(10, 'Password must be atleast 10 characters.')
    .max(40, 'Password must be less than 40 characters')
    .matches(
      Regex.generalPassword_regex.smallChar,
      'At least one small character',
    )
    .matches(
      Regex.generalPassword_regex.capsChar,
      'At least one capital character',
    )
    .matches(Regex.generalPassword_regex.number, 'At least one number')
    .matches(
      Regex.excludeSpecialChar,
      'Password should not contain any special character',
    ),
})

const EditSSHUser = (props: {
  show: boolean
  onClose: () => void
  handleCredSubmission: (
    values: iEditUser,
    {setSubmitting}: {setSubmitting: (value: boolean) => void},
  ) => void
  isUpdating: boolean
  appCredential?: iManageAccessCreds
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Modal show={props.show} classes="min-w-[507px]" onClose={props.onClose}>
      <Container styles="cwpf_manageaccess_edituser_modal">
        <Container styles="container">
          <Container styles="confirmuser_info">
            <Text type="h6" med styles="text-gray-80">
              Edit SSH/SFTP user
            </Text>
            <Container styles="cwpf_mangaeaccess_form mt-16">
              <Formik
                initialValues={{
                  username: props.appCredential?.username,
                  password: props.appCredential?.password,
                }}
                validationSchema={validate}
                onSubmit={props.handleCredSubmission}
              >
                {({dirty, isValid, values, isSubmitting}) => (
                  <Form>
                    <Container styles="cwpf_addAPP_form_inputBox mb-24">
                      <TextField
                        label="Username"
                        name="username"
                        type="text"
                        placeHolder="jenny Wilson"
                      />
                    </Container>
                    <Container styles="cwpf_addAPP_form_inputBox mb-24">
                      <TextField
                        label="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeHolder="******"
                        styles="cwpf_passowrd_field"
                        postfixIcon={
                          values.password !== '' && {
                            name: `${
                              showPassword ? 'visibility' : 'visibility_off'
                            }`,
                            handler: () => setShowPassword(prev => !prev),
                            styles: 'cwpf_password_icon text-gray-80',
                          }
                        }
                        showCopyIcon={values.password !== '' ? true : false}
                      />
                    </Container>
                    <Container styles="flex mt-[38px] mb-[32px]">
                      <Button
                        type="submit"
                        loading={props.isUpdating}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {props.isUpdating ? 'Saving ...' : 'Confirm'}
                      </Button>
                      <Button onClick={props.onClose} type="button" primaryText>
                        Cancel
                      </Button>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Container>
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}
export default EditSSHUser
