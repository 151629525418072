import {
  Col,
  Container,
  CopyClipboard,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

const Field = ({label, value, between = true}: any) => {
  return (
    <Container>
      <Text semi styles="p3 text-gray-80">
        {label}
      </Text>
      <Row middle between={between}>
        <Col>
          <Text med styles="text-gray-80 p3 break-all pr-10">
            {value}
          </Text>
        </Col>
        <Col>
          <Container styles="relative flex justify-end">
            <CopyClipboard
              noStyles
              styles={`text-gray-40 text-[14px] ${!between ? 'ml-16' : ''}`}
              tooltip={label}
              copyText={value}
            />
          </Container>
        </Col>
      </Row>
    </Container>
  )
}
export default Field
