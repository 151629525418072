/** Libraries */
import React, {useEffect, useState} from 'react'
import {TransitionGroup} from 'react-transition-group'
import {useTracking} from 'react-tracking'
import moment from 'moment'
import {useAppDispatch} from 'app/hooks'

/** Store/Services */
import {useGetAllApplicationsQuery} from '../../../../app/services'

/** Reusable Components */
import {Col, Container, Row, Text} from '../../Core/DesignTemplates/index'
import Pagination from './Pagination'
import AppListHeader from './AppHeader'
import Animation from 'Common/Components/Core/DesignTemplates/Animations/Animation'
import ApplicationListing from './AppView'
import {toggleDeleteModal} from 'Common/Components/Core/Modal/ModalSlice'

/** Helpers */
import {appListInterface, AppListTrackingObj, iShowCard} from './util'
import {ModalName} from 'Common/Components/Core/Modal/util'
import {
  AppStatus,
  OperationType,
} from 'Common/Components/Hooks/useOperationsPolling'

/** Assests (CSS/Images) */
import './ApplicationList.css'
import EmptyAppView from './AppView/EmptyAppView'
const ApplicationList = (): JSX.Element => {
  /** Local State */
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [postsPerPage] = useState<number>(10)
  const [listView, setListView] = useState<boolean>(true)
  const [selection, setSelection] = useState<appListInterface[]>([])
  const [headerCheck, setHeaderCheck] = useState<boolean>(false)
  const [showAppCard, setShowAppCard] = useState<iShowCard>({})
  const [sortData, setSortData] = useState<appListInterface[]>([])
  const [sortText, setSortText] = useState<string>('Date')

  /** === Ends === */
  const dispatch = useAppDispatch()
  /** Track Application Listing */
  const {Track, trackEvent} = useTracking<AppListTrackingObj>({
    attributes: {
      page: 'app_list',
      current_url: window.location.href,
    },
  })

  const {data, isLoading: isAppListLoading} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: !window.navigator.onLine,
    },
  )

  useEffect(() => {
    if (isAppListLoading) return
    setSortData(data?.results)
  }, [data, isAppListLoading])
  //
  ///for pagination
  // const indexOfLastPost = currentPage * postsPerPage
  // const indexOfFirstPost = indexOfLastPost - postsPerPage
  // const currentPosts = applisiting.slice(indexOfFirstPost, indexOfLastPost)sa

  //list and grid change handler
  const selectionHandler = (value: appListInterface, e: boolean) => {
    //when user unselect app
    if (!e) {
      const list = [...selection]
      const index = list?.findIndex(item => item.id == value.id)
      list.splice(index, 1)

      //if one of them is deselected make headercheck false
      list.length !== data?.results.length && setHeaderCheck(false)
      setSelection(list)
      return
    }
    //when user deselect app
    const list = [...selection]
    const NewVal = [...list, {...value}]
    //if all are selected make headercheck true
    NewVal.length === data?.results.length && setHeaderCheck(true)
    setSelection([...selection, {...value}])
  }

  const getCompletedApp = () => {
    let newData
    if (!isAppListLoading) {
      newData = data?.results.filter(
        (item: appListInterface) =>
          item?.operation?.operation_status === AppStatus.ENQUEUED ||
          item?.operation?.operation_status === AppStatus.RUNNING ||
          item?.operation?.operation_status === AppStatus.COMPLETED,
      )
    }
    return newData
  }

  //header functions
  const headerSelection = (e: {target: {checked: boolean}}) => {
    setHeaderCheck(e.target.checked)
    let newData
    if (!isAppListLoading) {
      newData = data?.results.filter(
        (item: appListInterface) =>
          item?.operation?.operation_status === AppStatus.COMPLETED,
      )
    }
    //
    e.target.checked ? setSelection(newData) : setSelection([])
  }
  const headerDeselectAll = () => {
    setHeaderCheck(false)
    setSelection([])
  }

  const multiDeleteHandler = () => {
    const id = Object.keys(selection).map(val => selection[Number(val)]['id'])
    if (id.length <= 1) {
      dispatch(
        toggleDeleteModal({
          type: ModalName.deleteAppModal,
          data: {name: selection[0]?.name, ids: [id]},
        }),
      )
      return
    }
    dispatch(
      toggleDeleteModal({
        type: ModalName.deleteAppModal,
        data: {ids: [...id]},
      }),
    )
  }

  //sort by date
  const sortDateHandler = (value: string) => {
    setSortText(value)
    //
    const sortData = [...data.results]
    const sortedData = sortData?.sort(
      (a: appListInterface, b: appListInterface) =>
        value === 'Date'
          ? moment(b?.created).diff(moment(a?.created))
          : a.name.localeCompare(b.name),
    )
    setSortData(sortedData)
  }

  const EmptyApp = (
    <Container styles="cwpf_appView_emptyWrap">
      <Text type="p" className="text-20">
        You do not have any application installed.
        <br />
        Please add an application to proceed
      </Text>
    </Container>
  )

  return (
    <Track>
      <div className="container">
        {isAppListLoading ? (
          <></>
        ) : (
          <>
            <AppListHeader
              headerSelection={headerSelection}
              setShowAppCard={setShowAppCard}
              sortText={sortText}
              sortDateHandler={sortDateHandler}
              viewHandler={() => {
                trackEvent({
                  event_name: 'fmui_app_view',
                  attributes: {
                    /** 
                     Condition may seem opposite but,
                     it's referenced to the state changes below 
                     */
                    view: listView ? 'grid' : 'list',
                  },
                })
                // State changes
                setListView(prev => !prev)
              }}
              view={listView}
              headerCheck={headerCheck}
              headerDeselectAll={headerDeselectAll}
              //totalApps={data !== undefined && data?.results.length}
              totalApps={data !== undefined && data?.count}
              selectedApp={selection.length}
              multiDeleteHandler={multiDeleteHandler}
            />
            {data?.count <= 0 ? (
              <EmptyAppView />
            ) : (
              <TransitionGroup>
                <Row
                  {...(listView ? {col: true} : {row: true, wrap: true})}
                  gutter={24}
                >
                  {sortData?.map((app: appListInterface, i: number) => (
                    <Animation key={app?.id} fadeIn timeout={500}>
                      <Col
                        lg={listView ? 12 : 3}
                        sm={listView ? 12 : 6}
                        styles="!transition-none"
                      >
                        <ApplicationListing
                          isListView={listView}
                          key={app.id}
                          app={app}
                          onChange={selectionHandler}
                          headerChecked={headerCheck}
                          selectedApp={selection.length}
                          showAppCard={showAppCard}
                          setShowAppCard={setShowAppCard}
                        />
                      </Col>
                    </Animation>
                  ))}
                </Row>
              </TransitionGroup>
            )}
          </>
        )}

        {data?.count > 10 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data?.count}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </Track>
  )
}

export default ApplicationList
