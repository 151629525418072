/** Libraries */
import React, {useState} from 'react'

/** Reusable Components */
import {
  Button,
  CommingSoonScreen,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import NewExclusionModal from 'Common/Components/Core/Modal/ManageAppModals/PerformanceModal/NewExclusionModal'
import CachingTable from './CachingTable'
import NoData from './NoData'

/** Assets (CSS/Images) */
import {ReactComponent as ChacheSettingImg} from '../../../../../../Assets/img/chache-setting.svg'
import {iCachingTable} from '../util'

const CachingSetting = () => {
  const [showModal, setShowModal] = useState(false)
  const [tempAppend, setTempAppend] = useState<{results: iCachingTable[]}>({
    results: [],
  })

  const RenderData =
    tempAppend.results.length !== 0 ? (
      <CachingTable data={tempAppend?.results} isLoading="" />
    ) : (
      <NoData />
    )

  const getValuesFromModal = (values: iCachingTable) => {
    setTempAppend(prev => {
      return {
        ...prev,
        results: [
          ...prev.results,
          {
            value: values.value,
            method: 'Exclude',
            type: values.type,
            id: prev.results.length + 1,
          },
        ],
      }
    })
  }

  return (
    <>
      <Container styles="relative">
        <CommingSoonScreen
          ImgComponent={ChacheSettingImg}
          heading="Cache Settings"
        />
        <Container styles="blur-sm px-12">
          <Row between middle styles="pt-20 hidden">
            <Text type="h5" semi styles="text-dark">
              Caching Setting
            </Text>
            <Button medium onClick={() => setShowModal(true)}>
              Add New Exclusion
            </Button>
          </Row>
          <div className="pt-18">{RenderData}</div>
        </Container>
        <NewExclusionModal
          parentHandler={getValuesFromModal}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      </Container>
    </>
  )
}

export default CachingSetting
