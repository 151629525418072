/** Libraries */
import {
  Button,
  Card,
  Col,
  Container,
  CommingSoonScreen,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import EnableBar from 'Common/Components/Core/DesignTemplates/EnableBar/EnableBar'

/** Assets (CSS/Images) */
import wordpressLogo from 'Assets/img/application-listing/wp-logo.svg'
import HealthIcon from 'Assets/img/manage-app/overview/Icons — Health.svg'
import PluginIcon from 'Assets/img/manage-app/overview/Icons — Plugin.svg'
import ThemeIcon from 'Assets/img/manage-app/overview/Icons — Theme.svg'
import {ReactComponent as wpUpdatesImg} from '../../../../../../Assets/img/wp-updates.svg'
import './WordPressUpdates.css'

const WordPressUpdates = () => {
  return (
    <Container styles="relative h-[100%] pb-10">
      <CommingSoonScreen
        ImgComponent={wpUpdatesImg}
        heading="WordPress Updates"
      />
      {/* <Row between styles="blur-sm">
        <Col>
          <Text type="h4" semi>
            WordPress Updates
          </Text>
        </Col>
      </Row> */}

      <Row stretch between gutter={24} styles="blur-sm cwpf_wpUpdates">
        <Col med={3} lg={3} sm={12}>
          <Card styles="px-[22px] py-[22px] flex flex-col gap-8 place-content-between pb-[16px] mt-10 h-full">
            <Row row between>
              <Text styles="text-16 md:text-[14px]" semi>
                WordPress Core
              </Text>{' '}
              <EnableBar styles="mr-0" success>
                Optium
              </EnableBar>
            </Row>
            <Row middle>
              <Col>
                <img
                  className={`mr-4 w-[40px] h-[40px] mb-[9px]`}
                  src={wordpressLogo}
                  alt={'worpress_logo'}
                />
              </Col>
              <Col styles="ml-[5px]">
                <Row row bottom>
                  <Text type="h1" semi styles="text-gray-80">
                    16
                  </Text>
                  <Text
                    type="h5"
                    semi
                    styles="relative top-[-7px] text-gray-80"
                  >
                    {' '}
                    / 24
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row middle>
              <Text styles="text-[14px] whitespace-nowrap">Click here to</Text>
              <Button small primaryText iconAlign="right" icon="chevron_right">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>

        <Col med={3} lg={3} sm={12}>
          <Card styles="px-[22px] py-[22px] flex flex-col gap-8 place-content-between pb-[16px]  mt-10 h-full">
            <Row row between>
              <Text styles="text-16 md:text-[14px]" semi>
                Theme Installed
              </Text>
            </Row>
            <Row middle>
              <Col>
                <img
                  className={`mr-4 w-[35px] h-[35px] mb-[7px]`}
                  src={ThemeIcon}
                  alt={'worpress_logo'}
                />
              </Col>
              <Col styles="ml-[8px] ">
                <Row row bottom>
                  <Text type="h1" semi styles="text-gray-80">
                    8
                  </Text>
                  <Text
                    type="h5"
                    semi
                    styles="relative top-[-7px] text-gray-80"
                  >
                    {' '}
                    / 20
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row middle>
              <Text styles="text-[14px] whitespace-nowrap">Click here to</Text>
              <Button small primaryText iconAlign="right" icon="chevron_right">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>

        <Col med={3} lg={3} sm={12}>
          <Card styles="px-[22px] py-[22px] flex flex-col gap-8 place-content-between pb-[16px] mt-10 h-full">
            <Row row between>
              <Text styles="text-16 md:text-[14px]" semi>
                Plugin Installed
              </Text>{' '}
              <EnableBar styles="mr-0" success>
                Optium
              </EnableBar>
            </Row>
            <Row middle>
              <Col>
                <img
                  className={`mr-4  w-[35px] h-[35px] mb-[7px]`}
                  src={PluginIcon}
                  alt={'worpress_logo'}
                />
              </Col>
              <Col styles="ml-[10px]">
                <Row row bottom>
                  <Text type="h1" semi styles="text-gray-80">
                    12
                  </Text>
                  <Text
                    type="h5"
                    semi
                    styles="relative top-[-7px] text-gray-80"
                  >
                    {' '}
                    / 24
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row middle>
              <Text styles="text-[14px] whitespace-nowrap">Click here to</Text>
              <Button small primaryText iconAlign="right" icon="chevron_right">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>

        <Col med={3} lg={3} sm={12}>
          <Card styles="px-[22px] py-[22px] flex flex-col gap-8 place-content-between pb-[16px] mt-10 h-full">
            <Row row between>
              <Text styles="text-16 md:text-[14px]" semi>
                WordPress Health
              </Text>{' '}
              <EnableBar styles="mr-0" error>
                Critical
              </EnableBar>
            </Row>
            <Row middle>
              <Col>
                <img
                  className={`mr-4 ml-[-3px] w-[45px] h-[45px] mb-[7px]`}
                  src={HealthIcon}
                  alt={'worpress_logo'}
                />
              </Col>
              <Col styles="ml-[2px]">
                <Row row bottom>
                  <Text type="h1" semi styles="text-gray-80">
                    48
                  </Text>
                  <Text
                    type="h5"
                    semi
                    styles="relative top-[-7px] text-gray-80"
                  >
                    {' '}
                    / 100
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row middle>
              <Text styles="text-[14px] whitespace-nowrap">Click here to</Text>
              <Button small primaryText iconAlign="right" icon="chevron_right">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default WordPressUpdates
