import { Button, Col, Container, Row, Separator, Text } from 'Common/Components/Core/DesignTemplates'
import RangeSlider from 'Common/Components/Hooks/RangeSlider/RangeSlider';
import React, { useState } from 'react'

const TotalVisits = () => {

  const [limitError, setLimitError] = useState(false);


    return (
        <>

            <Row row between middle styles="mt-40 mb-0 px-10">
                <Col>
                    <Text type="h5" semi>Bandwidth</Text>
                </Col>
                <Col>
                    <Text styles="text-primary" type="h1" semi>70K</Text>
                </Col>
            </Row>

            <Container
                styles={`${limitError && 'cwpf_slctPlan_sldrbox_error'
                    } cwpf_slctPlan_sldrBox mt-10 mb-[80px] px-6`}
            >
                <Container styles="cwpf_slctPlan_error_tooltip">

                    <RangeSlider
                        value={50}
                        max={70}
                        classes="cwpf_slctPlan_sldrInput w-[100%]"
                        data={[]}
                    />
                </Container>
            </Container>

            <Separator />

            <Row row between middle styles="mt-30  mb-[50px] px-10">
                <Col>
                    <Text>Current visits per Month</Text>
                </Col>
                <Col>
                    <Text semi>12000</Text>
                </Col>
            </Row>

            <Row col>
                <Button iconAlign="right" icon="arrow_forward">Confirm</Button>
            </Row>

            <Row styles="mt-12" col>
                <Button primaryOutline>Cancel</Button>
            </Row>
        </>
    )
}

export default TotalVisits