import React, {useState, useEffect} from 'react'
import {useAppDispatch} from 'app/hooks'
import {AppDispatch} from 'app/store'
import {setIsOnline} from '../Core/NoConnection/NoConnectionslice'

const useConnection = () => {
  //Dispatch Selected Plan
  const dispatch: AppDispatch = useAppDispatch()

  useEffect(() => {
    window.addEventListener('offline', () => {
      dispatch(setIsOnline(false))
    })
    window.addEventListener('online', () => {
      dispatch(setIsOnline(true))
    })

    return () => {
      window.removeEventListener('offline', () => {
        dispatch(setIsOnline(false))
      })
      window.removeEventListener('online', () => {
        dispatch(setIsOnline(true))
      })
    }
  }, [])
}

export default useConnection
