/** Libraries */
import React, {useEffect, useRef, useState} from 'react'

/** Reusable Components */
import {
  Card,
  Row,
  Col,
  Text,
  Icon,
} from 'Common/Components/Core/DesignTemplates'
import {iAppDetailCard} from '../util'
import {iOperationSteps} from 'Constants/Interfaces/iGlobal'

const opreationPerameters: {[key: string]: string} = {
  db: 'Database',
  wp: 'WordPress',
  sshd: 'SSH',
  redis: 'Redis',
  object_cache: 'OCP Plugin',
}

const AppDetailCard = ({
  handler,
  operationData,
  app,
}: iAppDetailCard): JSX.Element => {
  const completedSteps = operationData?.steps?.filter(
    (eachStep: {step_status: string}) => eachStep.step_status === 'completed',
  )
  const progressBar =
    completedSteps &&
    operationData?.steps &&
    (completedSteps?.length / operationData?.steps?.length) * 100

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler()
      }
    }

    document.addEventListener('click', handleOutside, true)
    return () => {
      document.removeEventListener('click', handleOutside, true)
    }
  }, [ref, handler])
  return (
    <>
      <Card styles="cwpf_listingItem_card" ref={ref}>
        <progress
          className="progress w-full progress-primary absolute left-0 top-0 transition-all"
          max="100"
          value={progressBar?.toString()}
        ></progress>
        <Row between>
          <Col>
            <Text styles="p3 text-gray-40 capitalize hidden">
              {app?.app_type?.name}
            </Text>
            <Text semi styles="p2 text-gray-100">
              {app?.name}
            </Text>
            <Text styles="p3 text-gray-100">
              Your application is being installed
            </Text>
          </Col>
          <Col>
            <Icon name="close" styles="text-gray-60" onClick={handler}></Icon>
          </Col>
        </Row>
        <ul className="mt-16 cwpf_listingapp_card">
          {operationData?.steps?.map((val: iOperationSteps) => (
            <>
              <li className={`p3-med text-gray-80 cwpf_steps_card`}>
                {val.step_status === 'completed' ? (
                  <Icon
                    name="check_circle"
                    styles="text-16 text-green-100 mr-10 relative left-[-2px]"
                  ></Icon>
                ) : (
                  <span className="cwpf_installList_loader"></span>
                )}
                {opreationPerameters[val.name]}
              </li>
            </>
          ))}
        </ul>
      </Card>
    </>
  )
}

export default AppDetailCard
