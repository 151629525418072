import * as Yup from 'yup'
import {Formik, Form} from 'formik'
//modals
import Modal from 'Common/Components/Hooks/Modal/Modal'

//design system
import {
  Container,
  Text,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  Separator,
} from 'Common/Components/Core/DesignTemplates'

//components
import Field from './Field'

const TxtRecordModal = ({show, onClose, customDomain}: any) => {
  const name: string[] = []
  const value: string[] = []
  customDomain?.txt_records &&
    Object.keys(customDomain?.txt_records).forEach(key => {
      name.push(key)
      value.push(customDomain?.txt_records[key])
    })
  const ModalAccordion = (
    <Accordion
      title={
        <Text styles="p2 text-blue-100">
          Does your DNS provider require you add root domain to your host names
          ?
        </Text>
      }
    >
      <Text styles="text-gray-60 mb-16 p2">
        Please use these details instead
      </Text>
      <Text bold styles="p2 text-gray-80 mb-14">
        TXT Record 1
      </Text>
      <Field label="Name /Host" value={name.length > 0 ? name[0] : ''} />
    </Accordion>
  )
  return (
    <Modal
      show={show}
      classes="min-w-[507px] overflow-y-translate cwpf_domainOwnership_modal"
      onClose={onClose}
    >
      <Container styles="py-32 px-24">
        <Text styles="text-gray-80 " med type="h6">
          Verify ownership of {customDomain?.domain}
        </Text>
        <Text type="p" styles="p3 py-20 text-gray-60">
          To enable Cloudflare services, you will need to verify the ownership
          of the domain by adding the TXT records in your DNS management service
          (this could either be your domain registrar or a separate DNS
          service). Once you have added the TXT records in your DNS management
          service.
        </Text>
        <Card styles="rounded-[0px] p-[0] ">
          <Row wrap>
            <Col lg={6} sm={12} md={12} styles="p-14 pb-[0px]">
              <Container styles="pb-14">
                <Text bold styles="p2 text-gray-80 mb-14">
                  TXT Record 1
                </Text>
                <Field
                  border
                  label="Name /Host"
                  value={name.length > 0 ? name[0] : ''}
                />
              </Container>
              <Separator styles="bg-gray-10"></Separator>
            </Col>
            <Col
              lg={6}
              sm={12}
              md={12}
              styles="border-l border-l-gray-10 p-14 pb-[0px]"
            >
              <Container styles="pb-14 ">
                <Text bold styles="p2 text-gray-80 mb-14">
                  TXT Record 2
                </Text>
                <Field
                  border
                  label="Name /Host"
                  value={name.length > 0 ? name[1] : ''}
                />
              </Container>
              <Separator styles="bg-gray-10"></Separator>
            </Col>
            <Col lg={6} sm={12} md={12} styles="p-14">
              <Field label="Value" value={value.length > 0 ? value[0] : ''} />
            </Col>
            <Col lg={6} sm={12} md={12} styles="p-14 border-l border-l-gray-10">
              <Field label="Value" value={value.length > 0 ? value[1] : ''} />
            </Col>
          </Row>
        </Card>
        <Container styles="mt-20 bg-gray-3">{ModalAccordion}</Container>
        <Button onClick={onClose} primary medium styles="mt-32 text-gray-80">
          I Have Copied These Records
        </Button>
      </Container>
    </Modal>
  )
}
export default TxtRecordModal
