import {useAppSelector} from '../app/hooks'
import {useRoutes} from 'react-router-dom'
import {isUserAuthenticated} from '../features/login/loginSlice'
import privateRoutes from '../Routes/PrivateRoutes'

const Index = (currentPlan: any) => {
  const isLoggedIn = useAppSelector(isUserAuthenticated)
  const authRoutes = useRoutes(privateRoutes(isLoggedIn, currentPlan))
  return authRoutes
}

export default Index
