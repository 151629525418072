import {
  useIntelligenceLogsCountQuery,
  useIntelligenceLogsQuery,
} from 'app/services'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import useInfiniteScroll from 'Common/Components/Hooks/useInfiniteScroll'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import EmptyLog from './UpdatedLogs/EmptyLog'
import UpdateLog from './UpdatedLogs/UpdatedLog'

const AccessLogs = () => {
  const {app_id} = useParams()
  //const [t, setT] = useState<any>(null)
  const {
    lastElementRef: accessRef,
    isFetching: accessIsFetching,
    isLoading: accessLoading,
    refresh: accessRefresh,
    combinedData: AccessData,
    hasMore: accessHasMore,
  } = useInfiniteScroll(useIntelligenceLogsQuery, {
    limit: 10,
    app_id,
    type: 'access-logs',
  })
  useEffect(() => {
    if (app_id) {
      accessRefresh()
    }
  }, [app_id])
  const refreshHandler = () => {
    accessRefresh()
  }
  // const onChange = (text: string) => {
  //   if (t) clearTimeout(t)
  //   setT(
  //     setTimeout(() => {
  //       accessRefresh(text)
  //     }, 2000),
  //   )
  // }
  return (
    <UpdateLog
      lastElementRef={accessRef}
      isFetching={accessIsFetching}
      refresh={accessRefresh}
      combinedData={AccessData}
      hasMore={accessHasMore}
      isLoading={accessLoading}
    />
  )
}

export default AccessLogs
