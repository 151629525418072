import React, {useEffect, useState} from 'react'
import DashBoard from '../../Common/Components/Pages/Dashboard/Dashboard'
import {Outlet} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import FullPageErrorFallBack from 'Common/Components/Core/ErrorFallbacks/FullPageErrorFallback'
import {apiSlice, useGetAllApplicationsQuery} from 'app/services'
import useOperationsPolling, {
  AppStatus,
} from 'Common/Components/Hooks/useOperationsPolling'
import {useAppDispatch} from 'app/hooks'
import Operation from './Operation'
import PureWrapper from './PureWrapper'

const ProtectedRoute = () => {
  const {data: appData} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )
  return (
    <>
      {appData?.results?.map((app: any) =>
        app?.operations.map(
          (operation: any) =>
            [AppStatus.RUNNING, AppStatus.ENQUEUED].includes(
              operation?.operation_status,
            ) && <Operation operation={operation} app={app} />,
        ),
      )}
      <DashBoard />
      <main className="min-h-[100vh] md:pl-48 pt-[48px]">
        <ErrorBoundary FallbackComponent={FullPageErrorFallBack}>
          <PureWrapper>
            <Outlet />
          </PureWrapper>
        </ErrorBoundary>
      </main>
    </>
  )
}

export default ProtectedRoute
