import {Form, Formik, FormikHelpers} from 'formik'
import React from 'react'
import Modal from '../../../../Hooks/Modal/Modal'
import * as Yup from 'yup'
import Regex from 'Constants/Regex'
import {
  Button,
  Col,
  Row,
  Text,
  TextField,
} from 'Common/Components/Core/DesignTemplates'
import './EditApplicationName.css'
import {useParams} from 'react-router-dom'
import {useUpdateApplicationMutation} from 'app/services'
import {values} from 'lodash'

interface iUpdateName {
  name: string
}
const initialValues: iUpdateName = {
  name: '',
}

const EditApplicationNameModal = ({show, onClose, onConfirm}: any) => {
  const {app_id} = useParams()
  const [updateApplication] = useUpdateApplicationMutation()

  //validations
  const validate = Yup.object({
    name: Yup.string()
      .required('Please Name your Application')
      .min(3, 'Application Name must be greater than 3 characters.')
      .max(40, 'Application Name must be less than 40 characters')
      .matches(Regex.appName_regex, 'Application Name is not valid'),
  })

  //form submit handler
  const onSubmit = (
    values: iUpdateName,
    {setSubmitting}: FormikHelpers<{name: string}>,
  ) => {
    updateApplication({
      app_id: app_id,
      body: {
        name: values.name,
      },
    })
    onClose()

    // onConfirm(values.name)
    // return
  }

  return (
    <Modal show={show} onClose={onClose} classes="cwpf_editAppName_modal">
      <Text styles="mb-30" type="h5" med>
        Edit Application Name
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          /* and other goodies */
        }) => (
          <Form>
            <TextField
              label="Application Name"
              name="name"
              type="text"
              labelStyle="p2"
              placeHolder="Enter a title for your application"
            />

            <Row styles="mt-30" gutter={16}>
              <Col>
                <Button type="submit" medium>
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button type="button" primaryText medium onClick={onClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditApplicationNameModal
