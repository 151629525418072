/** Libraries */
import React, {useEffect} from 'react'
import {DatePicker} from 'antd'
import {Form, Formik, FormikHelpers} from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

/** Store/Services */
import {
  useGetAllApplicationsQuery,
  useGetMonitoringStatsQuery,
  useGetResourceBandwidthQuery,
} from 'app/services'

/** Reusable Components */
import {Col, Container, Row} from 'Common/Components/Core/DesignTemplates'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import PieChart1 from '../Charts/PieChartVisits'
import PieChart2 from '../Charts/XYChartVIsits'
import LoadingState from './LoadingState'

/** Helpers */
import {EpochToDate, niceBytes} from 'Common/Components/Core/Modal/util'
import addDaysToGraphData from './util/addDaysToGraphData'
import EmptyStates from './EmptyStates'

interface iMtdOpitons {
  mtd_options: any
}

const initialValues: iMtdOpitons = {
  mtd_options: '',
}

const BanndWidthTab = ({
  everythingSummary,
  isEverythingSummaryLoading,
  isEverythingDetailsLoading,
}: any) => {
  const {data, isLoading} = useGetResourceBandwidthQuery()
  const {data: bandwidthDatapoints, isLoading: isBandwidthDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'bandwidth',
    })

  const appNames: Array<string> = []

  // useEffect(() => {
  //   if (bandwidthDetailsLoading) return

  //   bandwidthDetails?.map((val: any) => {

  //   })
  // }, [bandwidthDetailsLoading])

  //validations
  const validate = Yup.object({
    mtd_options: Yup.string().required('Please select an option.'),
  })
  const initialValues: iMtdOpitons = {
    mtd_options: '',
  }
  const onSubmit = (
    values: iMtdOpitons,
    {setSubmitting}: FormikHelpers<iMtdOpitons>,
  ) => {
    return
  }

  const {RangePicker} = DatePicker

  if (
    isEverythingSummaryLoading ||
    isBandwidthDatapointsLoading ||
    isEverythingDetailsLoading
  )
    return <LoadingState />
  return (
    <>
      {everythingSummary?.allocated_bandwidth === 0 ||
      bandwidthDatapoints === undefined ||
      bandwidthDatapoints?.application?.length === 0 ? (
        <EmptyStates />
      ) : (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className="flex justify-end mt-24 pr-24 cwpf_dashboard_datepicker">
                <Tooltip text="Coming Soon!">
                  <RangePicker disabled />
                </Tooltip>
              </Form>
            )}
          </Formik>
          <Container>
            <>
              <Row between styles="cwpf_dashboard_chart">
                <Col>
                  <PieChart2
                    datapoints={
                      bandwidthDatapoints?.data?.map((eachPoint: any) => {
                        return {
                          date: new Date(eachPoint.Date),
                          value: eachPoint.BandwidthSum / 9000999,
                          formattedValue: niceBytes(
                            eachPoint.BandwidthSum,
                            0,
                            true,
                            2,
                          ),
                          formattedDate: moment(eachPoint.Date).format(
                            'DD MMMM, YYYY ',
                          ),
                          utcTime: moment(eachPoint.Date).format('HH:MM UTC'),
                        }
                      }) || []
                    }
                    title="hello"
                    tooltipTitle="Bandwith Usage: "
                  />
                </Col>
                <Col>
                  <PieChart1
                    Legendtitle="Bandwith Breakdown"
                    Title="Total Bandwidth"
                    space={everythingSummary?.bandwidth_total_usage || 0}
                    totalspace={everythingSummary?.allocated_bandwidth || 0}
                    apps={
                      bandwidthDatapoints?.application?.map((eachApp: any) => ({
                        value: eachApp?.BandwidthSum,
                        name: eachApp.appName,
                      })) || []
                    }
                    type="space"
                  />
                </Col>
              </Row>
            </>
          </Container>
        </div>
      )}
    </>
  )
}

export default React.memo(BanndWidthTab)
