/** Libraries */
import React from 'react'
import {DatePicker} from 'antd'
import {Form, Formik, FormikHelpers} from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

/** Store/Services */
import {
  useGetCurrentPlanQuery,
  useGetResourceBandwidthQuery,
  useGetResourceDiskSpaceQuery,
  useGetResourceVisitsQuery,
} from 'app/services'

/** Reusable Components */
import {
  Col,
  CommingSoonScreen,
  Container,
  Row,
} from 'Common/Components/Core/DesignTemplates'
import {ReactComponent as resourcesImg} from 'Assets/img/resources.svg'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import PieChart1 from '../Charts/PieChartVisits'
import PieChart2 from '../Charts/XYChartVIsits'
import LoadingState from './LoadingState'

/** Helpers */
import {
  EpochToDate,
  niceBytes,
  niceBytesDiskspace,
} from 'Common/Components/Core/Modal/util'
import addDaysToGraphData from './util/addDaysToGraphData'
import EmptyStates from './EmptyStates'

interface iMtdOpitons {
  mtd_options: any
}

const initialValues: iMtdOpitons = {
  mtd_options: '',
}

const DiskSpaceTab = () => {
  const {data, isLoading} = useGetResourceDiskSpaceQuery()
  const {data: currentPlan} = useGetCurrentPlanQuery()

  //validations
  const validate = Yup.object({
    mtd_options: Yup.string().required('Please select an option.'),
  })
  const initialValues: iMtdOpitons = {
    mtd_options: '',
  }
  const onSubmit = (
    values: iMtdOpitons,
    {setSubmitting}: FormikHelpers<iMtdOpitons>,
  ) => {
    return
  }

  const {RangePicker} = DatePicker

  if (isLoading) return <LoadingState />

  return (
    <>
      {data?.total_used_space === 0 ||
      data === undefined ||
      data?.apps?.length === 0 ? (
        <EmptyStates />
      ) : (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className="flex justify-end mt-24 pr-24 cwpf_dashboard_datepicker">
                <Tooltip text="Coming Soon!">
                  <RangePicker disabled />
                </Tooltip>
              </Form>
            )}
          </Formik>
          <>
            <Row between styles="cwpf_dashboard_chart">
              {/* <Row between styles="blur-sm">
          <Col>
            <Text type="h4" semi>
              WordPress Updates
            </Text>
          </Col>
        </Row> */}
              <Container styles="relative mb-60 ml-30">
                <CommingSoonScreen
                  ImgComponent={resourcesImg}
                  heading="Resources : "
                  detail="DiskSpace"
                />
                <Col styles="blur-sm">
                  <PieChart2
                    datapoints={addDaysToGraphData(
                      data?.datapoints?.map((eachPoint: any) => {
                        return {
                          date: EpochToDate(eachPoint?.epoch),
                          value: eachPoint?.value,
                          formattedValue: niceBytesDiskspace(eachPoint?.value),
                          formattedDate: moment(
                            EpochToDate(eachPoint?.epoch),
                          ).format('DD MMMM, YYYY '),
                          utcTime: moment(EpochToDate(eachPoint?.epoch)).format(
                            'HH:MM UTC',
                          ),
                        }
                      }) || [],
                      2,
                    )}
                    title="hello"
                    tooltipTitle="Disk Space Used: "
                  />
                </Col>
              </Container>
              <Col>
                <PieChart1
                  Legendtitle="Disk Space Breakdown"
                  Title="Total Usage"
                  space={data?.total_used_space || 0}
                  totalspace={parseInt(currentPlan?.plan?.storage_gb) || 0}
                  apps={
                    data?.apps?.map((eachApp: any) => ({
                      value: eachApp?.used_space,
                      name: eachApp.name,
                    })) || []
                  }
                  type="space"
                />
              </Col>
            </Row>
          </>
        </div>
      )}
    </>
  )
}

export default React.memo(DiskSpaceTab)
