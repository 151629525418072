/** Libraries */
import {useLocation} from 'react-router-dom'
/** Reusable Components */
import {Container, Tabs} from 'Common/Components/Core/DesignTemplates'
import AddDatabase from './Database'
import DomainManagementAdd from './DomainManagement'
import SSH from './SSH'
/** Helpers */
import {MANAGE_ACCESS_SUB_TABS, propState} from './util'
const SideBar = () => {
  const location = useLocation()
  const {tab} = (location.state as propState) || {}
  return (
    <Container styles="container">
      <div className="mt-[60px] max-md:flex-col max-md:mt-80 max-md:items-center">
        <Tabs
          vertical
          containerStyle="max-sm:flex-col max-md:flex-col max-md: gap-[60px] max-sm:gap-[60px]"
          mainEvent={'fmui_manage_access_tab'}
          defaultActive={tab}
        >
          <div id="SSH/SFTP" aria-label={MANAGE_ACCESS_SUB_TABS.SSH_SFTP}>
            <SSH />
          </div>
          <div
            id="Domain Management"
            aria-label={MANAGE_ACCESS_SUB_TABS.DOMAIN_MANAGEMENT}
          >
            <DomainManagementAdd />
          </div>
          <div id="Database" aria-label={MANAGE_ACCESS_SUB_TABS.DATABASE}>
            <AddDatabase />
          </div>
        </Tabs>
      </div>
    </Container>
  )
}
export default SideBar
