/** Libraries */
import React, {useState, useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

const PieChart2 = ({datapoints = []}: {datapoints?: any}) => {
  const chartRef = useRef<any>(null)
  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv3', am4charts.XYChart)
      chartRef.current.width = am4core.percent(100)
      chartRef.current.height = am4core.percent(100)

      const dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis())
      const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis())

      dateAxis.renderer.grid.template.location = 0
      valueAxis.renderer.grid.template.location = 0

      dateAxis.renderer.ticks.template.disabled = true
      valueAxis.renderer.ticks.template.disabled = true
      valueAxis.renderer.grid.template.disabled = true
      valueAxis.renderer.grid.template.strokeOpacity = 1
      valueAxis.renderer.grid.template.stroke = am4core.color('#D8D8D8')
      dateAxis.renderer.labels.template.disabled = true
      valueAxis.renderer.labels.template.disabled = true
      dateAxis.renderer.grid.template.strokeWidth = 0
      valueAxis.renderer.grid.template.strokeWidth = 0
      const series = chartRef.current.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'date'
      series.name = 'Series #1'
      series.tooltip = new am4core.Tooltip()
      series.tooltip.getFillFromObject = false
      series.tooltip.pointerOrientation = 'vertical'
      valueAxis.cursorTooltipEnabled = false
      dateAxis.cursorTooltipEnabled = false
      series.tooltip.disabled = true
      series.strokeWidth = 2
      series.tensionX = 0.8
      series.stroke = am4core.color('#B764D8')
      dateAxis.renderer.line.strokeOpacity = 0

      valueAxis.renderer.line.strokeOpacity = 0
      const bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.zIndex = 9999
      bullet.circle.stroke = am4core.color('#fff')
      bullet.strokeOpacity = 0
      bullet.circle.disabled = true
      bullet.stroke = am4core.color('#2F4858')
      bullet.strokeWidth = 1

      // Make square drop shadow by adding a DropShadow filter
      valueAxis.renderer.baseGrid.disabled = true

      const bulletState = bullet.states.create('hover')
      bulletState.properties.fillOpacity = 1
      bulletState.properties.strokeOpacity = 1
      //   return series
      // }
      // createSeries('value', 'Series #1')
      chartRef.current.cursor = new am4charts.XYCursor()
      chartRef.current.cursor.lineY.disabled = true
      chartRef.current.cursor.lineX.disabled = true
    }
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])
  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = datapoints
    }
  }, [datapoints])
  return (
    <>
      <div
        id="chartdiv3"
        // style={{width: '132px', height: '75px'}}
      ></div>
    </>
  )
}
export default PieChart2
