import {Icon} from 'Common/Components/Core/DesignTemplates'
import React from 'react'

const Pagination = ({
  postsPerPage,
  totalPosts,
  setCurrentPage,
  currentPage,
}: {
  postsPerPage: number
  totalPosts: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
}) => {
  const pageNumbers = []
  const paginate = (pageNumber: number | null, val: string) => {
    if (val === 'prev') {
      setCurrentPage(currentPage - 1)
      return
    }
    if (val === 'next') {
      setCurrentPage(currentPage + 1)
      return
    }
    if (pageNumber) setCurrentPage(pageNumber)
  }

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav className="flex justify-end items-center">
      <ul className="flex justify-end space-x-8 mt-10 mb-60">
        <li
          className={`border-gray-10 text-blue-100  border-solid border rounded-4 w-32 h-32 flex items-center justify-center cursor-pointer ${
            currentPage === 1 ? 'text-gray-20' : ''
          }`}
          onClick={() => currentPage !== 1 && paginate(null, 'prev')}
        >
          <Icon>chevron_left</Icon>
        </li>
        {pageNumbers.map(number => (
          <li
            key={number}
            className={`border-gray-10 text-dark border-solid border rounded-4 w-32 h-32 flex items-center justify-center cursor-pointer ${
              currentPage === number && 'border-blue-100 text-blue-100'
            }`}
            onClick={() => paginate(number, '')}
          >
            {number}
          </li>
        ))}
        <li
          className={`border-gray-10 text-blue-100 border-solid border rounded-4 w-32 h-32 flex items-center justify-center cursor-pointer ${
            currentPage === Math.ceil(totalPosts / postsPerPage)
              ? 'text-gray-20'
              : ''
          }`}
          onClick={() =>
            currentPage !== Math.ceil(totalPosts / postsPerPage) &&
            paginate(null, 'next')
          }
        >
          <Icon>chevron_right</Icon>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
