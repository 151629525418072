/** Libraries */
import {useAppDispatch} from 'app/hooks'
import {useState} from 'react'

/** Reusable Components */
import {Card, Icon, Toggle} from 'Common/Components/Core/DesignTemplates'
import NewExclusionModal from 'Common/Components/Core/Modal/ManageAppModals/PerformanceModal/NewExclusionModal'
import {toggleDeleteModal} from 'Common/Components/Core/Modal/ModalSlice'

/** Helpers */
import {ModalName} from 'Common/Components/Core/Modal/util'

const CachingTable = (props: any) => {
  const dispatch = useAppDispatch()

  const {data: cache, ...rest} = props
  const [editModal, setEditModal] = useState(false)
  const [data, setData] = useState({})

  const showEditHandler = (val: any) => {
    setData(val)
    setEditModal(true)
  }
  return (
    <>
      <Card styles="overflow-x-auto p-0">
        <table className="table w-full cwpf_caching-table ">
          <thead>
            <tr className="text-gray-80">
              <th className="w-[30%] semi pl-24 p1">Type</th>
              <th className="w-[30%] semi  p1">Method</th>
              <th className="w-[20%] semi p1">Value</th>
              <th className="w-[15%] semi p1">Enable</th>
              <th className=" w-[5%] semi pr-24 p1">Actions</th>
            </tr>
          </thead>
          <tbody>
            {cache.map((item: any, i: number) => (
              <tr className="text-gray-80 " key={item.id}>
                <td className="w-[30%] p1 pl-28">{item.type}</td>
                <td className="w-[30%] p1">{item.method}</td>
                <td className="w-[20%] p1">{item.value}</td>
                <td className="w-[15%] p1">
                  <Toggle index={i} />
                </td>
                <td className="w-[5%] pr-24">
                  <Icon
                    styles="mr-10 text-gray-40 "
                    onClick={() =>
                      showEditHandler({type: item.type, value: item.value})
                    }
                  >
                    edit
                  </Icon>
                  <Icon
                    styles="text-gray-40  "
                    onClick={() =>
                      dispatch(
                        toggleDeleteModal({
                          type: ModalName.deleteModal,
                          id: item.id,
                        }),
                      )
                    }
                  >
                    delete
                  </Icon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <NewExclusionModal
        show={editModal}
        onClose={() => setEditModal(false)}
        name="www.thebabystore.com"
        data={data}
      />
    </>
  )
}

export default CachingTable
