import React from 'react'

//design system
import {
  Card,
  Container,
  Text,
  Row,
  Col,
  Accordion,
  Icon,
  Separator,
  Button,
} from 'Common/Components/Core/DesignTemplates'

//components
import Field from './Field'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {useGetCloudfareApisQuery} from 'app/services'

const DomainARecordModal = (props: any) => {
  const {data} = useGetCloudfareApisQuery()
  const ModalAccordion = (
    <Accordion
      title={
        <>
          <Row between>
            <Col>
              <Icon name="info_outline" styles="text-blue-100" />
            </Col>
            <Col>
              <Text styles="p3 text-gray-100 ml-12 pr-30">
                If your DNS provider accepts only fully qualified domains in the
                host or name field, then please complete your hostname by adding
                your root domain to the end of it. For example, instead of using
                @, use {data?.cloudflare_alias}
              </Text>
            </Col>
          </Row>
        </>
      }
    >
      <Container styles="ml-36 mt-12">
        <Field label="Name /Host" value={data?.cloudflare_alias} />
      </Container>
    </Accordion>
  )
  return (
    <Modal
      show={props.show}
      classes="min-w-[650px] cwpf_domaincloudfaremodal"
      onClose={props.onClose}
    >
      <Container styles="py-24 px-32">
        <Text type="h6" semi styles="text-gray-80">
          Point your domain to Cloudflare
        </Text>
        <Container styles="my-12">
          <Text styles="p3">
            We have successfully verified the ownership of your domain. Go to
            the DNS management service to point the domain to Cloudflare
            Enterprise Network. The DNS integration can be successfully
            configured using either A record shown. However, we highly recommend
            configuring your DNS using both A records to ensure you are able to
            take full advantage of our Cloudflare load network balancing
            configuration.
          </Text>
          <Text styles="p3 mt-4">
            If you already have a corresponding A, AAAA or CNAME record with the
            given Name/Host, you should remove them and add the one share below:
          </Text>
        </Container>
        <Card>
          <Row top>
            <Col lg={3}>
              <Text semi styles="p2 text-gray-80">
                A Records
              </Text>
            </Col>
            <Col lg={9}>
              <Row wrap>
                <Col lg={12}>
                  <Field label="Name /Host" value={props?.domain} />
                </Col>
                <Separator styles="my-12 text-gray-10" h={1} />
                <Col lg={12}>
                  <Row bottom>
                    <Col lg={6}>
                      <Field
                        label="Value"
                        value={data?.cloudflare_ips[0]}
                        between={false}
                      />
                    </Col>
                    <Col lg={6}>
                      <Field
                        label="Value"
                        value={data?.cloudflare_ips[1]}
                        between={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Text styles="p3 text-gray-60 my-20">
          In addition to configuring the A records for your root domain, we
          recommend pointing the www version of your domain to our Cloudflare
          Enterprise as well. To do this, add or edit a CNAME record for your
          www domain pointing to your root domain.
        </Text>
        <Container styles="mt-20 bg-gray-3">{ModalAccordion}</Container>
        <Button primary medium styles="mt-32" onClick={props.onClose}>
          Done
        </Button>
      </Container>
    </Modal>
  )
}

export default DomainARecordModal
