/** Libraries */
import {useState} from 'react'

/** Reusable Components */
import {
  Button,
  Card,
  Col,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import EnableBar from 'Common/Components/Core/DesignTemplates/EnableBar/EnableBar'
import DummyModal from 'Common/Components/Core/Modal/ManageAppModals/PerformanceModal/DummyModal'

const filter = (percent: number) => {
  if (percent <= 49 && percent >= 0) {
    return 'progress-warning'
  } else if (percent >= 50 && percent <= 89) {
    return 'progress-error'
  } else if (percent >= 90 && percent <= 100) {
    return 'progress-secondary'
  }
}
const AppDetailsItem = ({
  name,
  percent,
  icon,
}: {
  name: string
  percent: number
  icon: string
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const color = filter(percent)

  return (
    <>
      <Card styles="py-16 px-20">
        <Row col start>
          <Col>
            <Row>
              <Text type="h6" semi styles="pb-[18px] text-gray-100 flex">
                {name}
              </Text>
              <EnableBar success>Optimum</EnableBar>
            </Row>
          </Col>
          <Col>
            <Text type="h1" bold styles="text-dark pb-8">
              <Icon styles="text-gray-80 mr-8 relative top-4 text-40">
                {icon}
              </Icon>
              {percent}
            </Text>
          </Col>
          <Col>
            <Col>
              <progress
                className={`progress text-green-100 ${color}`}
                value={percent}
                max="100"
              ></progress>
            </Col>
            <Row middle>
              <Text styles="p3 text-gray-80">Click here to</Text>
              <Button
                primaryText
                icon="navigate_next"
                small
                styles="p2 self-start pl-0 text-blue-100 ml-4"
                onClick={() => setShowModal(true)}
              >
                Learn More
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      <DummyModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default AppDetailsItem
