/** Libraries */
import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  toastList: [],
}
const ToastSlice = createSlice({
  name: 'toastSlice',
  initialState,

  reducers: {
    toastSuccess: (state, action) => {
      const id = Math.floor(Math.random() * 101 + 1)

      return {
        toastList: [
          ...state.toastList,
          {
            id,
            description: action.payload,
            backgroundColor: '#09EBAF',
            fontColor: '#253858',
            icon: 'check_circle_outline',
          },
        ],
      }
    },
    toastError: (state, action) => {
      const id = Math.floor(Math.random() * 101 + 1)

      return {
        toastList: [
          ...state.toastList,
          {
            id,
            description: action.payload,
            fontColor: '#fff',
            backgroundColor: '#EA4545',
            icon: 'error_outline',
          },
        ],
      }
    },
    toastInfo: (state, action) => {
      const id = Math.floor(Math.random() * 101 + 1)

      return {
        toastList: [
          ...state.toastList,
          {
            id,
            description: action.payload,
            fontColor: '#ffff',
            backgroundColor: '#5961CC',
            icon: 'error_outline',
          },
        ],
      }
    },
    toastWarning: (state, action) => {
      const id = Math.floor(Math.random() * 101 + 1)

      return {
        toastList: [
          ...state.toastList,
          {
            id,
            description: action.payload,
            fontColor: '#253858',
            backgroundColor: '#FCE039',
            icon: 'warning_amber',
          },
        ],
      }
    },
    deleteToast: (state, action) => {
      const listItemIndex = state.toastList.findIndex(
        (e: any) => e.id === action.payload,
      )
      state.toastList.splice(listItemIndex, 1)
    },
  },
})

//export const modalState = (state: RootState) => state.modalState.showModal
export const {toastSuccess, toastError, toastInfo, toastWarning, deleteToast} =
  ToastSlice.actions

export default ToastSlice.reducer
