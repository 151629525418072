import React, {useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import './Popup.css'

interface PopUpProps {
  parentRef: React.MutableRefObject<any>
  onClose: (props?: any) => void
  children: React.ReactNode
  styles?: string
  closeOnClickInside?: boolean
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  topLeft?: boolean
  topRight?: boolean
  bottomLeft?: boolean
  bottomRight?: boolean
  id?:string
}

const Popup = (props: PopUpProps) => {
  const location=useLocation()
  const locationPath = location.pathname.replaceAll("/", "_");

  const {
    parentRef,
    onClose,
    children,
    styles = '',
    top = false,
    bottom = false,
    left = false,
    right = false,
    topLeft = false,
    topRight = false,
    bottomLeft = true,
    bottomRight = false,
    closeOnClickInside = true,
    id=''
  } = props

  const ref = useRef<any>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      )
        onClose()
    }

    document.addEventListener('keydown', onEscapePress, false)
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('keydown', onEscapePress, false)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, parentRef, onClose])

  const getPosition = () => {
    if (bottom) return 'top-[110%] left-[-100%]'
    else if (bottomRight) return 'top-[110%] left-0'
    else if (top) return 'bottom-[110%]'
    else if (topLeft) return 'bottom-[110%] right-0'
    else if (topRight) return 'bottom-[110%] left-0'
    else if (right) return 'top-[-100%] left-[110%]'
    else if (left) return 'top-[-100%] right-[110%]'
    else if (bottomLeft) return 'top-[115%] right-0'
    return ''
  }

  const onEscapePress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose()
  }

  return (
    <div
    id={`${locationPath}${id ? `_id` : ''}_popup`}
      ref={ref}
      className={`cwpf_glbl_popup flex flex-col bg-white absolute items-start justify-evenly border border-[#C1C7D0] border-solid rounded-4 cursor-pointer z-10 ${getPosition()} ${styles}`}
      data-testid="menu-popup"
      onClick={e => !closeOnClickInside && e.stopPropagation()}
    >
      {children}
    </div>
  )
}

export default Popup
