import {createSlice} from '@reduxjs/toolkit'
import {Plan} from 'app/services/plans'
export interface PlanSlice {
  checkoutPlanIndex: number
  checkoutPlan: Plan
  planLimitError: boolean
  plansDataLength: number
}

export const initialState: PlanSlice = {
  checkoutPlanIndex: 1,
  checkoutPlan: {
    bandwith_gb: 0,
    id: 0,
    max_app_count: 0,
    max_montly_visits: 0,
    price: '0',
    storage_gb: 0,
  },
  planLimitError: false,
  plansDataLength: 0,
}
const currentPlanSlice = createSlice({
  name: 'planSlice',
  initialState,

  reducers: {
    setCheckoutPlan: (state, action) => {
      state.checkoutPlan = action.payload
    },
    setCheckoutPlanIndex: (state, action) => {
      state.checkoutPlanIndex = action.payload
    },
    setPlanLimitError: (state, action) => {
      state.planLimitError = action.payload
    },
    setPlansDataLength: (state, action) => {
      state.plansDataLength = action.payload
    },
  },
})
export const {
  setCheckoutPlan,
  setCheckoutPlanIndex,
  setPlanLimitError,
  setPlansDataLength,
} = currentPlanSlice.actions

export default currentPlanSlice.reducer
