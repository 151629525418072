import React from 'react'
import {useTracking} from 'react-tracking'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {useAddCacheSettingMutation} from 'app/services/platform3'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {
  Button,
  Col,
  Container,
  Row,
  TextField,
  Text,
  RadioButton,
} from 'Common/Components/Core/DesignTemplates'

const NewExclusionModal = (props: any) => {
  const [addCacheSetting, result] = useAddCacheSettingMutation()
  const {trackEvent} = useTracking()
  return (
    <Modal
      show={props.show}
      onClose={props.onClose}
      classes={'py-[25px] px-[35px] min-w-[456px]'}
    >
      <Text type="h6" med styles="pb-30 text-gray-80">
        {props.name ? `Editing ${props.name}` : 'Add New Exclusion'}
      </Text>
      <Formik
        initialValues={{type: '', value: ''}}
        validationSchema={Yup.object({
          value: Yup.string().required(
            `Value already exists! Please choose a unique value`,
          ),
        })}
        onSubmit={(values, {resetForm}) => {
          // addCacheSetting(values)
          trackEvent({
            event_name: 'fmui_add_new_exclusion',
            attributes: {
              exclusion_type: values.type,
              value: values.value,
            },
          })
          props.parentHandler(values)
          props.onClose()
          resetForm()
        }}
      >
        <Form>
          <Container styles="mb-24">
            <RadioButton
              label="Type"
              labelStyles="text-gray-100"
              name="type"
              options={[
                {value: 'Cookie', name: 'Cookie'},
                {value: 'URL', name: 'URL'},
              ]}
              spacing="space-x-40"
              defaultChecked={props.type}
            />
          </Container>
          <TextField
            label="Value"
            labelStyle="text-gray-100"
            name="value"
            type="text"
            placeHolder={props.data ? props.data.value : 'Add New Exclusion'}
          />
          <Container styles="mt-40 flex space-x-12 text-blue-100">
            <Button type="submit" medium>
              {props.name ? 'Save' : 'Submit'}
            </Button>
            <Button type="button" primaryText large onClick={props.onClose}>
              Cancel
            </Button>
          </Container>
        </Form>
      </Formik>
    </Modal>
  )
}

export default NewExclusionModal
