import React from 'react'

import {useAppDispatch, useAppSelector} from 'app/hooks'
import {closeDeleteModal, closeModal, toggleDeleteModal} from './ModalSlice'
import {ModalName} from './util'

//modals
import ExclusionDeleteModal from './ManageAppModals/PerformanceModal/ExclusionDeleteModal'
import MaxLimit from './ApplicationLimitModal/ApplicationLimitModal'
import DeleteApp from './ApplicationDeleteModal/ApplicationDeleteModal'
import AddApplicationModal from './AddApplicationModal/AddApplicationModal'
import ConfirmPlanModal from './ConfirmPlanAddedModal/ConfirmPlanModal/ConfirmPlanModal'
import PlanAddedModal from './ConfirmPlanAddedModal/PlanAddedModal/PlanAddedModal'
import ModifyPlanModal from './ModifyPlanModals/ModifyPlan'
import SessionExpiredModal from './SessionExpiredModal/SessionExpiredModal'
const GlobalModals = () => {
  const dispatch = useAppDispatch()
  const show = useAppSelector(state => state.modals)

  //

  return (
    <>
      <MaxLimit
        show={show.limitAppModal}
        onClose={() => dispatch(closeModal(ModalName.limitAppModal))}
      />
      <ModifyPlanModal
        show={show.modifyPlanModal}
        onClose={() => dispatch(closeModal(ModalName.modifyPlanModal))}
      />
      <AddApplicationModal
        show={show.addApplicationModal}
        onClose={() => dispatch(closeModal(ModalName.addApplicationModal))}
      />
      <ConfirmPlanModal
        show={show.confirmPlanModal}
        onClose={() => dispatch(closeModal(ModalName.confirmPlanModal))}
      />
      <PlanAddedModal
        show={show.addAppPlanModal}
        onClose={() => dispatch(closeModal(ModalName.addAppPlanModal))}
      />

      <ExclusionDeleteModal
        show={show.deleteModal.show}
        data={show.deleteModal.data}
        onClose={() =>
          dispatch(toggleDeleteModal({type: ModalName.deleteModal}))
        }
      />
      <DeleteApp
        data={show.deleteAppModal.data}
        show={show.deleteAppModal.show}
        onClose={() =>
          dispatch(
            closeDeleteModal({
              type: ModalName.deleteAppModal,
              data: show.deleteAppModal.data,
            }),
          )
        }
      />
      <SessionExpiredModal
        show={show.showSessionExpiredModal}
        onClose={() => dispatch(closeModal(ModalName.showSessionExpiredModal))}
      />
    </>
  )
}

export default GlobalModals
