/** Libraries */
import React, {useState, useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {DatePicker, Space} from 'antd'

/** Reusable Components */
import {
  Container,
  Row,
  Col,
  Text,
  CommingSoonScreen,
  Card,
  CustomSelect,
} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import {ReactComponent as attacksMitigatedImg} from '../../../../../../Assets/img/attacks-mitigated.svg'
import 'antd/dist/antd.css'

const data = [
  {
    date: new Date(2018, 0, 1),
    value: 450,
  },
  {
    date: new Date(2018, 0, 2),
    value: 300,
  },
  {
    date: new Date(2018, 0, 3),
    value: 100,
  },
  {
    date: new Date(2018, 0, 4),
    value: 420,
  },
  {
    date: new Date(2018, 0, 5),
    value: 200,
  },
  {
    date: new Date(2018, 0, 6),
    value: 650,
  },
  {
    date: new Date(2018, 0, 15),
    value: 300,
  },
]

interface iMtdOpitons {
  date_Options: any
}

//validations
const validate = Yup.object({
  date_Options: Yup.string().required('Please select an option.'),
})
const initialValues: iMtdOpitons = {
  date_Options: '',
}
const onSubmit = (
  values: iMtdOpitons,
  {setSubmitting}: FormikHelpers<iMtdOpitons>,
) => {
  return
}

export const Chart = () => {
  const date_Options = [
    {label: '22 March 2022', value: '22 March 2022'},
    {label: '23 March 2022', value: '23 March 2022'},
    {label: '24 March 2022', value: '24 March 2022'},
  ]
  const {RangePicker} = DatePicker
  const chartRef = useRef<any>(null)

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv', am4charts.XYChart)
      chartRef.current.data = data

      const dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis())
      //CAHNGE LINE
      dateAxis.renderer.grid.template.location = 0
      dateAxis.renderer.minGridDistance = 30
      const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.grid.template.strokeOpacity = 1
      valueAxis.renderer.grid.template.stroke = am4core.color('#D8D8D8')
      valueAxis.renderer.labels.template.fill = am4core.color('#A2A3A5')
      valueAxis.renderer.labels.template.fontSize = 13
      dateAxis.renderer.labels.template.fill = am4core.color('#A2A3A5')
      dateAxis.renderer.labels.template.fontSize = 13
      dateAxis.renderer.grid.template.strokeWidth = 0
      valueAxis.renderer.grid.template.strokeWidth = 1
      // Create series
      // function createSeries(field: any, name: any) {
      const series = chartRef.current.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'date'
      series.name = 'Series #1'
      series.tooltip = new am4core.Tooltip()
      // series.tooltipText =
      //   '[font-size: 10px]{dateX},\n\n[font-size: 14px][bold]210 Attacks\n\n[/]{time} UTC\n'
      const tooltiphtml = `
        <table>
        <tr>
          <td style=color:white;font-size:10px;font-weight:400>14th December, 2021,</td>
        </tr>
        <tr>
          <td style=font-weight:700;font-size:14px>210 Attacks</td>
        </tr>
        <tr>
          <td style=font-size:12px;font-weight:600>09:55 UTC</td>
        </tr>
        </table>`
      // series.tooltip.html = tooltiphtml
      series.tooltipHTML = tooltiphtml
      series.tooltip.background.fillOpacity = 1

      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = am4core.color('#253858')
      series.tooltip.pointerOrientation = 'vertical'
      valueAxis.cursorTooltipEnabled = false
      dateAxis.cursorTooltipEnabled = false
      series.tooltip.label.minWidth = 151
      series.tooltip.label.minHeight = 60
      series.dateFormatter.dateFormat = 'dd-MM-yyyy'
      series.tooltip.background.cornerRadius = 6
      series.strokeWidth = 6
      series.tensionX = 0.8
      series.stroke = am4core.color('#2F39BF')
      series.tooltip.dy = -9
      // series.tooltipY= am4core.percent(60);
      // series.tooltipX= am4core.percent(90);
      // series.smoothing = "monotoneX";
      const bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.stroke = am4core.color('#fff')
      bullet.circle.strokeWidth = 5
      bullet.circle.fill = am4core.color('#2F39BF')
      bullet.fillOpacity = 0
      bullet.circle.width = 40
      bullet.circle.radius = 13
      bullet.strokeOpacity = 0
      bullet.stroke = am4core.color('#2F4858')
      bullet.strokeWidth = 1
      // Make square drop shadow by adding a DropShadow filter
      const shadow = new am4core.DropShadowFilter()
      shadow.dx = 3
      shadow.dy = 3
      bullet.filters.push(shadow)
      const bulletState = bullet.states.create('hover')
      bulletState.properties.fillOpacity = 1
      bulletState.properties.strokeOpacity = 1
      //   return series
      // }
      // createSeries('value', 'Series #1')
      chartRef.current.cursor = new am4charts.XYCursor()
      chartRef.current.cursor.lineX.stroke = am4core.color('#2F39BF')
      chartRef.current.cursor.lineX.strokeWidth = 2
      chartRef.current.cursor.lineY.stroke = am4core.color('#FEB155')
      chartRef.current.cursor.lineY.strokeWidth = 3
      chartRef.current.cursor.lineX.strokeDasharray = '9,8'
      chartRef.current.cursor.lineY.strokeDasharray = '9,10'
      chartRef.current.cursor.lineX.strokeOpacity = 2
      chartRef.current.cursor.lineY.strokeOpacity = 3
    }
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])
  return (
    <>
      <Container styles="relative h-[100%]">
        <CommingSoonScreen
          ImgComponent={attacksMitigatedImg}
          heading="Attacks Mitigated"
        />
        <Card styles="overflow-hidden my-[30px] py-[20px] blur-sm">
          <Row between styles="px-24">
            <Col styles="mb-[33px]">
              <Text type="h4" styles="text-gray-80 ">
                133k
              </Text>
              <Text med styles="p3 text-[#222E37]">
                Attacks Mitigated from 1st Jan 2022 to 31st Jan 2022
              </Text>
            </Col>
            <Col>
              <Formik
                initialValues={initialValues}
                validationSchema={validate}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <Space direction="vertical" size={12}>
                      <RangePicker showTime />
                    </Space>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

          <div id="chartdiv" className=" max-md:min-w-auto h-[411px]"></div>
        </Card>
      </Container>
    </>
  )
}
