import React from 'react'

//design system
import {
  Card,
  Container,
  Text,
  Row,
  Col,
  Accordion,
  Icon,
  Separator,
  Button,
} from 'Common/Components/Core/DesignTemplates'

//components
import Field from './Field'
import Modal from 'Common/Components/Hooks/Modal/Modal'
import {useGetCloudfareApisQuery} from 'app/services'

const TxtOneRecordModal = ({show, onClose, customDomain}: any) => {
  const name: string[] = []
  const value: string[] = []
  customDomain?.txt_records &&
    Object.keys(customDomain?.txt_records).forEach(key => {
      name.push(key)
      value.push(customDomain?.txt_records[key])
    })
  const {data} = useGetCloudfareApisQuery()
  const ModalAccordion = (
    <Accordion
      title={
        <>
          <Row between>
            <Col>
              <Icon name="info_outline" styles="text-blue-100" />
            </Col>
            <Col>
              <Text styles="p3 text-gray-100 ml-12 pr-30">
                If your DNS provider accepts only fully qualified domains in the
                host or name field, then please complete your hostname by adding
                your root domain to the end of it. For example, instead of using
                _cf-cutom-hostname, use _cf-cutom-hostname.shaheergadit.com
              </Text>
            </Col>
          </Row>
        </>
      }
    >
      <Container styles="ml-36 mt-12">
        <Field label="Name /Host" value={name.length && name[0]} />
      </Container>
    </Accordion>
  )
  return (
    <Modal
      show={show}
      classes="min-w-[650px] cwpf_domaincloudfaremodal"
      onClose={onClose}
    >
      <Container styles="py-24 px-32">
        <Text type="h6" semi styles="text-gray-80">
          Verify Domain Ownership
        </Text>
        <Text styles="p3 my-12">
          To enable Cloudflare services, you will need to verify the ownership
          of the domain by adding the TXT records in your DNS management service
          (this could either be your domain registrar or a separate DNS
          service). Once you have added the TXT records in your DNS management
          service, click "Next".
        </Text>
        <Card>
          <Row top>
            <Col lg={3}>
              <Text semi styles="p2 text-gray-80">
                TXT Record 1
              </Text>
            </Col>
            <Col lg={9}>
              <Row wrap>
                <Col lg={12}>
                  <Field label="Name /Host" value={name.length && name[0]} />
                </Col>
                <Separator styles="my-12 text-gray-10" h={1} />
                <Col lg={12}>
                  <Field label="Value" value={value.length && value[0]} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <Container styles="mt-20 bg-gray-3">{ModalAccordion}</Container>
        <Button primary medium styles="mt-32" onClick={onClose}>
          Close
        </Button>
      </Container>
    </Modal>
  )
}

export default TxtOneRecordModal
