import React, {useEffect, useMemo, useState} from 'react'
import {useTracking} from 'react-tracking'
import {Outlet, useParams} from 'react-router-dom'

/** Store/Services */
import {useGetSingleApplicationQuery} from 'app/services'
import useOperationsPolling, {
  OperationType,
  opreationPerameters,
} from 'Common/Components/Hooks/useOperationsPolling'
import UpdateAppPolling from './UpdateAppPolling/UpdateAppPolling'

/** Reusable Components */
import {Container} from 'Common/Components/Core/DesignTemplates'
import HeaderTab from './Header/Header'
import ApplicationNotFound from './AppNotFound'
import ApplicationRemovedModal from './ApplicationRemovedModal'
import {AppStatus} from '../ApplicationListing/useOperationsPolling'

/** Helpers */
import {iManageAppTrackingObj} from './Util'

/** Assets (CSS/Images) */
import './ManageApp.css'
import {iOperationData} from 'Constants/Interfaces/iGlobal'

export default function ManageApp() {
  const {app_id} = useParams()
  const [isAppNotFound, setIsAppNotFound] = useState(false)
  const [isAppFetchedInitially, setIsAppFetchedInitially] = useState(false)

  const {
    data: applicationData,
    isLoading: isAppLoading,
    isFetching: isAppFetching,
    error,
    isError,
    isSuccess,
  } = useGetSingleApplicationQuery(app_id, {
    skip: isAppNotFound && !window.navigator.onLine,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  })
  const {Track} = useTracking<iManageAppTrackingObj>({
    attributes: {
      page: 'manage_app',
      current_url: window.location.href,
      app_id,
    },
  })
  const isPolling = true
  const checkPolling = (value: string) => {
    const findApp = applicationData?.operations
      .filter((operation: iOperationData) =>
        operation?.parameters?.some((item: any) => item === value),
      )
      .find((operation: iOperationData) =>
        [AppStatus.ENQUEUED, AppStatus.RUNNING, AppStatus.WAITING].includes(
          operation?.operation_status,
        ),
      )
    if (findApp) {
      return true
    }
    return false
  }
  useEffect(() => {
    const errorObject: any = {...error}
    if (
      isError &&
      (errorObject?.data?.status_code === 404 ||
        errorObject?.data?.status_code === 403 ||
        errorObject?.status === 'PARSING_ERROR')
    )
      setIsAppNotFound(true)
  }, [error, isError])

  useEffect(() => {
    if (isSuccess) setIsAppFetchedInitially(true)
  }, [isSuccess])

  if (isAppNotFound && !isAppFetchedInitially)
    return (
      <ApplicationNotFound
        show={isAppNotFound}
        onClose={() => setIsAppNotFound(false)}
      />
    )
  return (  
    <Track>
      <section>
        <div className=" relative border-b border-solid border-b-gray-10 max-lg:border-none bg-[#FCFCFC] ">
          <Container styles="container">
            <HeaderTab
              applicationData={applicationData}
              isAppLoading={isAppLoading}
              isAppFetching={isAppFetching}
              isPolling={isPolling}
            />
          </Container>
        </div>
        {/* <UpdateAppPolling
          isPolling={isPolling}
          opreation={
            opreationPerameters[applicationData?.operation?.parameters[0]]
          }
        /> */}
      </section>

      <section>
        <Container styles="container">
          <Outlet
            context={{
              isPolling: isPolling,
              checkPolling: checkPolling,
              operationData: applicationData?.operation,
              applicationData: applicationData,
              isAppLoading: isAppLoading,
            }}
          />
        </Container>
      </section>

      {isAppNotFound && isAppFetchedInitially && (
        <ApplicationRemovedModal
          show={isAppNotFound && isAppFetchedInitially}
          onClose={() => {
            setIsAppFetchedInitially(false)
            setIsAppNotFound(false)
          }}
        />
      )}
    </Track>
  )
}
