/** Libraries */
import {useTracking} from 'react-tracking'

/** Reusable Components */
import {Col, Container, Row, Tabs} from 'Common/Components/Core/DesignTemplates'
import AttackMitigation from './AttackMitigated/AttackMitigate'
import WhiteList from './IPWhiteList/WhiteList'
import SftpSshLogs from './SSHLogs/SftpSshLogs'

/** Helpers */
import {iSecurityTrackingObj, SECURITY_SUB_TABS} from './util'

/** Assets (CSS/Images) */
import './security.css'
// import AddDatabase from './Database'
// import DomainManagementAdd from './DomainManagement'
// import SSH from './SSH'

const SideBar = () => {
  const {Track} = useTracking<iSecurityTrackingObj>({
    attributes: {
      tab_name: 'security',
    },
  })
  return (
    <Track>
      <Container className="container">
        <div className="mt-[60px] relative cwpf_disbale_tabs_wrap">
          <div className="absolute z-[9] left-0 top-0 h-[100%] w-[100%]"></div>
          <Tabs
            vertical
            mainEvent={'fmui_security_tab'}
            containerStyle="max-sm:flex-col max-md:flex-col max-md: gap-[60px] max-sm:gap-[60px]"
            styles="cwpf_disbale_tabs"
          >
            <div
              id="Attacks Mitigated"
              aria-label={SECURITY_SUB_TABS.ATTACK_MITIGATION}
            >
              <AttackMitigation />
            </div>
            <div id="Whitelist" aria-label={SECURITY_SUB_TABS.WHITELIST}>
              {/* <WhiteList /> */}
            </div>
            <div id="SftpSshLogs" aria-label={SECURITY_SUB_TABS.SFTP_SSH_LOGS}>
              {/* <SftpSshLogs /> */}
            </div>
          </Tabs>
        </div>
      </Container>
    </Track>
  )
}

export default SideBar
