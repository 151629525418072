/** Libraries */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Formik, Form} from 'formik'
import {useParams} from 'react-router-dom'

/** Store/Services */
import {useGetSingleApplicationQuery} from 'app/services'

/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Icon,
  Row,
  Text,
  TextField,
  Card,
} from 'Common/Components/Core/DesignTemplates'
import Popup from 'Common/Components/Core/Popup/Popup'

/** Assets (CSS/Images) */
import {ReactComponent as Wordpresssvg} from '../../../../../Assets/img/manage-app/wordpress-logo.svg'

export default function Dropdown() {
  const {app_id} = useParams()
  const {
    data: appilicationsData,
    isLoading: isAppLoading,
    isFetching: isAppFetching,
  } = useGetSingleApplicationQuery(app_id)

  //

  const [openCard, setOpenCard] = useState(false)
  const [temp, setTemp] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const iconLinkRef = useRef<any>(null)

  function truncate(text: string, maxLength: number) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  return (
    <section className="relative inline-block">
      <Button medium primaryText styles=" border-0 relative">
        <div
          className="flex items-center relative"
          onClick={() => setOpenCard(prev => !prev)}
          ref={iconLinkRef}
        >
          <Wordpresssvg />
          <span className="inline-block align-middle">View Credentials</span>
          {/* <figure className="w-[20px] h-[20px] mr-6">
          <img src={WordpressLogo} alt="wordpress logo"></img>
        </figure> */}
          <div
            className="relative pt-6"
            // ref={iconLinkRef}
            // onClick={() => setOpenCard(prev => !prev)}
          >
            {openCard ? (
              <Icon styles="ml-10">expand_less</Icon>
            ) : (
              <Icon styles="ml-10">expand_more</Icon>
            )}

            {openCard && (
              <Popup
                parentRef={iconLinkRef}
                onClose={() => setOpenCard(false)}
                styles="p-32 text-left"
                data-testid="popup"
                closeOnClickInside={false}
              >
                <>
                  <Row styles="w-full">
                    <Col styles="mb-20">
                      <Text type="p" styles="p1">
                        URL
                      </Text>
                      <div className="flex items-center">
                        <Text
                          ellipsis={275}
                          type="a"
                          href={`http://${
                            appilicationsData?.custom_domain?.domain
                              ? appilicationsData?.custom_domain?.domain
                              : appilicationsData?.app_fqdn
                          }/wp-admin`}
                          target="_blank"
                          rel="noreferrer"
                          styles="text-14 text-blue-100 w-full hover:text-blue-100 "
                        >
                          {`http://${
                            appilicationsData?.custom_domain?.domain
                              ? appilicationsData?.custom_domain?.domain
                              : appilicationsData?.app_fqdn
                          }/wp-admin`}
                        </Text>
                        <a
                          href={`http://${
                            appilicationsData?.custom_domain?.domain
                              ? appilicationsData?.custom_domain?.domain
                              : appilicationsData?.app_fqdn
                          }/wp-admin`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon styles="text-18 ml-6">launch</Icon>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Formik
                    onSubmit={values => {
                      //
                    }}
                    initialValues={{
                      wp_user: !isAppLoading
                        ? truncate(
                            appilicationsData?.wp_params?.management_email,
                            30,
                          )
                        : '',
                      wp_password: !isAppLoading
                        ? appilicationsData?.wp_params?.password
                        : '',
                    }}
                  >
                    {({values}) => (
                      <Form>
                        <Text type="p" semi styles="p1 mt-20 mb-20">
                          WordPress Credentials
                        </Text>
                        <Row top>
                          <Col styles="w-[317px]">
                            <TextField
                              label="Username"
                              name="wp_user"
                              type="text"
                              styles="border-0 bg-gray-3 mb-20 text-gray-80 pr-40 overflow-hidden text-ellipsis"
                              labelStyle="text-16"
                              placeHolder="Cloud"
                              disabled
                              showCopyIcon={true}
                            />
                            <TextField
                              label="Password"
                              name="wp_password"
                              styles="border-0 bg-gray-3 text-gray-80 cwpf_passowrd_field"
                              type={showPassword ? 'text' : 'password'}
                              postfixIcon={{
                                name: `${
                                  showPassword ? 'visibility' : 'visibility_off'
                                }`,
                                handler: () => setShowPassword(prev => !prev),
                                styles: 'cwpf_password_icon text-gray-80',
                              }}
                              labelStyle="text-16"
                              placeHolder="Cloud1!123"
                              showCopyIcon={true}
                              disabled
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </>
              </Popup>
            )}
          </div>
        </div>
      </Button>
    </section>
  )
}
