/** Libraries */
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Navigate, useParams, useLocation} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {
  useGetAllApplicationsQuery,
  useGetSingleApplicationQuery,
} from 'app/services'

/** Reusable Components */
import Popup from 'Common/Components/Core/Popup/Popup'
import {
  Col,
  DropDownMenu,
  Icon,
  Row,
  Text,
  Card,
  Container,
  WebSnap,
  Button,
} from 'Common/Components/Core/DesignTemplates'
import Dropdown from './Dropdown'
import HeaderTabs from './HeaderTabs'
import {OperationType} from 'Common/Components/Hooks/useOperationsPolling'
import Animation from 'Common/Components/Core/DesignTemplates/Animations/Animation'

/** Assets (CSS/Images) */
import '../ManageApp.css'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'
import {customToast} from 'Common/Components/Core/CustomizedToast'

const Header = ({
  isPolling,
  applicationData,
  isAppLoading,
  isAppFetching,
}: any) => {
  const navigate = useNavigate()
  const {app_id} = useParams()
  const appDropdownIconRef = useRef<any>()
  const [showAppDropDown, setShowAppDropdown] = useState(false)

  const {post} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {
      selectFromResult: ({data, isSuccess}) => ({
        post: (() => {
          return data?.results.filter(
            (post: any) =>
              !post.operation.type ||
              post.operation.type !== OperationType.ADD_APP ||
              (post.operation.type === OperationType.ADD_APP &&
                post.operation.operation_status === 'completed'),
          )
        })(),
        isSuccess,
      }),
      skip: !window.navigator.onLine,
    },
  )
  useEffect(() => {
    if (isAppLoading && isAppFetching) return
  }, [isAppLoading, isAppFetching])

  const {pathname} = useLocation()
  const path = pathname.split('/')[3] ? `/${pathname.split('/')[3]}` : ''

  return (
    <>
      <section className="@apply border-b border-solid !border-0 border-b-gray-10 max-lg:border-none bg-[#FCFCFC]">
        <Container styles="container">
          <div className="bg-[#FCFCFC] pt-40">
            <Row wrap between styles="w-full cwpf_manageHeaderResp">
              <div className="flex flex-col cwpf_manageHeaderColResp">
                <Text semi className="text-gray-80">
                  {isAppLoading ? (
                    <Skeleton width={150} height={10} borderRadius={10} />
                  ) : (
                    'MANAGE APP'
                  )}
                </Text>
                {isAppLoading ? (
                  <Skeleton width={220} height={10} borderRadius={10} />
                ) : (
                  <Row start>
                    <Button medium primaryText styles="pl-[0px]">
                      <div
                        onClick={() => setShowAppDropdown(prev => !prev)}
                        ref={appDropdownIconRef}
                        className="relative"
                      >
                        <Text
                          //ref={appDropdownIconRef}
                          bold
                          type="h3"
                          styles="flex items-center text-gray-80 relative"
                        >
                          {applicationData?.name}
                          {post?.length > 1 && (
                            <>
                              <Icon
                                //ref={appDropdownIconRef}
                                name={
                                  showAppDropDown
                                    ? 'keyboard_arrow_up'
                                    : 'keyboard_arrow_down'
                                }
                                styles={`relative w-30 h-26 text-20 border border-solid rounded-4 border-gray-10 flex items-center justify-center cursor-pointer hover:bg-grey-60  ml-8 border-0`}
                                // onClick={() =>
                                //   setShowAppDropdown(prev => !prev)
                                // }
                                data-testid="dropdown-icon"
                              >
                                <Animation
                                  bottomRight
                                  in={showAppDropDown}
                                  animationStyle={'fadeInDropdown'}
                                  timeout={500}
                                  unmountOnExit
                                >
                                  <Popup
                                    parentRef={appDropdownIconRef}
                                    onClose={() => setShowAppDropdown(false)}
                                    styles="border-[#E5E5E5] shadow-3"
                                  >
                                    <Row
                                      col
                                      styles="cwpf_glbl_popup max-h-[360px] w-[290px] overflow-y-auto"
                                    >
                                      {post?.map((eachApp: any, i: number) => {
                                        return (
                                          <Row
                                            key={eachApp?.name}
                                            onClick={() => {
                                              app_id !== eachApp.id &&
                                                navigate(`${eachApp.id}${path}`)
                                            }}
                                            middle
                                            styles="px-[24px] py-[17px] flex-1 mr-0 hover:bg-gray-3"
                                          >
                                            <Col>
                                              <div className="w-[50px] h-[37px]">
                                                <WebSnap
                                                  url={eachApp?.app_fqdn}
                                                  styles="w-[100%] h-[100%]"
                                                />
                                              </div>
                                            </Col>
                                            <Col styles="pl-[12px]">
                                              <Tooltip
                                                bottom
                                                text={eachApp?.name}
                                              >
                                                <Text
                                                  type="p"
                                                  styles="p2 text-gray-80 pt-8 whitespace-nowrap text-ellipsis overflow-hidden break-all  max-w-[180px]"
                                                  semi
                                                >
                                                  {eachApp?.name}
                                                </Text>
                                              </Tooltip>
                                            </Col>
                                          </Row>
                                        )
                                      })}
                                    </Row>
                                  </Popup>
                                </Animation>
                              </Icon>
                            </>
                          )}
                        </Text>
                      </div>
                    </Button>
                  </Row>
                )}

                {isAppLoading ? (
                  <Skeleton width={300} height={10} borderRadius={10} />
                ) : (
                  <div className="flex items-center text-blue-100">
                    <a
                      href={`http://${
                        applicationData?.custom_domain?.domain
                          ? applicationData?.custom_domain?.domain
                          : applicationData?.app_fqdn
                      }`}
                      target="__blank"
                    >
                      {applicationData?.custom_domain?.domain
                        ? applicationData?.custom_domain?.domain
                        : applicationData?.app_fqdn}
                    </a>
                  </div>
                )}
              </div>
              <div className="flex items-center space-x-6 cwpf_manageHeaderCol">
                {/* <img src={WordPress} className="w-[20px] h-[20px]"></img>
          <span className="text-blue-75">Admin</span> */}
                {isAppLoading ? (
                  <Skeleton width={250} height={10} borderRadius={10} />
                ) : (
                  <Dropdown />
                )}
              </div>
            </Row>
            <HeaderTabs />
          </div>
        </Container>
      </section>
    </>
  )
}
export default Header
