/** Libraries */
import React, {useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {
  useGetCurrentPlanQuery,
  useGetMonitoringStatsQuery,
  useGetResourceVisitsQuery,
  useGetTotalVisitsQuery,
} from 'app/services'

/** Reusable Components */
import {
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import CardChart from './Charts/XYCharthead'

/** Helpers */
import {abbreviateNumber, EpochToDate} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {setDashboardWarningVisible} from './Slice/Dashboardslice'
import {useAppDispatch} from 'app/hooks'

const TotalVisits = ({
  everythingSummary,
  isEverythingSummaryLoading,
  currentPlan,
}: any) => {
  const dispatch = useAppDispatch()
  // const { data, isSuccess } = useGetTotalVisitsQuery(); // @Todo
  // const {data: currentPlan} = useGetCurrentPlanQuery()
  // const {data, isLoading, isSuccess} = useGetResourceVisitsQuery(undefined, {
  //   skip: currentPlan?.app?.count <= 0,
  //   refetchOnMountOrArgChange: true,
  // })
  const {data: visitsDatapoints, isLoading: isVisitsDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'visits',
    })
  useEffect(() => {
    if (
      (everythingSummary?.total_visits || 0) >
      currentPlan?.plan?.max_montly_visits
    ) {
      dispatch(setDashboardWarningVisible(true))
      return
    }
    setDashboardWarningVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [everythingSummary])

  return (
    <Container styles="px-8 min-h-[102px]">
      <Row>
        <Col>
          <Row>
            <Text type="p" semi styles="text-gray-80 mb-10">
              Total Visits
            </Text>
            {/* {(everythingSummary?.total_visits || 0) >
              currentPlan?.plan?.max_montly_visits && (
              <Icon name="warning_amber" styles="text-orange-100 ml-8" />
            )} */}
          </Row>
        </Col>
        <Col>
          <Container styles="h-80 w-[130px] flex absolute right-4 top-6">
            {!isVisitsDatapointsLoading && (
              <CardChart
                datapoints={
                  visitsDatapoints?.data?.map((eachPoint: any) => {
                    return {
                      date: eachPoint?.Date,
                      value: eachPoint?.UniqueVisits,
                    }
                  }) || []
                }
              />
            )}
          </Container>
        </Col>
      </Row>
      <Row>
        {isEverythingSummaryLoading || isVisitsDatapointsLoading ? (
          <Skeleton width={100} height={10} borderRadius={10} />
        ) : (
          <Col styles="flex">
            <Text type="div" styles="h1-semi text-gray-80">
              {abbreviateNumber(everythingSummary?.total_visits || 0)}
            </Text>
            <Text
              type="h5"
              semi
              styles="text-gray-80 relative top-[5px] self-center"
            >
              /{abbreviateNumber(everythingSummary?.allocated_visits || 0)}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Text type="p" styles="p3 text-gray-80">
            Total visits consumed since start of this month
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(TotalVisits)
