import moment from 'moment'
import {customEvent} from 'index'

declare const window: customEvent
export const sendToAnalytics = ({
  id,
  name,
  value,
}: {
  id: string
  name: string
  value: number
}): void => {
  const vitalMetric = {
    event_name: `fmui_web_vital`,
    attributes: {
      vitalId: id, // id unique to current page load
      vitalValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      vitalName: name,
      nonInteraction: true, // avoids affecting bounce rate
      event_category: 'frontend',
      device: window.innerWidth <= 768 ? 'mobile' : 'desktop',
      distinct_id: window.distinct_id,
      time: moment.utc().valueOf(),
      current_url: window.location.href,
    },
  }

  ;(window.events = window.events || []).push(vitalMetric)
}
