/** Reusable Components */
import {Container} from 'Common/Components/Core/DesignTemplates'

export default function AttackMitigationTable() {
  return (
    <>
      <Container styles="container">
        <Container styles="overflow-x-auto mt-10 mb-[97px]  border border-b-0 border-solid border-gray-10 rounded-4; blur-sm">
          <table className="table w-full cwpf_attackmitigated_table">
            <thead>
              <tr className="text-gray-80 items-center">
                <th className="w-[14%] p3-semi">Date</th>
                <th className="w-[16%] p3-semi">Action</th>
                <th className=" w-[14%] p3-semi">Services</th>
                <th className=" w-[13%] p3-semi">IP Address</th>
                <th className="w-[16%] p3-semi">Location</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4].map(() => {
                return (
                  <tr className="text-gray-80 ">
                    <td className="p3">19-08-2021 11:21:53</td>
                    <td className="p3">Blocked</td>
                    <td className="p3">Rate Limiting</td>
                    <td className="p3">141.101.71.242</td>
                    <td className="p3">United Kingdom</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Container>
      </Container>
    </>
  )
}
