/** Libraries */
import {useState} from 'react'

/** Reusable Components */
import {
  Button,
  Card,
  Col,
  Container,
  CommingSoonScreen,
  Row,
  Text,
  Icon,
} from 'Common/Components/Core/DesignTemplates'
import ManageAppLimit from 'Common/Components/Core/Modal/ManageAppModals/ManageAppLimit/ManageAppLimit'
import BandwidthChart from './BandwidthChart'
import HeadpieChart from './HeadPieChart'
import CardChart from './XYCharthead'
import Skeleton from 'react-loading-skeleton'

/** Assets (CSS/Images) */
import {ReactComponent as resourcesImg} from '../../../../../../Assets/img/resources.svg'
import './Resources.css'
import {
  useGetCurrentPlanQuery,
  useGetMonitoringStatsQuery,
  useGetTotalDiskSpaceQuery,
} from 'app/services'
import {useParams} from 'react-router-dom'
import {
  abbreviateNumber,
  niceBytes,
  niceBytesDiskspace,
} from 'Common/Components/Core/Modal/util'
import moment from 'moment'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const Resources = () => {
  const {app_id} = useParams()
  const {data: currentPlan} = useGetCurrentPlanQuery()
  const [showLimitModal, setShowLimitModal] = useState(false)
  const {data: visitsSummary, isLoading: isVisitsSummaryLoading} =
    useGetMonitoringStatsQuery({
      action: 'summary',
      source: 'visits',
      app_ids: app_id,
    })
  const {
    data: diskspaceData,
    isLoading: isDiskspaceLoading,
    isSuccess,
  } = useGetTotalDiskSpaceQuery(
    {app_id: app_id},
    {
      skip: currentPlan?.app?.count <= 0,
      refetchOnMountOrArgChange: true,
    },
  )

  const {data: visitsDatapoints, isLoading: isVisitsDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'visits',
      app_ids: app_id,
    })
  const {data: bandwidthSummary, isLoading: isBandwidthSummaryLoading} =
    useGetMonitoringStatsQuery({
      action: 'summary',
      source: 'bandwidth',
      app_ids: app_id,
    })
  const {data: bandwidthDatapoints, isLoading: isBandwidthDatapointsLoading} =
    useGetMonitoringStatsQuery({
      action: 'datapoints',
      source: 'bandwidth',
      app_ids: app_id,
    })
  console.log(bandwidthDatapoints, 'bandwidthDatapoints')

  return (
    <Container styles="relative h-[100%] ">
      {/* <CommingSoonScreen
        ImgComponent={resourcesImg}
        heading="Resources : "
        detail="Visits / Disk / Bandwidth"
      /> */}
      <Row middle between>
        <Col>
          <Row middle>
            <Text type="h4" styles="mr-4" semi>
              Resources
            </Text>
            <Tooltip
              leftBottom
              text="This section shows you the visits, disk space and bandwidth consumption by this particular application, and the values of visits and bandwidth usage get reset every billing cycle. The values below are not overall consumption. You can see a breakdown of resources' consumption per application on the dashboard page under 'Resource Analysis' section."
            >
              <Icon styles="neutral-focus">info_outline</Icon>
            </Tooltip>
          </Row>
        </Col>
        {/* <Button onClick={() => setShowLimitModal(true)} small primaryText>
          Manage Limit
        </Button> */}
        {/* <Col>
          <Button
            primaryOutline
            small
            icon="refresh"
            iconAlign="left"
            styles="ml-24"
          >
            Refresh
          </Button>
        </Col> */}
      </Row>

      {showLimitModal && (
        <ManageAppLimit
          show={showLimitModal}
          onClose={() => setShowLimitModal(false)}
        />
      )}

      <Row stretch between gutter={24} styles="cwpf_resourcesResp">
        <Col med={6} lg={6} sm={12}>
          <Card styles="px-[22px] mt-10 px-[22px]">
            <Row row between>
              <Col>
                <Text styles="text-16" semi>
                  Total Visits
                </Text>
                <Row styles="mt-16" middle>
                  <Col styles="ml-[5px]">
                    {isVisitsSummaryLoading ? (
                      <Skeleton width={100} height={10} borderRadius={10} />
                    ) : (
                      <>
                        <Row row bottom>
                          <Text type="div" styles="h1-semi text-gray-80">
                            {abbreviateNumber(visitsSummary?.total_visits || 0)}
                          </Text>
                          {/* <Text
                            type="h5"
                            semi
                            styles="relative top-[-7px] text-gray-80"
                          >
                            {' '}
                            /
                            {abbreviateNumber(
                              visitsSummary?.allocated_visits || 0,
                            )}
                          </Text> */}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Row middle styles="mt-10">
                  <Text styles="text-14 mb-[2px]">
                    Total visits consumed by this application since start of
                    this month
                  </Text>
                </Row>
              </Col>
              <Col styles=" h-[52px] w-[110px] flex">
                {!isVisitsDatapointsLoading && (
                  <CardChart
                    datapoints={
                      visitsDatapoints?.data?.map((eachPoint: any) => {
                        return {
                          date: eachPoint?.Date,
                          value: eachPoint?.BandwidthSum,
                        }
                      }) || []
                    }
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        <Col med={6} lg={6} sm={12}>
          <Card styles="px-[22px] mt-10">
            <Row row between>
              <Col>
                <Text styles="text-16" semi>
                  Total Disk Space
                </Text>
                <Row styles="mt-16" middle>
                  <Col styles="ml-[5px]">
                    {isDiskspaceLoading ? (
                      <Skeleton width={100} height={10} borderRadius={10} />
                    ) : (
                      <Row row bottom>
                        <Text type="div" styles="h1-semi text-gray-80">
                          {niceBytesDiskspace(diskspaceData?.used)}
                        </Text>
                        {/* <Text
                          type="h5"
                          semi
                          styles="relative top-[-7px] text-gray-80"
                        >
                          {' '}
                          /{currentPlan?.plan?.storage_gb + 'GB'}
                        </Text> */}
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row middle styles="mt-10">
                  <Text styles="text-14 mb-[2px]">
                    Total Disk Space consumed by this application at the{' '}
                    <br></br> moment
                  </Text>
                </Row>
              </Col>
              <Col>
                {isSuccess && (
                  <HeadpieChart
                    total={diskspaceData?.allocated}
                    allocated={diskspaceData?.used}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row styles="mt-24">
        <BandwidthChart
          datapoints={
            bandwidthDatapoints?.data?.map((eachPoint: any) => {
              return {
                date: new Date(eachPoint.Date),
                value: eachPoint.BandwidthSum / 9000999,
                formattedValue: niceBytes(eachPoint.BandwidthSum, 0, true, 2),
                formattedDate: moment(eachPoint.Date).format('DD MMMM, YYYY '),
                utcTime: moment(eachPoint.Date).format('HH:MM UTC'),
              }
            }) || []
          }
          title="hello"
          tooltipTitle="Bandwith Usage: "
          bandwidthSummary={bandwidthSummary}
          bandwidthDatapoints={bandwidthDatapoints}
          isBandwidthSummaryLoading={isBandwidthSummaryLoading}
          isBandwidthDatapointsLoading={isBandwidthDatapointsLoading}
        />
      </Row>
    </Container>
  )
}

export default Resources
