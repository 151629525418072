import React from 'react'
import {Field, ErrorMessage} from 'formik'

interface iAppRadioButton {
  label?: string
  name: string
  options: Array<string>
  classes: string
  activeClass: string
  defaultChecked?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioButton = ({
  label,
  name,
  options,
  classes,
  activeClass,
  defaultChecked,
  ...rest
}: iAppRadioButton) => {
  return (
    <>
      <Field name={name} {...rest}>
        {({field}: any) => {
          return options.map((option: any, ind: any) => {
            //
            return (
              <React.Fragment key={option.id}>
                <label
                  htmlFor={option.name}
                  className={`${
                    field.value == option.id
                      ? `${classes + ' ' + option.name + ' ' + activeClass}`
                      : `${classes} ${option.name}`
                  }
                  `}
                >
                  <input
                    type="radio"
                    {...field}
                    value={option.id}
                    checked={field.value == option.id}
                    id={`uid_radioBtn_${ind}`}
                    {...rest}
                    // disabled={
                    //   option.name_backend === 'woocommerce' ? true : false
                    // }
                    // className={`${
                    //   option.name_backend === 'woocommerce'
                    //     ? '!cursor-not-allowed	'
                    //     : ''
                    // }`}
                  />

                  {option.name}
                </label>
              </React.Fragment>
            )
          })
        }}
      </Field>

      <ErrorMessage component="div" className="cwpf_error_box" name={name} />
    </>
  )
}

export default RadioButton
