import moment from 'moment'
import {useState, useEffect, useCallback, useRef} from 'react'

export const isValidNotEmptyArray = (array: any): boolean => {
  return !!(array && array?.length && array?.length > 0)
}

const useInfiniteScroll = (
  useGetDataListQuery: any,
  {limit = 10, ...queryParameters}: any,
) => {
  const [combinedData, setCombinedData] = useState<any>([])
  const [hasMore, setHasMore] = useState(false)
  const [allParams, setAllParams] = useState<{
    offset: number
    datetime_to?: string
    search_term?: string
  }>({
    offset: 1,
    datetime_to: moment(new Date()).format(),
    search_term: undefined,
  })

  const observer = useRef<any>()
  const {
    data: fetchData = [],
    isLoading,
    isFetching,
  } = useGetDataListQuery({
    limit,
    ...allParams,
    ...queryParameters,
  })
  useEffect(() => {
    if (isValidNotEmptyArray(fetchData)) {
      if (allParams.offset === 1) {
        setCombinedData(fetchData)
      } else {
        setCombinedData((previousData: any) => [...previousData, ...fetchData])
      }
      setHasMore(true)
    } else {
      setHasMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData])
  const refresh = useCallback((text?: string) => {
    setCombinedData([])
    console.log(text, 'text')
    setAllParams(prev => {
      return {
        search_term: text,
        offset: 1,
        datetime_to: moment(new Date()).format(),
      }
    })
  }, [])
  const readMore = useCallback(() => {
    return setAllParams(prev => {
      return {...prev, offset: prev.offset + limit}
    })
  }, [])

  const lastElementRef = useCallback(
    node => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          fetchData.length >= 10 && readMore()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore, readMore, fetchData],
  )
  return {
    combinedData,
    offset: allParams.offset,
    readMore,
    refresh,
    setCombinedData,
    hasMore,
    isLoading: isLoading,
    isFetching: isFetching,
    lastElementRef,
  }
}

export default useInfiniteScroll
