/** Libraries */
import React from 'react'
import {Tooltip} from '../Tooltip/Tooltip'

const Icon = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    children,
    styles = '',
    color = '',
    backgroundColor = '',
    dataTip = '',
    positionStyles = '',
    ...rest
  } = props
  const renderIcon = () => {
    return (
      <span
        style={{backgroundColor: backgroundColor, color: color}}
        className={`material-icons cursor-pointer ${styles}`}
        ref={ref}
        {...rest}
      >
        {name}
        {children}
      </span>
    )
  }
  const renderPosition = () =>
    dataTip ? (
      <Tooltip text={dataTip} top>
        {renderIcon()}
      </Tooltip>
    ) : (
      renderIcon()
    )
  return positionStyles ? (
    <span className={`${positionStyles}`}>{renderPosition()}</span>
  ) : (
    renderPosition()
  )
})

export default Icon
