/** Libraries */
import React, {useState} from 'react'
import {useTracking} from 'react-tracking'
import {useParams} from 'react-router-dom'

/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import DummyModal from 'Common/Components/Core/Modal/ManageAppModals/PerformanceModal/DummyModal'
import Section from './Section'
import {customToast} from 'Common/Components/Core/CustomizedToast'

/** Helpers */
import {useClearCloudfareCacheMutation} from 'app/services'

/** Assets (CSS/Images) */
import ResetCacheIcon from 'Assets/img/manage-app/resetcache.svg'
import CachePro from '../../../../../../Assets/img/manage-app/object-cache-pro.png'
import LiteSpeed from '../../../../../../Assets/img/manage-app/life-speed.png'
import Cloudflare from '../../../../../../Assets/img/manage-app/cloudflare.png'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'

const CachingDetails = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState(false)
  const {app_id} = useParams()
  const [clearCloudfareCache] = useClearCloudfareCacheMutation()
  const {trackEvent} = useTracking()
  const resetCacheHandler = useDebounce(() => {
    setSubmitting(true)
    clearCloudfareCache({app_id})
      .unwrap()
      .then((fulfilled: any) => {
        setSubmitting(false)
        if (fulfilled.error) throw new Error()
        customToast({
          level: 'success',
          title: '',
          description: 'Cache is cleared successfully',
        })
      })
      .catch(err => {
        console.log(err, 'error')
      })
  }, 3000)
  return (
    <>
      <Container>
        <Row between middle>
          <Col>
            <Text type="h5" styles="text-dark" semi>
              Caching Details
            </Text>
          </Col>
          <Col>
            <Button
              medium
              primaryText
              styles="text-blue-100 px-[0px] hidden"
              onClick={() => {
                trackEvent({
                  event_name: 'fmui_purge_all_cache',
                })
                setShowModal(true)
              }}
            >
              <img src={ResetCacheIcon} className="mr-4"></img>
              Reset All Cache
            </Button>
          </Col>
        </Row>
        <Row styles="pt-18 cwpf_cachingDetailCardsResp" between gutter={16}>
          <Col lg={6} sm={12} styles="cwpf_cachingDetailCardResp">
            <Section
              submit={submitting}
              name="Cloudways Stack"
              data={[
                {
                  name: 'Object Cache Pro',
                  detail:
                    'Integrates with your WordPress itself & the dashboard',
                  ImgSrc: CachePro,
                },
                {
                  name: 'Lite Speed',
                  detail:
                    'Web Server is compatible with all popular Apache features',
                  ImgSrc: LiteSpeed,
                },
              ]}
            />
          </Col>
          <Col lg={6} sm={12} styles="cwpf_cachingDetailCardResp">
            <Section
              submit={submitting}
              name="CDN"
              data={[
                {
                  name: 'Cloudfare CDN',
                  detail:
                    'Secure your websites, APIs Internet applications and Protect networks',
                  ImgSrc: Cloudflare,
                  button: 'Purge Cache',
                  buttonHandler: resetCacheHandler,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <DummyModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default CachingDetails
