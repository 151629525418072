import {Container} from 'Common/Components/Core/DesignTemplates'
import LogsHeader from './LogsHeader'
import moment from 'moment'
import '../styles/LogsViwer.css'
import Skeleton from 'react-loading-skeleton'
import {iIntelligennceLogs} from '../utils'
import EmptyLog from './EmptyLog'
const UpdateLog = ({
  lastElementRef,
  isFetching,
  isLoading,
  refresh,
  combinedData,
  hasMore,
}: iIntelligennceLogs) => {
  return (
    <Container styles="container">
      <LogsHeader refresh={refresh} />
      {combinedData.length <= 0 && !isLoading && !isFetching ? (
        <EmptyLog />
      ) : (
        <table className="cwpf_log_table w-full table overflow-x-auto overflow-y-hidden border border-solid border-gray-10 rounded-4">
          <tbody>
            {combinedData.map((item: any, index: number) =>
              combinedData.length === index + 1 && combinedData.length >= 10 ? (
                <tr key={index} ref={lastElementRef}>
                  <td className="text-12 w-[20%] text-gray-80">
                    {moment(item?.timestamp).format('MMMM DD hh:mm:ss')}
                  </td>
                  <td className="text-14 w-[80%] text-gray-60 cwpf_logsDetail">
                    {item?.payload}
                  </td>
                </tr>
              ) : (
                <tr key={index}>
                  <td className="text-12 w-[20%] text-gray-80">
                    {moment(item?.timestamp).format('MMMM DD hh:mm:ss')}
                  </td>
                  <td className="text-14 w-[80%] text-gray-60 cwpf_logsDetail">
                    {item?.payload}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      )}
      {isFetching && (
        <>
          {combinedData.length <= 0 && (
            <>
              <Skeleton width={'100%'} height={50} borderRadius={2} />
              <Skeleton width={'100%'} height={50} borderRadius={2} />
            </>
          )}
          <Skeleton width={'100%'} height={50} borderRadius={2} />
        </>
      )}
    </Container>
  )
}

export default UpdateLog
