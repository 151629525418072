/** Libraries */
import {useTracking} from 'react-tracking'

/** Reusable Components */
import {
  Button,
  Card,
  Col,
  CommingSoonScreen,
  Container,
  DropDownMenu,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import EnableBar from 'Common/Components/Core/DesignTemplates/EnableBar/EnableBar'

/** Assets (CSS/Images) */
import EmptyStateImg from 'Assets/img/manage-app/overview/staging-empty-state.svg'
import wordpressLogo from 'Assets/img/application-listing/wp-logo.svg'
import {ReactComponent as StagingManagementImg} from '../../../../../../Assets/img/staging-management.svg'
import './StagingManagement.css'

const StagingManagement = ({isEmpty = false}) => {
  const {trackEvent} = useTracking()
  const EmptyState = () => (
    <Row styles="flex-1 mt-24 mb-20" center col>
      <Row center>
        <img
          src={EmptyStateImg}
          alt="app-list"
          className="w-[170px] h-[132px] rounded-l-[20px]"
        />
      </Row>
      <Row col styles="mt-20 text-center">
        <Text type="h5" styles="h6-med mb-4 text-gray-80">
          Staging Application
        </Text>
        <Text styles="p2 text-gray-80">Create staging of your application</Text>
      </Row>
      <Row center styles="mt-20">
        <Button
          primaryOutline
          medium
          icon="content_copy"
          iconAlign="left"
          onClick={() =>
            trackEvent({
              event_name: 'fmui_push_to_staging',
            })
          }
        >
          Create Staging
        </Button>
      </Row>
    </Row>
  )

  const StagingApp = () => (
    <Row styles="flex-1" col>
      <Row between>
        {/* <Col>
          <Text type="h4" semi>
            Staging Management
          </Text>
        </Col> */}
      </Row>

      <Row styles="mt-30 ml-6" middle>
        <Col>
          <Text type="h5" styles="h5-bold text-gray-80" ellipsis={180}>
            Bankfy
          </Text>
        </Col>
        <Col styles="ml-8">
          <img className={`mr-4`} src={wordpressLogo} alt={'worpress_logo'} />
        </Col>
        <Col>
          {' '}
          <EnableBar styles="ml-10" showIcon={false} info>
            Staging
          </EnableBar>
        </Col>
      </Row>

      <Row styles="mb-4 ml-6">
        <Text className="text-12 text-gray-60 my-[5px]">
          Created: 26th Jan, 2022
        </Text>
      </Row>

      <Row styles="ml-6" row>
        <Row middle styles="ml-[-2px]">
          <Icon name="location_on" styles="cursor-default" />
          <Text className="text-12 ml-[5px]  whitespace-nowrap text-ellipsis overflow-hidden break-all  max-w-[150px] ">
            North America
          </Text>
        </Row>
        <Row middle styles="ml-16">
          <Icon name="insert_link" />
          <Text className="text-12 whitespace-nowrap text-ellipsis overflow-hidden break-all  max-w-[150px] ml-[5px]">
            <a target="_blank" href="https://www.bankfy.com">
              www.bankfy.com
            </a>
          </Text>
        </Row>
      </Row>

      <Row styles="mt-30 ml-4">
        <Col styles="mr-30">
          <Text className="text-12 ml-[5px]">Disk Usage</Text>
          <Text type="h5" styles="h6-med text-gray-80 ml-[5px]">
            2 GB
          </Text>
        </Col>
        <Col styles="mr-30">
          <Text className="text-12 ml-[5px]">Visits</Text>
          <Text type="h5" styles="h6-med text-gray-80 ml-[5px]">
            2420 K
          </Text>
        </Col>
        <Col styles="mr-30">
          <Text className="text-12 ml-[5px]">Bandwidth</Text>
          <Text type="h5" styles="h6-med text-gray-80 ml-[5px]">
            1280 MB
          </Text>
        </Col>
      </Row>

      <Row styles="mt-36 mb-6 ml-8">
        <Button small primaryOutline>
          Manage Staging
        </Button>
      </Row>
    </Row>
  )

  return (
    <Container styles="relative h-[100%]">
      <CommingSoonScreen
        ImgComponent={StagingManagementImg}
        heading="Staging Management"
      />
      <Card styles="px-[22px] py-[18px] min-h-[352px] blur-sm">
        <Row col>
          <Row>{isEmpty ? <EmptyState /> : <StagingApp />}</Row>
        </Row>
      </Card>
    </Container>
  )
}

export default StagingManagement
