/** Libraries */
import React, {useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {useGetCurrentPlanQuery, useGetTotalDiskSpaceQuery} from 'app/services'

/** Reusable Components */
import {
  Col,
  Container,
  Icon,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import HeadpieChart from './Charts/HeadPieChart'

/** Helpers */
import {niceBytes, niceBytesDiskspace} from 'Common/Components/Core/Modal/util'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {useAppDispatch} from 'app/hooks'
import {setDashboardWarningVisible} from './Slice/Dashboardslice'

const TotalDiskSpace = () => {
  const dispatch = useAppDispatch()
  const {data: currentPlan} = useGetCurrentPlanQuery()
  const {data, isLoading, isSuccess} = useGetTotalDiskSpaceQuery('', {
    skip: currentPlan?.app?.count <= 0,
    refetchOnMountOrArgChange: true,
  })
  useEffect(() => {
    if (parseInt(niceBytes(data?.used)) > currentPlan?.plan?.storage_gb) {
      dispatch(setDashboardWarningVisible(true))
      return
    }
    setDashboardWarningVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Container styles=" px-8 min-h-[102px]">
      <Row>
        <Col>
          <Row>
            <Text type="p" semi styles=" text-gray-80 mb-10">
              Total Disk Space
            </Text>
            {/* {parseInt(niceBytes(data?.used)) >
              currentPlan?.plan?.storage_gb && (
              <Icon name="warning_amber" styles="text-orange-100 ml-8" />
            )} */}
          </Row>
        </Col>
        <Col>
          <Container styles=" h-80 w-[130px] flex justify-end  absolute right-8 top-12">
            {isSuccess && (
              <HeadpieChart total={data?.allocated} allocated={data?.used} />
            )}
          </Container>
        </Col>
      </Row>

      <Row>
        {isLoading ? (
          <Skeleton width={100} height={10} borderRadius={10} />
        ) : (
          <Col styles="flex">
            <Text type="div" styles="h1-semi text-gray-80">
              {niceBytesDiskspace(data?.used)}
            </Text>
            <Text
              type="h5"
              semi
              styles="text-gray-80 relative top-[5px] self-center"
            >
              /{currentPlan?.plan?.storage_gb + 'GB'}
            </Text>
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Text type="p" styles="p3 text-gray-80">
            Total Disk Space consumed at the moment
          </Text>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(TotalDiskSpace)
