/** Libraries */
import React, {useState} from 'react'

/** Reusable Components */
import AccessDrawerList from './AccessbilityDrawerList'
import AccessDrawerHeader from './AccessbilityDrawerHeader'
import {Row} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import '../style.css'
export default function AccessDrawer({
  values,
  onCancel,
  check,
  drawerState,
}: any) {
  return (
    <>
      <Row end>
        <div
          className={` py-20  z-[9999] cwpf_dashBoard_drawer h-full fixed drawer drawer-end drawer-mobile
          right-0 top-0 bg-white w-[354px] max-md:w-auto max-lg:overflow-y-auto ${
            drawerState ? 'cwpf_dashBoard_drawer_actv' : ''
          }`}
        >
          <div className="drawer-side max-lg:overflow-y-scroll bg-white">
            {/* <label for="my-drawer" class="drawer-overlay"></label> */}
            <AccessDrawerHeader onCancel={onCancel} values={values} />
            <ul className="menu text-base-content bg-base-100">
              {[1, 2, 3, 4, 5, 6].map((item: any, index) => (
                <AccessDrawerList
                  onCancel={onCancel}
                  check={check}
                  key={index}
                />
              ))}
            </ul>
          </div>
        </div>
      </Row>
    </>
  )
}
