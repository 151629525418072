/** Libraries */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

/** Store/Services */
import {useGetCurrentUserQuery} from 'app/services/platform3'

/** Reusable Components */
import {
  Avatar,
  Col,
  DropDownMenu,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import CW_LOGO from '../../../../Assets/img/clodways_logo.svg'
import {useClearUserSessionMutation} from 'app/services'

const Header: FC = (): JSX.Element => {
  //Clear User Session
  //const [clearUserSession] = useClearUserSessionMutation()

  //Get Current User Plan
  const {currentData, isLoading: isCurrentUserLoading} =
    useGetCurrentUserQuery('')

  //Application Logout Handler
  const logoutHandler = () => {
    window.location.replace(`${window.location.origin}/logout`)
    // clearUserSession({
    //   x_user_id: String(sessionStorage.getItem('x_user_session')),
    // })
    //   .unwrap()
    //   .then(() => {
    //     sessionStorage.removeItem('x_user_session')
    //     window.location.replace(`${window.location.origin}/logout`)
    //   })
    //   .catch(err => new Error(err))
  }

  return (
    <>
      <section className="cwpf_hm_bnr_sec pt-40 pb-[450px] mb-[-400px] relative">
        <div className="container">
          <Row between>
            <Col>
              <Link to="/">
                <img src={CW_LOGO} alt="Cloudways" />
              </Link>
            </Col>

            <Col styles="flex items-center">
              <Row middle>
                <Col styles="cwpf_avatar_box text-right">
                  {isCurrentUserLoading ? (
                    <Skeleton height={50} width={50} circle />
                  ) : (
                    <Avatar sm source={currentData?.image} alt="" />
                  )}
                </Col>

                <Col>
                  <Row middle>
                    <Text
                      type="span"
                      styles="text-14 text-gray-3 font-normal ml-12"
                    >
                      {isCurrentUserLoading ? (
                        <Skeleton height={10} width={50} />
                      ) : (
                        currentData?.name
                      )}
                    </Text>
                    <DropDownMenu
                      styles=" text-light cursor-pointer ml-10 !bg-transparent"
                      icon="expand_more"
                      buttons={[
                        {name: 'View Profile'},
                        {
                          name: 'Logout',
                          handler: logoutHandler,
                        },
                      ]}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default Header
