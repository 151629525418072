/** Libraries */
import Select from 'react-select'
import Row from '../Grid/Row'
import '../../../../../../src/Assets/css/custom.css'
import Icon from '../Icon/Icon'
import {Tooltip} from '../Tooltip/Tooltip'
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select'

/** Reusable Components */
import TextError from 'Common/Components/Core/DesignTemplates/Form/TextError'
import { useLocation } from 'react-router-dom'

const CustomSelect = ({
  label,
  onChange,
  onBlur,
  options,
  value,
  className,
  name,
  error,
  touched,
  indicatorSeparator,
  hintText,
  styles = '',
  id,
  ...rest
}: any) => {
  const location=useLocation()
  const locationPath = location.pathname.replaceAll("/", "_");

  const valueObj = options
    ? options.find((option: any) => option.value === value)
    : ''

  const indicator = indicatorSeparator ? (
    <>
      <Tooltip bottom text={hintText} style="absolute z-10">
        <Icon name="info_outline" styles="text-gray-60 text-16"></Icon>
      </Tooltip>
    </>
  ) : null

  const colourStyles: any = {
    control: (styles: any, state: any) => ({
      ...styles,
      border: '1px solid #8993A4',
      height: '48px',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #8993A4',
      },
    }),
    option: (styles: any) => {
      return {
        ...styles,
      }
    },
    menu: (styles: any) => ({...styles, zIndex: 24}),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      marginLeft: '10px',
      transition: 'all .2s ease-in-out',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  }

  const renderIcon = (option: any, meta: FormatOptionLabelMeta<any>) => {
    if (option.hideIcon) return null

    if (option.showIconOnSelect) if (meta.context === 'menu') return null

    return <div className={`${option.iconClass}`}></div>
  }

  return (
    <div className={`${className}  cwpf_customSelect_box relative`}>
      <label className={`text-14 text-dark`} htmlFor={name}>
        {label}
      </label>
      <Select
        id={`${locationPath}${id ? `_id` : ''}_selector`}
        onChange={(value: any) => onChange(name, value.value)}
        onBlur={() => onBlur(name, true)}
        options={options}
        value={valueObj}
        styles={colourStyles}
        components={{
          IndicatorSeparator: () => indicator,
        }}
        formatOptionLabel={(e: any, meta) => (
          <Row row middle styles="w-full">
            {renderIcon(e, meta)}
            <span
              className={`${
                e.isDisabled ? 'text-gray-80 font-medium' : ''
              } overflow-hidden  whitespace-nowrap text-ellipsis w-[calc(100%-50px)]`}
            >
              {e.label}
            </span>
          </Row>
        )}
        className={`${label ? 'mt-10' : ''} ${styles}`}
        {...rest}
      />
      {error && touched && <TextError>{error}</TextError>}
    </div>
  )
}
export default CustomSelect
