/** Reusable Components */
import {Icon} from '..'

const TextError = ({icon, children}: any) => {
  return (
    <div className="text-red-100 text-12 absolute top-[110%] left-0 flex items-center">
      {icon && (
        <Icon className="text-red-100 text-14 mr-2 material-icons">{icon}</Icon>
      )}
      {children}
    </div>
  )
}

export default TextError
