import {Container, Tabs} from 'Common/Components/Core/DesignTemplates'
import React from 'react'
import CacheAnalysis from './CacheAnalytics/CacheAnalysis'
import LogsViewer from './Logs/LogsContainer'
import TrafficAnalysis from './Traffic Analysis/TrafficAnalysis'

const Intelligence = () => {
  return (
    <Container styles="mt-[40px]">
      <Tabs defaultActive="Logs" vertical>
        <div aria-disabled={true} id="Traffic Analysis">
          {/* <TrafficAnalysis /> */}
        </div>
        <div aria-disabled={true} id="Cache Analysis">
          {/* <CacheAnalysis /> */}
        </div>
        <div id="Logs">
          <LogsViewer />
        </div>
      </Tabs>
    </Container>
  )
}

export default Intelligence
