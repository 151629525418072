/** Libraries */
import {useState} from 'react'
import {useTracking} from 'react-tracking'
/** Reusable Components */
import {Col, Row} from '..'
import Tab from './Tab'
/** Assets (CSS/Images) */
import './Tabs.css'
export default function Tabs({
  children,
  vertical,
  start,
  end,
  center,
  component,
  listStyle = '',
  navStyle = '',
  itemStyle = '',
  styles = '',
  mainEvent = '',
  defaultActive = '',
  containerStyle = '',
  onClickHanler,
}: any) {
  const [isActive, setIsActive] = useState(
    defaultActive || children[0].props.id,
  )

  const {trackEvent} = useTracking()
  const onClick = (key: string, label: string) => {
    key &&
      trackEvent({
        event_name: mainEvent,
        attributes: {
          sub_tab: key || label,
        },
      })
    onClickHanler?.(label)
    setIsActive(label)
  }
  const getPosition = () => {
    if (start) return 'justify-start'
    if (center) return 'justify-center'
    if (end) return 'justify-end'
  }
  return (
    <Row styles={(!vertical ? 'flex-col' : '') + containerStyle}>
      <Col lg={vertical ? 3 : 12}>
        <nav
          className={`tabsNav ${navStyle} ${
            vertical ? 'vertical' : 'horizontal'
          }`}
        >
          <ul
            className={`flex align-center ${listStyle} ${
              vertical
                ? 'flex-col tabsVertical'
                : `tabsHorizontal ${getPosition()}`
            }`}
          >
            {children.map((child: any) => {
              const {
                id: label,
                ['aria-label']: key = undefined,
                ['aria-disabled']: disable = false,
              } = child.props
              return (
                <Tab
                  isActive={isActive}
                  key={key || label}
                  label={label}
                  onClick={() => !disable && onClick(key, label)}
                  itemStyle={itemStyle}
                  styles={styles}
                  disable={disable}
                />
              )
            })}
          </ul>
          {component}
        </nav>
      </Col>
      <Col lg={vertical ? 9 : 12}>
        {children.map((child: any) => {
          if (child.props.id !== isActive) return undefined
          return child.props.children
        })}
      </Col>
    </Row>
  )
}
