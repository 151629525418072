export interface iManageAccess {
  password: string
  username: string
  ip?: string
  databaseName?: string
}
export interface iMADatabase {
  password: string
  username: string
  databaseName: string
}
export const customFormType = {
  ssh: 'ssh',
  database: 'database',
}
export interface propState {
  tab: string | null
}

export interface iManageAccessTrackingObj {
  event_name: string
  attributes: {
    tab_name?: string
    sub_tab?: MANAGE_ACCESS_SUB_TABS
  }
}

export interface iDomainTrackingObj {
  event_name?: string
  attributes?: {
    sub_tab?: MANAGE_ACCESS_SUB_TABS.DOMAIN_MANAGEMENT
    is_success?: boolean
    domain?: string
  }
}

export interface iDatabaseTrackingObj {
  event_name?: string
  attributes?: {
    sub_tab?: MANAGE_ACCESS_SUB_TABS.DATABASE
    is_success?: boolean
  }
}

export const enum MANAGE_ACCESS_SUB_TABS {
  SSH_SFTP = 'ssh_sftp',
  DOMAIN_MANAGEMENT = 'domain_management',
  DATABASE = 'database',
}
export type iManageAccessCreds = {
  created: string
  enabled: boolean
  id: number
  password: string
  username: string
}
export interface iManageAccessTable {
  getSFTPCredsList: iManageAccessCreds[]
  refetch: () => void
}
export interface iEditUser {
  username?: string
  password?: string
}

//customTable
export interface iCustomForm {
  onSubmit: (
    values: iManageAccess,
    {setSubmitting}: {setSubmitting: (value: boolean) => void},
  ) => void
  initialValues: iManageAccess
  isLoading: boolean
  hideIcon?: boolean
  editMode: boolean
  setEditMode: React.Dispatch<boolean>
  label: {
    fieldOne: string
    fieldTwo: string
  }
  name: {
    fieldOne: string
    fieldTwo: string
  }
  styles?: string
  labelStyles?: string
  type: string
}
