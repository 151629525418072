import { Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import Modal from '../../../../Hooks/Modal/Modal'
import * as Yup from 'yup'
import Regex from 'Constants/Regex'
import { Button, Col, Container, Row, Separator, Tabs, Text, TextField } from 'Common/Components/Core/DesignTemplates'
import './ManageAppLimit.css';
import RangeSlider from 'Common/Components/Hooks/RangeSlider/RangeSlider'
import TotalVisits from './TotalVisits'
import DiskSpace from './DiskSpace'

const ManageAppLimit = ({ show, onClose, onConfirm }: any) => {

  return (
   
   <Modal show={show} onClose={onClose} classes="cwpf_editAppName_modal">
      <Text styles="mb-4" type="h6" med>Set limit of total visits for this application</Text>

      <Text styles="mb-30 mr-[55px]">You can set limit of resources as per you need for this application</Text>

      <Tabs>

        <div id="Total Visits">
          <TotalVisits />

        </div>

        <div id="Total Disk Space">
          <DiskSpace />
        </div>
        <div id="Bandwidth">
          <TotalVisits />
        </div>
      </Tabs>



    </Modal>
  )
}

export default ManageAppLimit
