/** Libraries */
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import {useOutletContext} from 'react-router-dom'
import * as Yup from 'yup'

/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Icon,
  Row,
  TextField,
} from 'Common/Components/Core/DesignTemplates'

/** Helpers */
import Regex from 'Constants/Regex'
import {Parameters} from 'Common/Components/Hooks/useOperationsPolling'
import {customFormType} from '../util'

/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {iCustomForm} from '../util'
import {iPropsOutlet} from 'Constants/Interfaces/iGlobal'
import {Tooltip} from 'Common/Components/Core/DesignTemplates/Tooltip/Tooltip'

const CustomForm = ({
  onSubmit,
  initialValues,
  isLoading,
  hideIcon = false,
  editMode,
  setEditMode,
  label,
  name,
  styles = '',
  labelStyles = '',
  type,
}: iCustomForm): JSX.Element => {
  const propsOutlet: iPropsOutlet = useOutletContext()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  // const [showIcon, setShowIcon] = useState('')
  const validate = Yup.object({
    password: Yup.string()
      .required('Please enter password')
      .min(10, 'Password must be atleast 10 characters.')
      .max(40, 'Password must be less than 40 characters')
      .matches(
        Regex.generalPassword_regex.smallChar,
        'At least one small character',
      )
      .matches(
        Regex.generalPassword_regex.capsChar,
        'At least one capital character',
      )
      .matches(Regex.generalPassword_regex.number, 'At least one number')
      .matches(
        Regex.excludeSpecialChar,
        'Password should not contain any special character',
      ),
  })
  return (
    <div className=" mt-30">
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, isSubmitting, isValid, dirty}) => (
          <Form>
            <Container styles="w-[290px] mb-40">
              <TextField
                // postfixIcon={{
                //   name: 'content_copy',
                //   styles: '',
                //   handler: () =>
                //     navigator.clipboard.writeText(initialValues[name.fieldOne]),
                // }}
                label={label.fieldOne}
                name={name.fieldOne}
                type="text"
                disabled
                loading={isLoading}
                styles={styles}
                showCopyIcon={true}
                labelStyles={labelStyles}
              />
            </Container>

            <Row
              gutter={32}
              styles=" mb-60  max-lg:flex-wrap  max-lg:space-x-0"
            >
              <Col bottom>
                <TextField
                  // postfixIcon={{
                  //   name: 'content_copy',
                  //   styles: '',
                  //   handler: () =>
                  //     navigator.clipboard.writeText(initialValues[name.fieldTwo]),
                  // }}
                  styles="w-[290px]"
                  label={label.fieldTwo}
                  name={name.fieldTwo}
                  type="text"
                  disabled
                  loading={isLoading}
                  showCopyIcon={true}
                  labelStyles={labelStyles}
                />
              </Col>
              <Col>
                <Row center>
                  <Col className="relative">
                    <TextField
                      styles="w-[290px] cwpf_passowrd_field"
                      label="Password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      postfixIcon={{
                        name: `${
                          showPassword ? 'visibility' : 'visibility_off'
                        }`,
                        handler: () => setShowPassword(prev => !prev),
                        styles: 'cwpf_password_icon',
                      }}
                      loading={isLoading}
                      showCopyIcon={true}
                      labelStyles={labelStyles}
                      disabled={
                        !editMode ||
                        propsOutlet.checkPolling(
                          type === customFormType.ssh &&
                            Parameters.ssh_password,
                        )
                      }
                    />
                  </Col>

                  {!editMode ? (
                    <Col bottom>
                      <Icon
                        styles={`ml-20 text-gray-60 mt-30 ${
                          hideIcon ? 'hidden' : ''
                        }`}
                        onClick={() => setEditMode(true)}
                      >
                        mode_edit
                      </Icon>
                    </Col>
                  ) : (
                    <Col bottom>
                      <Row styles="ml-20">
                        <Tooltip
                          text="This action is temporariliy disabled because 
of an operation in the background which might
potentially cause conflicts"
                          style="text-left"
                          hide={
                            !propsOutlet.checkPolling(Parameters.ssh_password)
                          }
                        >
                          <Button
                            type="submit"
                            loading={propsOutlet.checkPolling(
                              type === customFormType.ssh &&
                                Parameters.ssh_password,
                            )}
                            disabled={
                              propsOutlet.checkPolling(
                                type === customFormType.ssh &&
                                  Parameters.ssh_password,
                              ) ||
                              isSubmitting ||
                              !(isValid && dirty)
                            }
                          >
                            {propsOutlet.checkPolling(
                              type === customFormType.ssh &&
                                Parameters.ssh_password,
                            )
                              ? 'Updating'
                              : ' Confirm'}
                          </Button>
                        </Tooltip>
                        <Button
                          primaryText
                          onClick={() => {
                            setFieldValue('password', initialValues.password)
                            setEditMode(false)
                          }}
                          disabled={
                            propsOutlet.checkPolling(
                              type === customFormType.ssh &&
                                Parameters.ssh_password,
                            ) || isSubmitting
                          }
                        >
                          Cancel
                        </Button>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CustomForm
