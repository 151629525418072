/** Libraries */
import React, {useState} from 'react'
import {Formik, Form} from 'formik'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

/** Store/Services */
import {useGetAllApplicationsQuery} from 'app/services'

/** Reusable Components */
import {
  Button,
  Col,
  Container,
  Icon,
  Row,
  Text,
  TextField,
  Card,
  Separator,
  CheckBox,
} from 'Common/Components/Core/DesignTemplates'

/** Assets (CSS/Images) */
import {ReactComponent as Wordpresssvg} from '../../../../Assets/img/manage-app/wordpress-logo.svg'
import 'react-loading-skeleton/dist/skeleton.css'

function List({name, setIsChecked, isChecked, app}: any) {
  return (
    <>
      <li className="list-none border-b px-20 bg-white py-10 border-gray-20">
        <input
          type="checkbox"
          className="inline-block align-middle"
          name={name.toString()}
          checked={isChecked[name] || false}
          onChange={e =>
            setIsChecked({...isChecked, [e.target.name]: e.target.checked})
          }
        />

        <Container styles="inline-block align-middle">
          <Container styles="inline-block align-middle mx-12">
            <Wordpresssvg />
          </Container>
          <Container styles="inline-block align-middle">
            <Text med styles="p3 text-gray-80 leading-[14px]">
              {app?.name}
            </Text>
            <ul className="cwpf_dashboard_appDrawerBox">
              <li>
                <Icon name="developer_board" />
                <Text type="b" styles="ml-4">
                  4 GB
                </Text>
              </li>

              <li>
                <Icon name="people" />
                <Text type="b" styles="ml-4">
                  10 K
                </Text>
              </li>

              <li>
                <Icon name="donut_large" />
                <Text type="b" styles="ml-4">
                  512 GB
                </Text>
              </li>
            </ul>
          </Container>
        </Container>
      </li>
    </>
  )
}

const Dropdown = ({dropdownRef}: any) => {
  const [temp, setTemp] = useState('sss')
  const [isChecked, setIsChecked] = useState({})
  const {data, isLoading: isAppListLoading} = useGetAllApplicationsQuery(
    {
      page: 1,
      page_size: 100,
    },
    {skip: !window.navigator.onLine},
  )
  return (
    <Card
      styles="w-[290px] bg-white z-[2] right-10 absolute top-34 p-0 shadow-3"
      border
    >
      <div ref={dropdownRef}>
        <Formik
          onSubmit={values => {
            console.log(values)
          }}
          initialValues={{name: ''}}
        >
          <Form>
            <Row center>
              <Col styles="w-full px-20">
                <TextField
                  name="Search"
                  type="text"
                  styles="!border-gray-20 pl-40 h-32 !my-16 outline-0"
                  labelStyle="text-16"
                  placeHolder="Search Application"
                  iconAlign="left"
                  postfixIcon={{
                    name: 'search',
                    styles: '!text-gray-40 absolute left-0 px-12 w-30',
                    handler: () => navigator.clipboard.writeText(temp),
                  }}
                />
              </Col>
            </Row>
            <div className="border-t py-10 border-gray-10">
              <Text type="p" semi styles="p3 text-gray-80 mx-18">
                3 Applications
              </Text>
              <ul className="">
                {isAppListLoading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  data?.results.map((item: any, i: number) => (
                    <List
                      name={`app-${i}`}
                      setIsChecked={setIsChecked}
                      isChecked={isChecked}
                      app={item}
                    />
                  ))
                )}
              </ul>
            </div>
            <Container styles="mx-14 mt-24 mb-14">
              <Row between>
                <Col>
                  <Button
                    onClick={() => {
                      setIsChecked({})
                    }}
                    small
                    primaryText
                    styles="p2 text-blue-100"
                  >
                    Clear all
                  </Button>
                </Col>
                <Col>
                  <Button small>Apply</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Formik>
      </div>
    </Card>
  )
}

export default Dropdown
