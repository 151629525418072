/** Libraries */
import {Field, ErrorMessage} from 'formik'
import Skeleton from 'react-loading-skeleton'
/** Reusable Components */
import TextError from './TextError'
import {Icon} from '..'
import CopyClipboard from './CopyClipboard'
/** Assets (CSS/Images) */
import 'react-loading-skeleton/dist/skeleton.css'
import {useLocation} from 'react-router-dom'
const TextField = ({
  loading = false,
  label,
  name,
  type,
  icon,
  placeHolder,
  styles = '',
  labelStyle = '',
  iconStyle = '',
  disabled,
  postfixIcon = {name: '', styles: ''},
  showCopyIcon = false,
  id,
  ...rest
}: any) => {
  const location = useLocation()
  const locationPath = location.pathname.replaceAll('/', '_')

  const Disabled = disabled ? 'border-none bg-gray-3 text-ellipsis' : ''
  return (
    <div className={`relative`}>
      {label &&
        (loading ? (
          <Skeleton width={100} height={10} borderRadius={10} />
        ) : (
          <label className={`text-14 text-dark ${labelStyle}`} htmlFor={name}>
            {label}
          </label>
        ))}
      {loading ? (
        <Skeleton width={240} height={40} borderRadius={4} />
      ) : (
        <Field name={name}>
          {({field, meta}: any) => (
            <div className="cwpf_generic_fieldBox relative">
              <input
                id={`${locationPath}${id ? `_id` : id}_${
                  postfixIcon.name
                }_${name}_field`}
                type={type}
                placeholder={placeHolder}
                disabled={disabled}
                className={` w-[100%] ${
                  meta.touched && meta.error && 'is-invalid'
                } ${Disabled} border border-gray-40 p-12 mt-10 text-14 rounded-4 flex items.center overflow-x-hidden pr-[80px] whitespace-nowrap ${styles}`}
                {...field}
                {...rest}
              ></input>
              {postfixIcon.name && (
                <Icon
                  id={`${locationPath}${id ? `_id` : id}_${
                    postfixIcon.name
                  }_${name}_field`}
                  name={postfixIcon.name}
                  onClick={postfixIcon.handler}
                  styles={`${iconStyle} ${
                    postfixIcon.styles
                  } absolute top-[50%] translate-y-[-50%] ${
                    showCopyIcon ? 'right-[50px]' : 'right-12'
                  }  z-12 cursor-pointer text-20 text-gray-60`}
                  dataTip={`Copy ${label}`}
                  key="postFixIcon"
                />
              )}
              {showCopyIcon && (
                <CopyClipboard tooltip={label} copyText={field?.value} />
              )}
            </div>
          )}
        </Field>
      )}
      <ErrorMessage name={name}>
        {(msg: any) => <TextError icon={icon}>{msg}</TextError>}
      </ErrorMessage>
    </div>
  )
}
export default TextField
