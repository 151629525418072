import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import moment from 'moment'
import track from 'react-tracking'
import {SkeletonTheme} from 'react-loading-skeleton'
import {ToastContainer} from 'react-toastify'

/* Services */
import {
  apiSlice,
  useGetCurrentPlanQuery,
  useSetUserSessionMutation,
} from './app/services'
import {platform3, useGetCurrentUserQuery} from 'app/services/platform3'
import {usePostEventsMutation} from 'app/services'
import Routes from './Routes/Index'

/* Components */
import PreLoader from './Common/Components/Core/PreLoader'
import GlobalModals from 'Common/Components/Core/Modal'
import ToastDisplay from 'Common/Components/Core/DesignTemplates/Toast/ToastDisplay'

import 'react-toastify/dist/ReactToastify.min.css'
import './App.css'

import {customEvent} from 'index'
import ScrollToTop from 'Common/Components/Core/ScrollToTop/ScrollToTop'
import {data} from 'Common/Components/Pages/ManageApp/Performance/util'
import {store} from 'app/store'

/* Data Layer Type */
declare const window: customEvent

function App() {
  /* Service Integration */
  const {currentData: _, isLoading: isCurrentUserLoading} =
    useGetCurrentUserQuery('')
  const {
    data: currentPlan,
    isLoading: isCurrentPlanLoading,
    error: currentPlanErr,
  } = useGetCurrentPlanQuery<any>()
  /* Post user events */
  const [postEvent] = usePostEventsMutation()

  /* Post user events, every 25 seconds */
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.events?.length && window.navigator.onLine) {
        postEvent({events: window.events})
          .unwrap()
          .then(() => {
            window.events = []
          })
          .catch(error => new Error(error))
      }
    }, 25000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.events?.length])

  return (
    <main>
      {isCurrentUserLoading || isCurrentPlanLoading ? (
        <PreLoader />
      ) : (
        <>
          <SkeletonTheme baseColor="#F4F5F7" highlightColor="#DFE1E6">
            <Router>
              <GlobalModals />
              <ScrollToTop>
                <Routes
                  /* if the plan is no longer exist, redirect the user to root*/
                  currentPlan={
                    currentPlanErr?.status === 403 ? null : currentPlan
                  }
                />
              </ScrollToTop>
            </Router>
          </SkeletonTheme>
        </>
      )}
      <ToastContainer />
      <ToastDisplay />
    </main>
  )
}

const TrackedApp = track(
  {
    attributes: {
      event_category: 'frontend',
      device: window.innerWidth <= 768 ? 'mobile' : 'desktop',
    },
  },
  {
    dispatch: async event => {
      const eventsWithAdditionalAttributes = {
        ...event,
        attributes: {
          ...event.attributes,
          distinct_id: window.distinct_id,
          time: moment.utc().valueOf(),
        },
      }

      return (window.events = window.events || []).push(
        eventsWithAdditionalAttributes,
      )
    },
  },
)(App)

export default TrackedApp
