import React from 'react'
import {Container, Text, Col, Row} from '../DesignTemplates'

const FullPageErrorFallBack = ({error}: any) => {
  return (
    <main>
      <div className="cw_ssh relative h-[100vh]">
        {error?.message && (
          <article className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col items-center">
            <Text type="h1" styles="mb-20 text-red-75">
              Ahhh !! somthing went wrong ! Please Try Again
            </Text>
            <div className="collapse w-96 border rounded-box border-base-300 collapse-arrow block">
              <input type="checkbox" className="absolute" />
              <div className="collapse-title text-xl font-medium">
                {error.message}
              </div>
              <div className="collapse-content">
                <pre>{error.stack}</pre>
              </div>
            </div>
          </article>
        )}
        {error.status && (
          <article className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col items-center">
            <Text type="h1" styles="mb-20 text-red-75">
              {error.originalStatus}: {error.status}
            </Text>
            <div className="collapse w-96 border rounded-box border-base-300 collapse-arrow block">
              <input type="checkbox" className="absolute" />
              <div className="collapse-title text-xl font-medium">
                {error.error}
              </div>
              <div className="collapse-content">
                <>{error.data}</>
              </div>
            </div>
          </article>
        )}
      </div>
    </main>
  )
}

export default FullPageErrorFallBack
