/** Libraries */
import {CSSTransition} from 'react-transition-group'

/** Assets (CSS/Images) */
import './animation.css'
// interface iAnimation {
//   fadeIn?: boolean
//   slideInBottom?: boolean
//   fadeInOnly?: boolean
//   fadeOutOnly?: boolean
//   children?: React.ReactNode
//   timeout?: number
//   in: boolean
// }
const Animation = ({
  fadeIn,
  slideInBottom,
  fadeInOnly,
  fadeOutOnly,
  children,
  timeout = 300,
  ...rest
}: any) => {
  const getType = () => {
    if (fadeIn) return 'fadeIn'
    if (slideInBottom) return 'slideInBottom'
    if (fadeInOnly) return 'fadeInOnly'
    if (fadeOutOnly) return 'fadeOutOnly'
    return 'fadeIn'
  }
  return (
    <CSSTransition timeout={timeout} classNames={getType()} {...rest}>
      {children}
    </CSSTransition>
  )
}

export default Animation
