import React, {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useGetCurrentUserQuery} from 'app/services/platform3'
import cloudwaysLogo from '../../../../Assets/img/header/cloudways-logo.svg'
import Popup from '../Popup/Popup'
import './GlobalHeader.css'
import {DropDownMenu} from '../DesignTemplates'
import {Container, Text, Col, Row} from '../DesignTemplates'
import useConnection from 'Common/Components/Hooks/useConnection'
import {useAppSelector} from 'app/hooks'
import NoConnectionLoader from '../NoConnection/NoConnectionLoader'
import {useClearUserSessionMutation} from 'app/services'

const GlobalHeader = () => {
  const [showMenu, setShowMenu] = useState(false)
  const iconRef = useRef<any>(null)

  //Clear User Session
  //const [clearUserSession] = useClearUserSessionMutation()

  //Get Current User Plan
  const currentUser = useGetCurrentUserQuery('')

  const showHandler = () => {
    setShowMenu(false)
  }
  const logoutHandler = () => {
    window.location.replace(`${window.location.origin}/logout`)
    // clearUserSession({
    //   x_user_id: String(sessionStorage.getItem('x_user_session')),
    // })
    //   .unwrap()
    //   .then(() => {
    //     sessionStorage.removeItem('x_user_session')
    //     window.location.replace(`${window.location.origin}/logout`)
    //   })
    //   .catch(err => new Error(err))
  }
  return currentUser.isLoading ? (
    <>Loading</>
  ) : (
    <header className="cwpf_glbHdr_wrap">
      <Container>
        <Row between>
          <Col styles="cwpf_glbHdr_logoBox">
            <Link to="/">
              <img src={cloudwaysLogo} alt="" />
            </Link>
          </Col>
          <Row>
            {/* <Col styles="cwpf_glbHdr_searchBox">
              <Text
                type="span"
                styles="material-icons relative top-[6px] mr-10 text-22 text-gray-30"
              >
                search
              </Text>
              <input
                type="search"
                className="bg-transparent"
                placeholder="Search"
                disabled
              />
            </Col> */}
            <Col styles="cwpf_glbHdr_userHandler">
              <Text type="b">{currentUser?.currentData?.name}</Text>
              <DropDownMenu
                icon="expand_more"
                buttons={[
                  // {name: 'View Profile'},
                  {name: 'Logout', handler: logoutHandler},
                ]}
              />
            </Col>
            <Col>
              <a
                href="https://support.cloudways.com/"
                target="_blank"
                className="cwpf_glbHdr_support"
              >
                <Text type="span" styles="material-icons">
                  {' '}
                  help
                </Text>
              </a>
            </Col>
          </Row>
        </Row>
      </Container>
    </header>
  )
}

export default GlobalHeader
