import {Icon, Text} from 'Common/Components/Core/DesignTemplates'
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'

const Tab = ({
  onClick,
  label,
  item,
  isActive,
  itemStyle,
  routeEnable,
  styles,
  disable,
}: any) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.split('/').length > 4) {
      setOpen(true)
    }
  }, [])
  if (routeEnable) {
    if (item.childrens) {
      let parentClass = ''
      item.childrens.map((child: any, key: number) => {
        if (child.childrens !== undefined) {
          child.childrens.map((gChild: any, key: number) => {
            if (location.pathname.includes(gChild.path.trim())) {
              parentClass = 'parentActive'
            }
          })
        }
      })
      return (
        <li
          id={item.title.trim().toLowerCase()}
          onClick={() => onClick(label)}
          className={`cursor-pointer flex flex-col ${
            item.path.length === 0
              ? ''
              : location.pathname.includes(item.path.trim())
              ? 'active'
              : ''
          }  ${itemStyle} ${parentClass}`}
        >
          <Link
            to={item.path.length === 0 ? location.pathname : item.path}
            className="flex items-center parentItem"
            onClick={() => setOpen(!open)}
          >
            <Text styles={`${styles} p1 text-gray-60`}>{label}</Text>
            {open ? (
              <Icon styles="ml-8 text-14">expand_less</Icon>
            ) : (
              <Icon styles="ml-8 text-14">expand_more</Icon>
            )}
          </Link>

          {open &&
            item.childrens.map((child: any) => {
              const {title: label, ['aria-label']: key = undefined} = child
              return (
                <Tab
                  isActive={isActive}
                  key={key || label}
                  label={label}
                  item={child}
                  routeEnable={routeEnable}
                  onClick={() => onClick(key, label)}
                  itemStyle={`ml-20 nonactive`}
                  styles={styles}
                />
              )
            })}
        </li>
      )
    } else {
      return (
        <li
          onClick={() => onClick(label)}
          className={`${itemStyle} cursor-pointer flex items-center ${
            item.path.length === 0
              ? ''
              : location.pathname.includes(item.path.trim())
              ? 'active'
              : ''
          }`}
        >
          <Link to={item.path}>
            <Text styles={`${styles} p1 text-gray-60`}>{label}</Text>
          </Link>
        </li>
      )
    }
  } else {
    return (
      <li
        onClick={() => onClick(label)}
        className={`${itemStyle}  cursor-pointer flex items-center ${
          isActive === label ? 'active' : ''
        } ${disable ? 'opacity-[0.3]' : ''}`}
      >
        <Text styles={`${styles} p1 text-gray-60`}>{label}</Text>
      </li>
    )
  }
}

export default Tab
