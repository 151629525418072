import {
  useIntelligenceLogsCountQuery,
  useIntelligenceLogsQuery,
} from 'app/services'
import {useDebounce} from 'Common/Components/Hooks/useDebounce'
import useDebouncedInput from 'Common/Components/Hooks/useDebounceInput'
import useInfiniteScroll from 'Common/Components/Hooks/useInfiniteScroll'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import EmptyLog from './UpdatedLogs/EmptyLog'
import UpdateLog from './UpdatedLogs/UpdatedLog'

const ErrorLogs = () => {
  const {app_id} = useParams()
  // const [t, setT] = useState<any>(null)

  const {
    lastElementRef: errorRef,
    isFetching: errorIsFetching,
    isLoading: errorLoading,
    refresh: errorRefresh,
    combinedData: errorData,
    hasMore: errorHasMore,
  } = useInfiniteScroll(useIntelligenceLogsQuery, {
    limit: 10,
    app_id,
    type: 'error-logs',
  })
  useEffect(() => {
    if (app_id) {
      errorRefresh()
    }
  }, [app_id])

  return (
    <UpdateLog
      lastElementRef={errorRef}
      isFetching={errorIsFetching}
      refresh={errorRefresh}
      combinedData={errorData}
      hasMore={errorHasMore}
      isLoading={errorLoading}
    />
  )
}

export default ErrorLogs
