/** Reusable Components */
import {
  Card,
  Col,
  CommingSoonScreen,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'
import Logs from 'Common/Components/Core/DesignTemplates/Logs/Logs'

/** Assets (CSS/Images) */
import {ReactComponent as ActivityLogsImg} from '../../../../../../Assets/img/activitylogs.svg'
import EmptyStateImg from 'Assets/img/manage-app/overview/logs-empty-state.svg'
import './ActivityLogs.css'

const sampleLogs = [
  {
    title: 'Backup',
    description:
      "your app 'Woo Commerce' was updated to the lastest version 4.5",
  },
  {
    title: 'Plugin Updated',
    description:
      "your app 'Woo Commerce' was updated to the lastest version 4.5",
  },
  {
    title: 'Theme Updated',
    description:
      "your app 'Woo Commerce' was updated to the lastest version 4.5",
  },
  {
    title: 'App Updated',
    description:
      "your app 'Woo Commerce' was updated to the lastest version 4.5",
  },
]

const ActivityLogs = ({isEmpty = false}) => {
  const EmptyState = () => (
    <Row styles="flex-1  mt-20 mb-20" middle col>
      <Col>
        <img
          src={EmptyStateImg}
          alt="app-list"
          className="w-[170px] h-[132px] rounded-l-[20px]"
        />
      </Col>
      <Col styles="mt-20">
        <Text type="h5" styles="h6-med text-gray-80">
          Not enough logs results to show right now.
        </Text>
      </Col>
    </Row>
  )

  const RenderLogs = () => <Logs styles="mt-14 mb-12" logsList={sampleLogs} />

  return (
    <Container styles="relative h-[100%]">
      <CommingSoonScreen
        ImgComponent={ActivityLogsImg}
        heading="Activity Logs"
      />
      <Card styles="px-[22px] py-[18px]  h-full  blur-sm">
        <Row col styles="relative" row>
          <Row>
            {/* <Text type="h4" semi>
              Activity Logs
            </Text> */}
          </Row>

          <Row>{isEmpty ? <EmptyState /> : <RenderLogs />}</Row>
        </Row>
      </Card>
    </Container>
  )
}

export default ActivityLogs
