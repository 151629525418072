import React from 'react'

//design system
import {
  Button,
  Col,
  Container,
  Row,
  Text,
} from 'Common/Components/Core/DesignTemplates'

//components
import Modal from 'Common/Components/Hooks/Modal/Modal'

const DeleteCloudfareModal = (props: any) => {
  return (
    <Modal show={props.show} classes="w-[25%]" onClose={props.onClose}>
      <Container styles="manageaccess_Delete_sshUser_modal">
        <Container styles="container">
          <Text type="h6" med styles="text-gray-80 mb-14">
            Confirmation
          </Text>
          <Text styles="p2 text-gray-80">
            You are about to delete the “{props.domain}” CDN subscription. Your
            traffic on this domain will no longer proxy through cloudfare. Make
            sure DNS Records are updated to your server directly instead of
            Cloudfare.
          </Text>

          <Row styles="mt-[19px]">
            <Col>
              <Button
                danger
                onClick={props.handler}
                disabled={props.loading}
                styles="p1-med px-24"
              >
                Delete Domain
              </Button>
            </Col>
            <Col>
              <Button onClick={props.onClose} primaryText>
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Modal>
  )
}
export default DeleteCloudfareModal
