import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  limitAppModal: false,
  modifyPlanModal: false,
  unsubscribePlanModal: false,
  addApplicationModal: false,
  confirmPlanModal: false,
  addAppPlanModal: false,
  planUpgradedModal: false,
  showSessionExpiredModal: false,
  deleteAppModal: {
    show: false,
    data: {},
  },
  deleteModal: {
    show: false,
    id: '',
  },
}

const modalSlice = createSlice({
  name: 'modalState',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleModal: (state, action) => {
      return {...state, [action.payload]: !state[action.payload]}
    },
    openModal: (state, action) => {
      return {...state, [action.payload]: true}
    },
    closeModal: (state, action) => {
      return {...state, [action.payload]: false}
    },

    toggleDeleteModal: (state, action) => {
      return {
        ...state,
        [action.payload.type]: {
          show: !state[action.payload.type].show,
          data: action.payload.data,
        },
      }
    },
    closeDeleteModal: (state, action) => {
      return {
        ...state,
        [action.payload.type]: {
          show: false,
          data: action.payload.data,
        },
      }
    },
  },
})

//export const modalState = (state: RootState) => state.modalState.showModal
export const {
  toggleModal,
  closeModal,
  openModal,
  toggleDeleteModal,
  closeDeleteModal,
} = modalSlice.actions

export default modalSlice.reducer
