/** Libraries */
import {useAppSelector} from 'app/hooks'

/** Reusable Components */
import Toast from './Toast'

const ToastDisplay = () => {
  const {toastList} = useAppSelector(state => state.toast)

  return <Toast toastList={toastList} position="top-right" autoDelete={true} />
}

export default ToastDisplay
