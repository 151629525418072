import React from 'react'
import {toast} from 'react-toastify'
import {Container, Icon, Text} from '../DesignTemplates'
import './CustomToast.css'

const CustomizedToast = ({
  level = 'info',
  title,
  description,
  loading,
}: {
  level: 'info' | 'success' | 'warning' | 'error'
  title: string
  description: string | JSX.Element
  loading?: boolean
}) => {
  const getIcon = () => {
    if (level.toLowerCase() === 'success')
      return {
        backgroundColor: '#09EBAF',
        fontColor: '#253858',
        icon: 'check_circle_outline',
      }
    if (level.toLowerCase() === 'error')
      return {
        fontColor: '#fff',
        backgroundColor: '#EA4545',
        icon: 'error_outline',
      }
    if (level.toLowerCase() === 'warning')
      return {
        fontColor: '#253858',
        backgroundColor: '#FCE039',
        icon: 'warning_amber',
      }
    if (level.toLowerCase() === 'info')
      return {
        backgroundColor: '#5961CC',
        fontColor: '#ffff',
        icon: 'error_outline',
      }
    return {
      backgroundColor: '#09EBAF',
      fontColor: '#253858',
      borderColor: 'border-[#09EBAF]',
      icon: 'check_circle_outline',
    }
  }
  return (
    <>
      {loading ? (
        <Container
          style={{
            backgroundColor: getIcon().backgroundColor,
            color: getIcon().fontColor,
          }}
          styles={`min-w-[48px] h-[100%]  self-start flex items-center justify-center text-gray-80 cwpf_glb_spinner`}
        ></Container>
      ) : (
        <Icon
          name={getIcon().icon}
          color={getIcon().fontColor}
          backgroundColor={getIcon().backgroundColor}
          styles="min-w-[48px] h-[100%]  self-start flex items-center justify-center text-gray-80"
        />
      )}
      <Text type="p" styles="text-gray-80 p3 py-[6px] ">
        {description}
      </Text>
    </>
  )
}

export const customToast = ({
  level,
  title,
  description,
  loading = false,
  ...other
}: {
  level: 'info' | 'success' | 'warning' | 'error'
  title: string
  description: string | JSX.Element
  loading?: boolean
}): React.ReactText => {
  return toast[level](
    <CustomizedToast
      level={level}
      title={title}
      loading={loading}
      description={description}
    />,
    {icon: false, hideProgressBar: true, ...other},
  )
}
