/** Libraries */
import {useTracking} from 'react-tracking'

/** Reusable Components */
import {Col, Container, Row, Text} from 'Common/Components/Core/DesignTemplates'
import ActivityLogs from './ActivityLogs'
import ApplicationOverview from './ApplicationOverview'
import Resources from './Resources'
import StagingManagement from './StagingManagement'
import WordPressUpdates from './WordPressUpdates'

/** Helpers */
import {iOverviewAppTrackingObj} from './util'
import {TABNAMES} from '../Util'

/** Assets (CSS/Images) */
import './Overview.css'

const Overview = () => {
  const {Track} = useTracking<iOverviewAppTrackingObj>({
    attributes: {
      tab_name: TABNAMES.OVERVIEW,
    },
  })
  return (
    <Track>
      <Container styles="pt-60 pb-40 space-y-24">
        <ApplicationOverview />
        <Resources />

        <Row styles="cwpf_overViewResp" stretch between gutter={24}>
          <Col lg={6} sm={12}>
            <ActivityLogs />
          </Col>
          <Col styles="cwpf_overViewColResp" lg={6} sm={12}>
            <StagingManagement />
          </Col>
        </Row>

        <WordPressUpdates />
      </Container>
    </Track>
  )
}

export default Overview
