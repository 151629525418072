import {Card, Text} from 'Common/Components/Core/DesignTemplates'
import React from 'react'
import {ReactComponent as EmptySvg} from '../../../../../../../Assets/img/manage-app/Intelligence/empty.svg'

const EmptyLog = () => {
  return (
    <Card styles="w-full mt-40 h-[60vh] flex flex-col justify-center items-center">
      <EmptySvg />
      <Text>No logs to show</Text>
    </Card>
  )
}

export default EmptyLog
