/** Reusable Components */
import { Container } from "Common/Components/Core/DesignTemplates"

const ListBoxes = ({
  installedApp,
  listColor = 'bg-blue-50',
}: {
  installedApp: number
  listColor?: string
}) => {
  return (
    <Container styles="list-none flex flex-row flex-wrap gap-4 w-[51px] ">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, idx) => {
        return (
          <Container
            key={idx}
            className={
              idx + 1 <= installedApp
                ? `cwpf_li_filled ${listColor}`
                : `cwpf_li_empty`
            }
          ></Container>
        )
      })}
    </Container>
  )
}

export default ListBoxes
