import {Navigate} from 'react-router-dom'
import ConditionalRoutes from '../ConditionalRoutes'
import ProtectedRoute from '../ProtectedRoute'
import AddApplication from '../../Common/Components/Pages/AddApplication/AddApplication'

import {CurrentPlan} from 'app/services/plans'
//Dashboard Route
import Dashboard from '../../Common/Components/Pages/Dashboard/DashboardScreen'
import ApplicationList from '../../Common/Components/Pages/ApplicationListing'
import ManageApp from '../../Common/Components/Pages/ManageApp'
//ManageApp Child Components
import Performance from '../../Common/Components/Pages/ManageApp/Performance'
import Security from '../../Common/Components/Pages/ManageApp/Security'
import ManageAccess from 'Common/Components/Pages/ManageApp/ManageAccess'
import ApplicationTools from 'Common/Components/Pages/ManageApp/WorkflowTools'

import Overview from 'Common/Components/Pages/ManageApp/Overview'
import Intelligence from 'Common/Components/Pages/ManageApp/Intelligence'

const routes = (
  isLoggedIn: boolean,
  {currentPlan}: {currentPlan: CurrentPlan},
) => [
  {path: '/', element: <ConditionalRoutes currentPlan={currentPlan} />},
  {path: '*', element: <ConditionalRoutes currentPlan={currentPlan} />},
  {
    path: '/',
    element: !isLoggedIn ? <ProtectedRoute /> : <Navigate to="/" />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
        // element:
        //   currentPlan && currentPlan?.app?.count > 0 ? (
        //     <Dashboard />
        //   ) : (
        //     <Navigate to="/" />
        //   ),
      },
      {
        path: '/add-application',
        element:
          currentPlan && currentPlan?.app?.count === 0 ? (
            <AddApplication />
          ) : (
            <Navigate to="/" />
          ),
      },
      {
        path: '/application-listing',
        element:
          currentPlan && currentPlan?.app?.count > 0 ? (
            <ApplicationList />
          ) : (
            <Navigate to="/" />
          ),
      },
      {
        path: 'manage-app',
        element:
          currentPlan && currentPlan?.app?.count > 0 ? (
            <ManageApp />
          ) : (
            <Navigate to="/" />
          ),
        children: [
          {index: true, element: <Overview />},
          {path: ':app_id/', element: <Overview />},
          {path: ':app_id/overview', element: <Performance />},
          {path: ':app_id/performance', element: <Performance />},
          {path: ':app_id/intelligence', element: <Intelligence />},
          {path: ':app_id/security', element: <Security />},
          {
            path: ':app_id/workflow-tools',
            element: <ApplicationTools />,
          },
          {
            path: ':app_id/manage-access',
            element: <ManageAccess />,
          },
        ],
      },
    ],
  },
]

export default routes
