/** Reusable Components */
import {Container, Tabs} from 'Common/Components/Core/DesignTemplates'
import TimeZone from './TimeZone'
import './styles/workFlowTools.css'
import BackupRestore from './BackupRestore'
//import BackupRestore from './BackupRestore/index'
const ApplicationTools = () => {
  return (
    <Container className="mt-[35px] relative">
      {/* <TimeZone /> */}
      <Tabs vertical>
        <div id="TimeZone">
          <TimeZone />
        </div>
        <div id="Backup/Restore">
          <BackupRestore />
        </div>
      </Tabs>
    </Container>
  )
}

export default ApplicationTools
