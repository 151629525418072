/** Reusable Components */
import {Icon, Text} from '../index'
import Container from '../Container/Container'
import Row from '../Grid/Row'

/** Assets (CSS/Images) */
import './Logs.css'

interface Logslist {
  title: string
  description: string
}
const Logs = ({
  logsList = [],
  styles = '',
}: {
  logsList: Logslist[]
  styles?: string
}) => {
  return (
    <Container styles={`cwpf_log_mainBox ${styles}`}>
      {logsList.map(eachLog => (
        <Container styles="cwpf_log_inrBox">
          <Icon styles="cwpf_logDtl_point"></Icon>
          <Row styles="cwpf_logs_box p-18 pb-0 pt-12">
            <Text styles="text-16 text-gray-80 text-left">
              <Text
                type="strong"
                className="font-medium whitespace-nowrap mr-8"
              >
                {eachLog.title}:
              </Text>
              {eachLog.description}
            </Text>
          </Row>
        </Container>
      ))}
    </Container>
  )
}

export default Logs
