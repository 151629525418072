import {useAppDispatch} from 'app/hooks'
import React from 'react'
import errorimg from '../../../../../Assets/img/modal/error-icon.png'
import Modal from '../../../Hooks/Modal/Modal'
import {toggleModal} from '../ModalSlice'
import {ModalName} from '../util'
//Design system
import {Button, Container, Text} from '../../DesignTemplates'

export default function MaxLimit({show, onClose}: any) {
  const dispatch = useAppDispatch()
  const modalHandler = () => {
    onClose()
    dispatch(toggleModal(ModalName.modifyPlanModal))
  }
  return (
    <>
      <Modal show={show} onClose={onClose} classes="cwpf_appLimit_modal">
        <Container styles="cwpf_cnfrmPlan_mainsec">
          <Container styles="cwpf_cnfrmPlan_mainBox pt-100 px-[48px]">
            <Container styles="cwpf_cnfrmPlan_img  mb-24">
              <img src={errorimg} alt="" />
            </Container>
            <Text semi type="h3" styles="text-gray-80 mt-[24px] mb-[36px]">
              You have reached <br /> your application limit!
            </Text>

            <Text type="h6" styles="text-gray-80">
              Please upgrade your plan to add another <br /> application
            </Text>
            <Container styles="cwpf_cnfrmPlan_ctaButton mb-20 mt-100">
              <Button
                medium
                type="button"
                icon="arrow_forward"
                iconAlign="right"
                block
                onClick={modalHandler}
                id="7"
              >
                Upgrade Plan
              </Button>
            </Container>
          </Container>
        </Container>
      </Modal>
    </>
  )
}
