import {useState} from 'react'
import './Accordion.css'

interface faqsType {
  title: string
  content: string
}

const FaqItem = ({title, content}: faqsType): JSX.Element => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      className={`cwPltfrm_faq_inrBox cursor-pointer overflow-hidden`}
      onClick={() => {
        setVisible(!visible)
      }}
    >
      <div className="cwPltfrm_faq_ques relative">
        <h6 className="h6-med py-20 border-b border-gray-10">{title}</h6>
        <span className="material-icons absolute top-[calc(50%)] translate-y-[-50%] right-0 text-gray-80">
          {!visible ? `expand_more` : `expand_less`}
        </span>
      </div>
      <div className={!visible ? `cwPltfrm_faq_ans` : `cwPltfrm_faq_ans_actv`}>
        <p className="p-20">{content}</p>
      </div>
    </div>
  )
}

export default FaqItem
