/** Libraries */
import {useParams} from 'react-router-dom'
import {useTracking} from 'react-tracking'

/** Reusable Components */
import CustomLink from 'Common/Components/Core/DesignTemplates/CustomLink/CustomLink'

/** Helpers */
import {TABNAMES} from '../Util'

/** Assets (CSS/Images) */
import {ReactComponent as Overview} from 'Assets/img/manage-app/overview.svg'
import {ReactComponent as Performance} from 'Assets/img/manage-app/performance.svg'
import {ReactComponent as Intelligence} from 'Assets/img/manage-app/intelligence.svg'
import {ReactComponent as Security} from 'Assets/img/manage-app/security.svg'
import {ReactComponent as Tools} from 'Assets/img/manage-app/Tools.svg'
import {ReactComponent as ManageAccess} from 'Assets/img/manage-app/manage-access.svg'

const HeaderTabs = () => {
  const {app_id} = useParams()
  const {trackEvent} = useTracking()

  const onTabChange = (tab_name: TABNAMES) => {
    trackEvent({
      event_name: 'fmui_manage_app_tab',
      attributes: {
        tab_name,
        app_id,
      },
    })
  }

  return (
    <div className="cwpf_manage_tabs tabs mt-36 flex justify-between ">
      <ul className="flex tabs b">
        <li className="border-b-blue-100 cwpf_headertabResp">
          <CustomLink
            onClick={() => onTabChange(TABNAMES.OVERVIEW)}
            className="cwpf_manageApp_tabLink pl-[0px] !ml-0"
            to={`/manage-app/${app_id}`}
          >
            <Overview className="bg-red" />
            <b>Overview</b>
          </CustomLink>
        </li>
        <li className="cwpf_headertabResp">
          <CustomLink
            onClick={() => onTabChange(TABNAMES.PERFORMANCE)}
            className="cwpf_manageApp_tabLink"
            to={`/manage-app/${app_id}/performance`}
          >
            <Performance />
            <b>Performance</b>
          </CustomLink>
        </li>
        <li className="cwpf_headertabResp">
          <CustomLink
            onClick={() => onTabChange(TABNAMES.SECURITY)}
            className="cwpf_manageApp_tabLink"
            to={`/manage-app/${app_id}/security`}
          >
            <Security />
            <b>Security</b>
          </CustomLink>
        </li>
        <li className="cwpf_headertabResp">
          <CustomLink
            onClick={() => onTabChange(TABNAMES.WORKFLOW_TOOLS)}
            className="cwpf_manageApp_tabLink"
            to={`/manage-app/${app_id}/workflow-tools`}
          >
            <Tools />
            <b>Workflow/Tools</b>
          </CustomLink>
        </li>
        <li className="cwpf_headertabResp">
          <CustomLink
            // onClick={e => e.preventDefault()}
            // className="cwpf_manageApp_tabLink opacity-50 disabled"
            onClick={() => onTabChange(TABNAMES.INTELLIGENCE)}
            className="cwpf_manageApp_tabLink"
            to={`/manage-app/${app_id}/intelligence`}
          >
            <Intelligence />
            <b>Intelligence</b>
          </CustomLink>
        </li>
      </ul>
      <ul className="tabs between">
        <li className="">
          <CustomLink
            onClick={() => onTabChange(TABNAMES.MANAGE_ACCESS)}
            className="cwpf_manageApp_tabLink"
            to={`/manage-app/${app_id}/manage-access`}
          >
            <ManageAccess />
            <b>Manage Access</b>
          </CustomLink>
        </li>
      </ul>
    </div>
  )
}

export default HeaderTabs
