/** Libraries */
import {useTracking} from 'react-tracking'
/** Reusable Components */
import {Container} from 'Common/Components/Core/DesignTemplates'
import SideBar from './SideBar'

/** Helpers */
import {iManageAccessTrackingObj} from './util'

/** Assets (CSS/Images) */
import './styles/ManageAccess.css'

export default function ManageAccess() {
  const {Track} = useTracking<iManageAccessTrackingObj>({
    attributes: {
      tab_name: 'manage_access',
    },
  })
  return (
    <Track>
      <Container styles="cwpf_manageAccess">
        <SideBar />
      </Container>
    </Track>
  )
}
